export interface PaginationProps {
    offset: number
    limit: number
    lengthData: number
    metaTotal: number
    setOffset: (offset:number) => void
    setLimit: (limit:number) => void
}

const Pagination: React.FC<PaginationProps> = ({ offset, limit, lengthData, metaTotal,  setOffset, setLimit }) => {
    const handleDownPage = () => {
        setOffset(offset - limit)
    }

    const handleUpPage = () => {
        setOffset(offset + limit)
    }
    const setRows = (e: any) => {
        let value = e.target.value
        setLimit(Number(value))
        setOffset(0)
    }

    return (
        <div className="h-20 w-full flex justify-between items-center px-8 border-2 border-t-0 border-stroke">
            <div className="flex items-center">
                <h1 className="text-base">Rows</h1>
                <select onChange={setRows} name="rows" value={limit} className="h-12 ml-4 focus:outline-none text-base cursor-pointer">
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                </select>
            </div>

            <div className="flex flex-col items-center my-12">
                <div className="flex items-center">
                    <div className="h-12 w-12 mr-1 flex justify-center items-center rounded-full">
                        {offset === 0 ?
                            <h1 className="text-base text-gray-400 cursor-default">Prev</h1> : <h1 onClick={handleDownPage} className="text-base text-black cursor-pointer hover:text-opacity-70">Prev</h1>}
                    </div>
                    <div className="flex h-12 font-medium rounded-full">
                        <div className="w-12 md:flex justify-center items-center hidden cursor-default leading-5 transition duration-150 ease-in rounded-full text-base">{offset / limit + 1}</div>
                    </div>
                    <div className="h-12 w-12 ml-1 flex justify-center items-center rounded-full">
                        {lengthData === metaTotal - offset ?
                            <h1 className="text-base text-gray-400 cursor-default">Next</h1> : <h1 onClick={handleUpPage} className="text-base text-black cursor-pointer hover:text-opacity-70">Next</h1>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Pagination;