import moment from "moment";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../components/atoms/breadcrumb/Breadcrumb";
// import TextArea from "../../components/atoms/input/TextArea";
// import Loading from "../../components/atoms/loading/Loading";
import MproductListCard from "../../components/molecules/MproductListCard";
import { renderStatusColor } from "../../components/molecules/MtransactionCard";
import { ERetailTransactionStatus } from "../../core/retail_transaction/entities";
import {
  useFetchRetailTransaction,
  useFetchRetailTransactionTrack,
} from "../../hooks/retail_transaction";
import { formatRupiah } from "../../helpers/common";
import { Aloading } from "../../components/atoms/Aloading";
import Abutton from "../../components/atoms/Abutton";
import OgenerateShippingLabel from "../../components/organisms/transaction_product/OgenerateShippingLabel";

export const retailTransactionRoute = (code?: string) => {
  return [
    {
      id: 1,
      path: "/transaction-product",
      name: "Transaction (Product)",
    },
    {
      id: 2,
      path: `/transaction-product/${code}`,
      name: "Detail",
    },
  ];
};

const TransactionProductDetail: React.FC = () => {
  const params = useParams();
  const { data, isError, isLoading } = useFetchRetailTransaction(
    params.code || ""
  );
  const { data: dataTrack, isLoading: loadTrack } =
    useFetchRetailTransactionTrack(params.code || "");
  const [openGenerateShippingLabelDialog, setOpenGenerateShippingLabelDialog] =
    useState(false);

  if (isError) {
    return <h1>Something went wrong! Please refresh the page</h1>;
  } else if (isLoading) {
    return (
      <div className="w-full flex justify-center mt-20">
        <Aloading type="Bars" />
      </div>
    );
  } else if (data) {
    return (
      <main className="-mt-1">
        <aside>
          {<Breadcrumb data={retailTransactionRoute(params.code)} />}
        </aside>

        {data && openGenerateShippingLabelDialog && (
          <OgenerateShippingLabel
            isOpen={openGenerateShippingLabelDialog}
            setOpen={setOpenGenerateShippingLabelDialog}
            data={data}
          />
        )}

        <main className="w-full">
          <section className="mt-5">
            <article>
              <h1 className="text-lg font-Bold">Transaction Details</h1>
            </article>
          </section>
          <section className="mt-5 w-full h-full lg:flex gap-[2%]">
            <aside className="lg:w-2/3 sm:w-11/12 w-full h-auto">
              {/* <div
                id="review"
                className="rounded-lg shadow-md bg-white p-5 mb-5"
              >
                <article>
                  <h1 className="text-textPrimary">Review from Customer</h1>
                  <hr className="mt-4" />
                  <h1 className="my-4 text-textPrimary">Message</h1>
                  <TextArea name="text" />
                </article>
              </div> */}
              {data.status === ERetailTransactionStatus.BuyerComplain && (
                <div
                  id="complain"
                  className="rounded-lg shadow-md bg-white p-5 mb-5"
                >
                  <article>
                    <h1 className="text-textPrimary">Complain</h1>
                    {/* <hr className="mt-4" />
                    <h1 className="my-4 text-textPrimary">Photo or videos</h1>
                    <div className="grid grid-cols-10 my-5">
                      {data.retail_transaction_complain.image_path ? (
                        data.retail_transaction_complain.image_path
                          .split(",")
                          .map((el, idx) => <img key={idx} src={el} alt="-" />)
                      ) : (
                        <h1 className="text-xs text-textSecondary col-span-2">No photo or videos uploaded</h1>
                      )}
                    </div> */}
                    <hr className="mt-4" />
                    <h1 className="my-4 text-textPrimary">Message</h1>
                    {/* <TextArea
                      name="text"
                      value={data.retail_transaction_complain.message}
                    /> */}
                  </article>

                  {/* <hr className="mt-4" />

<article className="mt-7 flex items-center justify-end">
<div className="flex gap-3">
<Abutton title="Reject" theme="secondary" model="long" />
<Abutton
title="Accept"
theme="primary"
                      model="long"
                      //   onClick={() => goDetail(3)}
                    />
                    </div>
                  </article> */}
                </div>
              )}
              <div id="info" className="rounded-lg shadow-md bg-white p-5">
                <article className="w-full ">
                  <div className="flex">
                    <div className="w-1/2 text-sm">
                      <h1 className="text-textPrimary">Transaction ID</h1>
                      <h1 className="text-textSecondary mt-1.5">
                        {data?.code}
                      </h1>
                    </div>
                    <div className="w-1/2 text-sm">
                      <h1 className="text-textPrimary">Status</h1>
                      <h1
                        className={"mt-1.5 " + renderStatusColor(data.status)}
                      >
                        {data?.status_message}
                      </h1>
                    </div>
                  </div>
                  <hr className="mt-4" />
                </article>

                <article className="mt-4">
                  <div className="w-full">
                    <h1 className="text-textPrimary">Product</h1>
                  </div>
                  <div className="grid grid-cols-2 gap-5 mt-4">
                    <MproductListCard
                      quantity={data.quantity}
                      image={data.retail_product_detail.display_image_path}
                      name={data.retail_product_detail.name}
                      variant={
                        data.retail_product_detail.retail_product_stock.label
                      }
                      border={true}
                    />
                  </div>
                  <hr className="mt-5" />
                </article>

                <article className="mt-4">
                  <div className="w-full">
                    <h1 className="text-textPrimary">Address</h1>
                  </div>
                  <div className="mt-1.5 font-rom text-sm text-textPrimary w-full">
                    <p>
                      {data.buyer_shipping_address.recipient} |{" "}
                      {data.buyer_shipping_address.phone}
                    </p>
                    <p className="mt-0.5">
                      {data.buyer_shipping_address.address},{data.buyer_shipping_address.sub_district},{data.buyer_shipping_address.district},{data.buyer_shipping_address.city},{data.buyer_shipping_address.province},{data.buyer_shipping_address.country}, {data.buyer_shipping_address.zip_code}
                    </p>
                  </div>
                  <hr className="mt-4" />
                </article>

                <article className="mt-4">
                  <div className="w-full">
                    <h1 className="text-textPrimary">
                      Courier <span className="font-rom">(Must Same)</span>
                    </h1>
                  </div>
                  <div className="mt-1.5 font-rom text-sm text-textPrimary w-full">
                    <p>
                      {data.shipping_courier_name} - Reguler{" "}
                      <span className="text-textSecondary">
                        ({formatRupiah(data.buyer_shipping_cost)})
                      </span>
                    </p>
                  </div>
                </article>
                <hr className="mt-5" />

                <article className="pt-7 flex items-center justify-end">
                  {/* <div className="flex items-center font-rom text-sm">
                    <span>Get Shipping Label</span>
                  </div> */}

                  <div className="flex gap-3">
                    {/* <Abutton
                      title="Cancel Transaction"
                      theme="secondary"
                      model="long"
                    /> */}

                    {data.status ===
                      ERetailTransactionStatus.WaitingTrackingCode && (
                      <Abutton
                        title="Receive Order"
                        theme="primary"
                        model="long"
                        onClick={() =>
                          setOpenGenerateShippingLabelDialog(
                            !openGenerateShippingLabelDialog
                          )
                        }
                      />
                    )}
                  </div>
                </article>
              </div>

              <div
                id="invoice"
                className="rounded-lg shadow-md bg-white p-5 mt-5"
              >
                <article className="w-full">
                  <div className="flex justify-between w-full text-sm">
                    <div className="w-1/2 flex justify-start gap-1">
                      <h1 className="text-textPrimary">Customer :</h1>
                      <h1 className="text-textPrimary font-rom">
                        {data.buyer_username}
                      </h1>
                    </div>
                    <div className="w-1/2 flex justify-end gap-1 font-rom">
                      <h1 className="text-textPrimary">Transaction Date :</h1>
                      <h1 className="text-textPrimary">
                        {moment(data.created_at).format("llll")}
                      </h1>
                    </div>
                  </div>
                  <hr className="mt-4" />
                </article>
                <article className="mt-4 flex flex-col">
                  <div className="flex bg-stroke rounded-md px-4 py-2 font-rom text-sm">
                    <div className="w-2/5">Product</div>
                    <div className="w-1/5 flex justify-end">Price(IDR)</div>
                    <div className="w-1/5 flex justify-end">Quantity</div>
                    <div className="w-1/5 flex justify-end">Subtotal(IDR)</div>
                  </div>
                  <div>
                    {
                      <div className="">
                        <div className="flex px-4 py-2 font-rom my-2.5">
                          <div className="w-2/5">
                            <MproductListCard
                              quantity={data.quantity}
                              image={
                                data.retail_product_detail.display_image_path
                              }
                              name={data.retail_product_detail.name}
                              variant={
                                data.retail_product_detail.retail_product_stock
                                  .label
                              }
                            />
                          </div>
                          <div className="w-1/5 flex justify-end text-sm">
                            <span>{formatRupiah(data.price)}</span>
                          </div>
                          <div className="w-1/5 flex justify-end text-sm">
                            <span>{data.quantity}</span>
                          </div>
                          <div className="w-1/5 flex justify-end text-sm">
                            <span>
                              {formatRupiah(data.price * data.quantity)}
                            </span>
                          </div>
                        </div>
                        <hr />
                      </div>
                    }
                  </div>
                  <div className="flex justify-end gap-2 w-1/2 mt-4 self-end">
                    <div className="w-1/2 border-r b-border-stroke flex flex-col gap-y-1.5 font-rom text-sm">
                      <h1>Total Order</h1>
                      <h1>Shipping Fee</h1>
                      <h1 className="font-med">Total Selling</h1>
                    </div>
                    <div className="w-1/2 flex flex-col items-end gap-y-1.5 font-rom text-sm pr-4">
                      <h1>{formatRupiah(data.price * data.quantity)}</h1>
                      <h1>{formatRupiah(data.buyer_shipping_cost)}</h1>
                      <h1 className="font-med text-primary">
                        {formatRupiah(data.total)}
                      </h1>
                    </div>
                  </div>
                </article>
              </div>
            </aside>

            <aside className="rounded-lg shadow-md bg-white lg:w-1/3 sm:w-11/12 w-full lg:mt-0 mt-5 h-fit">
              {loadTrack ? (
                <div className="my-20 flex justify-center">
                  <Aloading type="Bars" />
                </div>
              ) : (
                <div className="p-5">
                  <article>
                    <h1>Track Order</h1>
                  </article>
                  <hr className="mt-4" />
                  {dataTrack?.[0]?.history.map((el, idx) => (
                    <article key={idx} className="my-4 font-rom text-sm">
                      <div>
                        <h1>{el.status}</h1>
                      </div>
                      <div>
                        <h1 className="text-textSecondary">
                          {moment(el.date_time).format("llll")}
                        </h1>
                      </div>
                      <hr className="my-3" />
                    </article>
                  ))}
                </div>
              )}
            </aside>
          </section>
        </main>
      </main>
    );
  }

  return <div></div>;
};

export default TransactionProductDetail;
