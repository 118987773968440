import { PRetailShop } from "../../core/retail_shop/entities";
import RetailShopRepository from "../../core/retail_shop/repository/repository";
import RetailShopUsecase from "../../core/retail_shop/usecase/usecase";

export class RetailShopService {
  static getRetailShop = async () => {
    try {
      const retailShopRepo = new RetailShopRepository();
      const retailShopUsecase = new RetailShopUsecase(retailShopRepo);
      const retailShop = await retailShopUsecase.getRetailShop();
      return retailShop;
    } catch (error) {
      console.log(error);
    }
  };

  static updateRetailShop = async (shop: PRetailShop) => {
    try {
      const retailShopRepo = new RetailShopRepository();
      const retailShopUsecase = new RetailShopUsecase(retailShopRepo);
      const retailShop = await retailShopUsecase.updateRetailShop(shop);
      return retailShop;
    } catch (error) {
      console.log(error);
    }
  };
}
