import axios from "../../../../config/axiosInstance";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

//icons
import { cancel } from "../../../../assets/icons";

//components
import Input from "../../../../component/input/Input";
import Handler from "../../../../component/toast/Handler";

interface IProps {
  setShowWithdraw: (showWithdraw: boolean) => void;
  showWithdraw: boolean;
  setSuccess: (showWithdraw: boolean) => void;
  success: boolean;
  refetch: any
}

const WithdrawCheck: React.FC<IProps> = ({ setShowWithdraw, showWithdraw, success, setSuccess, refetch }) => {
  const [withdraw, setWithdraw] = useState<string>("");
  const navigate = useNavigate();
  
  const goBack = () => {
    setShowWithdraw(!showWithdraw);
  };

  const handleInputChange = (e: any) => {
    const target = e.target;
    setWithdraw(target.value);
  };

  const goSave = async () => {
    if (withdraw === "Withdraw") {
      const result = await axios()
        .post(`retail/retail-withdraw-request`)
        .then((data) => {
          refetch()
          setSuccess(!success);
          setShowWithdraw(!showWithdraw);
        })
        .catch((err) => Handler("error", "Withdraw Failed!"));
    } else {
      Handler("error", "Withdraw Failed!");
    }
  };

  return (
      <div className="w-full min-h-full font-med bg-black bg-opacity-70 inset-0 fixed z-50 flex justify-center items-center">
        <div className="md:w-WModal md:h-Hmodal md:py-6 py-4 bg-white rounded-md shadow-md absolute">
          <div className="flex justify-between md:px-10 px-5">
            <div className="flex items-center">
              <h1 className="md:text-lg flex text-primary">Withdraw</h1>
              <h1 className="text-black ml-1.5">Confirmation</h1>
            </div>
            <img
              src={cancel}
              alt="cancel"
              onClick={goBack}
              className="cursor-pointer md:w-5 w-3"
            />
          </div>
          <hr className="my-5" />
          <div className="flex flex-col md:mt-7 mt-0 md:px-10 px-5">
            <h1 className="md:text-base text-sm font-medium text-textPrimary">
              Please write 'Withdraw'
            </h1>
            <Input
              type={"text"}
              placeholder={"Place holder"}
              handleChange={handleInputChange}
              name={"withdraw"}
            />
          </div>
          <div className="flex justify-center mt-10 px-20">
            <button
              onClick={goSave}
              className="bg-primary md:w-36 w-28 md:h-10 h-8 rounded-full focus:outline-none hover:bg-opacity-60"
            >
              <h1 className="text-white md:text-lg text-sm">Submit</h1>
            </button>
          </div>
        </div>
      </div>
  );
};

export default WithdrawCheck;
