import React from "react";
import { useQuery } from "react-query";
import { Link,  useParams } from "react-router-dom";
import Loading from "../../../../component/loading/Loading";
import axios from "../../../../config/axiosInstance";

// components
import Complain from "./revenueDetailComponent/Complain";
import Information from "./revenueDetailComponent/Information";
import Pricing from "./revenueDetailComponent/Pricing";
import Shipping from "./revenueDetailComponent/Shipping";

function RevenueDetailTrans() {
  const params = useParams();

  const getTransactionsByCode = async () => {
    try {
      const results = await axios().get(
        `retail/retail-transaction/${params.code}/`
      );
      const result = results.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const { data, isLoading } = useQuery(
    "getRevenueDetail",
    getTransactionsByCode
  );

  return (
    <>
      <div className="bg-white font-med p-2">
        <div>
          <ul className="flex text-breadscrumbColor md:text-base text-sm">
            <li className="inline-flex items-center">
              <Link to="/admin/home" className="hover:text-black">
                Home
              </Link>
            </li>
            <li className="inline-flex items-center">
              <svg className="h-5 w-auto" viewBox="0 0 20 20" fill="#000000">
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <Link to="/admin/revenues" className="hover:text-black">
                Revenues
              </Link>
            </li>
            <li className="inline-flex items-center">
              <svg className="h-5 w-auto" viewBox="0 0 20 20" fill="#000000">
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <Link
                className="text-black"
                to={`/admin/revenues/${params.code}`}
              >
                Details
              </Link>
            </li>
          </ul>
        </div>
        {isLoading ? (
          <div className="flex justify-center mt-10">
            <Loading />
          </div>
        ) : (
          <div className="w-full h-full md:my-14 my-8 md:flex justify-between">
            {/* Product Images */}
            <div className="md:w-1/3 w-full h-full rounded-md border-2 border-stroke">
              <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
                <h1 className="font-medium md:text-xl text-base">
                  Product Images
                </h1>
              </div>
              <hr />

              <div className="md:mx-8 mx-5 md:my-7 my-5">
                <div className="flex flex-col justify-center">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Display Images
                  </h1>
                  <div className="md:w-full flex flex-col items-center">
                    <img
                      className="my-3 rounded-md shadow-md w-44"
                      src={data?.retail_product_detail.display_image_path}
                      alt="display_image"
                    />
                  </div>
                </div>
                <div className="mt-6 flex flex-col justify-center">
                  <h1 className="md:text-base text-sm">Detail Images</h1>
                  <div className="grid grid-cols-3 md:gap-3 gap-2 justify-center h-auto">
                    {data?.retail_product_detail.detail_image_path.length <=
                    0 ? (
                      <h1 className="my-5">No images uploaded</h1>
                    ) : (
                      data?.retail_product_detail.detail_image_path
                        ?.split(",")
                        .map((el: string | undefined) => (
                          <div className="flex justify-start my-2">
                            <img
                              className="w-full rounded-md shadow-md text-xs"
                              src={el}
                              alt="detail_images"
                            />
                          </div>
                        ))
                    )}
                  </div>
                </div>
                <div className="mt-6 flex flex-col justify-center ">
                  <h1 className="md:text-base text-sm">Description Images</h1>
                  <div className="grid grid-cols-3 md:gap-3 gap-2 justify-center h-auto">
                    {data?.retail_product_detail.description_image_path
                      .length <= 0 ? (
                      <h1 className="my-5">No images uploaded</h1>
                    ) : (
                      data?.retail_product_detail.description_image_path
                        ?.split(",")
                        .map((el: string | undefined) => (
                          <div className="flex justify-start my-2">
                            <img
                              className="w-full rounded-md shadow-md text-xs"
                              src={el}
                              alt="description_images"
                            />
                          </div>
                        ))
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="md:w-custom md:mt-0 mt-10 w-full h-full">
              {/* transactions information */}
              <Information data={data} />

              {/* Pricing information */}
              <Pricing data={data} />

              {/* Shipping information */}
              <Shipping data={data} />

              {/* Complain information */}
              <Complain data={data} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default RevenueDetailTrans;
