import { FC } from "react";
import { IIconProps } from "./Ahome";

export const Awallet: FC<IIconProps> = ({
  stroke = "#666666",
  width = "25",
  height = "23",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 3.64966V19.6497C1 20.1801 1.21071 20.6888 1.58579 21.0639C1.96086 21.4389 2.46957 21.6497 3 21.6497H23C23.2652 21.6497 23.5196 21.5443 23.7071 21.3568C23.8946 21.1692 24 20.9149 24 20.6497V6.64966C24 6.38444 23.8946 6.13009 23.7071 5.94255C23.5196 5.75502 23.2652 5.64966 23 5.64966H3C2.46957 5.64966 1.96086 5.43894 1.58579 5.06387C1.21071 4.6888 1 4.18009 1 3.64966ZM1 3.64966C1 3.11923 1.21071 2.61052 1.58579 2.23544C1.96086 1.86037 2.46957 1.64966 3 1.64966H20"
        stroke={stroke}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19.9375 13.6497C19.9375 14.4436 19.2939 15.0872 18.5 15.0872C17.7061 15.0872 17.0625 14.4436 17.0625 13.6497C17.0625 12.8557 17.7061 12.2122 18.5 12.2122C19.2939 12.2122 19.9375 12.8557 19.9375 13.6497Z"
        fill="#666666"
        stroke={stroke}
        stroke-width="0.125"
      />
    </svg>
  );
};
