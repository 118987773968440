import moment from "moment";
import React, { useContext } from "react";
import { useQuery } from "react-query";
import { TicketRequest } from "../../../../../../api/ticket/Ticket";
import { cancel } from "../../../../../../assets/icons";
import CircularLoad from "../../../../../../component/loading/CircularLoad";
import { ActionType } from "../../../../../../store/ticket/action";
import TicketContext from "../../../../../../store/ticket/context";

const ScanQrModal = () => {
  const { TicketDispatch, TicketState } = useContext(TicketContext);
  const { showScanQr } = TicketState;
  const { data, isLoading } = useQuery(
    [`getTicketVariant`],
    () => TicketRequest.getScanHistory(showScanQr.id.toString()),
    {
      refetchOnWindowFocus: false,
    }
  );

  const goBack = () => {
    TicketDispatch({
      type: ActionType.ShowScanQr,
      payload: {
        id: 0,
        status: false,
      },
    });
  };

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white md:w-1/4 lg:w-1/3 w-10/12 h-auto rounded-xl my-auto mx-auto font-med">
        <div className="flex w-full justify-between items-center px-10 my-7">
          <h1 className="text-xl">Scan History</h1>
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-5"
            onClick={goBack}
          />
        </div>
        <hr />
        {isLoading ? (
          <div className="flex justify-center items-center my-20">
            <CircularLoad color={"#DF5354"} height={40} width={40} />
          </div>
        ) : (
          <section>
            <div className="px-10 my-10 h-full">
              {data?.length <= 0 ? (
                <div className="flex flex-col items-center">Empty Data</div>
              ) : (
                <div className="overflow-y-auto h-64">
                  {data?.map((el: any) => (
                    <div>
                      <div className="flex items-center">
                        <div className="rounded-full w-8 h-8 bg-success" />
                        <h1 className="ml-5">
                          {moment(el.created_at).format("LL HH:mm:ss")}
                        </h1>
                      </div>
                      <hr className="my-5" />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

export default ScanQrModal;
