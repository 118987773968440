import React, { useState } from "react";
import { useNavigate } from "react-router";
import axios from "../../config/axiosInstance";

import { padlock } from "../../assets/icons";
import Backdrop from "./login/Backdrop";
import Input from "../../component/input/Input";
import Handler from "../../component/toast/Handler";

function ForgetPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>("");
  const handleChangeInput = (e: any) => {
    setEmail(e.target.value);
  };

  const handleForgetPassword = (e: any) => {
    e.preventDefault();
    try {
      axios()
        .post("retail/auth/forget-password", {
          email: email,
        })
        .then((data) => {
          Handler('success', 'Please check your email!')
          navigate("/login");
        })
        .catch((err) => Handler('error', 'Account is not exist!'));
    } catch (error) {
      console.log(error);
    }
  };

  const goBack = () => {
    navigate("/login");
  };

  return (
    <div>
      <Backdrop active={true} />
      <div className="w-full h-screen flex items-center justify-center font-med">
        <div className="flex flex-col bg-white rounded-md md:w-Wlogin md:h-Hlogin w-4/5">
          <form onSubmit={handleForgetPassword}>
            <div className="md:mt-7 mt-8 md:mb-8 mb-8 flex flex-col justify-center items-center">
              <img alt="" src={padlock} className="md:w-20 w-10" />
              <div className="flex flex-col items-center md:mt-8 mt-3">
                <h1 className="text-textPrimary md:text-xl text-base">
                  Forgot Your Password ?
                </h1>
                <h1 className="md:text-sm text-xs font-rom mt-1.5 text-textPrimary">
                  We will send futher instruction to your email.
                </h1>
              </div>
            </div>

            <div className="md:mx-14 mx-8 mb-5">
              <div className="">
                <h1 className="md:text-sm text-xs text-textPrimary">Email</h1>
                <Input
                  required={true}
                  placeholder={"email"}
                  type={"email"}
                  name={"email"}
                  handleChange={handleChangeInput}
                />
              </div>
            </div>
            <div className="flex justify-center md:mt-8 mt-8 md:mb-0 mb-5 mx-14">
              <button
                type="button"
                className="rounded-full md:w-36 w-28 md:h-9 h-7 md:mr-5 md:inline-block border-2 border-stroke focus:outline-none hover:bg-opacity-50"
              >
                <h1
                  onClick={goBack}
                  className="text-placeholderColor md:text-base text-sm hover:text-black"
                >
                  back
                </h1>
              </button>
              <button
                type="submit"
                className="rounded-full md:w-36 w-28 md:h-9 h-7 ml-2 md:ml-0 bg-primary focus:outline-none hover:bg-opacity-50"
              >
                <h1 className="text-white md:text-base text-sm">Send</h1>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ForgetPassword;
