import React from "react";
import { Route, Routes } from "react-router-dom";
import Revenue from "../../pages/admin/revenue/Revenue";
import RevenueDetail from "../../pages/admin/revenue/revenueComponent/mobileView/RevenueDetail";
import RevenueDetailTrans from "../../pages/admin/revenue/revenueComponent/RevenueDetailTrans";

const RevenueRoute = () => {
  //   const [TicketState, TicketDispatch] = useReducer(
  //     TicketReducer,
  //     initialTicketState
  //   );

  return (
    // <TicketContextProvider value={{ TicketState, TicketDispatch }}>
    <Routes>
      <Route path="/" element={<Revenue />} />
      <Route path="/detail/:code" element={<RevenueDetail />} />
      <Route path=":code" element={<RevenueDetailTrans />} />
    </Routes>
    // </TicketContextProvider>
  );
};

export default RevenueRoute;
