interface IMTrendingCardProps {
  index: number;
  title: string;
  subtitle: string;
  image: string;
}
const MTrendingCard: React.FC<IMTrendingCardProps> = ({
  index,
  subtitle,
  title,
  image,
}) => {
  return (
    <aside>
      <div className={"flex items-center " + (index !== 5 ? "my-5" : "mt-5")}>
        <div className="w-1/12 flex justify-center">
          <h1 className="text-primary text-4xl">{index}</h1>
        </div>
        <div className="w-11/12 flex items-start">
          <div className="w-12 ml-2">
            <img
              src={image}
              alt="-"
              className="w-full rounded-md aspect-square"
            />
          </div>
          <div className="ml-4 truncate">
            <h1 className="truncate">{title}</h1>
            <h1 className="truncate text-textPrimary font-rom">
              Viewers: {subtitle}
            </h1>
          </div>
        </div>
      </div>
      {index !== 5 && <hr />}
    </aside>
  );
};

export default MTrendingCard;
