import React from "react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ERetailTransactionStatus,
  IRetailTransaction,
} from "../../core/retail_transaction/entities";
import { formatRupiah, isMobile } from "../../helpers/common";
import Abutton from "../atoms/Abutton";
import { AfileArrowDown, Atruck } from "../atoms/icons";
import MproductListCard from "./MproductListCard";
import Countdown from "react-countdown";
import Acountdown from "../atoms/Acountdown";

export const renderStatusColor = (status: number) => {
  const statusColor: any = {
    0: "text-success",
    1: "text-success",
    2: "text-success",
    3: "text-warning",
    4: "text-primary",
    5: "text-primary",
    6: "text-primary",
    7: "text-success",
    8: "text-success",
  };
  return statusColor[status];
};
interface IMtransactionCardProps {
  status: number;
  data: IRetailTransaction;
}

const MtransactionCard: React.FC<IMtransactionCardProps> = ({
  status,
  data,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // const [more, setMore] = useState(false);
  // const length = 1;

  const goDetail = (code: string) => {
    navigate(`${pathname}/${code}`);
  };
  const goDetailMobile = (code: string) => {
    navigate(`${pathname}/detail/${code}`);
  };
  const goTrackOrder = (code: string) => {
    navigate(`${pathname}/track/${code}`);
  };

  const isAvailableTrackOrder = (status: number) => {
    if (
      status === ERetailTransactionStatus.ArrivedAtBuyer ||
      status === ERetailTransactionStatus.BuyerComplain ||
      status === ERetailTransactionStatus.Done
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <aside className="w-full bg-white rounded-lg shadow-md sm">
      <main
        onClick={
          isMobile()
            ? () => goDetailMobile(data.code)
            : () => goDetail(data.code)
        }
      >
        <article className="sm:px-5 sm:pt-5 sm:pb-5 px-3 pt-3 pb-0 flex justify-between sm:border-b-2 sm:text-sm text-xxs">
          <div className="sm:flex hidden xl:flex-row flex-col gap-1 w-3/4">
            <span className="text-primary">{data.code}</span>
            <span>| {data.buyer_username} |</span>
            <span className="text-textSecondary">
              Transaction Date : {moment(data.created_at).format("llll")}
            </span>
          </div>

          {data.status === ERetailTransactionStatus.WaitingTrackingCode ? (
            <div className="sm:w-auto w-full gap-1">
              <div className="flex justify-between items-center ">
                <h1 className="font-rom mr-1">Shipping Before:</h1>
                <Countdown
                  zeroPadTime={2}
                  date={data.shipping_input_expired_at}
                  renderer={(props) => (
                    <Acountdown
                      seconds={props.seconds}
                      minutes={props.minutes}
                      hours={props.hours}
                      days={props.days}
                      completed={props.completed}
                    />
                  )}
                />
              </div>
              <hr className="sm:hidden mt-1.5" />
            </div>
          ) : (
            <div className="sm:w-auto w-full gap-1">
              {/* <div className="flex justify-between items-center ">
                <h1 className="font-rom mr-1">Shipping Method:</h1>
               
              </div>
              <hr className="sm:hidden mt-1.5" /> */}
            </div>
          )}
        </article>

        <article className="sm:p-5 p-3">
          <aside className="grid sm:grid-cols-3 grid-cols-1 gap-7">
            <div className="sm:border-r-2 sm:pr-5">
              <MproductListCard
                image={data?.retail_product_detail?.display_image_path}
                name={data.retail_product_detail?.name || "-"}
                variant={
                  data.retail_product_detail?.retail_product_stock?.label
                }
                quantity={data.quantity}
              />
              <hr className="mt-3" />
            </div>

            <div className="sm:flex hidden flex-col border-r-2 pr-5 text-textPrimary text-xs">
              <h1 className="text-sm">Address</h1>
              <p className="font-rom mt-0.5">
                {data.buyer_shipping_address.recipient} |{" "}
                {data.buyer_shipping_address.phone}
              </p>
              <p className="font-rom mt-0.5">
                {data.buyer_shipping_address.address}
              </p>
            </div>

            <div className="pr-5 sm:flex hidden flex-col text-textPrimary text-xs">
              <h1 className="flex text-sm items-center">
                Courier{" "}
                <span className="ml-1 font-rom text-xs">(Must Same)</span>
              </h1>
              <span className="font-rom mt-0.5">
                {data.shipping_courier_name} - Regular (
                {formatRupiah(data.buyer_shipping_cost)})
              </span>
              <h1 className="mt-0.5">Status</h1>
              <span className={"mt-0.5 " + renderStatusColor(data.status)}>
                {data.status_message}
              </span>
            </div>
          </aside>
          <aside className="sm:block hidden my-4">
            <div className="bg-stroke rounded-md py-2.5 px-5 flex justify-between text-xs">
              <h1>Total Selling</h1>
              <h1 className="text-primary">{formatRupiah(data.total || 0)}</h1>
            </div>
          </aside>
          <hr className="sm:block hidden" />
        </article>
      </main>
      <article
        className={
          "flex sm:px-5 px-3 sm:pb-5 pb-3 " +
          (isAvailableTrackOrder(data.status)
            ? "justify-between"
            : "justify-end")
        }
      >
        {isAvailableTrackOrder(data.status) && (
          <div
            onClick={
              isMobile()
                ? () => goDetailMobile(data.code)
                : () => goDetail(data.code)
            }
            className="sm:flex hidden items-center gap-5 font-rom sm:text-xs text-xxs"
          >
            <div className="flex items-center gap-2 hover:text-textPrimary cursor-pointer">
              <AfileArrowDown />
              <span>Get Shipping Label</span>
            </div>
            <div className="sm:flex hidden items-center gap-2 hover:text-textPrimary cursor-pointer">
              <Atruck />
              <span>Track Order</span>
            </div>
          </div>
        )}
        <div className="sm:flex hidden sm:gap-3">
          {data.status === ERetailTransactionStatus.BuyerComplain && (
            <Abutton
              title="Complain Details"
              theme="secondary"
              model="long"
              onClick={() => goDetail(data.code)}
            />
          )}
          <Abutton
            title="Details"
            theme="primary"
            model="long"
            onClick={() => goDetail(data.code)}
          />
        </div>

        {isAvailableTrackOrder(data.status) && (
          <div className="sm:hidden flex justify-center w-full">
            <h1
              onClick={() => goTrackOrder(data.code)}
              className="text-xxs cursor-pointer hover:text-primary"
            >
              Track Order
            </h1>
          </div>
        )}

        {data.status === ERetailTransactionStatus.BuyerComplain && (
          <div className="sm:hidden flex justify-center w-full border-l">
            <h1
              onClick={() => goDetailMobile(data.code)}
              className="text-xxs cursor-pointer hover:text-primary"
            >
              Complain Details
            </h1>
          </div>
        )}
      </article>
    </aside>
  );
};

export default MtransactionCard;
