import React from "react";
import { Aloading } from "../../components/atoms/Aloading";
import MChart from "../../components/molecules/MChart";
import MhomeCard from "../../components/molecules/MhomeCard";
import MTrendingCard from "../../components/molecules/MtrendingCard";
import { IRetailProduct } from "../../core/retail_product/entities";
import { useFetchRetailStatistic } from "../../hooks/retail_statistic";

const Home = () => {
  const { data, isLoading } = useFetchRetailStatistic();
  return (
    <>
      {isLoading ? (
        <div className="w-full h-full container flex justify-center mx-auto my-20">
          <Aloading type="Bars" />
        </div>
      ) : (
        <main className="w-full h-full container mx-auto">
          <section>
            <aside>
              <h1 className="font-Bold text-lg">Important Today's</h1>
              <span className="text-textPrimary">
                Activities you need to monitor to maintain buyer satisfaction
              </span>
            </aside>

            {data && (
              <aside className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 md:gap-5 gap-4 mt-8">
                <MhomeCard
                  title="New Order"
                  subtitle={data?.new_order}
                  pathname="/transaction-product"
                  search="status=0"
                />
                <MhomeCard
                  title="Complain Order"
                  subtitle={data?.complained_order}
                  pathname="/transaction-product"
                  search="status=3"
                />
                <MhomeCard
                  title="Total Product Sold"
                  subtitle={data?.total_sold}
                  pathname="/transaction-product"
                />
                <MhomeCard
                  title="Total Ticket Sold"
                  subtitle={0}
                  pathname="/transaction-ticket"
                />
              </aside>
            )}
          </section>

          <section className="mt-10">
            <aside>
              <h1 className="font-Bold text-lg">
                Analyze your store and products
              </h1>
              <span className="text-textPrimary">
                You can see your Store Traffic and your Most Trending Product
              </span>
            </aside>

            <aside className="grid grid-cols-1 md:grid-cols-2 md:gap-5 gap-4 mt-8">
              <article>
                <MChart />
              </article>
              <article>
                <div
                  className={
                    "bg-white rounded-lg shadow-md p-7 md:mt-0 mt-4 w-auto duration-500 h-[62%]"
                    // (showSidebar
                    //   ? "3xl:h-[41.5vh] 2xl:h-[44.5vh] xl:h-[35vh] h-[33vh]"
                    //   : "3xl:h-[47vh] 2xl:h-[50vh] xl:h-[40vh] h-[35vh]")
                  }
                >
                  <aside className="w-full flex justify-between">
                    <h1>Trending Products</h1>
                    <h1>2022</h1>
                  </aside>

                  <aside className="no-scrollbar mt-1 overflow-auto w-full pb-5 h-[460px]">
                    {data?.trending_product.map(
                      (el: IRetailProduct, idx: number) => (
                        <MTrendingCard
                          key={idx}
                          index={idx + 1}
                          image={el.display_image_path}
                          title={el.name}
                          subtitle={el.view_count.toString()}
                        />
                      )
                    )}
                  </aside>
                </div>
              </article>
            </aside>
          </section>
        </main>
      )}
    </>
  );
};

export default Home;
