import React from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { EAllowedRoles } from "../core/auth/entities";
import ProtectedRoutes from "../helpers/ProtectedRoutes";
import RequireAuth from "../helpers/RequireAuth";
import { Profile } from "../pages/admin/profile";
import Revenue from "../pages/admin/revenue/Revenue";
import RevenueDetail from "../pages/admin/revenue/revenueComponent/mobileView/RevenueDetail";
import RevenueDetailTrans from "../pages/admin/revenue/revenueComponent/RevenueDetailTrans";
import Scan from "../pages/admin/scan/Scan";
import WalletMutation from "../pages/admin/walletMutation/WalletMutation";
import ForgetPassword from "../pages/auth/ForgetPassword";
import LoginPage from "../pages/auth/LoginPage";
import NotFoundPage from "../pages/auth/NotFoundPage";
import ResetPassword from "../pages/auth/ResetPassword";
import UnauthorizedPage from "../pages/auth/UnauthorizedPage";
import Home from "../pages/home/Home";
import ProductRoute from "./product/ProductRoute";
import RequestWithdrawRoute from "./request_withdraw/RequestWithdrawRoute";
import RevenueRoute from "./revenue/RevenueRoute";
import SubAccountRoute from "./sub_account/SubAccountRoute";
import TicketRoute from "./ticket/TicketRoute";
import TransactionProductRoute from "./transaction_product/TransactionProductRoute";
import TransactionTicketRoute from "./transaction_ticket/TransactionTicketRoute";

const MainRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<Outlet />}>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="reset-password/:token" element={<ResetPassword />} />
        <Route path="forget-password" element={<ForgetPassword />} />
        <Route path="unauthorized" element={<UnauthorizedPage />} />

        <Route path="/" element={<ProtectedRoutes />}>
          <Route
            element={<RequireAuth allowedRoles={[EAllowedRoles.RETAIL]} />}
          >
            <Route path="home" element={<Home />} />
            <Route path="profile" element={<Profile />} />
            <Route path="admin/ticket/*" element={<TicketRoute />} />
            <Route path="admin/products/*" element={<ProductRoute />} />
            <Route path="admin/products/*" element={<ProductRoute />} />
            <Route path="admin/revenues/*" element={<RevenueRoute />} />
            <Route path="/admin/scan/*" element={<Scan />} />
            <Route
              path="/admin/request-withdraw/*"
              element={<RequestWithdrawRoute />}
            />
            <Route
              path="/admin/wallet-mutation/*"
              element={<WalletMutation />}
            />
            <Route
              path="/transaction-product/*"
              element={<TransactionProductRoute />}
            />
            <Route
              path="/transaction-ticket/*"
              element={<TransactionTicketRoute />}
            />
            <Route path="/admin/sub-account/*" element={<SubAccountRoute />} />
          </Route>
          <Route path="/" element={<Navigate replace to="/home" />} />
        </Route>
      </Route>
    </Routes>
  );
};

export default MainRoute;
