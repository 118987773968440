import { useNavigate, useParams } from "react-router";
import { next } from "../../../../../../assets/icons";

export interface ProductVariantListProps {
  data: any;
}

const ProductVariantList: React.FC<ProductVariantListProps> = ({ data }) => {
  const navigate = useNavigate();
  const params = useParams()
  const dataStock = data?.retail_product_stocks;

  const goDetailProductVariant = (detail:number) => {
    navigate(`/admin/products/${params.id}/stock-product/${detail}`)
  };

  return (
    <div className="w-full mb-12">
      {dataStock.map((el: any) => (
        <div key={el.id}>
          <div
            className="flex h-20 items-center"
            onClick={() => goDetailProductVariant(el.id)}
          >
            <div>
              <img
                src={data.display_image_path}
                className="w-18 h-18 shadow-sm rounded-md"
                alt="img_product"
              />
            </div>
            <div className="flex ml-4 items-center justify-around w-3/4">
              <div className="flex flex-col">
                <div className="w-56">
                  <h1 className="text-sm">Variant: {el.label}</h1>
                </div>
                <div className="flex flex-col mt-0.5">
                  <h1 className="text-sm">Status :</h1>
                  {el.stock > 0 && el.is_pre_order === true ? (
                    <div className="flex justify-start items-center">
                      <h1 className="text-sm text-success">Ready Stock</h1>
                      <h1 className="text-xs text-black ml-1">
                        (Allow Pre-Order)
                      </h1>
                    </div>
                  ) : el.stock > 0 && el.is_pre_order === false ? (
                    <h1 className="text-sm text-success">Ready Stock</h1>
                  ) : el.stock <= 0 && el.is_pre_order === false ? (
                    <h1 className="text-sm text-primary">Out Of Stock</h1>
                  ) : el.stock <= 0 && el.is_pre_order === true ? (
                    <h1 className="text-sm text-yellow-300">Pre Order</h1>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div>
                <img src={next} alt="imgs" className="w-3 h-3" />
              </div>
            </div>
          </div>
          <hr className="my-4" />
        </div>
      ))}
    </div>
  );
};

export default ProductVariantList;
