import { IRetailShop, PRetailShop } from "../entities";
import IRetailShopRepository from "../repository";
import IRetailShopUsecase from "../usecase";

export default class RetailShopUsecase implements IRetailShopUsecase {
  private retailShopRepo: IRetailShopRepository;

  constructor(retailShopRepo: IRetailShopRepository) {
    this.retailShopRepo = retailShopRepo;
  }

  async getRetailShop(): Promise<IRetailShop> {
    const retailShop = await this.retailShopRepo.getRetailShop();
    return retailShop;
  }

  async updateRetailShop(shop: PRetailShop): Promise<IRetailShop> {
    const retailShop = await this.retailShopRepo.updateRetailShop(shop);
    return retailShop;
  }
}
