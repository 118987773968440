import React, { useContext } from "react";
import { useQuery } from "react-query";
import { useNavigate, useLocation } from "react-router";
import { TicketRequest } from "../../../api/ticket/Ticket";
import DynamicBreadcrumb from "../../../component/breadcrumb/DynamicBreadcrumb";
import CircularLoad from "../../../component/loading/CircularLoad";
import Loading from "../../../component/loading/Loading";
import TicketContext from "../../../store/ticket/context";
import { useQueries } from "../../../utils/QuerySearchParams";
import { optRoutesticket } from "./helpers/list";
import TicketList from "./mobile_view/TicketList";
import DeleteTicketModal from "./ticket_component/DeleteTicketModal";
import TicketTable from "./ticket_component/TicketTable";

const Ticket = () => {
  const query = useQueries(useLocation);
  const { TicketState } = useContext(TicketContext);
  const { showDeleteTicket } = TicketState;

  const navigate = useNavigate();
  const { data, isLoading, isFetching } = useQuery(
    [
      "ticket",
      query.get("sort"),
      query.get("limit"),
      query.get("offset"),
      query.get("isAsc"),
    ],
    TicketRequest.getTicket,
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
    }
  );

  return (
    <div className="bg-white font-med p-4">
      <section className="" id="top">
        {showDeleteTicket.status && <DeleteTicketModal />}

        {isLoading ? (
          <div className="flex justify-center my-20">
            <Loading />
          </div>
        ) : (
          <div>
            <div className="">
              <div>
                <div className="md:flex hidden justify-end">
                  <h1
                    onClick={() => navigate(`/admin/ticket/add-ticket`)}
                    className="hover:text-primary md:text-base cursor-pointer inline-flex ml-5"
                  >
                    Add Ticket
                  </h1>
                </div>

                {/* <div className="md:flex hidden justify-between mt-2">
                  <div className="flex">
                    <h1
                      onClick={() =>
                        SizingDispatch({
                          type: ActionType.ShowFilter,
                        })
                      }
                      className="text-base mr-5 cursor-pointer hover:text-primary"
                    >
                      Show Filter
                    </h1>
                  </div>
                  <NewSorting option={optSortingSizing} />
                </div> */}

                {/* <Chip
                  queryParams={queryparamsSizing}
                  ActionType={ActionType}
                  dispatchContext={SizingDispatch}
                  stateContext={SizingState}
                /> */}
              </div>
              <div className="md:hidden flex justify-center fixed w-full bottom-10 pr-6">
                <div className="md:hidden flex rounded-full shadow-lg w-3/5 h-10 justify-center bg-white items-center text-mobile">
                  <div className="border-r-2 h-full flex justify-center items-center w-1/2">
                    <h1>Filter</h1>
                  </div>
                  <div className="h-full flex justify-center items-center w-1/2">
                    <h1
                      className="text-primary"
                      onClick={() => navigate(`/admin/ticket/add-ticket`)}
                    >
                      Add Ticket
                    </h1>
                  </div>
                </div>
              </div>

              <div className="flex justify-end my-4">
                {isFetching ? (
                  <div className="flex items-center">
                    <CircularLoad color="#DF5354" height={20} width={20} />
                    <h1 className="ml-2">Updating</h1>
                  </div>
                ) : (
                  <h1 className="md:text-base text-sm">Auto Update</h1>
                )}
              </div>
            </div>
            <div className="md:flex hidden">
              <TicketTable data={data} />
            </div>
            <div className="md:hidden flex">
              <TicketList data={data} />
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default Ticket;
