import { FC } from "react";
import { IIconProps } from "./Ahome";

export const Ascan: FC<IIconProps> = ({
  stroke = "#666666",
  width = "25",
  height = "25",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.5261 1.64966H23.5261V6.64966"
        stroke={stroke}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.52612 23.6497H1.52612V18.6497"
        stroke={stroke}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23.5261 18.6497V23.6497H18.5261"
        stroke={stroke}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1.52612 6.64966V1.64966H6.52612"
        stroke={stroke}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M17.5261 6.64966H7.52612C6.97384 6.64966 6.52612 7.09737 6.52612 7.64966V17.6497C6.52612 18.2019 6.97384 18.6497 7.52612 18.6497H17.5261C18.0784 18.6497 18.5261 18.2019 18.5261 17.6497V7.64966C18.5261 7.09737 18.0784 6.64966 17.5261 6.64966Z"
        stroke={stroke}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
