import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";

import { next } from "../../../../../assets/icons";
import axiosInstance from "../../../../../config/axiosInstance";
import ContactSkeletonLoading from "../../../../../component/loading/SkeletonLoading";
import moment from "moment";

export interface WalletListProps {
  data: any;
  sortValue: string;
  isAsc: string;
}

const WalletList: React.FC<WalletListProps> = ({ data, sortValue, isAsc }) => {
  const navigate = useNavigate();
  const dataTrans = data.data.data;
  const meta = data.data.meta.total;
  const [transaction, setTransaction] = useState<any>(dataTrans);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(10);
  const [more, setMore] = useState<boolean>(true);

  const goDetail = (id: number) => {
    navigate(`/admin/wallet-mutation-detail/${id}`);
  };

  useEffect(() => {
    setTransaction(dataTrans);
  }, [data]);

  const getWallet = async () => {
    try {
      const results: any = await axiosInstance().get(
        `retail/retail-wallet-mutation`,
        {
          params: {
            limit: limit,
            offset: offset,
            sort: `${sortValue} ${isAsc}`,
          },
        }
      );
      const result = await results.data.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMoredata = async () => {
    const new_data = [...transaction, ...(await getWallet())];
    setTransaction(new_data);
  };

  useEffect(() => {
    if (meta - transaction.length < limit) {
      setMore(false);
    } else {
      setMore(true);
    }
  }, [transaction.length]);

  useEffect(() => {
    if (transaction.length > limit) {
      setOffset(offset + 10);
    }
  }, [transaction.length]);

  return (
    <div className="w-full">
      <div className="w-full mb-28">
        <InfiniteScroll
          initialLoad={false}
          loadMore={fetchMoredata}
          hasMore={more}
          loader={<ContactSkeletonLoading />}
        >
          {transaction.map((el: any) => (
            <div key={el.id} className="w-full px-4">
              <div
                className="flex h-20 items-center w-full"
                // onClick={() => goDetail(el.id)}
              >
                <div className="flex items-center justify-between w-full">
                  <div className="flex flex-col">
                    <div className="w-56">
                      <h1 className="text-sm">
                        {el?.amount.toLocaleString("EN-US", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </h1>
                    </div>
                    <div className="flex mt-2">
                      <div className="flex items-center mt-0.5">
                        <h1 className="text-sm mr-2">Status :</h1>
                        {el.type === "credit" ? (
                          <h1 className="text-mobile text-primary">
                            {el.type}
                          </h1>
                        ) : el.type === "debit" ? (
                          <h1 className="text-mobile  text-success">
                            {el.type}
                          </h1>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <img src={next} alt="imgs" className="w-3 h-3" />
                  </div>
                </div>
              </div>
              <hr className="my-2" />
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default WalletList;
