import React, { useState } from "react";
import ADialogBox from "../../atoms/ADialogBox";
import { Divider } from "@material-ui/core";
import AsearchInput from "../../atoms/AsearchInput";
import {
  useFetchAddressCity,
  useFetchAddressDistrict,
  useFetchAddressProvince,
  useFetchAddressSubdistrict,
} from "../../../hooks/address";
import { Aloading } from "../../atoms/Aloading";
import { useMutateUpdateRetailShop } from "../../../hooks/retail_shop";
import {
  IAddressCity,
  IAddressDistrict,
  IAddressProvince,
  IAddressSubdistrict,
} from "../../../core/address/entities";
import ATextField from "../../atoms/AtextField";
import { useFormik } from "formik";
import * as Yup from "yup";
import Abutton from "../../atoms/Abutton";

interface IOselectAddressProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}

const OselectAddress: React.FC<IOselectAddressProps> = ({
  isOpen,
  setOpen,
}) => {
  const [search, setSearch] = useState("");
  const [province, setProvince] = useState({
    province_id: "",
    province_name: "",
  });
  const [city, setCity] = useState({ city_id: "", city_name: "" });
  const [district, setDistrict] = useState({
    district_id: "",
    district_name: "",
  });
  const [subDistrict, setSubdistrict] = useState({
    sub_district_id: "",
    sub_district_name: "",
    postal_code: "",
  });

  const { handleChange, handleSubmit, values, errors, touched, handleReset } =
    useFormik({
      initialValues: {
        latitude: "",
        longitude: "",
      },
      validationSchema: Yup.object({
        latitude: Yup.string().required("Latitude is required!"),
        longitude: Yup.string().required("Longtitude is required!"),
      }),
      onSubmit: (values) => {
        mutate({
          sub_district: subDistrict.sub_district_name,
          zip_code: subDistrict.postal_code,
          latitude: Number(values.latitude),
          longitude: Number(values.longitude),
        });
      },
    });

  const handleResetAll = () => {
    setProvince({
      province_id: "",
      province_name: "",
    });
    setCity({ city_id: "", city_name: "" });
    setDistrict({
      district_id: "",
      district_name: "",
    });
    setSubdistrict({
      sub_district_id: "",
      sub_district_name: "",
      postal_code: "",
    });
    handleReset({});
  };

  const {
    data: dataProvince,
    isLoading: loadProvince,
    refetch,
  } = useFetchAddressProvince(search);

  const {
    data: dataCity,
    isLoading: loadCity,
    refetch: refetchCity,
  } = useFetchAddressCity(province.province_id, search);

  const {
    data: dataDistrict,
    isLoading: loadDistrict,
    refetch: refetchDistrict,
  } = useFetchAddressDistrict(city.city_id, search);

  const {
    data: dataSubdistrict,
    isLoading: loadSubdistrict,
    refetch: refetchSubdistrict,
  } = useFetchAddressSubdistrict(district.district_id, search);

  const { mutate, isLoading } = useMutateUpdateRetailShop();

  const handleSelectSubdistrict = (el: IAddressSubdistrict) => {
    setSubdistrict(el);
    setSearch("");
  };

  const handleSelectDistrict = (el: IAddressDistrict) => {
    setDistrict(el);
    setSearch("");
  };

  const handleSelectCity = (el: IAddressCity) => {
    setCity(el);
    setSearch("");
  };

  const handleSelectProvince = (el: IAddressProvince) => {
    setProvince(el);
    setSearch("");
  };

  return (
    <ADialogBox
      content={{
        title: "",
        body: (
          <form onSubmit={handleSubmit} className="flex flex-col">
            <h1 className="mb-1">Define your Store Location</h1>
            <Divider />
            <div className="w-full flex items-center my-4">
              <AsearchInput
                search={search}
                setSearch={setSearch}
                handleSearch={
                  province.province_name.length <= 0
                    ? refetch
                    : city.city_name.length <= 0
                    ? refetchCity
                    : district.district_name.length <= 0
                    ? refetchDistrict
                    : subDistrict.sub_district_name.length <= 0
                    ? refetchSubdistrict
                    : () => {}
                }
              />
            </div>
            {province.province_name && (
              <section>
                <div className="flex justify-between text-xs">
                  <h1>Selected Location</h1>
                  <h1
                    onClick={handleResetAll}
                    className="font-rom cursor-pointer hover:text-primary"
                  >
                    Reset
                  </h1>
                </div>
                <aside>
                  <div className="flex items-center gap-1 mt-2">
                    <div className="w-2.5 h-2.5 bg-primary rounded-full" />
                    <h1 className="text-xs">{province.province_name}</h1>
                  </div>
                  <div className="h-3 border-l ml-1" />
                </aside>
              </section>
            )}
            {city.city_name && (
              <aside>
                <div className="flex items-center gap-1">
                  <div className="w-2.5 h-2.5 bg-primary rounded-full" />
                  <h1 className="text-xs">{city.city_name}</h1>
                </div>
                <div className="h-3 border-l ml-1" />
              </aside>
            )}
            {district.district_name && (
              <aside>
                <div className="flex items-center gap-1">
                  <div className="w-2.5 h-2.5 bg-primary rounded-full" />
                  <h1 className="text-xs">{district.district_name}</h1>
                </div>
                <div className="h-3 border-l ml-1" />
              </aside>
            )}
            {subDistrict.sub_district_name && (
              <aside>
                <div className="flex items-center gap-1">
                  <div className="w-2.5 h-2.5 bg-primary rounded-full" />
                  <h1 className="text-xs">{subDistrict.sub_district_name}</h1>
                </div>
              </aside>
            )}

            {loadProvince ? (
              <div className="flex justify-center my-10">
                <Aloading type="TailSpin" />
              </div>
            ) : (
              <div>
                <ul>
                  {dataProvince && dataProvince.length <= 0 ? (
                    <div className="flex justify-center text-sm font-rom">
                      <h1>Empty Data</h1>
                    </div>
                  ) : (
                    province.province_name.length <= 0 &&
                    dataProvince?.map((el) => (
                      <div className="my-3">
                        <li
                          className="text-sm font-rom cursor-pointer hover:text-textSecondary mb-3"
                          key={el.province_id}
                          onClick={() => handleSelectProvince(el)}
                        >
                          {el.province_name}
                        </li>
                        <Divider />
                      </div>
                    ))
                  )}
                </ul>
              </div>
            )}

            {loadCity ? (
              <div className="flex justify-center my-10">
                <Aloading type="TailSpin" />
              </div>
            ) : (
              <div>
                <ul>
                  {dataCity && dataCity.length <= 0 ? (
                    <div className="flex justify-center text-sm font-rom">
                      <h1>Empty Data</h1>
                    </div>
                  ) : (
                    province.province_name.length > 0 &&
                    city.city_name.length <= 0 &&
                    dataCity?.map((el) => (
                      <div className="my-3">
                        <li
                          className="text-sm font-rom cursor-pointer hover:text-textSecondary mb-3"
                          key={el.city_id}
                          onClick={() => handleSelectCity(el)}
                        >
                          {el.city_name}
                        </li>
                        <Divider />
                      </div>
                    ))
                  )}
                </ul>
              </div>
            )}

            {loadDistrict ? (
              <div className="flex justify-center my-10">
                <Aloading type="TailSpin" />
              </div>
            ) : (
              <div>
                <ul>
                  {dataDistrict && dataDistrict.length <= 0 ? (
                    <div className="flex justify-center text-sm font-rom">
                      <h1>Empty Data</h1>
                    </div>
                  ) : (
                    city.city_name.length > 0 &&
                    district.district_name.length <= 0 &&
                    dataDistrict?.map((el) => (
                      <div className="my-3">
                        <li
                          className="text-sm font-rom cursor-pointer hover:text-textSecondary mb-3"
                          key={el.district_id}
                          onClick={() => handleSelectDistrict(el)}
                        >
                          {el.district_name}
                        </li>
                        <Divider />
                      </div>
                    ))
                  )}
                </ul>
              </div>
            )}

            {loadSubdistrict ? (
              <div className="flex justify-center my-10">
                <Aloading type="TailSpin" />
              </div>
            ) : (
              <div>
                <ul>
                  {dataSubdistrict && dataSubdistrict.length <= 0 ? (
                    <div className="flex justify-center text-sm font-rom">
                      <h1>Empty Data</h1>
                    </div>
                  ) : (
                    district.district_name.length > 0 &&
                    subDistrict.sub_district_name.length <= 0 &&
                    dataSubdistrict?.map((el) => (
                      <div className="my-3">
                        <li
                          className="text-sm font-rom cursor-pointer hover:text-textSecondary mb-3"
                          key={el.sub_district_id}
                          onClick={() => handleSelectSubdistrict(el)}
                        >
                          {el.sub_district_name}
                        </li>
                        <Divider />
                      </div>
                    ))
                  )}
                </ul>
              </div>
            )}

            {subDistrict.sub_district_name.length > 0 && (
              <section>
                <aside className="flex gap-3 mt-5">
                  <div className="w-1/2">
                    <ATextField
                      type="text"
                      label="latitude"
                      name="latitude"
                      placeholder="Place Holder"
                      value={values.latitude}
                      handleChange={handleChange}
                      error={touched.latitude && errors.latitude ? true : false}
                      helperText={errors.latitude}
                    />
                  </div>
                  <div className="w-1/2">
                    <ATextField
                      type="text"
                      name="longitude"
                      label="longtitude"
                      placeholder="Place Holder"
                      value={values.longitude}
                      handleChange={handleChange}
                      error={
                        touched.longitude && errors.longitude ? true : false
                      }
                      helperText={errors.longitude}
                    />
                  </div>
                </aside>
                <aside className="mt-3">
                  <Abutton
                    title="Submit"
                    type="submit"
                    model="full"
                    isLoading={isLoading}
                  />
                </aside>
              </section>
            )}
          </form>
        ),
      }}
      isOpen={isOpen}
      setIsOpen={setOpen}
    />
  );
};

export default OselectAddress;
