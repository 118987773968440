import moment from "moment";
import ErrorHandler from "../../component/toast/ErrorHandler";
import { IBuiltinSort, IPagination } from "../../core/helpers/helper";
import {
  ERetailTransactionSortField,
  PRetailTransactionFilter,
} from "../../core/retail_transaction/entities";
import RetailTransactionRepository from "../../core/retail_transaction/repository/repository";
import RetailTransactionUsecase from "../../core/retail_transaction/usecase/usecase";

export class RetailTransactionService {
  static getRetailTransactions = async (
    pagination: IPagination,
    filter: PRetailTransactionFilter,
    sorts: IBuiltinSort<ERetailTransactionSortField>[]
  ) => {
    try {
      const retailTransactionRepo = new RetailTransactionRepository();
      const retailTransactionUsecase = new RetailTransactionUsecase(
        retailTransactionRepo
      );
      const retailTransaction =
        await retailTransactionUsecase.getRetailTransactions(
          pagination,
          filter,
          sorts
        );
      return retailTransaction;
    } catch (error) {
      console.log(error);
    }
  };

  static getRetailTransactionCounter = async (
    filter: PRetailTransactionFilter
  ) => {
    try {
      const retailTransactionRepo = new RetailTransactionRepository();
      const retailTransactionUsecase = new RetailTransactionUsecase(
        retailTransactionRepo
      );
      const counter =
        await retailTransactionUsecase.getRetailTransactionCounter(filter);
      return counter;
    } catch (error) {
      console.log(error);
    }
  };

  static getRetailTransaction = async (code: string) => {
    try {
      const retailTransactionRepo = new RetailTransactionRepository();
      const retailTransactionUsecase = new RetailTransactionUsecase(
        retailTransactionRepo
      );
      const retailTransaction =
        await retailTransactionUsecase.getRetailTransaction(code);
      return retailTransaction;
    } catch (error) {
      console.log(error);
    }
  };

  static getRetailTransactionTrack = async (code: string) => {
    try {
      const retailTransactionRepo = new RetailTransactionRepository();
      const retailTransactionUsecase = new RetailTransactionUsecase(
        retailTransactionRepo
      );
      const retailTransactionTrack =
        await retailTransactionUsecase.getRetailTransactionTrack(code);
      return retailTransactionTrack;
    } catch (error) {
      console.log(error);
    }
  };

  static sendProduct = async (
    code: string,
    payload: { pickup_time: string; is_simulated: boolean }
  ) => {
    try {
      const newPayload = {
        pickup_time: moment(payload.pickup_time).toISOString(),
        is_simulated: payload.is_simulated
      };
      const retailTransactionRepo = new RetailTransactionRepository();
      const retailTransactionUsecase = new RetailTransactionUsecase(
        retailTransactionRepo
      );
      const retailTransaction = await retailTransactionUsecase.sendProduct(
        code,
        newPayload
      );
      return retailTransaction;
    } catch (errors) {
      ErrorHandler("#ed4846", errors);
    }
  };
}
