import React, { useReducer } from "react";
import { Route, Routes } from "react-router-dom";
import SubAccount from "../../pages/admin/sub_account/SubAccount";
import {
  initialSubAccountState,
  SubAccountContextProvider,
} from "../../store/sub_account/context";
import { SubAccountReducer } from "../../store/sub_account/reducer";

const SubAccountRoute = () => {
  const [SubAccountState, SubAccountDispatch] = useReducer(
    SubAccountReducer,
    initialSubAccountState
  );
  return (
    <SubAccountContextProvider value={{ SubAccountState, SubAccountDispatch }}>
      <Routes>
        <Route path="/" element={<SubAccount />} />
      </Routes>
    </SubAccountContextProvider>
  );
};

export default SubAccountRoute;
