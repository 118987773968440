import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../config/axiosInstance";

//icons
import { padlock } from "../../assets/icons";
import { showPass, unshowPass } from "../../assets/icons/Web";

//components
import Loading from "../../component/loading/Loading";
import Handler from "../../component/toast/Handler";
import Backdrop from "./login/Backdrop";

function ResetPassword() {
  const params = useParams();
  const [passType, setPassType] = useState<string>("password");
  const [newPassType, setNewPassType] = useState<string>("password");
  const [error, setError] = useState<boolean>(false);
  const navigate = useNavigate();
  const [auth, setAuth] = useState({
    token: params.token,
    password: "",
    new_password: "",
  });

  const handleVisibleType = () => {
    if (passType === "password") {
      setPassType("text");
    } else {
      setPassType("password");
    }
  };
  const handleVisibleNewType = () => {
    if (newPassType === "password") {
      setNewPassType("text");
    } else {
      setNewPassType("password");
    }
  };

  useEffect(() => {
    if (auth.password !== auth.new_password) {
      setError(true);
    } else {
      setError(false);
    }
  }, [auth]);

  const handleInput = (e: any) => {
    const name = e.target.name;
    let value = e.target.value;
    setAuth({ ...auth, [name]: value });
  };

  const submitPassword = async (e: any) => {
    try {
      e.preventDefault();
      if (auth.password !== auth.new_password) {
        Handler("error", "The Password Confirmation Does not match!");
      } else {
        const result = await axiosInstance()
          .post("retail/auth/reset-password", {
            new_password: auth.password,
            token: auth.token,
          })
          .then((data) => {
            Handler("success", "Password updated successfully!");
            navigate("/login");
          })
          .catch((err) => Handler("error", "Update password failed!"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getToken = async () => {
    try {
      const results = await axiosInstance()
        .get(`retail/auth/reset-password/${params.token}`)
        .then(({ data }) => {})
        .catch((err) => {
          Handler("error", "Session Expired!");
          navigate("/login");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const { data, isLoading } = useQuery("getToken", getToken, {
    refetchOnWindowFocus: false,
  });

  return (
    <>
      <Backdrop active={true} />
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <Loading />
        </div>
      ) : (
        <div className="w-full h-screen flex items-center justify-center font-med">
          <div className="flex flex-col bg-white rounded-md md:w-Wlogin md:h-Hlogin w-4/5 shadow-xl">
            <form onSubmit={submitPassword}>
              <div className="md:mt-7 mt-8 md:mb-8 mb-8 flex flex-col justify-center items-center">
                <img alt="" src={padlock} className="md:w-12 w-10" />
                <div className="flex md:mt-4 mt-3">
                  <h1 className="text-primary md:text-xl text-lg">DEPATU</h1>
                  <h1 className="md:text-xl text-lg ml-2">Multi Brand Store</h1>
                </div>
              </div>

              <div className="md:mx-14 mx-8 mb-5">
                <div className="">
                  <h1 className="md:text-sm text-xs text-textPrimary">
                    New Password
                  </h1>
                  <div className="md:h-10 h-8 rounded-md border-2 my-2 border-stroke flex justify-between">
                    <input
                      required
                      type={passType}
                      placeholder="password"
                      className={
                        "md:text-sm text-xs font-rom px-3 w-full mr-5  shadow-sm focus:outline-none " +
                        (error ? "border-2 border-primary" : "")
                      }
                      name="password"
                      onChange={handleInput}
                    />
                    {passType === "password" ? (
                      <img
                        src={showPass}
                        onClick={handleVisibleType}
                        className="md:w-5 w-3 mr-5 cursor-pointer"
                        alt=""
                      />
                    ) : (
                      <img
                        src={unshowPass}
                        onClick={handleVisibleType}
                        className="md:w-5 w-3 mr-5 cursor-pointer"
                        alt=""
                      />
                    )}
                  </div>
                </div>
                <div className="mt-4">
                  <h1 className="md:text-sm text-xs text-textPrimary">
                    Confirm new Password
                  </h1>
                  <div className="md:h-10 h-8 rounded-md border-2 my-2 border-stroke flex justify-between">
                    <input
                      required
                      type={newPassType}
                      placeholder="Confirm New Password"
                      className={
                        "md:text-sm text-xs font-rom px-3 w-full mr-5  shadow-sm focus:outline-none " +
                        (error ? "border-2 border-primary" : "")
                      }
                      name="new_password"
                      onChange={handleInput}
                    />
                    {newPassType === "password" ? (
                      <img
                        src={showPass}
                        onClick={handleVisibleNewType}
                        className="md:w-5 w-3 mr-5 cursor-pointer"
                        alt=""
                      />
                    ) : (
                      <img
                        src={unshowPass}
                        onClick={handleVisibleNewType}
                        className="md:w-5 w-3 mr-5 cursor-pointer"
                        alt=""
                      />
                    )}
                  </div>
                </div>
                <div className="flex justify-start h-5">
                  {error ? (
                    <h1 className="text-primary text-xs font-rom">
                      The Password Confirmation Does not match!
                    </h1>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="flex justify-center md:mb-0 mb-5">
                <button
                  type="submit"
                  className="rounded-full md:w-40 w-28 md:h-8 h-6 md:pb-0 pb-0.5 bg-primary focus:outline-none hover hover:bg-opacity-50"
                >
                  <h1 className="text-white md:text-base text-sm">Submit</h1>
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}

export default ResetPassword;
