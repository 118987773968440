import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "../../../../../config/axiosInstance";

import SubNav from "../../../../../component/navbar/SubNav";
import Toggle from "../../../../../component/toggleBtn/Toggle";
import Input from "../../../../../component/input/Input";
import Save from "../../../../../component/input/Save";
import Filepond from "../../../../../component/filepond/Filepond";
import FilepondMultiple from "../../../../../component/filepond/FilePondMultiple";
import ErrorHandler from "../../../../../component/toast/ErrorHandler";

function AddProduct() {
  const navigate = useNavigate();
  const [files, setFiles] = useState<any>(null);
  const [filesDetail, setFilesDetail] = useState<any>(null);
  const [filesDesc, setFilesDesc] = useState<any>([]);
  const [storeOpen, setStoreOpen] = useState<boolean>(false);
  const [load, setLoad] = useState<boolean>(false);
  const [limitedProduct, setLimitedProduct] = useState<boolean>(false);
  const [preOrder, setPreOrder] = useState<boolean>(false);
  const [product, setProduct] = useState({
    code: "",
    name: "",
    description: "",
    display_image: "",
    description_images: [],
    detail_images: [],
    is_active: "true",
    is_pre_order_limited: "false",
    is_limited: "false",
    release_date: "",
    limit_per_user: "",
    pre_order_limit_per_user: "",
    is_shipping_fee_charged: "",
    limited_start_date: new Date().toISOString().slice(0, 10),
    pre_order_limited_start_date: "",
  });

  const handleStoreOpen = () => {
    setStoreOpen(!storeOpen);
    if (product.is_active === "false") {
      setProduct({ ...product, is_active: "true" });
    } else {
      setProduct({ ...product, is_active: "false" });
    }
  };

  const handleLimitedProduct = () => {
    setLimitedProduct(!limitedProduct);
    if (product.is_limited === "false") {
      setProduct({ ...product, is_limited: "true" });
    } else {
      setProduct({ ...product, is_limited: "false", limit_per_user: "" });
    }
  };

  const handlePreOrder = () => {
    setPreOrder(!preOrder);
    if (product.is_pre_order_limited === "false") {
      setProduct({ ...product, is_pre_order_limited: "true" });
    } else {
      setProduct({
        ...product,
        is_pre_order_limited: "false",
        pre_order_limit_per_user: "",
        pre_order_limited_start_date: "",
      });
    }
  };

  // const handleAddToMp = () => {
  //     setAddToMP(!addToMP)
  // }

  const goBack = () => {
    navigate("/admin/products");
  };

  const handleChangeInput = (e: any) => {
    const target = e.target;
    setProduct({ ...product, [target.name]: target.value });
  };

  const handleClear = () => {
    setProduct({
      ...product,
      name: "",
      code: "",
      description: "",
      detail_images: [],
      description_images: [],
      limit_per_user: "",
      release_date: "",
      is_shipping_fee_charged: "",
      pre_order_limit_per_user: "",
      pre_order_limited_start_date: "",
    });
  };

  const submitForm = async (e: any) => {
    e.preventDefault();
    setLoad(true);
    const new_product = new FormData();
    new_product.append("display_image", files);

    filesDetail?.file?.map((image: any) =>
      new_product.append("detail_images[]", image)
    );
    filesDesc?.file?.map((image: any) =>
      new_product.append("description_images[]", image)
    );

    new_product.append("is_active", product.is_active);
    new_product.append("name", product.name);
    new_product.append("code", product.code);
    new_product.append(
      "release_date",
      new Date(product.release_date).toISOString()
    );
    new_product.append(
      "is_shipping_fee_charged",
      product.is_shipping_fee_charged
    );
    new_product.append("description", product.description);
    new_product.append("is_pre_order_limited", product.is_pre_order_limited);
    if (
      product.is_pre_order_limited === "true" &&
      product.is_pre_order_limited.length !== 0
    ) {
      new_product.append(
        "pre_order_limit_per_user",
        product.pre_order_limit_per_user
      );
      new_product.append(
        "pre_order_limited_start_date",
        product.pre_order_limited_start_date
      );
    }
    new_product.append("is_limited", product.is_limited);
    if (product.is_limited === "true" && product.limit_per_user.length !== 0) {
      new_product.append("limit_per_user", product.limit_per_user);
      new_product.append(
        "limited_start_date",
        product.limited_start_date.toString()
      );
    }
    try {
      await axios().post("retail/retail-product", new_product);
      setLoad(false);
      ErrorHandler("#00C62E", "Add Product Completed");
      setTimeout(() => {
        navigate("/admin/products");
      }, 1500);
    } catch (error: any) {
      setLoad(false);
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Add Failed");
      }
    }
  };

  return (
    <div className="bg-white p-4 font-med">

      <form onSubmit={submitForm}>
        <div className="w-full h-full md:flex justify-between">
          <div className="md:w-1/3">
            <div className="w-full rounded-md border-2 border-stroke">
              <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5 bg">
                <h1 className="font-medium md:text-xl text-sm">
                  Product Images
                </h1>
              </div>
              <hr />

              <div className="md:m-7 m-5">
                <div>
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Display Image
                  </h1>
                  <h1 className="text-xs font-rom text-textPrimary mt-1">
                    (1 x 1), (PNG, JPG, JPEG) max. 1 MB
                  </h1>
                  <Filepond setFiles={setFiles} />
                </div>

                <div className="mt-6 text-textPrimary">
                  <h1 className="md:text-base text-sm">Upload Images</h1>
                  <h1 className="text-xs font-rom text-textPrimary mt-1">
                    Multiple Images, (1 x 1), (PNG, JPG, JPEG) max. 1 MB
                  </h1>
                  <FilepondMultiple setFiles={setFilesDetail} />
                </div>
              </div>
            </div>

            <div className="w-full my-10 rounded-md border-2 border-stroke">
              <div className="items-center md:m-7 m-5">
                <h1 className="md:text-base text-sm">Detail Images</h1>
                <h1 className="text-xs font-rom text-textPrimary mt-1">
                  Note: You can add your size chart or detail type of your
                  product
                </h1>
              </div>
              <hr />
              <div className="md:m-7 m-5">
                <FilepondMultiple setFiles={setFilesDesc} />
              </div>
            </div>
          </div>

          <div className="md:w-custom md:mt-0 mt-10 w-full h-full">
            <div className="rounded-md rounded-b-none border-2 border-stroke">
              <div className="md:h-16 h-14 flex items-center justify-between md:mx-8 mx-5">
                <h1 className="font-medium md:text-xl text-sm">
                  Product Information
                </h1>
                <div className="flex">
                  <h1 className="font-medium md:text-base text-sm mr-3 text-textPrimary">
                    Hide Product
                  </h1>
                  <Toggle input={!storeOpen} handleClick={handleStoreOpen} />
                </div>
              </div>
              <hr />

              {/*product Name*/}
              <div className="md:mx-8 mx-5 md:my-5 my-3">
                <div>
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Product Name
                  </h1>
                  <Input
                    required={true}
                    type={"text"}
                    name={"name"}
                    placeholder={"Place holder"}
                    handleChange={handleChangeInput}
                  />
                </div>
                <div className="md:mt-4 mt-3">
                  <h1 className="md:text-base text-sm  text-textPrimary">
                    Product Code
                  </h1>
                  <Input
                    title="Product code must contain A-Z or 0-9"
                    pattern="[a-zA-Z0-9]*"
                    required={true}
                    type={"text"}
                    name={"code"}
                    placeholder={"Place holder"}
                    handleChange={handleChangeInput}
                  />
                </div>
              </div>

              <div className="md:flex w-full">
                {/*form product left*/}
                <div className="md:mx-8 mx-5 md:w-1/2">
                  <div className="md:mt-0">
                    <h1 className="md:text-base text-sm  text-textPrimary">
                      Release Date
                    </h1>
                    <Input
                      required={true}
                      type={"datetime-local"}
                      name={"release_date"}
                      placeholder={"Place holder"}
                      handleChange={handleChangeInput}
                    />
                  </div>

                  <div className="md:block flex justify-between items-center md:mt-4 mt-24">
                    <h1 className="md:text-base text-sm  text-textPrimary">
                      Limited Product
                    </h1>
                    <div className="flex my-4">
                      <div className="flex justify-between items-center">
                        <Toggle
                          input={!limitedProduct}
                          handleClick={handleLimitedProduct}
                        />
                        <h1 className="font-rom md:text-base text-sm ml-4 text-textPrimary">
                          {!limitedProduct ? "Enable" : "Disable"}
                        </h1>
                      </div>
                    </div>
                  </div>

                  <div className="md:mt-9 md:block flex justify-between items-center">
                    <h1 className="md:text-base text-sm  text-textPrimary">
                      Pre-Order
                    </h1>
                    <div className="flex my-4">
                      <div className="flex justify-between items-center">
                        <Toggle
                          input={!preOrder}
                          handleClick={handlePreOrder}
                        />
                        <h1 className="font-rom md:text-base text-sm  ml-4 text-textPrimary">
                          {!preOrder ? "Enable" : "Disable"}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>

                {/*form product right*/}
                <div className="md:mx-8 mx-5 md:w-1/2">
                  <div className="md:mt-0 -mt-48">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Shipping Fee (Charged to)
                    </h1>
                    <select
                      onChange={handleChangeInput}
                      name="is_shipping_fee_charged"
                      className="md:h-10 h-9 font-rom w-full rounded-md focus:ring-1 focus:outline-none md:text-sm text-xs my-3 px-3 border-2 border-stroke appearance-none"
                    >
                      <option selected hidden>
                        Select Here
                      </option>
                      <option value="false">Seller</option>
                      <option value="true">Buyer</option>
                    </select>
                  </div>
                  {/* <div className="mt-3 h-28"></div> */}
                  {limitedProduct ? (
                    <div className="md:mt-3 mt-32">
                      <h1 className="md:text-base text-sm  text-textPrimary">
                        Limit / User
                      </h1>
                      <Input
                        required={true}
                        type={"number"}
                        name={"limit_per_user"}
                        placeholder={"Place holder"}
                        handleChange={handleChangeInput}
                      />
                    </div>
                  ) : (
                    <div className="h-24 mt-1"></div>
                  )}

                  {preOrder ? (
                    <div>
                      <div className="mt-4">
                        <h1 className="md:text-base text-sm  text-textPrimary">
                          Limit / User
                        </h1>
                        <Input
                          required={true}
                          type={"number"}
                          name={"pre_order_limit_per_user"}
                          placeholder={"Place holder"}
                          handleChange={handleChangeInput}
                        />
                      </div>
                      <div className="mt-4">
                        <h1 className="md:text-base text-sm  text-textPrimary">
                          Start Date
                        </h1>
                        <Input
                          required={true}
                          type={"date"}
                          name={"pre_order_limited_start_date"}
                          placeholder={"Place holder"}
                          handleChange={handleChangeInput}
                        />
                      </div>
                      {/* <div className="mt-6">
                                                <h1 className="md:text-xl text-sm  text-textPrimary">End Date</h1>
                                                <input type="date" onChange={handleChangeInput} placeholder="placeholder" className="md:h-14 h-9 w-full rounded-md md:text-base text-sm my-3 px-5 border-2 border-stroke" />
                                            </div> */}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/*product description*/}
              <div className="md:mb-8 mb-5 md:mt-4 mt-5 md:mx-8 mx-5">
                <h1 className="md:text-base text-sm  text-textPrimary">
                  Description
                </h1>
                <textarea
                  required
                  onChange={handleChangeInput}
                  name="description"
                  placeholder="Place holder"
                  className="md:h-28 h-24 font-rom w-full rounded-md md:text-base text-xs my-3 px-3 py-3 border-2 border-stroke focus:ring-1 focus:outline-none"
                ></textarea>
              </div>
            </div>

            {/*button save*/}
            <Save handleReset={handleClear} load={load} />
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddProduct;
