import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import React from "react";

registerPlugin(
  FilePondPluginFileValidateSize,
  FilePondPluginImageValidateSize,
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginImageCrop
);

function Filepond({setFiles}) {
  return (
    <div className="xl:w-full md:w-10/12 mt-5 mb-7">
      <FilePond
        className="w-full"
        imageValidateSizeLabelImageSizeTooBig={"Image is too big"}
        allowMultiple={false}
        maxFiles={1}
        maxFileSize={"1MB"}
        labelMaxFileSizeExceeded={"File is too large"}
        allowReplace
        checkValidity
        allowImagePreview={true}
        allowImageExifOrientation
        labelFileTypeNotAllowed={"File of invalid type"}
        acceptedFileTypes={["image/png", "image/jpg", "image/jpeg"]}
        imageCropAspectRatio={'1:1'}
        allowImageCrop={true}
        onupdatefiles={(fileItems) => {
          if (fileItems.length === 0) {
            setFiles(null);
          } else {
            fileItems.map((fileItem) => setFiles(fileItem.file));
          }
        }}
      />
    </div>
  );
}

export default Filepond;
