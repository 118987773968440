import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "../../../config/axiosInstance";
import { useQuery } from "react-query";

// components
import RevenueTable from "./revenueComponent/RevenueTable";
import Loading from "../../../component/loading/Loading";
import ExportFile from "../../../component/downloadCsv/ExportFile";
import RevenueFilter from "./revenueComponent/RevenueFilter";
import RevenueList from "./revenueComponent/mobileView/RevenueList";

//icons
import { asc, desc } from "../../../assets/icons";
import { useQueries } from "../../../utils/QuerySearchParams";
import NewSorting from "../../../component/sorting/NewSorting";

function Revenue() {
  const query = useQueries(useLocation);
  const { pathname, search, hash } = useLocation();
  const navigate = useNavigate();
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [sortValue, setSortValue] = useState<string>("created_at");
  const [chip, setChip] = useState<any>([]);
  const [filter, setFilter] = useState<any>({
    created_at_start: "",
    created_at_end: "",
  });

  const handleShowFilter = () => {
    if (showFilter) {
      setShowFilter(false);
      setFilter({
        ...filter,
        created_at_start: "",
        created_at_end: "",
      });
    } else {
      setShowFilter(true);
    }
  };

  const getRevenue = async () => {
    try {
      const results: any = await axios().get(`retail/retail-revenue`, {
        params: {
          ...(filter.created_at_start.length !== 0 &&
          filter.created_at_end.length !== 0
            ? {
                created_at: `${filter.created_at_start},${filter.created_at_end}`,
              }
            : ""),
          ...(filter.created_at_start.length !== 0 &&
          filter.created_at_end.length === 0
            ? { created_at: `${filter.created_at_start},2100-01-01` }
            : ""),
          ...(filter.created_at_start.length === 0 &&
          filter.created_at_end.length !== 0
            ? { created_at: `2000-01-01,${filter.created_at_end}` }
            : ""),
          limit: query.get("limit") !== null ? query.get("limit") : 10,
          offset: query.get("offset") !== null ? query.get("offset") : 0,
          sort: `${
            query.get("sort") !== null ? query.get("sort") : "created_at"
          } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
        },
      });
      return results;
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalRevenue = async () => {
    try {
      const results: any = await axios().get(`retail/statistic`);
      const result = results.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const { data, isSuccess, refetch } = useQuery(
    [
      "getRevenue",
      query.get("sort"),
      query.get("limit"),
      query.get("offset"),
      query.get("isAsc"),
      filter,
    ],
    getRevenue,
    { refetchOnWindowFocus: false }
  );
  
  const { data: dataTotal, isSuccess: successTotal } = useQuery(
    ["totalrevenue", sortValue],
    getTotalRevenue,
    { refetchOnWindowFocus: false }
  );
  const dataExport = data?.data.data;

  const applyChip = () => {
    const new_data = [];
    for (const key in filter) {
      if (filter[key].length > 0) {
        new_data.push(key);
      }
    }
    setChip(new_data);
  };

  const handleChipByKey = (el: any) => {
    const new_data = chip.filter((item: any) => item !== el);
    setChip(new_data);
    const new_filter = { ...filter, [el]: "" };
    setFilter(new_filter);
    refetch();
  };

  const handleAsc = () => {
    const prevAsc: any = query.get("isAsc");
    if (prevAsc === "asc") {
      let new_search;
      prevAsc !== null
        ? (new_search = search.replace(`isAsc=${prevAsc}`, "isAsc=desc"))
        : (new_search = `${search}&&isAsc=desc`);
      navigate({ pathname, search: new_search, hash });
    } else {
      let new_search;
      prevAsc !== null
        ? (new_search = search.replace(`isAsc=${prevAsc}`, "isAsc=asc"))
        : (new_search = `${search}&&isAsc=asc`);
      navigate({ pathname, search: new_search, hash });
    }
  };

  return (
    <div className="font-med bg-white p-2">
      {/* breadscrumb */}
      <div>
        <ul className="flex text-breadscrumbColor md:text-base text-xs">
          <li className="inline-flex items-center">
            <Link to="/admin/home" className="hover:text-black">
              Home
            </Link>
          </li>
          <li className="inline-flex items-center">
            <svg className="h-5 w-auto" viewBox="0 0 20 20" fill="#000000">
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <Link className="text-black" to="/admin/revenues">
              Revenues
            </Link>
          </li>
        </ul>
      </div>

      {/* main title and total revenue */}
      <div className="flex flex-col items-center my-14">
        <h1 className="md:text-2xl text-base md:mb-2">Total Revenue</h1>
        {successTotal ? (
          <h1 className="md:text-3xl text-xl md:mb-2 mb-1">
            {dataTotal.net_income?.toLocaleString("EN-US", {
              style: "currency",
              currency: "IDR",
            })}
          </h1>
        ) : (
          ""
        )}
        <ExportFile csvData={dataExport} fileName={"data-total-revenue"} />
      </div>

      {/*filter and sort*/}
      <div className="md:flex justify-between md:mt-16">
        <div className="md:flex hidden items-end">
          <h1
            onClick={handleShowFilter}
            className="text-base cursor-pointer hover:text-primary"
          >
            Show Filter
          </h1>
        </div>
        <div className="md:flex hidden">
          <NewSorting
            option={[
              { value: "created_at", name: "Date" },
              { value: "buyer_username", name: "Customer" },
              { value: "price", name: "Product Price" },
              { value: "quantity", name: "Quantity" },
              { value: "total", name: "Total Price" },
              { value: "admin_fee", name: "Admin Fee" },
              { value: "buyer_shipping_cost", name: "Shipping Cost" },
              { value: "seller_payout", name: "Seller Payout" },
            ]}
          />
        </div>

        {/**sorting mobile */}
        <div className="md:hidden w-full flex my-5 items-center">
          {query.get("isAsc") === "asc" ? (
            <img
              className="cursor-pointer w-6 mr-3"
              onClick={handleAsc}
              src={asc}
              alt="asc"
            />
          ) : (
            <img
              className="cursor-pointer w-6 mr-3"
              onClick={handleAsc}
              src={desc}
              alt="desc"
            />
          )}
          <div className="w-auto flex overflow-x-auto">
            <div className="grid md:gap-10 gap-x-24 grid-cols-8 md:grid-cols-4 w-full md:inline-grid font-med">
              <div className="w-24 flex flex-col items-center">
                <h1
                  className="text-sm"
                  onClick={() => setSortValue("created_at")}
                >
                  Date
                </h1>
                <hr
                  className={
                    sortValue === "created_at"
                      ? "w-full my-1 border-t-2 border-primary"
                      : "w-full my-1 border-t-2"
                  }
                />
              </div>
              <div className="w-24 flex flex-col items-center justify-center">
                <h1
                  className="text-sm"
                  onClick={() => setSortValue("buyer_username")}
                >
                  Customer
                </h1>
                <hr
                  className={
                    sortValue === "buyer_username"
                      ? "w-full my-1 border-t-2 border-primary"
                      : "w-full my-1 border-t-2"
                  }
                />
              </div>
              <div className="w-24 flex flex-col items-center justify-center">
                <h1 className="text-sm" onClick={() => setSortValue("price")}>
                  Product Price
                </h1>
                <hr
                  className={
                    sortValue === "price"
                      ? "w-full my-1 border-t-2 border-primary"
                      : "w-full my-1 border-t-2"
                  }
                />
              </div>
              <div className="w-24 flex flex-col items-center justify-center">
                <h1
                  className="text-sm"
                  onClick={() => setSortValue("quantity")}
                >
                  Quantity
                </h1>
                <hr
                  className={
                    sortValue === "quantity"
                      ? "w-full my-1 border-t-2 border-primary"
                      : "w-full my-1 border-t-2"
                  }
                />
              </div>
              <div className="w-24 flex flex-col items-center justify-center">
                <h1 className="text-sm" onClick={() => setSortValue("total")}>
                  Total Price
                </h1>
                <hr
                  className={
                    sortValue === "total"
                      ? "w-full my-1 border-t-2 border-primary"
                      : "w-full my-1 border-t-2"
                  }
                />
              </div>
              <div className="w-24 flex flex-col items-center justify-center">
                <h1
                  className="text-sm"
                  onClick={() => setSortValue("admin_fee")}
                >
                  Adm Fee
                </h1>
                <hr
                  className={
                    sortValue === "admin_fee"
                      ? "w-full my-1 border-t-2 border-primary"
                      : "w-full my-1 border-t-2"
                  }
                />
              </div>
              <div className="w-24 flex flex-col items-center justify-center">
                <h1
                  className="text-sm"
                  onClick={() => setSortValue("buyer_shipping_cost")}
                >
                  Shipping Cost
                </h1>
                <hr
                  className={
                    sortValue === "buyer_shipping_cost"
                      ? "w-full my-1 border-t-2 border-primary"
                      : "w-full my-1 border-t-2"
                  }
                />
              </div>
              <div className="w-24 flex flex-col items-center justify-center">
                <h1
                  className="text-sm"
                  onClick={() => setSortValue("seller_payout")}
                >
                  Seller Payout
                </h1>
                <hr
                  className={
                    sortValue === "seller_payout"
                      ? "w-full my-1 border-t-2 border-primary"
                      : "w-full my-1 border-t-2"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {chip ? (
        <div className="md:flex hidden my-4">
          {chip.map((el: any) => (
            <div className="inline-flex items-center rounded mx-2 bg-white border-2 border-stroke p-1 mt-1">
              <span className="px-2 text-sm">{el.split("_").join(" ")}</span>
              <button type="button" className="h-6 w-6 p-1 focus:outline-none">
                <svg
                  onClick={() => handleChipByKey(el)}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className="md:hidden h-10"></div>
      )}

      {/** filter for mobile */}
      <div className="flex justify-center">
        <div className="md:hidden flex rounded-full shadow-lg w-40 h-12 justify-center mt-96 bg-white fixed items-center z-40 text-primary">
          <h1 onClick={handleShowFilter}>Filter</h1>
        </div>
      </div>

      {/**show filter */}
      <RevenueFilter
        applyChip={applyChip}
        refetch={refetch}
        showFilter={showFilter}
        setShowFilter={() => setShowFilter(!showFilter)}
        filter={filter}
        setFilter={setFilter}
      />

      {/* table */}
      {isSuccess ? (
        <div>
          <div className="md:flex hidden -mt-6">
            <RevenueTable dataProps={data} />
          </div>
          <div className="md:hidden flex">
            <RevenueList data={data} sortValue={sortValue} />
          </div>
        </div>
      ) : (
        <div className="flex justify-center mt-10">
          <Loading />
        </div>
      )}
    </div>
  );
}

export default Revenue;
