import React from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { back } from "../../../../../../assets/icons";
import Loading from "../../../../../../component/loading/Loading";
import axiosInstance from "../../../../../../config/axiosInstance";
import { formatRupiah } from "../../../../../../utils/formatRupiah";

function ProductVariantDetail() {
  const{ pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const goBack = () => {
    navigate(`/admin/products/${params.id}/stock-product`);
  };

  const getVariantById = async (detail: string) => {
    try {
      const results: any = await axiosInstance().get(
        `retail/retail-product-stock/${detail}`
      );
      return results;
    } catch (error) {
      console.log(error);
    }
  };

  const { data, isSuccess } = useQuery("getVariantById", () =>
    getVariantById(params.detail || '')
  );

  const goEdit = () => {
    navigate(`${pathname}/edit-variant`);
  };

  const goDelete = () => {
    navigate(`${pathname}/delete-variant`);
  };
  return (
    <div className="mb-28 font-med">
      <div className="md:pt-28 pt-20 overflow-auto">
        <div onClick={goBack} className="flex items-center cursor-pointer">
          <img src={back} alt="back" />
          <h1 className="text-mobile ml-1.5">Back</h1>
        </div>

        {isSuccess ? (
          <div>
            <div className="px-5 md:mt-3 mt-10">
              <div>
                <h1 className="text-mobile">Variant</h1>
                <div className="flex items-center border-2 mt-2 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">{data?.data.label}</h1>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-mobile">Price</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  {data?.data.display_price ? (
                    <del className="text-mobile font-rom">
                      {formatRupiah(data?.data.display_price || "-")}
                    </del>
                  ) : (
                    <h1 className="text-mobile font-rom">
                      {formatRupiah(data?.data.price || "-")}
                    </h1>
                  )}
                </div>
              </div>
              {data?.data.display_price && (
                <div className="mt-5">
                  <h1 className="text-mobile">Discount Price</h1>
                  <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                    <h1 className="text-mobile font-rom">
                      {formatRupiah(data?.data.price || "-")}
                    </h1>
                  </div>
                </div>
              )}
              <div className="mt-5">
                <h1 className="text-mobile">Product Status</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  {data.data.stock > 0 && data?.data.is_pre_order === true ? (
                    <div className="flex justify-center">
                      <h1 className="text-mobile text-success font-rom">
                        Ready Stock
                      </h1>
                      <h1 className="text-black text-mobile ml-2 font-rom">
                        (Allow Pre-Order)
                      </h1>
                    </div>
                  ) : data?.data.stock > 0 &&
                    data?.data.is_pre_order === false ? (
                    <h1 className="text-mobile text-success font-rom">
                      Ready Stock
                    </h1>
                  ) : data?.data.stock <= 0 &&
                    data?.data.is_pre_order === true ? (
                    <h1 className="text-mobile text-yellow-300 font-rom">
                      Pre Order
                    </h1>
                  ) : data?.data.stock <= 0 &&
                    data?.data.is_pre_order === false ? (
                    <h1 className="text-mobile text-primary font-rom">
                      Out Of Stock
                    </h1>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-mobile">Stock (Ready)</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">{data?.data.stock}</h1>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-mobile">Stock (Pre-Order)</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">
                    {data?.data.pre_order_stock}
                  </h1>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-mobile">Product Sold</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">
                    {data?.data.sold_count}
                  </h1>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-mobile">Product Weight</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">{data?.data.weight}</h1>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-center mt-10">
            <Loading />
          </div>
        )}
        <footer className="h-24 p-3 px-10 flex justify-evenly items-center w-full left-0 bottom-0 bg-white shadow-inner fixed">
          <div
            onClick={goEdit}
            className="w-1/2 h-14 flex items-center justify-center  shadow-md rounded"
          >
            <h1 className="text-xs text-breadscrumbColor">Edit Variant</h1>
          </div>
          <div
            onClick={goDelete}
            className="w-1/2 h-14 ml-5 flex items-center justify-center bg-primary shadow-md rounded"
          >
            <h1 className="text-xs text-white">Delete Variant</h1>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default ProductVariantDetail;
