import React from "react";
import CircularLoad from "../loading/CircularLoad";

interface ButtonProps {
  handleClick?: any;
  isSubmitting?: boolean;
  dirty?: boolean;
  color?: string;
  hoverColor?: string;
  loadColor?: string;
  textColor?: string;
  text: string;
  type: string;
  border?: string;
  borderColor?: string;
  height?: number | string;
  width?: string;
  rounded?: string;
}

const SmallButton: React.FC<ButtonProps> = ({
  handleClick,
  isSubmitting,
  color,
  hoverColor,
  loadColor,
  textColor,
  text,
  dirty,
  type,
  border,
  borderColor,
  height,
  width,
  rounded,
}) => {
  return (
    <button
      type={type as any}
      disabled={isSubmitting || dirty === false ? true : false}
      onClick={handleClick}
      className={
        "focus:ring-0 focus:outline-none " +
        `${color ? color : "bg-primary"}  h-${height ? height : 9} w-${
          width ? width : 36
        }  ` +
        (isSubmitting || dirty === false
          ? "cursor-not-allowed"
          : `hover:${hoverColor ? hoverColor : "bg-opacity-70"} `) +
        (border ? ` ${border} ${borderColor}` : " ") +
        (rounded ? ` rounded-${rounded} ` : " rounded-full ")
      }
    >
      {isSubmitting ? (
        <div className="flex justify-center">
          <CircularLoad
            color={loadColor ? loadColor : "#ffffff"}
            height={17}
            width={17}
          />
        </div>
      ) : (
        <h1
          className={
            "md:text-base text-sm " + (textColor ? textColor : "text-white")
          }
        >
          {text}
        </h1>
      )}
    </button>
  );
};

export default SmallButton;
