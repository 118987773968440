import React from "react";
import { useQuery } from "react-query";
import { cancel } from "../../../../../assets/icons";
import Input from "../../../../../component/input/Input";
import axios from "../../../../../config/axiosInstance";

interface IProps {
  showFilter: boolean;
  setShowFilter: (showFilter: boolean) => void;
  filter: any;
  setFilter: any;
  refetch: () => void;
  applyChip: () => void;
}

const FilterProduct: React.FC<IProps> = ({
  showFilter,
  filter,
  setFilter,
  setShowFilter,
  applyChip,
  refetch,
}) => {
  const handleShowFilter = () => {
    setShowFilter(!showFilter);
    setFilter({
      ...filter,
      code: "",
      name: "",
      category: 0,
      status: "",
      release_date_from: "",
      release_date_to: "",
    });
  };

  const handleChangeFilter = (event: any) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name === "category") {
      Number(value);
    }
    setFilter({ ...filter, [name]: value });
  };

  const applyFilter = () => {
    refetch();
    applyChip();
    setTimeout(() => setShowFilter(!showFilter), 1000);
  };

  const getCategory = async () => {
    const result: any = await axios().get("public/retail-product-category");
    return result;
  };
  const { data: dataCategory, status: statusCategory } = useQuery(
    "category",
    getCategory
  );

  return (
    <div>
      {showFilter === false ? (
        ""
      ) : (
        <div className="fixed z-50 h-screen top-0 left-0 right-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
          <div
            className="bg-white md:rounded-lg rounded-t-none rounded-b-lg overflow-hidden shadow-xl transform transition-all md:max-w-2xl max-w-lg"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="px-7 py-5 flex justify-between w-full">
              <h1
                className="md:text-2xl text-lg leading-6 font-medium text-textPrimary"
                id="modal-headline"
              >
                Filter
              </h1>
              <img
                src={cancel}
                alt="back"
                className="cursor-pointer md:w-5 w-3"
                onClick={handleShowFilter}
              />
            </div>
            <hr className="w-full " />
            <div className="flex mx-4 p-3">
              <div className="mr-12 w-96">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Product Code
                </h1>
                <Input
                  type={"text"}
                  placeholder={"Place holder"}
                  name={"code"}
                  handleChange={handleChangeFilter}
                />
              </div>
              <div className=" w-96">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Product Name
                </h1>
                <Input
                  type={"text"}
                  placeholder={"Place holder"}
                  name={"name"}
                  handleChange={handleChangeFilter}
                />
              </div>
            </div>
            <hr className="w-full " />
            <div className="flex mx-4 p-3">
              <div className="mr-12 w-96">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Category
                </h1>
                <select
                  onChange={handleChangeFilter}
                  name="category"
                  id=""
                  className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke appearance-none transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
                >
                  <option selected disabled hidden>
                    Select Here
                  </option>
                  {dataCategory.data.data.map((category: any) => (
                    <option value={category.id}>{category.name}</option>
                  ))}
                </select>
              </div>
              <div className="w-96">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Product Status
                </h1>
                <select
                  onChange={handleChangeFilter}
                  name="status"
                  id=""
                  className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke appearance-none transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
                >
                  <option selected hidden>
                    Select Here
                  </option>
                  <option value="0">Waiting Approval</option>
                  <option value="1">Waiting for Revision</option>
                  <option value="2">On Selling</option>
                </select>
              </div>
            </div>
            <hr className="w-full" />
            {/* <div className="flex mx-4 p-3">
              <div className="mr-12 w-96">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Pre-Order
                </h1>
                <select
                  onChange={handleChangeFilter}
                  name="pre_order"
                  id=""
                  className="md:h-10 h-8 w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke appearance-none transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
                >
                  <option selected hidden>
                    Select Here
                  </option>
                  <option value="0">True</option>
                  <option value="1">False</option>
                </select>
              </div>
              <div className="w-96">
                <h1 className="md:text-base text-sm text-textPrimary">
                  limited Product
                </h1>
                <select
                  onChange={handleChangeFilter}
                  name="limited_product"
                  id=""
                  className="md:h-10 h-8 w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke appearance-none transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
                >
                  <option selected hidden>
                    Select Here
                  </option>
                  <option value="0">True</option>
                  <option value="1">False</option>
                </select>
              </div>
            </div>
            <hr className="w-full " /> */}
            <div className="flex mx-4 p-3">
              <div className="md:w-WDateInput w-34">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Release Date (From)
                </h1>
                <input
                  type="date"
                  onChange={handleChangeFilter}
                  name="release_date_from"
                  className="md:h-10 h-8 w-full font-rom rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
                />
              </div>
              <div className="md:w-WDateInput w-34 ml-12 md:ml-12">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Release Date (To)
                </h1>
                <input
                  type="date"
                  onChange={handleChangeFilter}
                  name="release_date_to"
                  className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
                />
              </div>
            </div>
            <hr className="w-full " />
            <div className="flex justify-center m-5">
              <button
                onClick={applyFilter}
                className="bg-primary w-36 h-9 rounded-full focus:ring-0 focus:outline-none hover:bg-red-300"
              >
                <h1 className="text-white text-base mb-0.5">Apply</h1>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterProduct;
