import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import axios from "../../../../config/axiosInstance";

//components
import Input from "../../../../component/input/Input";
import Loading from "../../../../component/loading/Loading";
import SuccessNav from "../../../../component/navbar/SuccessNav";
import Handler from "../../../../component/toast/Handler";
import WithdrawCheck from "./WithdrawCheck";

function WithdrawDetail() {
  const navigate = useNavigate();
  const [showWithdraw, setShowWithdraw] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
    }, 1500);
  }, [success]);

  const goBack = () => {
    navigate(`/admin/request-withdraw`);
  };

  const goProfile = () => {
    navigate(`/profile`);
  };

  const getDetailProfile = async () => {
    try {
      const results: any = await axios().get("retail/retail-shop");
      const result = results.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const { data, isSuccess, refetch } = useQuery("detail", getDetailProfile, {
    refetchOnWindowFocus: false,
  });

  const goWithdraw = (amount: number) => {
    if (amount <= 0) {
      Handler("error", "Your balance is Not enough!");
    } else {
      setShowWithdraw(!showWithdraw);
    }
  };
  return (
    <>
      {showWithdraw ? (
        <WithdrawCheck
          refetch={refetch}
          success={success}
          setSuccess={setSuccess}
          setShowWithdraw={setShowWithdraw}
          showWithdraw={showWithdraw}
        />
      ) : (
        ""
      )}

      {success ? <SuccessNav title={"Withdraw on Proccess"} /> : ""}
      <div className="bg-white p-4 font-med">
        {isSuccess ? (
          <div className="w-full h-full md:flex justify-between">
            {/* Withdraw */}
            <div className="md:w-1/3 w-full h-full">
              <div className="w-full h-full rounded-md rounded-b-none border-2 border-stroke">
                <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
                  <h1 className="font-medium md:text-xl text-sm">Withdraw</h1>
                </div>
                <hr />

                <div className="md:mx-8 mx-5 md:my-7 my-5">
                  <div>
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Wallet Balance
                    </h1>
                    <Input
                      type={"text"}
                      disable={true}
                      placeholder={data.retail_wallet.amount?.toLocaleString(
                        "EN-US",
                        { style: "currency", currency: "IDR" }
                      )}
                      name={"wallet"}
                    />
                  </div>
                </div>
              </div>

              {/*button continue*/}
              <div className="rounded-md rounded-t-none border-2 border-t-0  border-stroke flex w-full md:h-20 h-14">
                <div className="w-full flex items-center justify-center border-stroke">
                  <h1
                    onClick={() => goWithdraw(data.retail_wallet.amount)}
                    className="md:text-lg text-sm cursor-pointer text-primary hover:text-opacity-70"
                  >
                    Continue
                  </h1>
                </div>
              </div>
            </div>

            {/* Bank information */}
            <div className="md:w-custom h-full md:mt-0 mt-10">
              <div className="rounded-md rounded-b-none border-2 border-stroke">
                {/*Bank information title*/}
                <div className="md:h-16 h-14 flex items-center mx-8">
                  <h1 className="font-medium md:text-xl text-sm">
                    Bank Information
                  </h1>
                </div>
                <hr />

                <div className="md:flex w-full md:my-7 my-5">
                  {/*form bank left*/}
                  <div className="md:mx-8 mx-5 md:w-1/2">
                    <div>
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Bank Name
                      </h1>
                      <Input
                        type={"text"}
                        disable={true}
                        placeholder={data.retail_bank.name}
                        name={"retail_bank_name"}
                      />
                    </div>
                    <div className="my-4">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Account Number
                      </h1>
                      <Input
                        type={"number"}
                        disable={true}
                        placeholder={data.retail_bank.number}
                        name={"retail_bank_number"}
                      />
                    </div>
                  </div>

                  {/*form bank right*/}
                  <div className="md:mx-8 mx-5 md:w-1/2">
                    <div>
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Bank Account Name
                      </h1>
                      <Input
                        type={"text"}
                        disable={true}
                        placeholder={data.retail_bank.owner}
                        name={"retail_bank_owner"}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {/*button Edit*/}
              <div className="rounded-md rounded-t-none border-2 border-t-0 border-stroke flex w-full md:h-20 h-14 mb-10">
                <div className="w-1/2 flex items-center justify-center border-r-2 border-stroke ">
                  <button onClick={goBack} className="focus:outline-none">
                    <h1 className="md:text-xl text-sm cursor-pointer hover:text-primary">
                      Back
                    </h1>
                  </button>
                </div>
                <div className="w-1/2 flex items-center justify-center">
                  <button onClick={goProfile} className="focus:outline-none">
                    <h1 className="md:text-xl text-sm cursor-pointer hover:text-primary">
                      Edit
                    </h1>
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-center mt-10">
            <Loading />
          </div>
        )}
      </div>
    </>
  );
}

export default WithdrawDetail;
