import React from "react";

interface IProps {
  title: string
}

const SuccessNav:React.FC<IProps> = ({title}) => {
  return (
    <nav className="fixed md:mt-20 mt-14 font-med left-0 flex flex-wrap items-center justify-between px-10 py-2 w-full md:h-14 h-10 bg-success ease-in-out z-50">
      <div className="lg:flex items-center mx-auto justify-center">
        <h1 className="text-white md:text-base text-xs">{title}</h1>
      </div>
    </nav>
  );
}

export default SuccessNav;
