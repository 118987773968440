import { useMutation, useQuery, useQueryClient } from "react-query";
import { useLocation } from "react-router-dom";
import { RetailTransactionService } from "../../api/retail_transaction";
import { ERetailTransactionSortField } from "../../core/retail_transaction/entities";
import { useQueries } from "../../utils/QuerySearchParams";

const useFetchRetailTransactions = (date: {
  startDate: string;
  endDate: string;
}) => {
  const query = useQueries(useLocation);
  return useQuery(
    [
      "RetailTransactions",
      query.get("sort"),
      query.get("status"),
      query.get("code"),
      query.get("limit"),
      query.get("offset"),
      query.get("date"),
    ],
    () =>
      RetailTransactionService.getRetailTransactions(
        {
          offset: Number(query.get("offset")) || 0,
          limit: Number(query.get("limit")) || 10,
        },
        {
          status: query.get("status") || undefined,
          code: query.get("code") || undefined,
          date: query.get("date") || `${date.startDate},${date.endDate}`,
        },
        [
          {
            field: query.get("sort") || ERetailTransactionSortField.newest,
          },
        ]
      ),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 10000,
      staleTime: 20000,
    }
  );
};

const useFetchRetailTransaction = (code: string) => {
  return useQuery(
    [`RetailTransaction-${code}`],
    () => RetailTransactionService.getRetailTransaction(code),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const useFetchRetailTransactionCounter = (date: {
  startDate: string;
  endDate: string;
}) => {
  const query = useQueries(useLocation);
  return useQuery(["RetailTransactionsCounter", query.get("date")], () =>
    RetailTransactionService.getRetailTransactionCounter({
      date: query.get("date") || `${date.startDate},${date.endDate}`,
    })
  );
};

const useFetchRetailTransactionTrack = (code: string) => {
  return useQuery(
    [`RetailTransactionTrack-${code}`],
    () => RetailTransactionService.getRetailTransactionTrack(code),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 10000,
      staleTime: 20000,
    }
  );
};

const useMutateSendProduct = (
  code: string,
  setOpen: (isOpen: boolean) => void
) => {
  const queryClient = useQueryClient();
  return useMutation(
    (values: { pickup_time: string; is_simulated: boolean }) =>
      RetailTransactionService.sendProduct(code, values),
    {
      onMutate: () => {
        console.log("mutate send product to buyer");
      },
      onSettled: async (data) => {
        if (data?.message) {
          queryClient.invalidateQueries(`RetailTransaction-${code}`);
          setOpen(false);
        }
      },
    }
  );
};

export {
  useFetchRetailTransactions,
  useFetchRetailTransaction,
  useFetchRetailTransactionCounter,
  useFetchRetailTransactionTrack,
  useMutateSendProduct,
};
