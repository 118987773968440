import moment from "moment";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { TicketRequest } from "../../../api/ticket/Ticket";
import DynamicBreadcrumb from "../../../component/breadcrumb/DynamicBreadcrumb";
import SmallButton from "../../../component/button/Button";
import Input from "../../../component/input/Input";
import Loading from "../../../component/loading/Loading";
import NewPagination from "../../../component/pagination/NewPagination";
import { useQueries } from "../../../utils/QuerySearchParams";
import { optRoutesScan } from "./helpers/list";

const Scan = () => {
  const query = useQueries(useLocation);
  const { pathname, search, hash } = useLocation();
  const navigate = useNavigate();
  const [scanFrom, setScanFrom] = useState("");
  const [scanTo, setScanTo] = useState("");
  const [notes, setNotes] = useState<any>({});
  const { data, isLoading } = useQuery(
    [
      "getAllScanHistory",
      query.get("sort"),
      query.get("limit"),
      query.get("offset"),
      query.get("isAsc"),
      query.get("scan_date_from"),
      query.get("scan_date_to"),
    ],
    () => TicketRequest.getAllScanHistory(),
    {
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    const from = query.get("scan_date_from");
    const to = query.get("scan_date_to");
    if (from) setScanFrom(from);
    if (to) setScanTo(to);
  }, []);

  useEffect(() => {
    const from = query.get("scan_date_from");
    if (from !== null || scanFrom.length > 0) {
      let new_search =
        from !== null
          ? search.replace(
              `scan_date_from=${from}`,
              `scan_date_from=${scanFrom}`
            )
          : `${search}&&scan_date_from=${scanFrom}`;
      navigate({
        pathname,
        search: new_search,
        hash,
      });
    }
  }, [scanFrom]);

  useEffect(() => {
    const to = query.get("scan_date_to");
    if (to !== null || scanTo.length > 0) {
      let new_search =
        to !== null
          ? search.replace(`scan_date_to=${to}`, `scan_date_to=${scanTo}`)
          : `${search}&&scan_date_to=${scanTo}`;
      navigate({
        pathname,
        search: new_search,
        hash,
      });
    }
  }, [scanTo]);

  useEffect(() => {
    let tempNote: any = {};
    if (data) {
      data?.data?.data?.map(
        (el: any) => (tempNote[el.id] = { id: el.id, note: el.note })
      );
      setNotes(tempNote);
    }
  }, [data]);

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (note: { id: number; note: string }) => TicketRequest.addNoteScan(note),
    {
      onMutate: () => {
        console.log("mutate add add note");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          await queryClient.invalidateQueries("getAllScanHistory");
        }
      },
    }
  );

  const handleChangeNote = (e: any, id: number) => {
    const new_note = { ...notes };
    new_note[id].note = e.target.value;
    setNotes(new_note);
  };

  const SaveNote = (note: { id: number; note: string }) => {
    mutation.mutate(note);
  };

  return (
    <div className="bg-white p-4 font-med">
      <section id="top">
        {isLoading ? (
          <div className="flex justify-center mt-20">
            <Loading />
          </div>
        ) : (
          <div>
            <div className="">
              <div>
                <div className="flex items-center justify-end w-full">
                  <h1
                    onClick={() =>
                      navigate(
                        `/admin/ticket/1/stock-ticket/0/variant-transaction/scan-qr-code`
                      )
                    }
                    className="hover:text-primary cursor-pointer inline-flex ml-5 md:text-base text-sm"
                  >
                    Scan QR Code
                  </h1>
                </div>

                <hr className="my-3" />
                <div className="md:w-1/3 w-full flex mt-5">
                  <div className="w-1/2">
                    <h1 className="md:text-base text-sm">Scan Date (From)</h1>
                    <Input
                      type={"date"}
                      name={"scan_date_from"}
                      placeholder={"Place holder"}
                      value={scanFrom}
                      handleChange={(e: any) => setScanFrom(e.target.value)}
                    />
                  </div>
                  <div className="w-1/2 md:ml-5 ml-3">
                    <h1 className="md:text-base text-sm">Scan Date (to)</h1>
                    <Input
                      type={"date"}
                      name={"scan_date_to"}
                      placeholder={"Place holder"}
                      value={scanTo}
                      handleChange={(e: any) => setScanTo(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <section>
              {!data?.data?.data?.length ? (
                <div className="flex justify-center my-20 md:text-lg text-base">
                  <h1>Empty Data</h1>
                </div>
              ) : (
                <>
                  <div className="my-7">
                    {data?.data?.data?.map((el: any, i: number) => (
                      <section>
                        <div className="w-full my-5 items-center px-5 flex justify-between">
                          <div className="w-full">
                            <div className="flex justify-between w-full">
                              <h1 className="md:text-base text-sm">
                                {el.label || `scan-#${i + 1}`}
                              </h1>
                              <h1 className="md:text-base text-sm font-rom">
                                {moment(el.used_at).format("ll hh:mm a")}
                              </h1>
                            </div>
                            <h1 className="md:text-base text-sm font-rom">
                              Customer : {el.username || "-"}
                            </h1>
                            <div className="flex items-center w-full">
                              <h1 className="mr-5">Notes:</h1>
                              <div className="w-full flex items-center">
                                <Input
                                  type={"text"}
                                  name={"notes"}
                                  value={
                                    notes[el.id]?.id === el.id
                                      ? notes[el.id]?.note
                                      : ""
                                  }
                                  handleChange={(e: any) =>
                                    handleChangeNote(e, el.id)
                                  }
                                />
                                <SmallButton
                                  loadColor={"white"}
                                  type={"button"}
                                  isSubmitting={
                                    mutation.variables?.id === el.id &&
                                    mutation.isLoading
                                  }
                                  handleClick={() => SaveNote(notes[el.id])}
                                  color={"bg-primary"}
                                  hoverColor={"opacity-70"}
                                  text={"Save"}
                                  textColor={"text-white"}
                                  height={10}
                                  rounded="r-md"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr />
                      </section>
                    ))}
                  </div>
                  <NewPagination
                    length={data?.data?.data?.length}
                    total={data?.data?.meta?.total}
                    isTable={false}
                  />
                </>
              )}
            </section>
          </div>
        )}
      </section>
    </div>
  );
};

export default Scan;
