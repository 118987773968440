import { useContext } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { TicketRequest } from "../../../../../api/ticket/Ticket";
import { back } from "../../../../../assets/icons";
import Loading from "../../../../../component/loading/Loading";
import { ActionType } from "../../../../../store/ticket/action";
import TicketContext from "../../../../../store/ticket/context";
import { formatRupiah } from "../../../../../utils/formatRupiah";
import DeleteTicketVariantModal from "../stock_ticket_component/DeleteTicketVariantModal";

const StockTicketDetail: React.FC = () => {
  const { TicketDispatch, TicketState } = useContext(TicketContext);
  const { showDeleteTicketVariant } = TicketState;
  const navigate = useNavigate();
  const params = useParams();

  const goBack = () => {
    navigate(`/admin/ticket/${params.id}/stock-ticket`);
  };

  const goEdit = () => {
    navigate(
      `/admin/ticket/${params.id}/stock-ticket/edit-ticket-variant/${params.variantId}`
    );
  };

  const goDetailSoldTiket = () => {
    navigate(
      `/admin/ticket/${params.id}/stock-ticket/${params.variantId}/variant-transaction`
    );
  };

  const goDelete = () => {
    TicketDispatch({
      type: ActionType.ShowDeleteTicketVariant,
      payload: { status: true, id: Number(params.variantId) || 0 },
    });
  };

  const { data, isSuccess } = useQuery("getTicketVariantById", () =>
    TicketRequest.getTicketVariantById(params.variantId || "")
  );

  return (
    <div className="mb-28 font-med">
      {showDeleteTicketVariant.status && <DeleteTicketVariantModal />}
      <div className="md:pt-28 pt-20 overflow-auto">
        <div onClick={goBack} className="flex items-center">
          <img src={back} alt="back" />
          <h1 className="text-mobile ml-1.5">Back</h1>
        </div>

        {isSuccess ? (
          <div>
            <div className="mt-12 flex justify-center">
              <img
                className="rounded-md shadow-md w-56"
                src={data?.preview_image_path}
                alt=""
              />
            </div>
            <div className="px-5 mt-12">
              <div>
                <h1 className="text-mobile">Variant</h1>
                <div className="flex items-center border-2 mt-2 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">{data.label}</h1>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-mobile">Price</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">
                    {formatRupiah(data.price) || "-"}
                  </h1>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-mobile">Stock</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">{data.stock}</h1>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-mobile">Ticket Sold</h1>
                <div className="flex items-center justify-between border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">{data.sold_count}</h1>
                  {data.sold_count > 0 && (
                    <h1
                      onClick={goDetailSoldTiket}
                      className="text-mobile font-rom cursor-pointer"
                    >
                      Detail
                    </h1>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-center mt-10">
            <Loading />
          </div>
        )}
      </div>
      <footer className="h-24 py-3 px-10 flex justify-evenly items-center w-full left-0 bottom-0 bg-white shadow-inner fixed">
        <div
          onClick={goEdit}
          className="w-1/2 h-14 flex items-center justify-center border-disableColor border-2 shadow-md rounded"
        >
          <h1 className="text-xs text-textPrimary">Edit Ticket</h1>
        </div>
        <div
          onClick={goDelete}
          className="w-1/2 ml-4 h-14 flex items-center justify-center bg-primary shadow-md rounded"
        >
          <h1 className="text-xs text-white">Delete Ticket</h1>
        </div>
      </footer>
    </div>
  );
};

export default StockTicketDetail;
