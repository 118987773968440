import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import axiosInstance from "../../../../../config/axiosInstance";

import { cancel, next } from "../../../../../assets/icons";
import ContactSkeletonLoading from "../../../../../component/loading/SkeletonLoading";

export interface ProductListProps {
  data: any;
  sortValue: string;
  isAsc: string;
  detail: boolean;
  filterAp: void | any;
  setDetail: (detail: boolean) => void;
}

const ProductList: React.FC<ProductListProps> = ({
  data,
  sortValue,
  isAsc,
  detail,
  setDetail,
  filterAp
}) => {
  const navigate = useNavigate();
  const{ pathname } = useLocation();
  const dataProduct = data?.data?.data;
  const meta = data?.data?.meta?.total;
  const [product, setProduct] = useState<any>(dataProduct);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(10);
  const [more, setMore] = useState<boolean>(true);
  const [detailMsg, setdetailMsg] = useState<string | any>("");

  const goDetailProduct = (id: number) => {
    navigate(`${pathname}/${id}`);
  };

  useEffect(() => {
    setProduct(dataProduct);
  }, [data]);

  const getProducts = async () => {
    try {
      const results: any = await axiosInstance().get(`retail/retail-product`, {
        params: {
          limit: limit,
          offset: offset,
          sort: `${sortValue} ${isAsc}`,
        },
      });
      const result = await results.data.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMoredata = async () => {
    const new_data = [...product, ...(await getProducts())];
    setProduct(new_data);
  };

  useEffect(() => {
    if (meta - product?.length < limit) {
      setMore(false);
    } else {
      setMore(true);
    }
  }, [product?.length]);

  useEffect(() => {
    if (product?.length > limit) {
      setOffset(offset + 10);
    }
  }, [product?.length]);

  const showDetail = (msg?: string) => {
    if (detail) {
      setdetailMsg("");
      setDetail(false);
    } else {
      setdetailMsg(msg);
      setDetail(true);
    }
  };

  return (
    <div className="w-full mb-20">
       {detail ? (
        <div className="w-full min-h-full bg-black bg-opacity-70 inset-0 fixed z-50 flex justify-center items-center">
          <div className="md:w-WModal md:h-Hmodal w-72 h-auto md:py-6 py-4 bg-white rounded-xl shadow-md absolute">
            <div className="flex justify-between md:px-10 px-5">
              <div className="flex items-center">
                <h1 className="md:text-lg flex text-primary">Product</h1>
                <h1 className="md:text-lg text-black ml-1.5">Rejected</h1>
              </div>
              <img
                src={cancel}
                alt="cancel"
                onClick={() => showDetail()}
                className="cursor-pointer md:w-5 w-3"
              />
            </div>
            <hr className="mt-5" />
            <div className="flex flex-col md:mt-3 mt-0 md:px-10 px-5">
              <textarea
                value={detailMsg || "-"}
                disabled
                className="md:h-44 h-32 font-rom w-full rounded-md md:text-base text-xs my-3 px-3 py-2 border-2 border-stroke focus:ring-1 focus:outline-none"
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      
      <InfiniteScroll
        initialLoad={false}
        loadMore={fetchMoredata}
        hasMore={more}
        loader={<ContactSkeletonLoading />}
      >
        {product.map((el: any) => (
          <div key={el.id}>
            <div
              className="flex h-20 items-center"
              onClick={() => goDetailProduct(el.id)}
            >
              <div>
                <img
                  src={el.display_image_path}
                  className="w-18 h-18 shadow-sm rounded-md"
                  alt="img"
                />
              </div>
              <div className="flex ml-4 items-center justify-around w-3/4">
                <div className="flex flex-col">
                  <div className="w-56">
                    <h1 className="text-sm">{el.name}</h1>
                  </div>
                  <div className="flex mt-2">
                    <h1 className="text-sm">Status :</h1>
                    {el.status === 2 && el.is_active === false ? (
                      <h1 className="text-sm ml-1.5 text-primary">
                        {el.status_message}
                      </h1>
                    ) : el.status === 2 ? (
                      <h1 className="text-sm ml-1.5 text-success">
                        {el.status_message}
                      </h1>
                    ) : el.status === 1 ? (
                      <h1 className="text-sm ml-1.5 text-primary">
                        {el.status_message}
                        {el.status === 1 ? (
                          <h1
                            onClick={() => showDetail(el.rejected_reason)}
                            className="text-xs font-rom text-black hover:text-primary cursor-pointer"
                          >
                            Details
                          </h1>
                        ) : (
                          ""
                        )}
                      </h1>
                    ) : el.status === 0 ? (
                      <h1 className="text-sm ml-1.5 text-yellow-300">
                        {el.status_message}
                      </h1>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div>
                  <img src={next} alt="imgs" className="w-3 h-3" />
                </div>
              </div>
            </div>
            <hr className="my-4" />
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default ProductList;
