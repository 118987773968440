import { useQuery } from "react-query";
import { RetailUserService } from "../../api/retail_user";

const useFetchRetailProfile = () => {
  return useQuery(
    [`retail-user`],
    () => RetailUserService.getRetailUser(),
    {
      refetchOnWindowFocus: false,
    }
  );
};

export { useFetchRetailProfile };
