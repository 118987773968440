import _ from "lodash";
import AxiosInstance from "../../../config/Instance";
import { IBuiltinSort, IMeta, IPagination } from "../../helpers/helper";
import {
  ERetailTransactionTicketSortField,
  IRetailTransactionTicket,
  IRetailTransactionTicketCounter,
  PRetailTransactionTicketFilter,
} from "../entities";
import IRetailTransactionTicketRepository from "../repository";

export default class RetailTransactionTicketRepository
  implements IRetailTransactionTicketRepository
{
  // api routes
  private RETAIL_TRANSACTION_TICKET_ROUTE = "/admin/purchase/";
  private RETAIL_TRANSACTION_TICKET_COUNTER_ROUTE = "/admin/purchase/counter/";

  buildFilter(filter: PRetailTransactionTicketFilter) {
    const created_at_after = filter.date?.split(",")[0];
    const created_at_before = filter.date?.split(",")[1];
    const status__in = filter.status;
    const search = filter.code
    const clonedFilter = { ...filter, status__in , search};
    delete clonedFilter.date;
    delete clonedFilter.status;
    delete clonedFilter.code;
    return {
      ...clonedFilter,
      created_at_after,
      created_at_before,
    };
  }

  buildSort(sort: IBuiltinSort<ERetailTransactionTicketSortField>[]) {
    let strSort = "";
    sort.map((el) => (strSort += el.field));
    return strSort;
  }

  async getRetailTransactionTickets(
    pagination: IPagination,
    filter: PRetailTransactionTicketFilter,
    sorts: IBuiltinSort<ERetailTransactionTicketSortField>[]
  ): Promise<[IRetailTransactionTicket[], IMeta]> {
    try {
      const params = _.pickBy(
        {
          ...pagination,
          ...this.buildFilter(filter),
        },
        (val) => val
      );

      const sort = this.buildSort(sorts);

      const response = await AxiosInstance.Dicket().get(
        `${this.RETAIL_TRANSACTION_TICKET_ROUTE}?ordering=${sort}`,
        {
          params,
        }
      );
      const { data, meta } = response.data;
      return [data, meta];
    } catch (error) {
      throw error;
    }
  }

  async getRetailTransactionTicket(
    code: string
  ): Promise<IRetailTransactionTicket> {
    try {
      const response = await AxiosInstance.Dicket().get(
        `${this.RETAIL_TRANSACTION_TICKET_ROUTE}${code}/`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getRetailTransactionTicketCounter(
    filter: PRetailTransactionTicketFilter
  ): Promise<IRetailTransactionTicketCounter[]> {
    try {
      const params = _.pickBy(
        {
          ...this.buildFilter(filter),
        },
        (val) => val
      );

      const response = await AxiosInstance.Dicket().get(
        `${this.RETAIL_TRANSACTION_TICKET_COUNTER_ROUTE}`,
        {
          params,
        }
      );
      const { counter } = response.data;
      return counter;
    } catch (error) {
      throw error;
    }
  }
}
