import _ from "lodash";
import AxiosInstance from "../../../config/Instance";

import { IRetailStatistic, IRetailMonthlyRevenue } from "../entities";
import IRetailStatisticRepository from "../repository";

export default class RetailStatisticRepository
  implements IRetailStatisticRepository
{
  // api routes
  private RETAIL_STATISTIC_ROUTE = "/retail/statistic";

  private getError(error: unknown) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  async getRetailStatistic(): Promise<IRetailStatistic> {
    try {
      const response = await AxiosInstance.Mercury().get(
        `${this.RETAIL_STATISTIC_ROUTE}`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getRetailMonthlyRevenue(
    month: string,
    year: string
  ): Promise<IRetailMonthlyRevenue> {
    try {
      const response = await AxiosInstance.Mercury().get(
        `${this.RETAIL_STATISTIC_ROUTE}/revenue`,
        {
          params: {
            month,
            year,
          },
        }
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw error;
    }
  }
}
