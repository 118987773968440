import { FC } from "react";
import { IIconProps } from "./Ahome";

export const Amoney: FC<IIconProps> = ({
  stroke = "#666666",
  width = "35",
  height = "21",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 35 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M32.9598 1.32214H2.21109C1.55793 1.32214 1.02844 1.83183 1.02844 2.46055V18.3983C1.02844 19.027 1.55793 19.5367 2.21109 19.5367H32.9598C33.613 19.5367 34.1425 19.027 34.1425 18.3983V2.46055C34.1425 1.83183 33.613 1.32214 32.9598 1.32214Z"
        stroke={stroke}
        strokeWidth="1.64542"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5853 14.9831C20.1979 14.9831 22.3159 12.9443 22.3159 10.4294C22.3159 7.91452 20.1979 5.87579 17.5853 5.87579C14.9727 5.87579 12.8547 7.91452 12.8547 10.4294C12.8547 12.9443 14.9727 14.9831 17.5853 14.9831Z"
        stroke={stroke}
        strokeWidth="1.64542"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6813 1.32214L34.1424 9.29101"
        stroke={stroke}
        strokeWidth="1.64542"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.6813 19.5367L34.1424 11.5678"
        stroke={stroke}
        strokeWidth="1.64542"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4896 1.32214L1.02844 9.29101"
        stroke={stroke}
        strokeWidth="1.64542"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.4896 19.5367L1.02844 11.5678"
        stroke={stroke}
        strokeWidth="1.64542"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
