import moment from "moment";
import React, { useState } from "react";
import { down, up } from "../../../../../assets/icons";
import Input from "../../../../../component/input/Input";

export interface ComplainProps {
  data: {
    retail_transaction_complain: {
      status_message: string;
      action_message: string;
      message: string;
      created_at: string;
    };
  };
}

const Complain: React.FC<ComplainProps> = ({ data }) => {
  const [dropdownComplain, setdropdownComplain] = useState<boolean>(true);

  const handledropdownComplain = () => {
    setdropdownComplain(!dropdownComplain);
  };

  return (
    <div className={"w-full h-full my-8 " + (data.retail_transaction_complain === undefined ? 'hidden' : '')}>
      <div className="rounded-md border-2 border-stroke">
        {/*Complain information title and toggle*/}
        <div className="md:h-16 h-14 flex items-center justify-between md:mx-8 mx-5">
          <h1 className="font-medium md:text-xl text-base">Complain</h1>

          <div className="flex">
            <div className="flex justify-between items-center">
              {dropdownComplain ? (
                <img
                  src={up}
                  alt="up"
                  className="duration-300 ease-in-out cursor-pointer md:w-5 w-4"
                  onClick={handledropdownComplain}
                />
              ) : (
                <img
                  src={down}
                  alt="up"
                  className="duration-300 ease-in-out cursor-pointer md:w-5 w-4"
                  onClick={handledropdownComplain}
                />
              )}
            </div>
          </div>
        </div>
        {dropdownComplain ? <hr className="border-t-2" /> : ""}

        {dropdownComplain ? (
          <div>
             <div className="md:flex w-full md:pt-5 pt-4">
              {/*Complain data left*/}
              <div className="md:mx-8 mx-5 md:w-1/2">
                <div>
                  <h1 className="md:text-base text-sm  text-textPrimary">
                    Status
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"status_message"}
                    value={data?.retail_transaction_complain?.status_message}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm  text-textPrimary">
                    Complain Date
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"date"}
                    value={moment(
                      data.retail_transaction_complain?.created_at
                    ).format("llll")}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>

              {/*Complain data right*/}
              <div className="md:mx-8 mx-5 md:w-1/2">
                <div>
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Complain Action
                  </h1>
                   <Input
                    disable={true}
                    type={"text"}
                    name={"action_message"}
                    value={data?.retail_transaction_complain?.action_message}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>
            </div>

            {/*Complain Message*/}
            <div className="md:my-0 md:mb-2 my-4 md:mx-8 mx-5">
              <div>
                <h1 className="md:text-base text-sm text-textPrimary">Complain Message</h1>
                <textarea
                  disabled
                  placeholder="Place holder"
                  value={data?.retail_transaction_complain?.message}
                  className="md:h-28 font-rom h-24 w-full rounded-md md:text-sm text-xs my-3 px-3 py-2 border-2 border-stroke"
                ></textarea>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Complain;
