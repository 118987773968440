import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import { next } from "../../../../assets/icons";
import ContactSkeletonLoading from "../../../../component/loading/SkeletonLoading";
import axiosDicket from "../../../../config/axiosDicket";

const getTicket = async (limit: number, offset: number) => {
  try {
    const results: any = await axiosDicket().get(`admin/ticket/`, {
      params: {
        limit: limit,
        offset: offset,
        //   sort: `${sortValue} ${isAsc}`,
      },
    });
    const result = await results.data;
    return result;
  } catch (error) {
    console.log(error);
  }
};

export interface TicketListProps {
  data: any;
}

const TicketList: React.FC<TicketListProps> = ({ data }) => {
  const navigate = useNavigate();
  const{ pathname } = useLocation();
  const dataProduct = data?.data?.data;
  const meta = data?.data?.meta?.total;
  const [product, setProduct] = useState<any>(dataProduct);
  const [more, setMore] = useState<boolean>(true);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(10);

  const goDetailProduct = (id: number) => {
    navigate(`${pathname}/${id}`);
  };

  useEffect(() => {
    setProduct(dataProduct);
  }, [data]);

  const fetchMoredata = async () => {
    const data = await getTicket(limit, offset);
    const new_data = [...product, ...data?.data];
    setProduct(new_data);
  };

  useEffect(() => {
    if (meta - product?.length < limit) {
      setMore(false);
    } else {
      setMore(true);
    }
  }, [product?.length]);

  useEffect(() => {
    if (product?.length > limit) {
      setOffset(offset + 10);
    }
  }, [product?.length]);

  return (
    <div className="w-full">
      <InfiniteScroll
        initialLoad={false}
        loadMore={fetchMoredata}
        hasMore={more}
        loader={<ContactSkeletonLoading />}
      >
        {product?.map((el: any) => (
          <div key={el.id} className="w-full">
            <div
              className="flex  items-center w-full justify-between"
              onClick={() => goDetailProduct(el.id)}
            >
              <div className="w-1/4 p-2">
                <img
                  src={el.preview_image_path}
                  className="w-full h-full shadow-sm rounded-md"
                  alt="img"
                />
              </div>
              <div className="flex items-center w-3/4 pl-2 justify-between">
                <div className="flex flex-col">
                  <div className="">
                    <h1 className="text-sm">{el.name}</h1>
                  </div>
                  <div className="flex mt-1">
                    <h1 className="text-sm">Status :</h1>
                    {el.status === "on_selling" ? (
                      <h1 className="text-sm ml-1.5 text-success">
                        {el.status.split("_").join(" ")}
                      </h1>
                    ) : el.status === "awaiting_approval" ||
                      el.status === "awaiting_revision" ? (
                      <h1 className="text-sm ml-1.5 text-yellow-300">
                        {el.status.split("_").join(" ")}
                      </h1>
                    ) : (
                      <h1 className="text-sm ml-1.5 text-primary">
                        {el.status.split("_").join(" ")}
                      </h1>
                    )}
                  </div>
                </div>
                <div>
                  <img src={next} alt="imgs" className="w-3 h-3" />
                </div>
              </div>
            </div>
            <hr className="my-4" />
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default TicketList;
