import { IBuiltinSort, IMeta, IPagination } from "../../helpers/helper";
import {
  ERetailTransactionSortField,
  IRetailTransaction,
  IRetailTransactionCounter,
  IRetailTransactionTrack,
  PRetailTransactionFilter,
} from "../entities";
import IRetailTransactionRepository from "../repository";
import IRetailTransactionUsecase from "../usecase";

export default class RetailTransactionUsecase
  implements IRetailTransactionUsecase
{
  private RetailTransactionRepo: IRetailTransactionRepository;

  constructor(RetailTransactionRepo: IRetailTransactionRepository) {
    this.RetailTransactionRepo = RetailTransactionRepo;
  }

  async getRetailTransactions(
    pagination: IPagination,
    filter: PRetailTransactionFilter,
    sorts: IBuiltinSort<ERetailTransactionSortField>[]
  ): Promise<[IRetailTransaction[], IMeta]> {
    const [data, meta] = await this.RetailTransactionRepo.getRetailTransactions(
      pagination,
      filter,
      sorts
    );
    return [data, meta];
  }

  async getRetailTransaction(code: string): Promise<IRetailTransaction> {
    const RetailTransaction =
      await this.RetailTransactionRepo.getRetailTransaction(code);
    return RetailTransaction;
  }

  async getRetailTransactionTrack(
    code: string
  ): Promise<IRetailTransactionTrack[]> {
    const RetailTransactionTrack =
      await this.RetailTransactionRepo.getRetailTransactionTrack(code);
    return RetailTransactionTrack;
  }

  async getRetailTransactionCounter(
    filter: PRetailTransactionFilter
  ): Promise<IRetailTransactionCounter[]> {
    const data = await this.RetailTransactionRepo.getRetailTransactionCounter(
      filter
    );
    return data;
  }

  async sendProduct(
    code: string,
    payload: { pickup_time: string, is_simulated: boolean }
  ): Promise<{ message: string }> {
    const data = await this.RetailTransactionRepo.sendProduct(
      code,
      payload
    );
    return data;
  }
}
