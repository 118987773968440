import React, { useReducer } from "react";
import { Route, Routes } from "react-router-dom";
import RequestWithdraw from "../../pages/admin/requestWithdraw/RequestWithdraw";
import WithdrawDetail from "../../pages/admin/requestWithdraw/withdrawComponent/WithdrawDetail";
import {
  initialTicketState,
  TicketContextProvider,
} from "../../store/ticket/context";
import { TicketReducer } from "../../store/ticket/reducer";

const RequestWithdrawRoute = () => {
  const [TicketState, TicketDispatch] = useReducer(
    TicketReducer,
    initialTicketState
  );

  return (
    <TicketContextProvider value={{ TicketState, TicketDispatch }}>
      <Routes>
        <Route path="/" element={<RequestWithdraw />} />
        <Route path="details" element={<WithdrawDetail />} />
      </Routes>
    </TicketContextProvider>
  );
};

export default RequestWithdrawRoute;
