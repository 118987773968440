import React from "react";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate, formatRupiah } from "../../helpers/common";
import Abutton from "../atoms/Abutton";
import MproductListCard from "./MproductListCard";
import {
  ERetailTransactionTicketStatus,
  IRetailTransactionTicket,
} from "../../core/retail_transaction_ticket/entities";
import Countdown from "react-countdown";
import Acountdown from "../atoms/Acountdown";

export const renderStatusColor = (status: string) => {
  const statusColor: any = {
    success: "text-success",
    awaiting_payment: "text-warning",
    expired: "text-primary",
    failed: "text-primary",
    canceled: "text-primary",
    invalid: "text-primary",
  };
  return statusColor[status];
};

interface IMtransactionTicketCardProps {
  data: IRetailTransactionTicket;
}

const MtransactionTicketCard: React.FC<IMtransactionTicketCardProps> = ({
  data,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  // const [more, setMore] = useState(false);
  // const length = 1;

  const goDetail = (code: string) => {
    navigate(`${pathname}/${code}`);
  };
  const goDetailMobile = (code: string) => {
    navigate(`${pathname}/detail/${code}`);
  };

  return (
    <aside className="w-full bg-white rounded-lg shadow-md">
      <article className="sm:px-5 sm:pt-5 sm:pb-5 px-3 pt-3 pb-0 flex justify-between sm:border-b-2 sm:text-sm text-xs">
        <div className="sm:flex hidden xl:flex-row flex-col gap-1 w-2/3">
          <span className="text-primary">{data.code}</span>
          <span>| {data.username} |</span>
          <span className="text-textSecondary">
            Transaction Date : {moment(data.created_at).format("llll")}
          </span>
        </div>

        <div className="sm:w-auto w-full gap-1">
          {data.status === ERetailTransactionTicketStatus.WaitingPayment && (
            <div className="flex justify-between items-center ">
              <h1 className="font-rom mr-1">Time Remaining:</h1>
              <Countdown
                zeroPadTime={2}
                date={data.expired_at}
                renderer={(props) => (
                  <Acountdown
                    seconds={props.seconds}
                    minutes={props.minutes}
                    hours={props.hours}
                    days={props.days}
                    completed={props.completed}
                  />
                )}
              />
            </div>
          )}
          <hr className="sm:hidden mt-1.5" />
        </div>
      </article>

      <article className="sm:p-5 p-3">
        <aside className="grid sm:grid-cols-3 grid-cols-1 gap-7">
          <div className="sm:border-r-2 sm:pr-5">
            <MproductListCard
              image={data?.image_url}
              name={data.title || "-"}
              variant={data.sub_title}
              quantity={data.quantity}
            />
            <hr className="mt-3 sm:block hidden" />
            {/* {more && length > 1 ? (
              <div>
                <div className="w-full flex justify-between mt-5">
                  <div className="flex gap-4 text-textPrimary">
                    <div>
                      <img src={logo} alt="-" className="rounded-md" />
                    </div>
                    <div>
                      <h1>T-shirt for Man</h1>
                      <span className="font-rom text-sm">Variant : Black</span>
                    </div>
                  </div>

                  <div className="text-textPrimary">
                    <h1>x2</h1>
                  </div>
                </div>
                <hr className="mt-5" />
                <div className="w-full flex justify-between mt-5">
                  <div className="flex gap-4 text-textPrimary">
                    <div>
                      <img src={logo} alt="-" className="rounded-md" />
                    </div>
                    <div>
                      <h1>T-shirt for Man</h1>
                      <span className="font-rom text-sm">Variant : Black</span>
                    </div>
                  </div>

                  <div className="text-textPrimary">
                    <h1>x2</h1>
                  </div>
                </div>
                <hr className="mt-5" />
                <div className="flex font-rom text-sm cursor-pointer text-textPrimary hover:text-textSecondary my-2">
                  <span onClick={() => setMore(!more)}>Hide products</span>
                </div>
              </div>
            ) : length > 1 ? (
              <div className="flex font-rom text-sm cursor-pointer text-textPrimary hover:text-textSecondary my-2">
                <span
                  onClick={() => setMore(!more)}
                >{`See ${length} more products`}</span>
              </div>
            ) : (
              ""
            )} */}
          </div>

          <div className="sm:flex hidden flex-col border-r-2 pr-5 text-textPrimary text-xs">
            <h1 className="text-sm">Valid Until</h1>
            <p className="font-rom mt-0.5">
              {!data.valid_time_indefinite
                ? formatDate(data.valid_time_until, "llll")
                : "-"}
            </p>
          </div>

          <div className="pr-5 sm:flex hidden flex-col text-textPrimary text-xs">
            {/* <h1 className="flex text-sm items-center">
              Courier <span className="ml-1 font-rom text-xs">(Must Same)</span>
            </h1>
            <span className="font-rom mt-0.5">
              {data.shipping_provider} - Regular (
              {formatRupiah(data.buyer_shipping_cost)})
            </span> */}
            <h1 className="mt-0.5">Status</h1>
            <span className={"mt-0.5 " + renderStatusColor(data.status)}>
              {data.status?.split("_").join(" ")}
            </span>
          </div>
        </aside>
        <aside className="sm:block hidden my-4">
          <div className="bg-stroke rounded-md py-2.5 px-5 flex justify-between text-xs">
            <h1>Total Selling</h1>
            <h1 className="text-primary">
              {formatRupiah(data.total_price || 0)}
            </h1>
          </div>
        </aside>
        <hr className="sm:block hidden" />
      </article>

      <article className="sm:px-5 px-3 sm:pb-5 pb-3 sm:pt-0 pt-3 flex justify-end sm:border-t-0 border-t">
        <div className="sm:flex hidden gap-5">
          {/* <Abutton title="Cancel Transaction" theme="secondary" model="long" /> */}
          <Abutton
            title="Details"
            theme="primary"
            model="long"
            onClick={() => goDetail(data.code)}
          />
        </div>
        <div className="sm:hidden flex justify-center w-full">
          <h1
            onClick={() => goDetailMobile(data.code)}
            className="text-xxs cursor-pointer hover:text-primary"
          >
            Details
          </h1>
        </div>
      </article>
    </aside>
  );
};

export default MtransactionTicketCard;
