import React, { useContext } from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router";
import { SubAccountRequest } from "../../../api/sub_account/SubAccountRequest";
import DynamicBreadcrumb from "../../../component/breadcrumb/DynamicBreadcrumb";
import CircularLoad from "../../../component/loading/CircularLoad";
import Loading from "../../../component/loading/Loading";
import NewPagination from "../../../component/pagination/NewPagination";
import { ActionType } from "../../../store/sub_account/action";
import SubAccountContext from "../../../store/sub_account/context";
import { useQueries } from "../../../utils/QuerySearchParams";
import { optRoutesSubAccount } from "./helpers/list";
import AccountCard from "./sub_account_component/AccountCard";
import AddAccountModal from "./sub_account_component/AddAccountModal";
import DeleteAccountModal from "./sub_account_component/DeleteAccountModal";

const SubAccount = () => {
  const query = useQueries(useLocation);
  const { SubAccountState, SubAccountDispatch } = useContext(SubAccountContext);
  const { showAddSubAccount, showDeleteSubAccount } = SubAccountState;
  const { data, isLoading, isFetching } = useQuery(
    [
      "getSubAccount",
      query.get("sort"),
      query.get("limit"),
      query.get("offset"),
      query.get("isAsc"),
    ],
    SubAccountRequest.getSubAccount,
    {
      refetchOnWindowFocus: false,
      staleTime: 5000,
      refetchInterval: 10000,
    }
  );

  return (
    <div className="bg-white p-4 font-med">
      <section className="" id="top">
        {showAddSubAccount && <AddAccountModal />}
        {showDeleteSubAccount.status && <DeleteAccountModal />}

        {isLoading ? (
          <div className="flex justify-center my-20">
            <Loading />
          </div>
        ) : (
          <div>
            <div className="">
              <div className="">
                <div className="flex justify-end">
                  <h1
                    onClick={() =>
                      SubAccountDispatch({
                        type: ActionType.ShowAddSubAccount,
                      })
                    }
                    className="hover:text-primary md:text-base cursor-pointer inline-flex ml-5"
                  >
                    Add Account
                  </h1>
                </div>

                {/* <div className="flex justify-between mt-2">
                  <div className="flex">
                    <h1
                      onClick={() =>
                        SubAccountDispatch({
                          type: ActionType.ShowFilter,
                        })
                      }
                      className="text-base mr-5 cursor-pointer hover:text-primary"
                    >
                      Show Filter
                    </h1>
                  </div>
                  <NewSorting option{optSortingSizing} />
                </div> */}

                {/* <Chip
                  queryParams={queryparamsSizing}
                  ActionType={ActionType}
                  dispatchContext={SizingDispatch}
                  stateContext={SizingState}
                /> */}
              </div>
              <div className="flex justify-end my-4">
                {isFetching ? (
                  <div className="flex items-center">
                    <CircularLoad color="#DF5354" height={20} width={20} />
                    <h1 className="ml-2">Updating</h1>
                  </div>
                ) : (
                  <h1>Auto Update</h1>
                )}
              </div>
            </div>
            <section id="body" className="">
              {isLoading ? (
                <div className="flex justify-center mt-20">
                  <Loading />
                </div>
              ) : (
                <div className="w-full grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 mb-10">
                  {data?.data?.data?.map((el: any) => (
                    <AccountCard data={el} key={el.id} />
                  ))}
                </div>
              )}

              <NewPagination
                length={data?.data?.data?.length}
                total={data?.data?.meta?.total}
                isTable={false}
              />
            </section>
          </div>
        )}
      </section>
    </div>
  );
};

export default SubAccount;
