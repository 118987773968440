/* eslint-disable react-hooks/exhaustive-deps */
import jwtDecode from "jwt-decode";
import moment from "moment";
import React, { useEffect, useState } from "react";
import CircularLoad from "./component/loading/CircularLoad";
import Wrapper from "./components/organisms/Wrapper";
import { getWithExpiry } from "./config/axiosInstance";
import useAuth from "./helpers/useAuth";
import MainRoute from "./routes/Route";

function App() {
  const { setAuth } = useAuth();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const token = getWithExpiry("retail_token");
    if (token) {
      const decoded: { exp: number; role: string; username: string } =
        jwtDecode(token);
      if (decoded.exp * 1000 < moment().valueOf()) {
        setAuth({ role: "", token: "", username: "" });
        localStorage.clear();
      } else {
        setAuth({
          role: decoded.role.toString(),
          token: token,
          username: decoded.username,
        });
      }
    }
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center pt-32">s
        <CircularLoad color="#ed4846" height={40} width={40} />
      </div>
    );
  }

  return (
    <Wrapper>
      <MainRoute />
    </Wrapper>
  );
}

export default App;
