export interface InputProps {
  value?: any;
  handleChange?: any;
  type: string | any;
  name: string;
  placeholder?: string;
  disable?: boolean;
  required?: boolean;
  id?: string;
  pattern?: string;
  title?: string;
}

const Input: React.FC<InputProps> = ({
  required,
  disable,
  value,
  handleChange,
  type,
  name,
  placeholder,
  id,
  pattern,
  title,
}) => {
  return (
    <input
      title={title}
      pattern={pattern}
      id={id}
      required={required}
      disabled={disable}
      type={type}
      value={value}
      onChange={handleChange}
      name={name}
      placeholder={placeholder}
      className="md:h-10 h-8 w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke focus:ring-1 focus:outline-none font-rom"
    />
  );
};

export default Input;
