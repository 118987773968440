import { isEqual } from "lodash";
import React from "react";
import { DateRangePicker, Range } from "react-date-range";
import { formatDate } from "../../helpers/common";
import { defaultStaticRanges } from "../../utils/date";
import 'react-date-range/dist/styles.css'; 
import 'react-date-range/dist/theme/default.css';

export const checkLabelColor = (date: any) => {
    const element = document.getElementsByClassName("rdrStaticRange");
    defaultStaticRanges.filter((el, idx) =>
     isEqual(
      formatDate(date[0].startDate, "YYYY-MM-DD"),
      formatDate(el.range().startDate, "YYYY-MM-DD")
    ) &&
    isEqual(
      formatDate(date[0].endDate, "YYYY-MM-DD"),
      formatDate(el.range().endDate, "YYYY-MM-DD")
    )
      ? element[idx].classList["add"]("text-primary")
      : element[idx].classList["remove"]("text-primary")
  );
}

interface IAdateRangePickerProps {
  date: Range[];
  handleChangeDate: (item: any) => void;
}

const AdateRangePicker: React.FC<IAdateRangePickerProps> = ({
  date,
  handleChangeDate,
}) => {
  return (
    <DateRangePicker
      className="shadow-md"
      onChange={handleChangeDate}
      showMonthAndYearPickers={true}
      color="#ED4846"
      rangeColors={["#ED4846"]}
      retainEndDateOnFirstSelection={false}
      months={1}
      ranges={date}
      direction="vertical"
      staticRanges={defaultStaticRanges}
      inputRanges={[]}
    />
  );
};

export default AdateRangePicker;
