import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { breadcrumbnext, cancel, logo } from "../../assets/icons";
import { ActionType } from "../../store/sidebar/action";
import SidebarContext from "../../store/sidebar/context";
import "../../styles/sidebar.css";
import useAuth from "../../helpers/useAuth";
import { MlistMenu } from "../molecules/MlistMenu";
import { formatRupiah } from "../../helpers/common";
import { EAllowedRoles, listMenu } from "../../core/auth/entities";
import { useFetchRetailStatistic } from "../../hooks/retail_statistic";
import { Aloading } from "../atoms/Aloading";

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const { SidebarState, SidebarDispatch } = useContext(SidebarContext);
  const { showSidebar } = SidebarState;
  const { auth } = useAuth();
  const { role, username } = auth;

  const handleShowSidebar = () => {
    SidebarDispatch({ type: ActionType.ShowSidebar });
  };

  const { data, isLoading } = useFetchRetailStatistic();

  return (
    <div
      className={
        "bg-white fixed h-screen sm:pt-20 pt-5 flex flex-col items-center duration-700 transform ease-in-out transition-all sm:z-40 z-50  " +
        (showSidebar
          ? "sm:w-1/5 w-full lg:px-7 px-5"
          : "sm:w-[5%] sm:flex hidden overflow-auto no-scrollbar xl:px-5 md:px-3.5 sm:px-2.5")
      }
    >
      <section className="w-full h-full">
        <aside>
          <div
            className={
              "sm:flex hidden xl:mt-3 mt-2 xl:mb-5 mb-4 w-full " +
              (showSidebar ? "justify-start" : "justify-center")
            }
          >
            <img
              onClick={handleShowSidebar}
              src={breadcrumbnext}
              alt="-"
              className={
                "cursor-pointer duration-700 transform ease-in-out transition-all  " +
                (!showSidebar ? "rotate-180 w-3" : "w-2")
              }
            />
            <div onClick={handleShowSidebar}>
              <h1
                className={
                  "text-sm text-textPrimary ml-3 cursor-pointer duration-700 transform ease-in-out transition-all  " +
                  (showSidebar ? "inline-flex" : "opacity-0 hidden")
                }
              >
                Hide menu
              </h1>
            </div>
          </div>
          <hr className="sm:block hidden" />
          <div className="sm:hidden flex justify-end">
            <img
              onClick={handleShowSidebar}
              src={cancel}
              alt="-"
              className="cursor-pointer duration-700 transform ease-in-out transition-all w-4"
            />
          </div>
          <div
            className={
              "my-4 flex items-center w-full " +
              (showSidebar ? "justify-start" : "justify-center")
            }
          >
            <div
              className={
                "flex justify-start " +
                (showSidebar ? "xl:w-1/5 sm:w-1/3 w-[11%]" : "w-7")
              }
            >
              <img
                src={logo}
                alt="-"
                className="rounded-full duration-700 transform ease-in-out transition-all"
              />
            </div>
            <div
              className={
                "duration-700 transform ease-in-out transition-all overflow-hidden " +
                (showSidebar ? "block xl:w-4/5 w-2/3 pl-3" : "hidden")
              }
            >
              <h1 className="truncate xl:text-base text-sm">
                {username.charAt(0).toUpperCase() + username.slice(1)}
              </h1>
              <div className="flex items-center gap-1.5 text-xs">
                {/* <img src={star} alt="-" className="w-3" /> */}
                {/* <h1 className="font-rom text-textSecondary">5 / 5</h1> */}
                <h1 className="font-rom text-textSecondary">Official Store</h1>
              </div>
            </div>
          </div>
          <hr />
          <div
            className={
              "flex flex-col justify-start " +
              (showSidebar ? "items-start my-5" : "items-center")
            }
          >
            {showSidebar ? (
              <aside className="w-full">
                <div className="flex justify-between font-rom text-sm w-full">
                  <span className="truncate">Wallet Balance</span>
                  <h1
                    onClick={() => navigate("admin/request-withdraw")}
                    className="truncate cursor-pointer hover:text-primary"
                  >
                    Withdraw Here
                  </h1>
                </div>
                {isLoading ? (
                  <div className="mt-2 -ml-1">
                    <Aloading type="TailSpin" height={15} />
                  </div>
                ) : (
                  <div className="mt-1">
                    <h1 className="truncate lg:text-base text-sm">
                      {data && formatRupiah(data?.wallet_balance)}
                    </h1>
                  </div>
                )}
              </aside>
            ) : (
              <ul className="flex flex-col list-none w-full">
                <MlistMenu
                  type="Wallet"
                  link="/wallet-mutation"
                  showSidebar={showSidebar}
                  handleShowSidebar={handleShowSidebar}
                  target={"parent"}
                  allowedRoles={[EAllowedRoles.RETAIL]}
                  role={role}
                />
              </ul>
            )}
          </div>
        </aside>
        <ul
          className={
            "flex flex-col list-none " +
            (showSidebar && "overflow-scroll no-scrollbar sm:h-4/5 pb-[50%]")
          }
        >
          {listMenu.map((item, idx) => (
            <MlistMenu
              key={idx}
              type={item.type}
              link={item.link}
              target={item.target}
              role={role}
              allowedRoles={[EAllowedRoles.RETAIL]}
              handleShowSidebar={handleShowSidebar}
              showSidebar={showSidebar}
            />
          ))}
        </ul>
      </section>
    </div>
  );
};

export default Sidebar;
