import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios, { setWithExpiry } from "../../config/axiosInstance";
import jwtDecode from "jwt-decode";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useSpring, animated } from "react-spring";
import { logo } from "../../assets/icons";
import Backdrop from "./login/Backdrop";
import Input from "../../component/input/Input";
import { showPass, unshowPass } from "../../assets/icons/Web";
import CircularLoad from "../../component/loading/CircularLoad";
import useAuth from "../../helpers/useAuth";
import ErrorHandler from "../../component/toast/ErrorHandler";

function LoginPage() {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [passType, setPassType] = useState<string>("password");
  const [load, setLoad] = useState<boolean>(false);

  const { handleSubmit, handleChange, errors, touched, values } = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Required"),
      password: Yup.string()
        .required("Required!")
        .min(6, "The password must be of minimum 6 characters!"),
    }),
    onSubmit: (values) => {
      setLoad(true);
      axios()
        .post("retail/auth/signin", {
          username: values.username,
          password: values.password,
        })
        .then(({ data }) => {
          setLoad(false);
          const token = data.token;
          const decoded: { role: string; username: string } = jwtDecode(token);
          setWithExpiry("retail_token", token, 21600000);
          setWithExpiry("role", decoded.role, 21600000);
          setAuth({
            role: decoded.role,
            token: token,
            username: decoded.username,
          });
          const role = decoded.role;
          const navigateRole: any = {
            retail: "/home",
            assistant: "/profile",
          };
          const nav = navigateRole[role];
          navigate(nav);
          ErrorHandler("#00C62E", "Login successfully!");
        })
        .catch((err) => {
          setLoad(false);
          ErrorHandler("#ed4846", "Login unsuccessfully!");
        });
    },
  });

  const goToForgetPassword = () => {
    navigate(`/forget-password`);
  };

  const handleVisibleType = () => {
    if (passType === "password") {
      setPassType("text");
    } else {
      setPassType("password");
    }
  };

  const props = useSpring({
    to: { opacity: 1, marginTop: 0 },
    from: { opacity: 0, marginTop: -1000 },
  });

  return (
    <>
      <Backdrop active={true} />
      <animated.div
        style={props}
        className="w-full h-screen flex items-center justify-center font-med"
      >
        <div className="flex flex-col bg-white rounded-md md:w-Wlogin md:h-Hlogin w-4/5 shadow-xl transform-gpu">
          <form onSubmit={handleSubmit}>
            <div className="md:mt-7 mt-8 md:mb-8 mb-8 flex flex-col justify-center items-center">
              <img alt="" src={logo} className="md:w-12 w-10" />
              <div className="flex md:mt-4 mt-3">
                <h1 className="text-primary md:text-xl text-lg">DEPATU</h1>
                <h1 className="md:text-xl text-lg ml-2">Official Store</h1>
              </div>
            </div>

            <div className="md:mx-14 mx-8 mb-5">
              <div className="">
                <h1 className="md:text-sm text-xs text-textPrimary">
                  Username
                </h1>
                <Input
                  type={"text"}
                  placeholder={"username"}
                  value={values.username}
                  name={"username"}
                  handleChange={handleChange}
                />
                {touched.username && errors.username ? (
                  <h1 className="text-xs font-rom text-primary -mt-2 mb-2">
                    {errors.username}
                  </h1>
                ) : null}
              </div>
              <div className="">
                <h1 className="md:text-sm text-xs text-textPrimary">
                  Password
                </h1>
                <div className="md:h-10 h-8 rounded-md border-2 my-2 border-stroke flex justify-between">
                  <input
                    type={passType}
                    placeholder="password"
                    className="md:text-sm font-rom text-xs px-3 w-full mr-5 focus:outline-none"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                  {passType === "password" ? (
                    <img
                      src={showPass}
                      onClick={handleVisibleType}
                      className="md:w-5 w-3 mr-5 cursor-pointer"
                      alt=""
                    />
                  ) : (
                    <img
                      src={unshowPass}
                      onClick={handleVisibleType}
                      className="md:w-5 w-3 mr-5 cursor-pointer"
                      alt=""
                    />
                  )}
                </div>
                {touched.password && errors.password ? (
                  <h1 className="text-xs font-rom text-primary -mt-1 mb-1">
                    {errors.password}
                  </h1>
                ) : null}
              </div>
              <div className="flex justify-end cursor-pointer hover:text-primary">
                <h1
                  onClick={goToForgetPassword}
                  className="md:text-sm text-xs font-rom"
                >
                  Forget Password ?
                </h1>
              </div>
            </div>
            <div
              className={
                "flex justify-center md:mb-0 mb-5 " +
                (errors.password && errors.username ? "-mt-2" : "mt-6")
              }
            >
              <button
                type="submit"
                className="rounded-full md:w-40 w-28 md:h-8 h-6 md:pb-0 pb-0.5 bg-primary focus:outline-none hover hover:bg-opacity-50"
              >
                {load ? (
                  <div className="flex justify-center">
                    <CircularLoad color={"white"} height={17} width={17} />
                  </div>
                ) : (
                  <h1 className="text-white md:text-base text-sm">login</h1>
                )}
              </button>
            </div>
          </form>
        </div>
      </animated.div>
    </>
  );
}

export default LoginPage;
