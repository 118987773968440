import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import axios from "../../../config/axiosInstance";

// components
import ProductTable from "./productsComponent/subPage/ProductTable";
import ExportFile from "../../../component/downloadCsv/ExportFile";
import FilterProduct from "./productsComponent/subPage/FilterProduct";
import Loading from "../../../component/loading/Loading";
import ProductList from "./productsComponent/mobileView/ProductList";

// icons
import { asc, desc, search } from "../../../assets/icons";

function Products() {
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [filterAp, SetFilterAp] = useState<any>({
    fn: (items: any) => {
      return items;
    },
  });
  const [chip, setChip] = useState<any>([]);
  const [sortValue, setSortValue] = useState<string>("release_date");
  const [isAsc, setIsAsc] = useState("desc");
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [detail, setDetail] = useState<boolean>(false);
  const [deleteProduct, setDeleteProduct] = useState<boolean>(false);
  const [filter, setFilter] = useState<any>({
    code: "",
    name: "",
    category: 0,
    status: "",
    // is_pre_order_limited: false,
    // is_limited: false,
    release_date_from: "",
    release_date_to: "",
  });

  const handleAddProduct = () => {
    navigate(`${pathname}/add-product`);
  };

  const handleShowFilter = () => {
    if (showFilter) {
      setShowFilter(false);
      setFilter({
        ...filter,
        code: "",
        name: "",
        category: 0,
        status: "",
        release_date_from: "",
        release_date_to: "",
      });
    } else {
      setShowFilter(true);
    }
  };

  const handleChangeSearch = (event: any) => {
    let target = event.target;
    SetFilterAp({
      fn: (items: any[]) => {
        if (target.value === "") {
          return items;
        } else {
          return items.filter(function (el: any) {
            return el.name.toLowerCase().includes(target.value.toLowerCase());
          });
        }
      },
    });
  };

  const handleSort = (event: any) => {
    let target = event.target;
    setSortValue(target.value);
  };

  const handleAsc = () => {
    if (isAsc === "asc") {
      setIsAsc("desc");
    } else {
      setIsAsc("asc");
    }
  };

  const getProducts = async () => {
    try {
      const results: any = await axios().get(`retail/retail-product`, {
        params: {
          ...(filter.category !== 0 && filter.category.length !== 0
            ? { retail_product_category_id: filter.category }
            : ""),
          ...(filter.status.length !== 0 ? { status: filter.status } : ""),
          code: filter.code,
          name: filter.name,
          pre_order: filter.pre_order,
          limited_product: filter.limited_product,
          ...(filter.release_date_from.length !== 0 &&
          filter.release_date_to.length !== 0
            ? {
                release_date: `${filter.release_date_from},${filter.release_date_to}`,
              }
            : ""),
          ...(filter.release_date_from.length !== 0 &&
          filter.release_date_to.length === 0
            ? { release_date: `${filter.release_date_from},2100-01-01` }
            : ""),
          ...(filter.release_date_from.length === 0 &&
          filter.release_date_to.length !== 0
            ? { release_date: `2000-01-01,${filter.release_date_to}` }
            : ""),
          limit: limit,
          ...(offset !== 0 ? { offset: offset } : ""),
          sort: `${sortValue} ${isAsc}`,
        },
      });
      return results;
    } catch (error) {}
  };

  const applyChip = () => {
    const new_data = [];
    for (const key in filter) {
      if (filter[key].length > 0) {
        new_data.push(key);
      }
    }
    setChip(new_data);
  };

  const {
    data: dataProduct,
    isLoading,
    refetch,
  } = useQuery(
    ["products", sortValue, isAsc, limit, offset, filter],
    getProducts,
    { refetchOnWindowFocus: false }
  );
  const dataExport = dataProduct?.data.data;

  const handleChipByKey = (el: any) => {
    const new_data = chip.filter((item: any) => item !== el);
    setChip(new_data);
    const new_filter = { ...filter, [el]: "" };
    setFilter(new_filter);
    refetch();
  };

  return (
    <>
      <div className="font-med bg-white p-2">
        {/*breadescrumb*/}
        <div>
          <ul className="flex text-breadscrumbColor md:text-base text-xs">
            <li className="inline-flex items-center">
              <Link to="/admin/home" className="hover:text-black">
                Home
              </Link>
            </li>
            <li className="inline-flex items-center">
              <svg className="h-5 w-auto" viewBox="0 0 20 20" fill="#000000">
                <path
                  fill-rule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <Link className="text-black" to="/admin/products">
                Products
              </Link>
            </li>
          </ul>
        </div>

        <div className="md:mt-12 mt-10">
          {/*search and add product*/}
          <div className="flex justify-between">
            <div className="">
              <h1 className="md:text-xl text-sm">Search</h1>
            </div>

            <div>
              <h1
                onClick={handleAddProduct}
                className="text-primary md:text-xl cursor-pointer md:inline-flex hidden"
              >
                Add Product
              </h1>

              {isLoading ? (
                ""
              ) : (
                <h1
                  onClick={handleAddProduct}
                  className="cursor-pointer md:hidden"
                >
                  <ExportFile
                    csvData={dataExport}
                    fileName={"data-product-multibrand-store"}
                  />
                </h1>
              )}
            </div>
          </div>

          {/*search input*/}
          <div className="flex items-center w-full md:h-10 h-9 md:my-3 my-2">
            <div className="border-2 border-stroke rounded-md rounded-r-none h-full w-full">
              <input
                type="text"
                placeholder="Search by product name"
                name="name"
                onChange={handleChangeSearch}
                className="md:text-sm text-xs font-rom md:px-3 px-2 md:py-3 w-full h-full transition duration-100 ease-in-out shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 rounded-md rounded-r-none"
              />
            </div>

            <div className="flex items-center justify-center border-2 border-l-0 rounded-md rounded-l-none border-stroke h-full w-20">
              <img
                className="cursor-pointer md:w-5 w-3"
                src={search}
                alt="img"
              />
            </div>
          </div>

          {/*download csv and sort*/}
          <div className="md:flex justify-between mt-6 hidden">
            <div className="flex">
              <h1
                onClick={handleShowFilter}
                className="text-base mr-5 cursor-pointer hover:text-primary"
              >
                Show Filter
              </h1>
              {isLoading ? (
                ""
              ) : (
                <ExportFile
                  csvData={dataExport}
                  fileName={"data-product-multibrand-store"}
                />
              )}
            </div>

            <div className="flex justify-between items-center">
              {isAsc === "asc" ? (
                <img
                  className="cursor-pointer md:w-7 w-5"
                  onClick={handleAsc}
                  src={asc}
                  alt="asc"
                />
              ) : (
                <img
                  className="cursor-pointer md:w-7 w-5"
                  onClick={handleAsc}
                  src={desc}
                  alt="desc"
                />
              )}

              <div className="border-2 border-stroke h-10 w-28 rounded-md rounded-r-none flex justify-center items-center ml-5">
                <h1 className="text-base">Sort By</h1>
              </div>
              <div className="border-2 border-l-0 border-stroke h-10 w-44 rounded-md rounded-l-none flex justify-around items-center">
                <select
                  onChange={handleSort}
                  name="sorting"
                  id=""
                  className="w-full h-full text-base my-3 px-5 shadow-sm transition duration-100 ease-in-out rounded-md rounded-l-none focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 cursor-pointer"
                >
                  <option value="release_date">Date</option>
                  <option value="name">Product Name</option>
                  <option value="code">Product Code</option>
                  <option value="view_count">Product View</option>
                  <option value="status">Product Status</option>
                </select>
              </div>
            </div>
          </div>

          {/*filter chip*/}
          {chip ? (
            <div className="md:flex hidden">
              {chip.map((el: any) => (
                <div className="inline-flex items-center rounded mx-2 bg-white border-2 border-stroke p-1 mt-1">
                  <span className="px-2 text-sm">
                    {el.split("_").join(" ")}
                  </span>
                  <button
                    type="button"
                    className="h-6 w-6 p-1 focus:outline-none"
                  >
                    <svg
                      onClick={() => handleChipByKey(el)}
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="md:hidden h-10"></div>
          )}

          {/**sorting mobile */}
          <div className="md:hidden flex my-5 items-center overflow-x-scroll">
            {isAsc === "asc" ? (
              <img
                className="cursor-pointer w-6 mr-3"
                onClick={handleAsc}
                src={asc}
                alt="asc"
              />
            ) : (
              <img
                className="cursor-pointer w-6 mr-3"
                onClick={handleAsc}
                src={desc}
                alt="desc"
              />
            )}
            <div className="w-32 flex flex-col items-center">
              <h1
                className="text-sm mx-2"
                onClick={() => setSortValue("release_date")}
              >
                Date
              </h1>
              <hr
                className={
                  sortValue === "release_date"
                    ? "w-full my-1 border-t-2 border-primary"
                    : "w-full my-1 border-t-2"
                }
              />
            </div>
            <div className="w-32 flex flex-col items-center">
              <h1 className="text-sm mx-2" onClick={() => setSortValue("code")}>
                Code
              </h1>
              <hr
                className={
                  sortValue === "code"
                    ? "w-full my-1 border-t-2 border-primary"
                    : "w-full my-1 border-t-2"
                }
              />
            </div>
            <div className="w-32 flex flex-col items-center">
              <h1 className="text-sm mx-2" onClick={() => setSortValue("name")}>
                Name
              </h1>
              <hr
                className={
                  sortValue === "name"
                    ? "w-full my-1 border-t-2 border-primary"
                    : "w-full my-1 border-t-2"
                }
              />
            </div>
            <div className="w-32 flex flex-col items-center">
              <h1
                className="text-sm mx-2"
                onClick={() => setSortValue("view_count")}
              >
                View
              </h1>
              <hr
                className={
                  sortValue === "view_count"
                    ? "w-full my-1 border-t-2 border-primary"
                    : "w-full my-1 border-t-2"
                }
              />
            </div>
            <div className="w-32 flex flex-col items-center">
              <h1
                className="text-sm mx-2"
                onClick={() => setSortValue("status")}
              >
                Status
              </h1>
              <hr
                className={
                  sortValue === "status"
                    ? "w-full my-1 border-t-2 border-primary"
                    : "w-full my-1 border-t-2"
                }
              />
            </div>
          </div>
        </div>

        {/**Add product & filter for mobile */}
        <div className="md:hidden flex justify-center pr-10 fixed w-full bottom-10">
          <div className="md:hidden flex rounded-full shadow-lg w-3/5 h-10 justify-center bg-white items-center text-mobile">
            <div className="border-r-2 h-full flex justify-center items-center w-1/2">
              <h1 onClick={handleShowFilter}>Filter</h1>
            </div>
            <div className="h-full flex justify-center items-center w-1/2">
              <h1 className="text-primary" onClick={handleAddProduct}>
                Add Product
              </h1>
            </div>
          </div>
        </div>

        {/*show filter*/}
        <FilterProduct
          showFilter={showFilter}
          setFilter={setFilter}
          filter={filter}
          refetch={refetch}
          applyChip={applyChip}
          setShowFilter={setShowFilter}
        />

        {/*table & list*/}
        {isLoading ? (
          <div className="flex justify-center mt-10">
            <Loading />
          </div>
        ) : (
          <div>
            <div className="md:flex hidden">
              <ProductTable
                dataProps={dataProduct}
                filterAp={filterAp}
                limit={limit}
                setLimit={setLimit}
                offset={offset}
                setOffset={setOffset}
                detail={detail}
                setDetail={setDetail}
                deleteProduct={deleteProduct}
                setDeleteProduct={setDeleteProduct}
              />
            </div>
            <div className="md:hidden flex">
              <ProductList
                filterAp={filterAp}
                data={dataProduct}
                sortValue={sortValue}
                isAsc={isAsc}
                detail={detail}
                setDetail={setDetail}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Products;
