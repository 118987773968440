import _ from "lodash";
import qs from "qs";
import AxiosInstance from ".././../../config/Instance";
import { IBuiltinSort, IMeta, IPagination } from "../../helpers/helper";
import {
  ERetailTransactionSortField,
  IRetailTransaction,
  IRetailTransactionCounter,
  IRetailTransactionTrack,
  PRetailTransactionFilter,
} from "../entities";
import IRetailTransactionRepository from "../repository";

export default class RetailTransactionRepository
  implements IRetailTransactionRepository
{
  // api routes
  private RETAIL_TRANSACTION_ROUTE = "/retail/retail-transaction";
  private RETAIL_TRANSACTION_COUNTER_ROUTE =
    "/retail/retail-transaction/counter";

  private getError(error: any) {
    return _.get(error, "response.data.errors", "Something went wrong.");
  }

  buildFilter(filter: PRetailTransactionFilter) {
    const created_at = filter.date;
    const status = filter.status?.split(",");
    const clonedFilter = { ...filter, status };
    delete clonedFilter.date;
    return {
      ...clonedFilter,
      created_at,
    };
  }

  buildSort(sort: IBuiltinSort<ERetailTransactionSortField>[]) {
    let strSort = "";
    sort.map((el) => (strSort += el.field));
    return strSort;
  }

  async getRetailTransactions(
    pagination: IPagination,
    filter: PRetailTransactionFilter,
    sorts: IBuiltinSort<ERetailTransactionSortField>[]
  ): Promise<[IRetailTransaction[], IMeta]> {
    try {
      const params = _.pickBy(
        {
          ...pagination,
          ...this.buildFilter(filter),
        },
        (val) => val
      );

      const sort = this.buildSort(sorts);

      const response = await AxiosInstance.Mercury().get(
        `${this.RETAIL_TRANSACTION_ROUTE}?sort=${sort}`,
        {
          params,
          paramsSerializer: (params) => {
            return qs.stringify(params, { indices: false });
          },
        }
      );
      const { data, meta } = response.data;
      return [data, meta];
    } catch (error) {
      throw error;
    }
  }

  async getRetailTransaction(code: string): Promise<IRetailTransaction> {
    try {
      const response = await AxiosInstance.Mercury().get(
        `${this.RETAIL_TRANSACTION_ROUTE}/${code}/`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getRetailTransactionTrack(
    code: string
  ): Promise<IRetailTransactionTrack[]> {
    try {
      const response = await AxiosInstance.Mercury().get(
        `${this.RETAIL_TRANSACTION_ROUTE}/${code}/track`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getRetailTransactionCounter(
    filter: PRetailTransactionFilter
  ): Promise<IRetailTransactionCounter[]> {
    try {
      const params = _.pickBy(
        {
          ...this.buildFilter(filter),
        },
        (val) => val
      );

      const response = await AxiosInstance.Mercury().get(
        `${this.RETAIL_TRANSACTION_COUNTER_ROUTE}/`,
        {
          params,
        }
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw error;
    }
  }

  async sendProduct(
    code: string,
    payload: { pickup_time: string, is_simulated: boolean }
  ): Promise<{ message: string }> {
    try {
      const response = await AxiosInstance.Mercury().post(
        `${this.RETAIL_TRANSACTION_ROUTE}/${code}/send-product`,
        payload
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }
}
