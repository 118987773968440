import { createContext } from "react";
import { ISubAccountContext, ISubAccountState } from "./state";

export const initialSubAccountState: ISubAccountState = {
  showFilter: false,
  chip: [],
  showDeleteSubAccount: {
    status: false,
    id: 0,
  },
  showAddSubAccount: false,
};

const SubAccountContext = createContext<ISubAccountContext>({
  SubAccountState: initialSubAccountState,
  SubAccountDispatch: () => undefined,
});

export const SubAccountContextProvider = SubAccountContext.Provider;
export const SubAccountContextConsumer = SubAccountContext.Consumer;

export default SubAccountContext;
