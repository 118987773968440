import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../../../../config/axiosInstance";
import { useQuery } from "react-query";

//components
import Loading from "../../../../../component/loading/Loading";

//icons
import { back } from "../../../../../assets/icons";

export interface RevenueDetailProps {}

const RevenueDetail: React.FC<RevenueDetailProps> = () => {
  const params = useParams();
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/admin/revenues");
  };

  const getRevenuebyCode = async (code: string) => {
    try {
      const results: any = await axiosInstance().get(
        `retail/retail-transaction/${code}/`
      );
      const res = results.data;
      return res;
    } catch (error) {
      console.log(error);
    }
  };


  const { data, isSuccess } = useQuery("getRevenuebyCode", () =>
    getRevenuebyCode(params.code || '')
  );
  const adm_fee = data?.total * data?.admin_fee_percentage
  return (
    <div className="mb-28 font-med">
      <div className=" overflow-auto">
        <div onClick={goBack} className="flex items-center">
          <img src={back} alt="back" />
          <h1 className="text-mobile ml-1.5">Back</h1>
        </div>

        {isSuccess ? (
          <div>
            <div className="mt-12 flex justify-center">
              <img
                className="rounded-md shadow-md w-56"
                src={data.retail_product_detail.display_image_path}
                alt=""
              />
            </div>
            <div className="px-5 mt-12">
              <div>
                <h1 className="text-mobile">Transaction Code</h1>
                <div className="flex items-center border-2 mt-2 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">{data.code}</h1>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-mobile">Customer</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">
                    {data.buyer_shipping_address.recipient}
                  </h1>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-mobile">Product Price</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">
                    {data.price.toLocaleString("EN-US", {
                      style: "currency",
                      currency: "IDR",
                    })}
                  </h1>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-mobile">Quantity</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">
                    {data.quantity}
                  </h1>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-mobile">Total Price</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">
                    {data.total.toLocaleString("EN-US", {
                      style: "currency",
                      currency: "IDR",
                    })}
                  </h1>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-mobile">Administration Fee</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">
                    {adm_fee.toLocaleString("EN-US", {
                      style: "currency",
                      currency: "IDR",
                    })}
                  </h1>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-mobile">Shipping Cost</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">
                    {data.buyer_shipping_cost.toLocaleString("EN-US", {
                      style: "currency",
                      currency: "IDR",
                    })}
                  </h1>
                </div>
              </div>
              {/* <div className="mt-5">
                <h1 className="text-mobile">Seller Payout</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">
                    {data.quantity}
                  </h1>
                </div>
              </div> */}
            </div>
          </div>
        ) : (
          <div className="flex justify-center mt-10">
            <Loading />
          </div>
        )}
      </div>
      <footer className="h-24 p-3 flex justify-evenly items-center w-full left-0 bottom-0 bg-white shadow-inner fixed">
        <div
          onClick={() => {navigate(`/admin/revenues/${params.code}`)}}
          className="w-full h-14 flex items-center justify-center bg-primary border-disableColor border-2 shadow-md rounded"
        >
          <h1 className="text-xs text-white">Transaction Details</h1>
        </div>
      </footer>
    </div>
  );
};

export default RevenueDetail;
