import AxiosInstance from "../../config/Instance";
import { formatDate } from "../../helpers/common";

export const getTransactionTicket = async (
  limit: number,
  offset: number,
  setLoad: (load: boolean) => void,
  date: any[]
) => {
  try {
    setLoad(true);
    const results = await AxiosInstance.Dicket().get(
      `/admin/purchase/`,
      {
        params: {
          limit: limit,
          created_at_after: formatDate(date[0].startDate),
          created_at_before: formatDate(date[0].endDate),
          offset: offset,
          ordering: `created_at`,
        },
      }
    );
    const result = await results.data.data;
    console.log(result, 'res');
    setLoad(false);
    return result;
  } catch (error) {
    console.log(error);
  }
};
