import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "../../../../../config/axiosInstance";

// components
import ProductVariantTable from "./productVariantComponent/ProductVariantTable";
import ProductVariantList from "./mobileView/ProductVariantList";
import Loading from "../../../../../component/loading/Loading";
import SuccessNav from "../../../../../component/navbar/SuccessNav";

function ProductVariant() {
  const params: any = useParams();
  const navigate = useNavigate();
  const{ pathname } = useLocation();
  const [deleteProduct, setDeleteProduct] = useState<boolean>(false);
  const [successDelete, setSuccessDelete] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setSuccessDelete(false);
    }, 1500);
  }, [successDelete]);

  const handleAddVariant = () => {
    navigate(`${pathname}/add-variant`);
  };

  const getDataVariant = async () => {
    try {
      const results: any = await axios().get(
        `retail/retail-product/${params.id}`
      );
      const result = results.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const { data, isLoading, refetch } = useQuery("variant", getDataVariant, {
    refetchOnWindowFocus: false,
  });

  return (
    <>
      {successDelete ? <SuccessNav title={"Delete Complete"} /> : ""}
      <div className="bg-white p-4 font-med">
 
        <div className="flex flex-col items-center">
          {isLoading ? (
            ""
          ) : (
            <div className="flex flex-col items-center">
              <h1 className="md:text-3xl text-lg">{data.name}</h1>
              <h1
                onClick={handleAddVariant}
                className="md:text-lg text-base text-primary md:mt-1 cursor-pointer md:block hidden"
              >
                Add variant
              </h1>
            </div>
          )}

          <div className="md:hidden flex justify-center bg-red-300 fixed bottom-20 w-full">
            <div className="flex rounded-full shadow-lg w-2/5 h-10 justify-center bg-white fixed items-end">
              <div className="h-full flex justify-center items-center">
                <h1 className="text-primary text-mobile" onClick={handleAddVariant}>
                  Add Variant
                </h1>
              </div>
            </div>
          </div>
        </div>

        {/*Stock product table*/}
        {isLoading ? (
          <div className="flex justify-center">
            <Loading />
          </div>
        ) : (
          <div>
            <div className="md:flex hidden -mt-6">
              <ProductVariantTable
                data={data}
                deleteProduct={deleteProduct}
                setDeleteProduct={setDeleteProduct}
                refetch={refetch}
                successDelete={successDelete}
                setSuccessDelete={setSuccessDelete}
              />
            </div>
            <div className="md:hidden flex">
              <ProductVariantList data={data} />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ProductVariant;
