import { ActionType, TicketAction } from "./action";
import { ITicketState } from "./state";

export const TicketReducer = (state: ITicketState, action: TicketAction) => {
  switch (action.type) {
    case ActionType.ShowFilter:
      let showFilter = state.showFilter;
      document.body.classList[!showFilter ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showFilter: !showFilter };
    case ActionType.ShowDeleteTicket:
      let showDeleteTicket = action.payload;
      document.body.classList[showDeleteTicket.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showDeleteTicket };
    case ActionType.ShowDeleteTicketVariant:
      let showDeleteTicketVariant = action.payload;
      document.body.classList[
        showDeleteTicketVariant.status ? "add" : "remove"
      ]("h-screen", "overflow-hidden");
      return { ...state, showDeleteTicketVariant };
    case ActionType.ShowSendNotif:
      let showSendNotif = action.payload;
      document.body.classList[showSendNotif.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showSendNotif };
    case ActionType.ShowScanQr:
      let showScanQr = action.payload;
      document.body.classList[showScanQr.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showScanQr };
    case ActionType.ShowScanResult:
      let scanResult = action.payload;
      document.body.classList[scanResult.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, scanResult };
    case ActionType.ConfirmEditTicket:
      let confirmEditTicket = action.payload;
      document.body.classList[confirmEditTicket.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, confirmEditTicket };
    case ActionType.HandleChip:
      let new_chip = action.payload;
      return { ...state, chip: new_chip };
  }
};
