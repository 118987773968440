import { FC } from "react";
import { IIconProps } from "./Ahome";

export const Aprofile: FC<IIconProps> = ({
  stroke = "#666666",
  width = "26",
  height = "27",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 25.6753C19.6274 25.6753 25 20.3027 25 13.6753C25 7.04788 19.6274 1.67529 13 1.67529C6.37258 1.67529 1 7.04788 1 13.6753C1 20.3027 6.37258 25.6753 13 25.6753Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 17.6753C15.7614 17.6753 18 15.4367 18 12.6753C18 9.91387 15.7614 7.67529 13 7.67529C10.2386 7.67529 8 9.91387 8 12.6753C8 15.4367 10.2386 17.6753 13 17.6753Z"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.97498 22.6001C5.72736 21.1181 6.87542 19.8734 8.2919 19.004C9.70838 18.1345 11.3379 17.6743 13 17.6743C14.662 17.6743 16.2916 18.1345 17.708 19.004C19.1245 19.8734 20.2726 21.1181 21.025 22.6001"
        stroke={stroke}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
