import React, { useContext } from "react";
import { useQuery } from "react-query";
import {
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { TicketRequest } from "../../../../api/ticket/Ticket";
import DynamicBreadcrumb from "../../../../component/breadcrumb/DynamicBreadcrumb";
import Loading from "../../../../component/loading/Loading";
import TicketContext from "../../../../store/ticket/context";
import { useQueries } from "../../../../utils/QuerySearchParams";
import { optRoutesStockTicket } from "../helpers/list";
import StockTicketList from "./mobile_view/StockTicketList";
import DeleteTicketVariantModal from "./stock_ticket_component/DeleteTicketVariantModal";
import StockTicketTable from "./stock_ticket_component/StockTicketTable";

const StockTicket = () => {
  const query = useQueries(useLocation);
  const params = useParams();
  const navigate = useNavigate();
  const{ pathname } = useLocation();
  const { TicketState } = useContext(TicketContext);
  const { showDeleteTicketVariant } = TicketState;
  const { data, isLoading } = useQuery(
    [
      `getTicketVariant`,
      query.get("sort"),
      query.get("limit"),
      query.get("offset"),
      query.get("isAsc"),
    ],
    () => TicketRequest.getTicketVariant(params.id || ''),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: dataTicketBundle, isLoading: loadTicketBundle } = useQuery(
    [`getTicketById`],
    () => TicketRequest.getTicketById(params.id || '')
  );

  return (
    <div className="font-med bg-white p-4">
      {showDeleteTicketVariant.status && <DeleteTicketVariantModal />}
      {isLoading || loadTicketBundle ? (
        <div className="flex justify-center my-20">
          <Loading />
        </div>
      ) : (
        <div>
          <div className="md:my-14 my-10 flex flex-col items-center">
            <div className="flex flex-col items-center">
              <h1 className="md:text-3xl text-lg">{dataTicketBundle?.name}</h1>
              <h1
                onClick={() => navigate(`${pathname}/add-ticket-variant`)}
                className="md:text-lg text-base text-primary md:mt-1 cursor-pointer md:block hidden"
              >
                Add variant
              </h1>
            </div>

            <div className="md:hidden flex justify-center bg-red-300 fixed bottom-20 w-full">
              <div className="flex rounded-full shadow-lg w-2/5 h-10 justify-center bg-white fixed items-end">
                <div className="h-full flex justify-center items-center">
                  <h1
                    className="text-primary text-mobile"
                    onClick={() => navigate(`${pathname}/add-ticket-variant`)}
                  >
                    Add Variant
                  </h1>
                </div>
              </div>
            </div>
          </div>
          <div className="md:inline hidden">
            <StockTicketTable data={data} />
          </div>
          <div className="md:hidden flex">
            <StockTicketList data={data} />
          </div>
        </div>
      )}
    </div>
  );
};

export default StockTicket;
