import React, { useContext } from "react";
import { ActionType } from "../../../../store/sub_account/action";
import SubAccountContext from "../../../../store/sub_account/context";

interface IProps {
  data: any;
}

const AccountCard: React.FC<IProps> = ({ data }) => {
  const {SubAccountDispatch} = useContext(SubAccountContext);
  return (
    <div className="h-auto xl:mx-0 md:mx-2 rounded-xl border-2 border-stroke">
      <div className="ml-8 mt-8 flex flex-col justify-around">
        <h1 className="md:text-xl text-lg">{data.username}</h1>
        <h1 className="text-base mt-2 font-rom">Email : {data.email}</h1>
        <h1 className="text-base mt-2 font-rom">
          (+{data.country_code}) {data.phone}
        </h1>
      </div>
      <div className="flex justify-around text-lg h-20 mt-8 w-full">
        <div className="border-2 border-b-0 border-l-0 border-r-0 border-stroke h-full w-full flex justify-center items-center">
          <h1
            onClick={() =>
                SubAccountDispatch({
                type: ActionType.ShowDeleteSubAccount,
                payload: { status: true, id: data.id },
              })
            }
            className="hover:text-primary cursor-pointer md:text-base text-sm"
          >
            Delete Account
          </h1>
        </div>
      </div>
    </div>
  );
};

export default AccountCard;
