import AxiosInstance from "../../../config/Instance";
import { IRetailShop, PRetailShop, PRetailShopFilter } from "../entities";
import IRetailShopRepository from "../repository";

export default class RetailShopRepository implements IRetailShopRepository {
  // api routes
  private RETAIL_SHOP_ROUTE = "/retail/retail-shop";

  buildFilter(filter: PRetailShopFilter) {
    const created_at = filter.created_at_enabled
      ? `${filter.created_at_start},${filter.created_at_end}`
      : undefined;

    const clonedFilter = { ...filter };
    delete clonedFilter.created_at_enabled;
    delete clonedFilter.created_at_start;
    delete clonedFilter.created_at_end;

    return {
      ...clonedFilter,
      created_at,
    };
  }

  async getRetailShop(): Promise<IRetailShop> {
    try {
      const response = await AxiosInstance.Mercury().get(
        `${this.RETAIL_SHOP_ROUTE}`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw error;
    }
  }

  async updateRetailShop(shop: PRetailShop): Promise<IRetailShop> {
    try {
      console.log(shop, '<<<<')
      const response = await AxiosInstance.Mercury().patch(
        `${this.RETAIL_SHOP_ROUTE}`,
        shop
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw error;
    }
  }
}
