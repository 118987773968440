import axios from "axios";
import { getWithExpiry } from "./axiosInstance";

const axiosDicket = () => {
  const value = getWithExpiry("retail_token");
  return axios.create({
    baseURL: process.env.REACT_APP_DICKET_API_BASE_URL,
    headers: {
      authorization: `Bearer ${value}`,
    },
  });
};

export default axiosDicket;
