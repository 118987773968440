import moment from "moment";
import ErrorHandler from "../../component/toast/ErrorHandler";
import axiosDicket from "../../config/axiosDicket";

export class TicketRequest {
  static getTicket = async () => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results: any = await axiosDicket().get(`admin/ticket-bundle/`, {
        params: {
          limit: query.get("limit") !== null ? query.get("limit") : 10,
          offset: query.get("offset") !== null ? query.get("offset") : 0,
          sort: `${
            query.get("sort") !== null ? query.get("sort") : "created_at"
          } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
        },
      });
      return results;
    } catch (error) {
      console.log(error);
    }
  };

  static getTicketById = async (id: string) => {
    try {
      const results: any = await axiosDicket().get(
        `admin/ticket-bundle/${id}/`
      );
      const data = results.data;
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  static getTicketVariant = async (ticket_bundle_id: string) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results: any = await axiosDicket().get(`admin/ticket/`, {
        params: {
          ticket_bundle_id,
          limit: query.get("limit") !== null ? query.get("limit") : 10,
          offset: query.get("offset") !== null ? query.get("offset") : 0,
          sort: `${
            query.get("sort") !== null ? query.get("sort") : "created_at"
          } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
        },
      });
      const result = results.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  static getTicketVariantById = async (id: string) => {
    try {
      const results: any = await axiosDicket().get(`admin/ticket/${id}/`);
      const data = results.data;
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  static postTicket = async (data: any) => {
    try {
      const result = await axiosDicket().post("/admin/ticket-bundle/", data);
      ErrorHandler("#00C62E", "Add Ticket Completed");
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[
            Object.keys(error.response.data.errors)[0]
          ][0]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Add Failed");
      }
    }
  };

  static EditTicket = async (data: any, id: string) => {
    try {
      const result = await axiosDicket().patch(
        `/admin/ticket-bundle/${id}/`,
        data
      );
      ErrorHandler("#00C62E", "Edit Ticket Completed");
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Edit Failed");
      }
    }
  };

  static deleteTicket = async (id: any) => {
    try {
      const result = await axiosDicket().delete(`/admin/ticket-bundle/${id}/`);
      ErrorHandler("#00C62E", "Delete Ticket Completed");
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[
            Object.keys(error.response.data.errors)[0]
          ][0]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "delete Failed");
      }
    }
  };

  static postTicketVariant = async (data: any, simulated: boolean) => {
    try {
      let payload: any = { ...data };
      payload["simulated"] = simulated;
      !payload.tnc.length
        ? delete payload.tnc
        : (payload.tnc = payload.tnc.join());

      if (payload.discount_price > 0) {
        payload["price"] = +payload.discount_price;
        payload["display_price"] = +data.price;
      }
      delete payload.discount_price;
      delete payload.active_discount_price;

      if (payload.is_usage_limit_per_user) {
        payload["usage_limit_per_user"] = +payload.usage_limit_per_user;
      }
      delete payload.is_usage_limit_per_user;

      payload.valid_time_indefinite
        ? (payload.valid_time_type = "scheduled")
        : (payload.valid_time_type = payload.valid_time_type.value);
      !payload.is_limited && delete payload.limit_per_user;

      !payload.selling_time_enabled
        ? delete payload.selling_start_time
        : (payload.selling_start_time = `${payload.selling_start_time}:00+07:00`);

      !payload.selling_time_enabled
        ? delete payload.selling_end_time
        : (payload.selling_end_time = `${payload.selling_end_time}:00+07:00`);

      payload.valid_time_indefinite && delete payload.valid_time_from;
      payload.valid_time_indefinite && delete payload.valid_time_until;
      payload.valid_time_indefinite && delete payload.valid_time_interval;

      if (payload.valid_time_type === "interval_day") {
        delete payload.valid_time_from;
        delete payload.valid_time_until;
      } else if (
        payload.valid_time_type === "scheduled" &&
        !payload.valid_time_indefinite
      ) {
        delete payload.valid_time_interval;
        payload.valid_time_from = `${payload.valid_time_from}:00+07:00`;
        payload.valid_time_until = `${payload.valid_time_until}:00+07:00`;
      }

      Object.values(payload.claim_time).map(
        (el: any, idx: number) =>
          el.enabled &&
          payload.claim_time[idx].start.length > 0 &&
          (payload.claim_time[idx].start = `${moment(
            `2222-12-22 ${payload.claim_time[idx].start}`
          )
            .toISOString()
            .slice(11)}`)
      );

      Object.values(payload.claim_time).map(
        (el: any, idx: number) =>
          el.enabled &&
          payload.claim_time[idx].end.length > 0 &&
          (payload.claim_time[idx].end = `${moment(
            `2222-12-22 ${payload.claim_time[idx].end}`
          )
            .toISOString()
            .slice(11)}`)
      );

      const result = await axiosDicket().post("/admin/ticket/", payload);
      if (!simulated) {
        ErrorHandler("#00C62E", "Add Ticket Variant Completed");
      }
      const res = result.data;
      return res;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          `${Object.keys(error.response.data.errors)[0]
            .split("_")
            .join(" ")}: ${error.response.data.errors[
            Object.keys(error.response.data.errors)[0]
          ][0]
            .split("_")
            .join(" ")}`
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Add Failed");
      }
    }
  };

  static editTicketVariant = async (
    data: any,
    id: string,
    simulated: boolean
  ) => {
    try {
      let payload: any = { ...data };
      payload["simulated"] = simulated;
      !payload.tnc.length
        ? delete payload.tnc
        : (payload.tnc = payload.tnc.join());

      if (payload.discount_price > 0) {
        payload["price"] = +payload.discount_price;
        payload["display_price"] = +data.price;
      } else if (!payload.active_discount_price) {
        payload["display_price"] = null;
        payload["price"] = +data.price;
      }
      delete payload.discount_price;
      delete payload.active_discount_price;

      payload.valid_time_type = payload.valid_time_type.value;
      !payload.is_limited && delete payload.limit_per_user;

      if (!payload.selling_time_enabled) {
        delete payload.selling_start_time;
      } else if (payload.selling_start_time !== data.selling_start_time) {
        payload.selling_start_time = `${payload.selling_start_time}:00+07:00`;
      } else {
        payload.selling_start_time = `${payload.selling_start_time}`;
      }

      if (!payload.selling_time_enabled) {
        delete payload.selling_end_time;
      } else if (payload.selling_end_time !== data.selling_end_time) {
        payload.selling_end_time = `${payload.selling_end_time}:00+07:00`;
      } else {
        payload.selling_end_time = `${payload.selling_end_time}`;
      }

      payload.valid_time_indefinite && delete payload.valid_time_from;
      payload.valid_time_indefinite && delete payload.valid_time_until;
      payload.valid_time_indefinite && delete payload.valid_time_interval;

      if (payload.valid_time_type === "interval_day") {
        delete payload.valid_time_from;
        delete payload.valid_time_until;
      } else if (
        payload.valid_time_type === "scheduled" &&
        !payload.valid_time_indefinite
      ) {
        delete payload.valid_time_interval;
        payload.valid_time_from =
          payload.valid_time_from === data.valid_time_from
            ? `${payload.valid_time_from}`
            : `${payload.valid_time_from}:00+07:00`;
        payload.valid_time_until =
          payload.valid_time_until === data.valid_time_until
            ? `${payload.valid_time_until}`
            : `${payload.valid_time_until}:00+07:00`;
      }
      Object.values(payload.claim_time).map(
        (el: any, idx: number) =>
          el.enabled &&
          payload.claim_time[idx].start.length > 0 &&
          (payload.claim_time[idx].start = `${moment(
            `2222-12-22 ${payload.claim_time[idx].start}`
          )
            .toISOString()
            .slice(11)}`)
      );

      Object.values(payload.claim_time).map(
        (el: any, idx: number) =>
          el.enabled &&
          payload.claim_time[idx].end.length > 0 &&
          (payload.claim_time[idx].end = `${moment(
            `2222-12-22 ${payload.claim_time[idx].end}`
          )
            .toISOString()
            .slice(11)}`)
      );

      const result = await axiosDicket().patch(`/admin/ticket/${id}/`, payload);
      if (!simulated) {
        ErrorHandler("#00C62E", "Edit Ticket Variant Completed");
      }
      const res = result.data;
      return res;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          `${Object.keys(error.response.data.errors)[0]
            .split("_")
            .join(" ")}: ${error.response.data.errors[
            Object.keys(error.response.data.errors)[0]
          ][0]
            .split("_")
            .join(" ")}`
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Edit Failed");
      }
    }
  };

  static deleteTicketVariant = async (id: any) => {
    try {
      const result = await axiosDicket().delete(`/admin/ticket/${id}/`);
      ErrorHandler("#00C62E", "Delete Ticket Variant Completed");
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[
            Object.keys(error.response.data.errors)[0]
          ][0]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "delete Failed");
      }
    }
  };

  static getVariantTransaction = async (ticket_id: string) => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results: any = await axiosDicket().get(`admin/ownership/`, {
        params: {
          ticket_id,
          limit: query.get("limit") !== null ? query.get("limit") : 10,
          offset: query.get("offset") !== null ? query.get("offset") : 0,
          sort: `${
            query.get("sort") !== null ? query.get("sort") : "created_at"
          } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
        },
      });
      const result = results.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  static getVariantTransactionByCode = async (code: string) => {
    try {
      const results: any = await axiosDicket().get(`admin/ownership/${code}/`);
      const result = results.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  static scanTicket = async (code: string) => {
    try {
      const result = await axiosDicket().post("/admin/ticket/scan/", {
        code,
      });
      ErrorHandler("#00C62E", "Scan Ticket Completed");
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Scan Failed");
      }
    }
  };

  static getScanHistory = async (ticket_ownership_id: string) => {
    try {
      const results: any = await axiosDicket().get(
        `ownership/${ticket_ownership_id}/usage/`
      );
      const result = results.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  static deleteDetailImage = async (id: number) => {
    try {
      const results: any = await axiosDicket().delete(
        `ticket-bundle-media/${id}/`
      );
      return results;
    } catch (error) {
      console.log(error);
    }
  };

  static getAllScanHistory = async () => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results: any = await axiosDicket().get(`admin/usage/`, {
        params: {
          used_at_after: query.get("scan_date_from"),
          used_at_before: query.get("scan_date_to"),
          limit: query.get("limit") !== null ? query.get("limit") : 10,
          offset: query.get("offset") !== null ? query.get("offset") : 0,
          sort: `${
            query.get("sort") !== null ? query.get("sort") : "created_at"
          } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
        },
      });
      return results;
    } catch (error) {
      console.log(error);
    }
  };

  static addNoteScan = async (note: { id: number; note: string }) => {
    try {
      const result = await axiosDicket().patch(`/admin/usage/${note.id}/`, {
        note: note.note || null,
      });
      ErrorHandler("#00C62E", "Add Note Completed");
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          `${
            Object.keys(error.response.data.errors)[0]
          }: ${error.response.data.errors.note[0].split("_").join(" ")}`
        );
      } else {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors || "Add Note Failed"
        );
      }
    }
  };
}
