import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../../../../config/axiosInstance";
import moment from "moment";

import { deleteImage } from "../../../../../../assets/icons";
import Toggle from "../../../../../../component/toggleBtn/Toggle";
import Input from "../../../../../../component/input/Input";
import Save from "../../../../../../component/input/Save";
import Filepond from "../../../../../../component/filepond/Filepond";
import FilepondMultiple from "../../../../../../component/filepond/FilePondMultiple";
import ErrorHandler from "../../../../../../component/toast/ErrorHandler";

interface EditProps {
  data: {
    is_active: boolean;
    is_limited: boolean;
    name: string;
    code: string;
    display_image_path: string;
    detail_image_path: string;
    description_image_path: string;
    release_date: string;
    is_shipping_fee_charged: boolean;
    description: string;
    limit_per_user: number;
    pre_order_limit_per_user: number;
    pre_order_limited_start_date: string;
    is_pre_order_limited: boolean;
    limited_start_date: string;
  };
  refetch: any;
}

const EditForm: React.FC<EditProps> = ({ data, refetch }) => {
  const navigate = useNavigate();
  const params: any = useParams();
  const [files, setFiles] = useState<any>(null);
  const [filesDetail, setfilesDetail] = useState<any>(null);
  const [filesDesc, setFilesDesc] = useState<any>(null);
  const [load, setLoad] = useState<boolean>(false);

  const [product, setProduct] = useState<any>({
    name: "",
    code: "",
    is_limited: false,
    is_active: true,
    display_image_path: "",
    detail_image_path: "",
    description_image_path: "",
    release_date: "",
    is_shipping_fee_charged: false,
    description: "",
    limit_per_user: 0,
    pre_order_limit_per_user: 0,
    pre_order_limited_start_date: "",
    is_pre_order_limited: false,
    limited_start_date: "",
  });

  useEffect(() => {
    setProduct({
      name: data.name,
      code: data.code,
      is_limited: data.is_limited,
      is_active: data.is_active,
      display_image_path: data.display_image_path,
      detail_image_path: data.detail_image_path,
      description_image_path: data?.description_image_path,
      release_date: data.release_date,
      is_shipping_fee_charged: data.is_shipping_fee_charged,
      description: data.description,
      limit_per_user: data.limit_per_user,
      pre_order_limit_per_user: data.pre_order_limit_per_user,
      pre_order_limited_start_date: data.pre_order_limited_start_date?.slice(
        0,
        10
      ),
      is_pre_order_limited: data.is_pre_order_limited,
      limited_start_date: new Date().toISOString().slice(0, 10),
    });
    return function () {
      return setProduct({
        name: "",
        code: "",
        is_limited: false,
        is_active: false,
        display_image_path: "",
        detail_image_path: "",
        description_image_path: "",
        release_date: "",
        is_shipping_fee_charged: false,
        description: data.description,
        limit_per_user: 0,
        pre_order_limit_per_user: 0,
        pre_order_limited_start_date: "",
        is_pre_order_limited: false,
        limited_start_date: "",
      });
    };
  }, [data]);

  const handlehideProduct = () => {
    if (product.is_active === false) {
      setProduct({ ...product, is_active: true });
    } else {
      setProduct({ ...product, is_active: false });
    }
  };

  const handleLimitedProduct = () => {
    if (product.is_limited === false) {
      setProduct({ ...product, is_limited: true });
    } else {
      setProduct({ ...product, is_limited: false });
    }
  };

  const handlePreOrder = () => {
    if (product.is_pre_order_limited === false) {
      setProduct({ ...product, is_pre_order_limited: true });
    } else {
      setProduct({
        ...product,
        is_pre_order_limited: false,
      });
    }
  };

  const deleteDetailImage = async (url: string | any) => {
    try {
      const deleted_detail_images = new FormData();
      deleted_detail_images.append("deleted_detail_images", url);
      await axios()
        .patch(`retail/retail-product/${params.id}`, deleted_detail_images)
        .then((data) => refetch())
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  const deleteDescImage = async (url: string | any) => {
    try {
      const deleted_desc_images = new FormData();
      deleted_desc_images.append("deleted_description_images", url);
      await axios()
        .patch(`retail/retail-product/${params.id}`, deleted_desc_images)
        .then((data) => refetch())
        .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeInput = (e: any) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "limit_per_user") {
      value = Number(value);
    }
    if (name === "pre_order_limit_per_user") {
      value = Number(value);
    }
    setProduct({ ...product, [name]: value });
  };

  const handleSubmitForm = async (e: any) => {
    e.preventDefault();
    setLoad(true);
    const edited_product = new FormData();

    if (files !== null) {
      edited_product.append("new_display_image", files);
    }
    if (filesDetail !== null) {
      filesDetail?.file?.map((image: any) =>
        edited_product.append("new_detail_images[]", image)
      );
    }
    if (filesDesc !== null) {
      filesDesc?.file?.map((image: any) =>
        edited_product.append("new_description_images[]", image)
      );
    }

    if (product.is_active !== data.is_active) {
      edited_product.append("is_active", product.is_active);
    }

    if (product.name !== data.name && product.name.length > 0) {
      edited_product.append("name", product.name);
    }

    if (product.code !== data.code && product.code.length > 0) {
      edited_product.append("code", product.code);
    }

    if (
      product.release_date !== data.release_date &&
      product.release_date.length > 0
    ) {
      edited_product.append(
        "release_date",
        new Date(product.release_date).toISOString()
      );
    }

    if (product.is_shipping_fee_charged !== data.is_shipping_fee_charged) {
      edited_product.append(
        "is_shipping_fee_charged",
        product.is_shipping_fee_charged
      );
    }

    if (
      product.description !== data.description &&
      product.description.length > 0
    ) {
      edited_product.append("description", product.description);
    }

    if (product.is_limited !== data.is_limited) {
      edited_product.append("is_limited", product.is_limited);
    }

    if (product.is_limited) {
      edited_product.append("limit_per_user", product.limit_per_user);
      edited_product.append(
        "limited_start_date",
        product.limited_start_date.toString()
      );
    }

    if (product.is_pre_order_limited !== data.is_pre_order_limited) {
      edited_product.append(
        "is_pre_order_limited",
        product.is_pre_order_limited.toString()
      );
    }

    if (product.is_pre_order_limited) {
      edited_product.append(
        "pre_order_limit_per_user",
        product.pre_order_limit_per_user.toString()
      );
    }

    if (
      product.is_pre_order_limited &&
      product.pre_order_limited_start_date.length > 0
    ) {
      edited_product.append(
        "pre_order_limited_start_date",
        product.pre_order_limited_start_date
      );
    }

    try {
      await axios()
        .patch(`retail/retail-product/${params.id}`, edited_product)
        .then((data) => {
          setLoad(false);
          refetch();
          ErrorHandler("#00C62E", "Edit Product Completed");
          setTimeout(() => {
            navigate("/admin/products");
          }, 1500);
        });
    } catch (error: any) {
      setLoad(false);
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Add Failed");
      }
    }
  };
  const handleClear = () => {
    setProduct({
      ...product,
      name: "",
      code: "",
      is_limited: false,
      is_active: true,
      display_image_path: "",
      detail_image_path: "",
      description_image_path: "",
      release_date: "",
      is_shipping_fee_charged: false,
      description: "",
      limit_per_user: 0,
      pre_order_limit_per_user: 0,
      pre_order_limited_start_date: "",
      is_pre_order_limited: false,
      limited_start_date: "",
    });
  };

  return (
    <form onSubmit={handleSubmitForm}>
      <div className="w-full h-full  md:flex justify-between">
        <div className="md:w-1/3">
          <div className="w-full rounded-md border-2 border-stroke">
            <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5 bg">
              <h1 className="font-medium md:text-xl text-sm">Product Images</h1>
            </div>
            <hr />

            <div className="md:m-7 m-5">
              <div>
                <h1 className="md:text-base text-sm text-textPrimary">
                  Display Images
                </h1>
                <div className="md:w-full flex flex-col items-center">
                  <img
                    src={data?.display_image_path}
                    className="my-5 rounded-md shadow-md w-44"
                    alt=""
                  />
                </div>
                <h1 className="text-xs font-rom text-textPrimary mt-2 mb-1">
                  (1 x 1), (PNG, JPG, JPEG) max. 1 MB
                </h1>
                <Filepond setFiles={setFiles} />
              </div>

              <div className="mt-6 text-textPrimary">
                <h1 className="md:text-base text-sm">Upload Images</h1>
                <div className="grid grid-cols-3 md:gap-3 gap-2 justify-center h-auto">
                  {data.detail_image_path.length <= 0 ? (
                    <div className="flex justify-center w-96">
                      <h1 className="my-5">No images uploaded</h1>
                    </div>
                  ) : (
                    data.detail_image_path?.split(",").map((el: any) => (
                      <div className="ml-2 my-2 w-full" key={el.id}>
                        <div className="flex flex-col items-end">
                          <img
                            src={deleteImage}
                            className="absolute cursor-pointer w-6"
                            alt="delete_button"
                            onClick={() => deleteDetailImage(el)}
                          />
                        </div>
                        <div className="flex justify-start">
                          <img
                            className="w-full rounded-md shadow-md"
                            src={el}
                            alt="detail_image"
                          />
                        </div>
                      </div>
                    ))
                  )}
                </div>
                <h1 className="text-xs font-rom text-textPrimary mt-2">
                  Multiple Images, (1 x 1), (PNG, JPG, JPEG) max. 1 MB
                </h1>
                <FilepondMultiple setFiles={setfilesDetail} />
              </div>
            </div>
          </div>

          <div className="w-full my-10 rounded-md border-2 border-stroke">
            <div className="items-center md:m-7 m-5">
              <h1 className="md:text-base text-sm">Detail Images</h1>
              <h1 className="text-xs font-rom text-textPrimary mt-1">
                Note: You can add your size chart or detail type of your product
              </h1>
            </div>
            <hr />
            <div className="grid grid-cols-3 md:gap-3 gap-2 md:m-7 m-5 justify-center h-auto">
              {data.description_image_path.length <= 0 ? (
                <div className="flex justify-center w-96">
                  <h1 className="my-5">No images uploaded</h1>
                </div>
              ) : (
                data.description_image_path?.split(",").map((el: any) => (
                  <div className="w-full" key={el.id}>
                    <div className="flex flex-col items-end">
                      <img
                        src={deleteImage}
                        className="absolute cursor-pointer w-6"
                        alt="delete_button"
                        onClick={() => deleteDescImage(el)}
                      />
                    </div>
                    <div className="flex justify-start">
                      <img
                        className="w-full rounded-md shadow-md"
                        src={el}
                        alt="detail_image"
                      />
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className=" md:m-7 m-5">
              <h1 className="text-xs font-rom text-textPrimary">
                Multiple Images, (1 x 1), (PNG, JPG, JPEG) max. 1 MB
              </h1>
              <FilepondMultiple setFiles={setFilesDesc} />
            </div>
          </div>
        </div>

        {/* Product information */}
        <div className="md:w-custom md:mt-0 mt-10 w-full h-full">
          <div className="rounded-md rounded-b-none border-2 border-stroke">
            {/*product information title and toggle*/}
            <div className="md:h-16 h-14 flex items-center justify-between md:mx-8 mx-5">
              <h1 className="font-medium md:text-xl text-sm">
                Product Information
              </h1>

              <div className="flex">
                <h1 className="font-medium md:text-base text-sm mr-3 text-textPrimary">
                  Hide Product
                </h1>
                <Toggle
                  input={product.is_active}
                  handleClick={handlehideProduct}
                />
              </div>
            </div>
            <hr />

            {/*product Name*/}
            <div className="md:mx-8 mx-5 md:my-5 my-3">
              <div>
                <h1 className="md:text-base text-sm text-textPrimary">
                  Product Name
                </h1>
                <Input
                  required={true}
                  type={"text"}
                  name={"name"}
                  value={product.name}
                  placeholder={"place holder"}
                  handleChange={handleChangeInput}
                />
              </div>
              <div className="md:flex w-full">
                <div className="md:w-1/2 md:mt-4 mt-3">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Product Code
                  </h1>
                  <Input
                    title="Product code must contain A-Z or 0-9"
                    pattern="[a-zA-Z0-9]*"
                    required={true}
                    type={"text"}
                    name={"code"}
                    value={product.code}
                    placeholder={"place holder"}
                    handleChange={handleChangeInput}
                  />
                </div>
                <div className="md:w-1/2 md:mt-4 mt-3 md:ml-16">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Product Type
                  </h1>
                  <Input
                    disable={true}
                    required={true}
                    type={"text"}
                    name={"code"}
                    value={"-"}
                    placeholder={"place holder"}
                    handleChange={handleChangeInput}
                  />
                </div>
              </div>
            </div>

            <div className="md:flex w-full">
              {/*form product left*/}
              <div className="md:mx-8 mx-5 md:w-1/2">
                <div className="md:mt-0">
                  <h1 className="md:text-base text-sm  text-textPrimary">
                    Release Date
                  </h1>
                  <Input
                    required={true}
                    type={"datetime-local"}
                    name={"release_date"}
                    value={
                      product.release_date.length > 0
                        ? moment(product.release_date).format().slice(0, 16)
                        : ""
                    }
                    placeholder={"place holder"}
                    handleChange={handleChangeInput}
                  />
                </div>
                <div className="md:block flex justify-between items-center md:mt-4 mt-24">
                  <h1 className="md:text-base text-sm  text-textPrimary">
                    Limited Product
                  </h1>
                  <div className="flex md:my-3 my-4">
                    <div className="flex justify-between items-center">
                      <Toggle
                        input={!product?.is_limited}
                        handleClick={handleLimitedProduct}
                      />
                      <h1 className="font-rom md:text-base text-sm ml-4 text-textPrimary">
                        {!product?.is_limited ? "Enable" : "Disable"}
                      </h1>
                    </div>
                  </div>
                </div>

                <div className="md:mt-9 md:block flex justify-between items-center">
                  <h1 className="md:text-base text-sm  text-textPrimary">
                    Pre-Order
                  </h1>
                  <div className="flex md:my-3 my-4">
                    <div className="flex justify-between items-center">
                      <Toggle
                        input={!product?.is_pre_order_limited}
                        handleClick={handlePreOrder}
                      />
                      <h1 className="font-rom md:text-base text-sm  ml-4 text-textPrimary">
                        {!product?.is_pre_order_limited ? "Enable" : "Disable"}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              {/*form product right*/}
              <div className="md:mx-8 mx-5 md:w-1/2">
                <div className="md:mt-0 -mt-48">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Shipping Fee
                  </h1>
                  <select
                    required={true}
                    onChange={handleChangeInput}
                    name="is_shipping_fee_charged"
                    value={product.is_shipping_fee_charged}
                    className="md:h-10 h-8 font-rom w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke focus:ring-1 focus:outline-none appearance-none"
                  >
                    <option value="false">Seller</option>
                    <option value="true">Buyer</option>
                  </select>
                </div>

                {product?.is_limited ? (
                  <div className="md:mt-3 mt-32">
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Limit / User
                    </h1>
                    <Input
                      required={true}
                      type={"number"}
                      value={product.limit_per_user}
                      name={"limit_per_user"}
                      placeholder={"place holder"}
                      handleChange={handleChangeInput}
                    />
                    <h1 className="text-xs">
                      note: limit per user must be greater than 0.
                    </h1>
                  </div>
                ) : (
                  <div className="h-24 mt-1"></div>
                )}

                {product?.is_pre_order_limited ? (
                  <div>
                    <div className="mt-4">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Limit / User
                      </h1>
                      <Input
                        required={true}
                        type={"number"}
                        value={product.pre_order_limit_per_user}
                        name={"pre_order_limit_per_user"}
                        placeholder={"place holder"}
                        handleChange={handleChangeInput}
                      />
                      <h1 className="text-xs">
                        note: limit per user must be greater than 0.
                      </h1>
                    </div>
                    <div className="mt-4">
                      <h1 className="md:text-base text-sm  text-textPrimary">
                        Start Date
                      </h1>
                      <Input
                        required={true}
                        type={"date"}
                        name={"pre_order_limited_start_date"}
                        placeholder={"place holder"}
                        handleChange={handleChangeInput}
                        value={
                          product.pre_order_limited_start_date
                            ? new Date(product?.pre_order_limited_start_date)
                                .toISOString()
                                .slice(0, 10)
                            : ""
                        }
                      />
                    </div>
                  </div>
                ) : (
                  //   <div className="mt-6 h-60"></div>
                  ""
                )}

                {/* {discount ? <div>
                                <div className={preOrder ? "mt-7" : '-mt-28'}>
                                    <h1 className="text-xl text-textPrimary">Discount Percent (%)</h1>
                                    <input type="text" placeholder="placeholder" className="h-14 w-full rounded-md text-lg my-3 px-5 border-2 border-stroke" />
                                </div>
                                <div className="mt-10">
                                    <h1 className="text-xl text-textPrimary">Discount Start Date</h1>
                                    <input type="date" placeholder="placeholder" className="h-14 w-full rounded-md text-lg my-3 px-5 border-2 border-stroke" />
                                </div>
                                <div className="mt-6">
                                    <h1 className="text-xl text-textPrimary">Discount End Date</h1>
                                    <input type="date" placeholder="placeholder" className="h-14 w-full rounded-md text-lg my-3 px-5 border-2 border-stroke" />
                                </div>
                            </div> : ""} */}
              </div>
            </div>

            {/*product description*/}
            <div className="md:mb-8 mb-5 md:mt-4 mt-6 md:mx-8 mx-5">
              <h1 className="md:text-base text-sm  text-textPrimary">
                Description
              </h1>
              <textarea
                required={true}
                placeholder="placeholder"
                value={product.description}
                onChange={handleChangeInput}
                name="description"
                className="md:h-28 h-24 font-rom w-full rounded-md md:text-base text-xs my-3 px-3 py-3 border-2 border-stroke focus:ring-1 focus:outline-none"
              ></textarea>
            </div>
          </div>

          {/*button save*/}
          <Save handleReset={handleClear} load={load} />
        </div>
      </div>
    </form>
  );
};

export default EditForm;
