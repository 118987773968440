import React, { useReducer } from "react";
import { Route, Routes } from "react-router-dom";
import AddTicket from "../../pages/admin/ticket/AddTicket";
import EditTicket from "../../pages/admin/ticket/EditTicket";
import TicketDetail from "../../pages/admin/ticket/mobile_view/TicketDetail";
import StockTicketDetail from "../../pages/admin/ticket/stock_ticket/mobile_view/StockTicketDetail";
import StockTicket from "../../pages/admin/ticket/stock_ticket/StockTicket";
import AddTicketVariant from "../../pages/admin/ticket/stock_ticket/stock_ticket_component/AddTicketVariant";
import EditTicketVariant from "../../pages/admin/ticket/stock_ticket/stock_ticket_component/EditTicketVariant";
import ScanqrCode from "../../pages/admin/ticket/stock_ticket/stock_ticket_component/ScanqrCode";
import VariantTransaction from "../../pages/admin/ticket/stock_ticket/stock_ticket_component/VariantTransaction";
import VariantTransactionDetail from "../../pages/admin/ticket/stock_ticket/stock_ticket_component/variant_transaction/VariantTransactionDetail";
import Ticket from "../../pages/admin/ticket/Ticket";
import {
  initialTicketState,
  TicketContextProvider,
} from "../../store/ticket/context";
import { TicketReducer } from "../../store/ticket/reducer";

const TicketRoute = () => {
  const [TicketState, TicketDispatch] = useReducer(
    TicketReducer,
    initialTicketState
  );

  return (
    <TicketContextProvider value={{ TicketState, TicketDispatch }}>
      <Routes>
        <Route path="/" element={<Ticket />} />
        <Route path="add-ticket" element={<AddTicket />} />
        <Route path=":id" element={<TicketDetail />} />
        <Route path=":id/edit-ticket" element={<EditTicket />} />
        <Route path=":id/stock-ticket" element={<StockTicket />} />
        <Route
          path=":id/stock-ticket/add-ticket-variant"
          element={<AddTicketVariant />}
        />
        <Route
          path=":id/stock-ticket/:variantId"
          element={<StockTicketDetail />}
        />
        <Route
          path=":id/stock-ticket/edit-ticket-variant/:variantId"
          element={<EditTicketVariant />}
        />
        <Route
          path=":id/stock-ticket/:variantId/variant-transaction"
          element={<VariantTransaction />}
        />
        <Route
          path=":id/stock-ticket/:variantId/variant-transaction/scan-qr-code"
          element={<ScanqrCode />}
        />
        <Route
          path=":id/stock-ticket/:variantId/variant-transaction/:detailId"
          element={<VariantTransactionDetail />}
        />
      </Routes>
    </TicketContextProvider>
  );
};

export default TicketRoute;
