export enum ActionType {
  ShowFilter,
  ShowDeleteTicket,
  ShowDeleteTicketVariant,
  ShowSendNotif,
  ShowScanQr,
  ShowScanResult,
  HandleChip,
  ConfirmEditTicket,
}

export interface ShowFilter {
  type: ActionType.ShowFilter;
}

export interface ShowSendNotif {
  type: ActionType.ShowSendNotif;
  payload: { status: boolean; id: number };
}

export interface ShowScanQr {
  type: ActionType.ShowScanQr;
  payload: { status: boolean; id: number };
}

export interface ShowScanResult {
  type: ActionType.ShowScanResult;
  payload: { status: boolean; code: string };
}

export interface ShowDeleteTicket {
  type: ActionType.ShowDeleteTicket;
  payload: { status: boolean; id: number };
}

export interface ShowDeleteTicketVariant {
  type: ActionType.ShowDeleteTicketVariant;
  payload: { status: boolean; id: number };
}

export interface HandleChip {
  type: ActionType.HandleChip;
  payload: any[];
}

export interface ConfirmEditTicket {
  type: ActionType.ConfirmEditTicket;
  payload: { status: boolean; id: number; data: any };
}

export type TicketAction =
  | ShowFilter
  | HandleChip
  | ShowDeleteTicket
  | ShowScanQr
  | ShowSendNotif
  | ShowScanResult
  | ShowDeleteTicketVariant
  | ConfirmEditTicket;
