import { useNavigate } from "react-router-dom";

interface IMHomeCardProps {
  title: string;
  subtitle: number;
  pathname: string;
  search?: string;
}

const MHomeCard: React.FC<IMHomeCardProps> = ({
  title,
  subtitle,
  pathname,
  search,
}) => {
  const navigate = useNavigate();
  return (
    <article className="bg-white shadow-md rounded-lg border md:h-48 h-40 max-h-52 border-stroke text-textPrimary">
      <div className="p-5 h-2/3">
        <div className="flex justify-between font-rom">
          <h1>{title}</h1>
          <h1>i</h1>
        </div>
        <div className="mt-1.5">
          <h1 className="text-2xl">{subtitle}</h1>
        </div>
      </div>
      <div className="border-t-2 border-stroke px-5 flex items-center h-1/3 w-full">
        <h1
          onClick={() => navigate({ pathname, search })}
          className="text-base truncate cursor-pointer font-rom hover:font-med hover:text-primary"
        >
          Go to Transaction
        </h1>
      </div>
    </article>
  );
};

export default MHomeCard;
