import _ from "lodash";
import AxiosInstance from "../../../config/Instance";
import { IRetailUser } from "../entities";
import IRetailUserRepository from "../repository";

export default class RetailUserRepository implements IRetailUserRepository {
  // api routes
  private RETAIL_USER_ROUTE = "/retail/retail-user";

  private getError(error: unknown) {
    return _.get(error, "response.data.errors", "Something went wrong!");
  }

  async getRetailUser(): Promise<IRetailUser> {
    try {
      const response = await AxiosInstance.Mercury().get(
        `${this.RETAIL_USER_ROUTE}/profile`
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw this.getError(error);
    }
  }
}
