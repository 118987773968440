import { FC } from "react";
import { IIconProps } from "./Ahome";

export const Acardholder: FC<IIconProps> = ({
  stroke = "#666666",
  width = "26",
  height = "21",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23 1.64966H3C1.89543 1.64966 1 2.54509 1 3.64966V17.6497C1 18.7542 1.89543 19.6497 3 19.6497H23C24.1046 19.6497 25 18.7542 25 17.6497V3.64966C25 2.54509 24.1046 1.64966 23 1.64966Z"
        stroke={stroke}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 9.64967H8.1C8.3303 9.6484 8.55381 9.72766 8.73185 9.87375C8.90989 10.0198 9.03127 10.2236 9.075 10.4497C9.26597 11.3491 9.76003 12.1556 10.4745 12.7343C11.189 13.313 12.0806 13.6288 13 13.6288C13.9194 13.6288 14.811 13.313 15.5255 12.7343C16.24 12.1556 16.734 11.3491 16.925 10.4497C16.9687 10.2236 17.0901 10.0198 17.2682 9.87375C17.4462 9.72766 17.6697 9.6484 17.9 9.64967H25"
        stroke={stroke}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M1 5.64966H25"
        stroke={stroke}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
