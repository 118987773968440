import RetailStatisticRepository from "../../core/retail_statistic/repository/repository";
import RetailStatisticUsecase from "../../core/retail_statistic/usecase/usecase";

export class RetailStatisticService {
  static getRetailStatistic = async () => {
    try {
      const retailStatisticRepo = new RetailStatisticRepository();
      const retailStatisticUsecase = new RetailStatisticUsecase(
        retailStatisticRepo
      );
      const retailStatistic = await retailStatisticUsecase.getRetailStatistic();
      return retailStatistic;
    } catch (error) {
      console.log(error);
    }
  };

  static getRetailMonthlyRevenue = async (month: string, year: string) => {
    try {
      const retailStatisticRepo = new RetailStatisticRepository();
      const retailStatisticUsecase = new RetailStatisticUsecase(
        retailStatisticRepo
      );
      const retailStatistic =
        await retailStatisticUsecase.getRetailMonthlyRevenue(month, year);
      return retailStatistic;
    } catch (error) {
      console.log(error);
    }
  };
}
