import moment from "moment";
import React, { useContext } from "react";
import ReactTooltip from "react-tooltip";
import { listTrans, notify, scan } from "../../../../../../assets/icons";
import NewPagination from "../../../../../../component/pagination/NewPagination";
import { ActionType } from "../../../../../../store/ticket/action";
import TicketContext from "../../../../../../store/ticket/context";

interface IProps {
  data: any;
}

const VariantTransactionTable: React.FC<IProps> = ({ data }) => {
  const { TicketDispatch } = useContext(TicketContext);
  return (
    <div className="mt-5 w-full">
      <table className="w-full border relative text-center text-sm">
        <thead>
          <tr className="h-18 sticky top-0 bg-white shadow-sm">
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Transaction Code</h1>
              </div>
            </th>
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Customer</h1>
              </div>
            </th>
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Ticket #</h1>
              </div>
            </th>
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Status</h1>
              </div>
            </th>
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Valid (From)</h1>
              </div>
            </th>
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Valid (Until)</h1>
              </div>
            </th>
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Transaction Date</h1>
              </div>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.map((el: any) => (
            <tr className="border h-24 font-rom hover:bg-gray-50">
              <td className="border-r">{el.invoice_code}</td>
              <td className="border-r">{el.username || '-'}</td>
              <td className="border-r">{el.index}</td>
              <td
                className={
                  "border-r " +
                  (el.purchase_status === "awaiting_payment"
                    ? "text-yellow-400"
                    : "text-success")
                }
              >
                {el.purchase_status.split("_").join(" ")}
              </td>
              <td className="border-r">
                {el.valid_time_from
                  ? moment(el.valid_time_from).format("LL hh:mm")
                  : "-"}
              </td>
              <td className="border-r">
                {el.valid_time_until
                  ? moment(el.valid_time_until).format("LL hh:mm")
                  : "-"}
              </td>
              <td className="border-r">
                {moment(el.created_at).format("LL hh:mm")}
              </td>
              <td className="w-1/6">
                <div className="flex justify-evenly items-center">
                  <div>
                    <img
                      data-tip
                      data-for="notify"
                      className="cursor-pointer md:w-6"
                      // onClick={() =>
                      //   TicketDispatch({
                      //     type: ActionType.ShowSendNotif,
                      //     payload: {
                      //       status: true,
                      //       id: el.id,
                      //     },
                      //   })
                      // }
                      src={notify}
                      alt="notify"
                    />
                    <ReactTooltip id="notify" type="info">
                      <span>Notify</span>
                    </ReactTooltip>
                  </div>
                  <div>
                    <img
                      data-tip
                      data-for="scan"
                      className="cursor-pointer md:w-7"
                      onClick={() =>
                        TicketDispatch({
                          type: ActionType.ShowScanQr,
                          payload: {
                            status: true,
                            id: el.id,
                          },
                        })
                      }
                      src={scan}
                      alt="scan"
                    />
                    <ReactTooltip id="scan" type="success">
                      <span>Scan</span>
                    </ReactTooltip>
                  </div>
                  <div>
                    <img
                      data-tip
                      data-for="detail"
                      className="cursor-pointer md:w-5"
                      onClick={() =>
                        TicketDispatch({
                          type: ActionType.ShowDeleteTicketVariant,
                          payload: {
                            status: true,
                            id: el.id,
                          },
                        })
                      }
                      src={listTrans}
                      alt="detail"
                    />
                    <ReactTooltip id="detail" type="dark">
                      <span>Detail</span>
                    </ReactTooltip>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <NewPagination
        length={data?.data?.length}
        total={data?.meta?.total}
        isTable={true}
      />
    </div>
  );
};

export default VariantTransactionTable;
