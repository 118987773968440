import { useQuery } from "react-query";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { back } from "../../../../../assets/icons";
import Loading from "../../../../../component/loading/Loading";
import axios from '../../../../../config/axiosInstance'

const ProductDetail: React.FC = () => {
    const{ pathname } = useLocation()
    const navigate = useNavigate()
    const params = useParams()

    const goBack = () => {
        navigate('/admin/products')
    }

    const goEdit = () => {
        navigate(`${pathname}/edit-product`)
    }

    const goViewStock = () => {
        navigate(`${pathname}/stock-product`)
    }

    const goDelete = () => {
        navigate(`${pathname}/delete-product`)
    }

    const getById = async (id: string) => {
        try {
            const results: any = await axios().get(`retail/retail-product/${id}`)
            return results
        } catch (error) {
            if (error.message === "Request failed with status code 401") {
                navigate('/login')
            }
        }
    }

    const { data, isSuccess } = useQuery('productId', () => getById(params.id || ''))

    return (
        <div className="mb-28 font-med">
            <div className="md:pt-28 pt-20 overflow-auto">
                <div onClick={goBack} className="flex items-center" >
                    <img src={back} alt="back"/>
                    <h1 className="text-mobile ml-1.5">Back</h1>
                </div>

                {
                    isSuccess ?
                        <div>
                            <div className="mt-12 flex justify-center">
                                <img className="rounded-md shadow-md w-56" src={data.data.display_image_path} alt="" />
                            </div>
                            <div className="px-5 mt-12">
                                <div>
                                    <h1 className="text-mobile">Product Name</h1>
                                    <div className="flex items-center border-2 mt-2 px-3 rounded-md border-disableColor h-10">
                                        <h1 className="text-mobile font-rom">{data.data.name}</h1>
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <h1 className="text-mobile">Product Code</h1>
                                    <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                                        <h1 className="text-mobile font-rom">{data.data.code}</h1>
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <h1 className="text-mobile">Product View</h1>
                                    <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                                        <h1 className="text-mobile font-rom">{data.data.view_count}</h1>
                                    </div>
                                </div>
                                <div className="mt-5">
                                    <h1 className="text-mobile">Product Status</h1>
                                    <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                                        <h1 className="text-mobile font-rom">{data.data.status_message}</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : <div className="flex justify-center mt-10">
                            <Loading />
                        </div>
                }
            </div>
            <footer className="h-24 p-3 flex justify-evenly items-center w-full left-0 bottom-0 bg-white shadow-inner fixed">
                <div onClick={goViewStock} className="w-1/4 h-14 flex items-center justify-center border-disableColor border-2 shadow-md rounded">
                    <h1 className="text-xs text-textPrimary">View Stock</h1>
                </div>
                <div onClick={goEdit} className="w-1/4 h-14 flex items-center justify-center bg-success shadow-md rounded">
                    <h1 className="text-xs text-white">Edit Product</h1>
                </div>
                <div onClick={goDelete} className="w-1/4 h-14 flex items-center justify-center bg-primary shadow-md rounded">
                    <h1 className="text-xs text-white">Delete Product</h1>
                </div>
            </footer>
        </div>
    );
}

export default ProductDetail;