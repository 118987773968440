import React, { useState } from "react";

import Input from "../../../../component/input/Input";
import Save from "../../../../component/input/Save";
import Handler from "../../../../component/toast/Handler";
import PasswordModal from "./modal/PasswordModal";

interface IProps {
  detailData: any;
}

const BankInformation: React.FC<IProps> = ({ detailData }) => {
  const [bank, setBank] = useState<any>({
    bank_name: detailData.retail_bank.name,
    bank_number: detailData.retail_bank.number,
    bank_owner: detailData.retail_bank.owner,
    amount: detailData.retail_wallet.amount,
    password: "",
  });
  const [modal, setModal] = useState(false);

  const checkPassword = () => {
    if (
      bank.bank_name === "" ||
      bank.bank_number === "" ||
      bank.bank_owner === ""
    ) {
      Handler("error", "Please Check Your Input Field!");
    } else {
      setModal(!modal);
    }
  };

  const handleClear = () => {
    setBank({
      ...bank,
      bank_name: "",
      bank_number: "",
      bank_owner: "",
    });
  };

  const handleChangeInput = (e: any) => {
    const target = e.target;
    setBank({ ...bank, [target.name]: target.value });
  };

  return (
    <div className="w-full h-full my-8 md:flex justify-between">
      {modal && (
        <div className="h-full w-full absolute flex justify-center top-0 bottom-0 left-0">
          <PasswordModal
            setModal={() => setModal(!modal)}
            modal={modal}
            bank={bank}
            setBank={setBank}
          />
        </div>
      )}

      {/* wallet balance */}
      <div className="md:w-1/3 h-full rounded-md border-2 border-stroke">
        <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
          <h1 className="font-medium md:text-xl text-base">Wallet Balance</h1>
        </div>
        <hr />

        <div className="md:mx-8 mx-5 my-7">
          <div>
            <h1 className="md:text-base text-sm text-textPrimary">
              Wallet Balance
            </h1>
            <Input
              required={true}
              type="text"
              disable={true}
              placeholder={"Place holder"}
              value={bank.amount?.toLocaleString("EN-US", {
                style: "currency",
                currency: "IDR",
              })}
              name={"bank_amount"}
            />
          </div>
        </div>
      </div>

      {/* bank information */}
      <div className="md:w-custom h-full md:my-0 my-8">
        <div className="rounded-md rounded-b-none border-2 border-stroke">
          <div className="md:h-16 h-14  flex items-center justify-between md:mx-8 mx-5">
            <h1 className="font-medium md:text-xl text-base">
              Bank Information
            </h1>
          </div>
          <hr />

          <div className="md:flex w-full mb-8">
            <div className="md:mx-8 mx-5 mt-7 md:w-1/2">
              <div>
                <h1 className="md:text-base text-sm text-textPrimary">
                  Bank Name
                </h1>
                <Input
                  required={true}
                  type={"text"}
                  placeholder={"E.g : BCA"}
                  value={bank.bank_name}
                  name="bank_name"
                  handleChange={handleChangeInput}
                />
              </div>
              <div className="md:mt-4 mt-3">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Account Number
                </h1>
                <Input
                  required={true}
                  type={"number"}
                  placeholder={"Place holder"}
                  value={bank.bank_number}
                  name="bank_number"
                  handleChange={handleChangeInput}
                />
              </div>
            </div>

            <div className="md:mx-8 mx-5 md:mt-7 mt-4 md:w-1/2">
              <div>
                <h1 className="md:text-base text-sm text-textPrimary">
                  Bank Account Name
                </h1>
                <Input
                  type={"text"}
                  placeholder={"placeholder"}
                  value={bank.bank_owner}
                  name="bank_owner"
                  handleChange={handleChangeInput}
                />
              </div>
            </div>
          </div>
        </div>

        <Save handleReset={handleClear} handleSave={checkPassword} />
      </div>
    </div>
  );
};

export default BankInformation;
