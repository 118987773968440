import { TextField } from "@mui/material";
import React from "react";

interface IATextFieldProps {
  placeholder?: string;
  type?: string;
  handleChange?: any;
  error?: boolean;
  helperText?: string;
  isDisabled?: boolean;
  value?: string | number;
  name?: string;
  multiline?: boolean;
  maxRows?: number;
  minRows?: number;
  label?: string;
}

const ATextField: React.FC<IATextFieldProps> = ({
  placeholder = "Place holder",
  type = "text",
  handleChange,
  error = false,
  helperText,
  isDisabled = false,
  value,
  name,
  multiline = false,
  maxRows = 1,
  minRows = 1,
  label = "",
  ...otherProps
}) => {
  return (
    <TextField
      label={label}
      multiline={multiline}
      maxRows={maxRows}
      inputProps={{
        style: {
          height: "25px",
          fontSize: "0.8rem",
          paddingTop: "0.7rem",
          paddingBottom: "0.8rem",
        },
      }}
      InputLabelProps={{
        style: {
          marginLeft: 0,
          padding: "0",
          paddingBottom: "1rem",
          marginBottom: "1rem",
        },
      }}
      name={name}
      disabled={isDisabled}
      FormHelperTextProps={{
        style: { marginTop: "0.3rem", margin: 0, fontSize: "0.7rem" },
      }}
      sx={{ width: "100%" }}
      value={value}
      onChange={handleChange}
      type={type}
      size="small"
      placeholder={placeholder}
      variant="outlined"
      error={error}
      helperText={error && helperText}
      {...otherProps}
    />
  );
};

export default ATextField;
