import {
  IAddressCity,
  IAddressDistrict,
  IAddressProvince,
  IAddressSubdistrict,
} from "../entities";
import IAddressRepository from "../repository";
import IAddressUsecase from "../usecase";

export default class AddressUsecase implements IAddressUsecase {
  private addressRepo: IAddressRepository;

  constructor(addressRepo: IAddressRepository) {
    this.addressRepo = addressRepo;
  }

  async getAddressProvince(filter: {
    province_name?: string;
  }): Promise<IAddressProvince[]> {
    return await this.addressRepo.getAddressProvince(filter);
  }

  getAddressCity = async (filter: {
    province_id: string;
    city_name?: string;
  }): Promise<IAddressCity[]> => {
    return await this.addressRepo.getAddressCity(filter);
  };

  getAddressDistrict = async (filter: {
    city_id: string;
    district_name?: string;
  }): Promise<IAddressDistrict[]> => {
    return await this.addressRepo.getAddressDistrict(filter);
  };

  getAddressSubdistrict = async (filter: {
    district_id: string;
    sub_district_name?: string;
  }): Promise<IAddressSubdistrict[]> => {
    return await this.addressRepo.getAddressSubdistrict(filter);
  };
}
