import React from "react";
import { cancel } from "../../../../assets/icons";
import Input from "../../../../component/input/Input";

export interface WalletFilterProps {
  showFilter: boolean;
  setShowFilter: (showFilter: boolean) => void;
  filter: any;
  setFilter: any;
  refetch: () => void;
  applyChip: any;
}

const WalletFilter: React.FC<WalletFilterProps> = ({
  showFilter,
  filter,
  setFilter,
  setShowFilter,
  refetch,
  applyChip,
}) => {
  const handleShowFilter = () => {
    setShowFilter(!showFilter);
    setFilter({
      ...filter,
      type: "",
      withdraw_date_from: "",
      withdraw_date_to: "",
    });
  };

  const handleChangeFilter = (event: any) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name === "amount") {
      Number(value);
    }
    setFilter({ ...filter, [name]: value });
  };

  const applyFilter = () => {
    refetch();
    applyChip();
    setTimeout(() => setShowFilter(!showFilter), 1000);
  };
  return (
    <div>
      {showFilter === false ? (
        ""
      ) : (
        <div className="fixed z-50 inset-0 overflow-y-auto bg-black bg-opacity-70 flex justify-center md:items-center items-start">
          <div
            className="bg-white md:rounded-lg rounded-t-none rounded-b-lg overflow-hidden shadow-xl transform transition-all md:max-w-2xl max-w-lg"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white mt-7 mb-5">
              <div className="sm:flex sm:items-start">
                <div className="px-9 flex justify-between w-full">
                  <h1
                    className="md:text-2xl text-lg leading-6 font-medium text-textPrimary"
                    id="modal-headline"
                  >
                    Filter
                  </h1>
                  <img
                    src={cancel}
                    alt="back"
                    className="cursor-pointer md:w-5 w-3"
                    onClick={handleShowFilter}
                  />
                </div>
              </div>
            </div>

            <hr className="sm:mt-2 w-full " />
            {/* <div className="flex mx-6 p-3">
              <div className="w-full">
                <h1 className="md:text-base text-sm text-textPrimary">Status</h1>
                <select
                  name="type"
                  onChange={handleChangeFilter}
                  className="md:h-10 h-8 w-full rounded-md md:text-sm text-xs my-3 px-3 border-2 border-stroke appearance-none shadow-sm focus:ring-1 focus:outline-none"
                >
                  <option disabled selected hidden>
                    Select Status
                  </option>
                  <option value="debit">Debit</option>
                  <option value="credit">Credit</option>
                </select>
              </div>
              <div className=" w-96">
                <h1 className="text-xl text-textPrimary">Amount</h1>
                <input
                  type="text"
                  placeholder="Place holder"
                  onChange={handleChangeFilter}
                  name="amount"
                  className="h-14 w-full rounded-md text-lg my-3 px-5 border-2 border-stroke"
                />
              </div> 
            </div> */}

            {/* <hr className="w-full " /> */}
            <div className="flex mx-4 md:mx-3 sm:p-6 py-4">
              <div className="mr-12 w-96">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Withdraw Date (From)
                </h1>
                <Input
                  type={"date"}
                  placeholder={"Place holder"}
                  name={"withdraw_date_from"}
                  handleChange={handleChangeFilter}
                />
              </div>
              <div className="w-96">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Withdraw Date (To)
                </h1>
                <Input
                  type={"date"}
                  placeholder={"Place holder"}
                  name={"withdraw_date_to"}
                  handleChange={handleChangeFilter}
                />
              </div>
            </div>
            <hr className="w-full" />
            <div className="flex justify-center m-5">
              <button
                onClick={applyFilter}
                className="bg-primary w-36 h-9 rounded-full focus:ring-0 focus:outline-none hover:bg-red-300"
              >
                <h1 className="text-white text-base mb-0.5">Apply</h1>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default WalletFilter;
