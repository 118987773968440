import { useFormik } from "formik";
import React, { useEffect } from "react";
import Abutton from "../../atoms/Abutton";
import ATextField from "../../atoms/AtextField";
import * as Yup from "yup";
import { isAssistant } from "../../../helpers/checkRole";
import { getWithExpiry } from "../../../config/axiosInstance";
import { useState } from "react";
import OselectAddress from "./OselectAddress";
import { IRetailShop } from "../../../core/retail_shop/entities";
import { useMutateUpdateRetailShop } from "../../../hooks/retail_shop";

type props = {
  dataShop: IRetailShop;
};

const OGeneralSection = ({ dataShop }: props) => {
  const role = getWithExpiry("role");
  const [openSelectAddress, setOpenSelectAddress] = useState(false);

  const { mutate, isLoading } = useMutateUpdateRetailShop();

  const {
    handleChange,
    handleSubmit,
    values,
    errors,
    touched,
    handleReset,
    setValues,
  } = useFormik({
    initialValues: {
      name: "",
      address: "",
      country: "Indonesia",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Store name is required!"),
      address: Yup.string().required("Address is required!"),
    }),
    onSubmit: (values) => {
      mutate({
        address: values.address,
        name: values.name,
      });
    },
  });

  useEffect(() => {
    if (dataShop) {
      setValues({
        ...values,
        name: dataShop.name,
        address: dataShop?.retail_address.address,
      });
    }
  }, [dataShop]);

  return (
    <>
      {openSelectAddress && (
        <OselectAddress
          isOpen={openSelectAddress}
          setOpen={setOpenSelectAddress}
        />
      )}
      <form onSubmit={handleSubmit} className="flex gap-5  sm:text-sm text-xs">
        <section className="w-1/3 border-r pr-10">
          <div className="flex justify-between">
            <h1 className="font-med">Store Location</h1>
            <h1
              onClick={() => setOpenSelectAddress(true)}
              className="cursor-pointer hover:text-primary"
            >
              Change Location
            </h1>
          </div>
          <div className="w-full mt-3">
            <ATextField
              type="text"
              name="_"
              maxRows={1}
              value={`${dataShop.retail_address.province},${dataShop.retail_address.city},${dataShop.retail_address.district},${dataShop.retail_address.sub_district}`}
              isDisabled={true}
            />
          </div>
          <div className="mt-5">
            <ATextField
              type="text"
              name="address"
              label="Address"
              placeholder="Place Holder"
              multiline
              value={values.address}
              handleChange={handleChange}
              error={touched.address && errors.address ? true : false}
              helperText={errors.address}
            />
          </div>
        </section>

        <section className="w-2/3">
          <div className="flex justify-between items-center gap-3">
            <h1>Store Name</h1>
            <div className="w-10/12 flex items-center gap-2">
              :
              <ATextField
                isDisabled={isAssistant(role) && true}
                type="text"
                name="name"
                placeholder="Place Holder"
                value={values.name}
                handleChange={handleChange}
                error={touched.name && errors.name ? true : false}
                helperText={errors.name}
              />
            </div>
          </div>
          <div className="flex justify-between items-center gap-3 mt-5">
            <h1>Country</h1>
            <div className="w-10/12 flex items-center gap-2">
              :
              <ATextField
                isDisabled
                type="text"
                name="country"
                placeholder="Place Holder"
                value={values.country}
              />
            </div>
          </div>
          {/* <div className="flex justify-between items-center gap-3 mt-5">
          <h1>Store Birthday</h1>
          <div className="w-10/12 flex items-center gap-2">
            :
            <ATextField
              type="text"
              name="country"
              placeholder="Place Holder"
              // value={values.checker_initial}
              // handleChange={handleChange}
              // error={
              //   touched.checker_initial && errors.checker_initial ? true : false
              // }
              // helperText={errors.checker_initial}
            />
          </div>
        </div> */}

          <div className="flex justify-end items-center gap-3 mt-8">
            <Abutton
              onClick={() => handleReset({})}
              title="Clear"
              theme="secondary"
            />
            <Abutton type="submit" title="Save" isLoading={isLoading} />
          </div>
        </section>
      </form>
    </>
  );
};

export default OGeneralSection;
