import { FC } from "react";
import { IIconProps } from "./Ahome";

export const Auser: FC<IIconProps> = ({
  stroke = "#666666",
  width = "26",
  height = "27",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 17.6497C15.7614 17.6497 18 15.4111 18 12.6497C18 9.88823 15.7614 7.64966 13 7.64966C10.2386 7.64966 8 9.88823 8 12.6497C8 15.4111 10.2386 17.6497 13 17.6497Z"
        stroke={stroke}
        stroke-width="2"
        stroke-miterlimit="10"
      />
      <path
        d="M4.97498 22.5746C5.72736 21.0926 6.87542 19.848 8.2919 18.9786C9.70838 18.1091 11.3379 17.6489 13 17.6489C14.662 17.6489 16.2916 18.1091 17.7081 18.9786C19.1245 19.848 20.2726 21.0926 21.025 22.5746"
        stroke={stroke}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M19 4.64966H25"
        stroke={stroke}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M22 1.64966V7.64966"
        stroke={stroke}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M24.85 11.7622C24.9511 12.3862 25.0012 13.0175 25 13.6497C25 16.0231 24.2962 18.3431 22.9776 20.3165C21.6591 22.2899 19.7849 23.828 17.5922 24.7362C15.3995 25.6445 12.9867 25.8821 10.6589 25.4191C8.33115 24.9561 6.19295 23.8132 4.51472 22.135C2.83649 20.4567 1.6936 18.3185 1.23058 15.9908C0.767559 13.663 1.0052 11.2502 1.91345 9.05748C2.8217 6.86477 4.35977 4.99062 6.33316 3.67205C8.30655 2.35347 10.6266 1.64968 13 1.64968C13.6322 1.64844 14.2634 1.69861 14.8875 1.79968"
        stroke={stroke}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
