import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getWithExpiry } from "../../../config/axiosInstance";

import { camera } from "../../../assets/icons";
import BackgroundImage from "./profileComponent/BackgroundImage";
import {
  Avatar,
  Badge,
  Box,
  Divider,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/styles";
import moment from "moment";
import { useFetchRetailProfile } from "../../../hooks/retail_user";
import { Aloading } from "../../../components/atoms/Aloading";
import OGeneralSection from "../../../components/organisms/profile/OGeneralSection";
import {
  useFetchRetailShop,
  useMutateUpdateRetailShop,
} from "../../../hooks/retail_shop";
import BankInformation from "./profileComponent/BankInformation";
import Settings from "./profileComponent/Settings";

function Profile() {
  const navigate = useNavigate();
  const [files, setFiles] = useState<any>([]);
  const role = getWithExpiry("role");

  const { mutate, isLoading: loadUpdateProfile } = useMutateUpdateRetailShop();

  // const onDrop = useCallback((acceptedFiles) => {
  //   setFiles(
  //     acceptedFiles.map((file: any) =>
  //       Object.assign(file, {
  //         preview: URL.createObjectURL(file),
  //       })
  //     )
  //   );
  // }, []);

  // const thumbs = files.map((file: any) => (
  //   <div key={file.name} className="flex rounded-md w-24 h-24">
  //     <div className="flex min-w-0 overflow-hidden p-2">
  //       <img
  //         src={file.preview}
  //         className="w-auto h-full rounded-md shadow-md"
  //         alt="-"
  //       />
  //     </div>
  //   </div>
  // ));

  // useEffect(
  //   () => () => {
  //     files.forEach((file: any) => URL.revokeObjectURL(file.preview));
  //   },
  //   [files]
  // );

  // const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
  //   onDrop,
  //   accept: "image/jpeg, image/png, image/jpg",
  // });

  // const handleUpload = async () => {
  //   const new_logo_image = new FormData();
  //   new_logo_image.append("new_logo_image", acceptedFiles[0]);
  //   const result = await axios().patch("retail/retail-shop", new_logo_image);
  //   if (result) {
  //     setSuccess(!success);
  //     setTimeout(() => {
  //       navigate("/admin/profile");
  //     }, 1500);
  //     refetch();
  //   }
  // };

  // const cancelUpload = () => {
  //   setFiles([]);
  //   delete acceptedFiles[0];
  // };

  function TabPanel(props: {
    [x: string]: any;
    children: any;
    value: any;
    index: any;
  }) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const [value, setValue] = React.useState(0);

  const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
    setValue(newValue);
  };

  function a11yProps(index: string | number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const { data, isLoading } = useFetchRetailProfile();
  const { data: dataRetailShop, isLoading: loadRetailShop } =
    useFetchRetailShop();

  const SmallAvatar = styled(Avatar)(() => ({
    width: 22,
    height: 22,
    border: `2px solid white`,
  }));

  return (
    <main className="w-full">
      <section>
        <article>
          <h1 className="font-Bold sm:text-base text-sm">Profile</h1>
          <p className="text-sm font-rom">
            You can change your store information here
          </p>
        </article>
      </section>

      <section>
        {isLoading ? (
          <div className="py-10 mt-5 flex justify-center items-center bg-white">
            <Aloading type="TailSpin" />
          </div>
        ) : (
          <section className="grid grid-cols-3 gap-5 py-5 px-8 bg-white shadow rounded-md mt-5">
            <div className="md:flex items-center gap-5 border-r">
              <aside>
                <Badge
                  overlap="circle"
                  className="cursor-pointer"
                  anchorOrigin={{ vertical: "top", horizontal: "right" }}
                  badgeContent={
                    <Box className="bg-white rounded-full p-0.5 shadow">
                      <SmallAvatar alt="Remy Sharp" src={camera} />
                    </Box>
                  }
                >
                  <Avatar
                    alt="Remy Sharp"
                    src={data?.retail_shop?.logo_path}
                    style={{ width: 90, height: 90 }}
                  />
                </Badge>
              </aside>
              <aside>
                <h1 className="lg:text-lg">{data?.retail_shop?.name}</h1>
                {/* <div className="flex items-center gap-1.5 text-xs">
              <img src={star} alt="-" className="w-3" />
              <h1 className="font-rom text-textSecondary">5 / 5</h1>
            </div> */}
              </aside>
            </div>
            <div className="flex items-center gap-5 border-r sm:text-sm text-xs">
              <aside>
                <div className="flex flex-wrap gap-1">
                  <h1>Email :</h1>
                  <h1 className="font-rom text-textPrimary">{data?.email}</h1>
                </div>
                <div className="flex flex-wrap gap-1 mt-1">
                  <h1>Phone :</h1>
                  <h1 className="font-rom text-textPrimary">{data?.phone}</h1>
                </div>
              </aside>
            </div>
            <div>
              <aside className="flex justify-end w-full -mt-3">
                <FormControlLabel
                  control={
                    <Switch
                      checked={!data?.retail_shop?.is_closed}
                      onChange={() =>
                        mutate({ is_closed: !data?.retail_shop?.is_closed })
                      }
                    />
                  }
                  label="Store Open"
                  labelPlacement="start"
                />
              </aside>
              <aside className="sm:text-sm text-xs -mt-1">
                <div className="flex flex-wrap gap-1">
                  <h1 className="font-rom text-textPrimary">Join since :</h1>
                </div>
                <h1 className="text-textPrimary md:text-base text-xs">
                  {moment(data?.created_at).format("LL")}
                </h1>
              </aside>
            </div>
          </section>
        )}
      </section>

      <section>
        {isLoading || loadRetailShop ? (
          <div className="py-20 mt-5 flex justify-center items-center bg-white">
            <Aloading type="TailSpin" />
          </div>
        ) : (
          <section className="bg-white mt-8 shadow rounded-md ">
            <Box>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="fullWidth"
              >
                <Tab
                  label="Store Information"
                  {...a11yProps(0)}
                  style={{ fontSize: 12 }}
                />
                <Tab
                  label="Bank Information"
                  {...a11yProps(1)}
                  style={{ fontSize: 12 }}
                />
                <Tab
                  label="Store Background"
                  {...a11yProps(2)}
                  style={{ fontSize: 12 }}
                />
                <Tab
                  label="Settings"
                  {...a11yProps(3)}
                  style={{ fontSize: 12 }}
                />
              </Tabs>
            </Box>
            <Divider />
            {data && dataRetailShop && (
              <section className="p-8">
                <TabPanel value={value} index={0}>
                  <OGeneralSection dataShop={dataRetailShop} />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <BankInformation detailData={dataRetailShop} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <BackgroundImage detailData={dataRetailShop} />
                </TabPanel>
                <TabPanel value={value} index={3}>
                  <Settings />
                </TabPanel>
              </section>
            )}
          </section>
        )}
      </section>
    </main>
  );
}

export default Profile;
