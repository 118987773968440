import axios from "axios";
import jwt_decode from "jwt-decode";

export function getWithExpiry(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return localStorage.removeItem(key);
  }
  return item.value;
}

const axiosInstance = () => {
  const value = getWithExpiry("retail_token");
  return axios.create({
    baseURL: process.env.REACT_APP_MERCURY_API_BASE_URL,
    headers: {
      authorization: `Bearer ${value}`,
    },
  });
};

setTimeout(() => {
  refreshToken();
}, 1000000);

export function setWithExpiry(key, value, ttl) {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

async function refreshToken() {
  try {
    const data = await axiosInstance().get("retail/auth/refresh");
    const token = data.data.token;
    const payload = jwt_decode(token);
    setWithExpiry("retail_token", token, 21600000);
    setWithExpiry("role", payload.role, 21600000);
  } catch (err) {
    console.log(err);
  }
}

export default axiosInstance;
