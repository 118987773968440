import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SubNav from "../../../component/navbar/SubNav";
import Toggle from "../../../component/toggleBtn/Toggle";
import Input from "../../../component/input/Input";
import Save from "../../../component/input/Save";
import Filepond from "../../../component/filepond/Filepond";
import FilepondMultiple from "../../../component/filepond/FilePondMultiple";
import DynamicBreadcrumb from "../../../component/breadcrumb/DynamicBreadcrumb";
import { optRoutesEditTicket } from "./helpers/list";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { TicketRequest } from "../../../api/ticket/Ticket";
import Loading from "../../../component/loading/Loading";
import ConfirmEditTicketModal from "./ticket_component/ConfirmEditTicketModal";
import TicketContext from "../../../store/ticket/context";
import { ActionType } from "../../../store/ticket/action";
import { deleteImage } from "../../../assets/icons";

const EditTicket: React.FC = () => {
  const navigate = useNavigate();
  const { TicketState, TicketDispatch } = useContext(TicketContext);
  const { confirmEditTicket } = TicketState;
  const params = useParams();
  const [files, setFiles] = useState<any>(null);
  const [filesDetail, setFilesDetail] = useState<any>(null);
  const [imgSection1, setImgSection1] = useState<any>(null);
  const [imgSection2, setImgSection2] = useState<any>(null);
  const [filesDesc, setFilesDesc] = useState<any>([]);
  const [video, setVideo] = useState<any>(null);

  const { data, isLoading } = useQuery("getTicketById", () =>
    TicketRequest.getTicketById(params.id || "")
  );

  const {
    values,
    handleChange,
    handleSubmit,
    handleReset,
    setValues,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      name: "",
      description: "",
      is_active: true,
      release_date: "",
      display_media_type: "image",
      is_active_section_1: false,
      is_active_section_2: false,
      description_section_1: "",
      description_section_2: "",
      id_section_1: null,
      id_section_2: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      description: Yup.string().required("Description is required"),
      release_date: Yup.date().required("Release Date is required"),
      description_section_1: Yup.string().when("is_active_section_1", {
        is: true,
        then: Yup.string().required("Description is required"),
      }),
      description_section_2: Yup.string().when("is_active_section_2", {
        is: true,
        then: Yup.string().required("Description is required"),
      }),
    }),
    onSubmit: async () => {
      const ticket = new FormData();
      files && ticket.append("preview_image_path", files);
      if (values.display_media_type === "image" && filesDetail) {
        ticket.append("display_image_path", filesDetail);
      } else if (values.display_media_type === "video" && video) {
        ticket.append("display_video_path", video);
      }

      if (values.display_media_type !== data.display_media_type) {
        ticket.append("display_media_type", values.display_media_type);
      }

      filesDesc?.file?.map((image: any) =>
        ticket.append("description_images", image)
      );
      ticket.append("is_active", values.is_active.toString());
      ticket.append("name", values.name);
      ticket.append(
        "release_date",
        new Date(values.release_date).toISOString()
      );
      ticket.append("description", values.description);
      let section_details = [];
      if (values.is_active_section_1 || data?.section_details?.length >= 1) {
        section_details.push({
          is_active: values.is_active_section_1,
          description: values.description_section_1,
          id: values.id_section_1 || null,
          image_path:
            imgSection1 || !values.id_section_1
              ? null
              : data?.section_details[0]?.image_path,
        });
      }
      if (values.is_active_section_2 || data?.section_details?.length >= 2) {
        section_details.push({
          is_active: values.is_active_section_2,
          description: values.description_section_2,
          id: values.id_section_2 || null,
          image_path:
            imgSection2 || !values.id_section_2
              ? null
              : data?.section_details[1]?.image_path,
        });
      }
      section_details.length > 0 &&
        ticket.append("section_details", JSON.stringify(section_details));
      imgSection1 && ticket.append("section_images", imgSection1);
      imgSection2 && ticket.append("section_images", imgSection2);
      await TicketDispatch({
        type: ActionType.ConfirmEditTicket,
        payload: { status: true, id: Number(params.id) || 0, data: ticket },
      });
    },
  });

  useEffect(() => {
    !isLoading &&
      setValues({
        ...values,
        name: data?.name,
        is_active: data?.is_active,
        release_date: data?.release_date,
        display_media_type: data?.display_media_type,
        description: data?.description,
        description_section_1:
          data.section_details.length >= 1
            ? data.section_details[0].description
            : "",
        is_active_section_1:
          data.section_details.length >= 1 && data.section_details[0].is_active,
        id_section_1:
          data.section_details.length >= 1 && data.section_details[0].id,
        description_section_2:
          data.section_details?.length >= 2
            ? data.section_details[1].description
            : "",
        is_active_section_2:
          data.section_details.length >= 2 && data.section_details[1].is_active,
        id_section_2:
          data.section_details.length >= 2 && data.section_details[1].id,
      });
  }, [data]);

  const goBack = () => {
    navigate(-1);
  };

  const deleteDescImage = async (id: number) => {
    mutation.mutate(id);
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (id: number) => TicketRequest.deleteDetailImage(id),
    {
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          await queryClient.invalidateQueries("getTicketById");
        }
      },
    }
  );

  return (
    <div className="bg-white p-4 font-med">
      {confirmEditTicket.status && <ConfirmEditTicketModal />}
      {isLoading ? (
        <div className="flex justify-center my-20">
          <Loading />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="w-full h-full md:flex justify-between">
            <div className="md:w-1/3">
              <div className="w-full rounded-md border-2 border-stroke">
                <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5 bg">
                  <h1 className="font-medium md:text-xl text-sm">
                    Product Images
                  </h1>
                </div>
                <hr />

                <div className="md:m-7 m-5">
                  <div>
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Display Image
                    </h1>
                    <div className="flex justify-center items-center">
                      <img
                        src={data?.preview_image_path}
                        alt="-"
                        className="w-1/3 rounded-md shadow-md"
                      />
                    </div>
                    <h1 className="text-xs font-rom text-textPrimary -mb-3 mt-3">
                      (1 x 1), (PNG, JPG, JPEG) max. 1 MB
                    </h1>
                    <Filepond setFiles={setFiles} />
                  </div>
                  <h1 className="md:text-base text-sm mb-2.5 text-textPrimary">
                    Select Option
                  </h1>
                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="for_reward"
                      name="option_for_video"
                      row
                      value={values.display_media_type}
                      onChange={(e: any) =>
                        setValues({
                          ...values,
                          display_media_type: e.target.value,
                        })
                      }
                    >
                      <FormControlLabel
                        value="image"
                        control={<Radio className="bg-primary to-primary" />}
                        label="Photo"
                      />
                      <FormControlLabel
                        value="video"
                        control={<Radio className="bg-primary to-primary" />}
                        label="Video"
                      />
                    </RadioGroup>
                  </FormControl>
                  {values.display_media_type === "image" ? (
                    <div className="mt-6 text-textPrimary">
                      <h1 className="md:text-base text-sm">Upload Images</h1>
                      <div className="flex justify-center items-center">
                        <img
                          src={data?.display_image_path}
                          alt="-"
                          className="w-1/2 rounded-md shadow-md"
                        />
                      </div>
                      <h1 className="text-xs font-rom text-textPrimary -mb-3 mt-3">
                        (9 x 16), (PNG, JPG, JPEG) max. 1 MB
                      </h1>
                      <Filepond setFiles={setFilesDetail} />
                    </div>
                  ) : (
                    <div className="mt-2 text-textPrimary">
                      <div className="flex justify-center">
                        <video
                          controls
                          src={data?.display_video_path}
                          autoPlay={true}
                          className="w-1/2 rounded-md shadow-md"
                        />
                      </div>
                      <Input
                        type={"file"}
                        name={"display_video_path"}
                        placeholder={"Place holder"}
                        handleChange={(e: any) => setVideo(e.target.files[0])}
                      />
                    </div>
                  )}
                </div>
              </div>

              <div className="w-full my-10 rounded-md border-2 border-stroke">
                <div className="items-center md:m-7 m-5">
                  <h1 className="md:text-base text-sm">Detail Images</h1>
                  <h1 className="text-xs font-rom text-textPrimary mt-1">
                    Note: You can add your size chart or detail type of your
                    product
                  </h1>
                </div>
                <hr />
                <div className="md:m-7 m-5">
                  <div className="grid grid-cols-4 gap-3">
                    {data?.description_image_paths.map((el: any) => (
                      <div className="w-full" key={el.id}>
                        <div className="flex flex-col items-end">
                          <img
                            src={deleteImage}
                            className="absolute cursor-pointer w-6"
                            alt="delete_button"
                            onClick={() => deleteDescImage(el.id)}
                          />
                        </div>
                        <div className="flex justify-start">
                          <img
                            className="w-full rounded-md shadow-md"
                            src={el.image_path}
                            alt="detail_image"
                          />
                        </div>
                      </div>
                    ))}
                  </div>
                  <FilepondMultiple setFiles={setFilesDesc} />
                </div>
              </div>
            </div>

            <div className="md:w-custom md:mt-0 mt-10 w-full h-full">
              <div className="rounded-md rounded-b-none border-2 border-stroke">
                <div className="md:h-16 h-14 flex items-center justify-between md:mx-8 mx-5">
                  <h1 className="font-medium md:text-xl text-sm">
                    Product Information
                  </h1>
                  <div className="flex">
                    <h1 className="font-medium md:text-base text-sm mr-3 text-textPrimary">
                      Hide Product
                    </h1>
                    <Toggle
                      input={!values.is_active}
                      handleClick={() =>
                        setValues({ ...values, is_active: !values.is_active })
                      }
                    />
                  </div>
                </div>
                <hr />
                <div className="md:mx-8 mx-5 md:my-5 my-3">
                  <div>
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Product Name
                    </h1>
                    <Input
                      required={true}
                      type={"text"}
                      value={values.name}
                      name={"name"}
                      placeholder={"Place holder"}
                      handleChange={handleChange}
                    />
                  </div>
                </div>

                <div className="md:flex w-full">
                  {/*form product left*/}
                  <div className="md:mx-8 mx-5 md:w-1/2">
                    <div className="md:mt-0">
                      <h1 className="md:text-base text-sm  text-textPrimary">
                        Release Date
                      </h1>
                      <Input
                        required={true}
                        value={moment(values.release_date).format(
                          "YYYY-MM-DDTHH:mm"
                        )}
                        type={"datetime-local"}
                        name={"release_date"}
                        placeholder={"Place holder"}
                        handleChange={handleChange}
                      />
                    </div>
                  </div>

                  {/*form product right*/}
                  <div className="md:mx-8 mx-5 md:w-1/2"></div>
                </div>
                <div className="md:mb-8 mb-5 md:mt-4 mt-5 md:mx-8 mx-5">
                  <h1 className="md:text-base text-sm  text-textPrimary">
                    Description
                  </h1>
                  <textarea
                    required
                    value={values.description}
                    onChange={handleChange}
                    name="description"
                    placeholder="Place holder"
                    className="md:h-28 h-24 font-rom w-full rounded-md md:text-base text-xs my-3 px-3 py-3 border-2 border-stroke focus:ring-1 focus:outline-none"
                  ></textarea>
                </div>
                <section className="md:mx-8 mx-5 md:mb-8 mb-5">
                  <div className="flex justify-between">
                    <h1 className="text-xl">Section 1</h1>
                    <div className="flex items-center">
                      <h1 className="font-medium md:text-base text-sm mr-3 text-textPrimary">
                        Active
                      </h1>
                      <Toggle
                        input={!values.is_active_section_1}
                        handleClick={() =>
                          setValues({
                            ...values,
                            is_active_section_1: !values.is_active_section_1,
                          })
                        }
                      />
                    </div>
                  </div>
                  <hr className="my-1" />
                  <div className="w-full md:flex mt-4">
                    <div className="w-1/2">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        upload Image
                      </h1>
                      {data?.section_details?.length > 0 && (
                        <div className="flex justify-center items-center">
                          <img
                            src={data?.section_details[0]?.image_path}
                            alt="-"
                            className="md:w-1/2 rounded-md shadow-md my-1"
                          />
                        </div>
                      )}
                      <h1 className="text-xs font-rom text-textPrimary mt-1">
                        (1 x 1), (PNG, JPG, JPEG) max. 1 MB
                      </h1>
                      <Filepond setFiles={setImgSection1} />
                    </div>
                    <div className="md:w-1/2 md:ml-8">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Description
                      </h1>
                      <textarea
                        onChange={handleChange}
                        value={values.description_section_1}
                        name="description_section_1"
                        placeholder="Place holder"
                        className="h-24 font-rom w-full rounded-md md:text-base text-xs mt-5 px-3 py-3 border-2 border-stroke focus:ring-1 focus:outline-none"
                      ></textarea>
                      {errors.description_section_1 &&
                        touched.description_section_1 && (
                          <p className="text-primary text-xs font-rom -mt-2 mb-1">
                            {errors.description_section_1}
                          </p>
                        )}
                    </div>
                  </div>
                </section>

                <section className="md:mx-8 mx-5 md:mb-8 mb-5">
                  <div className="flex justify-between">
                    <h1 className="text-xl">Section 2</h1>
                    <div className="flex items-center">
                      <h1 className="font-medium md:text-base text-sm mr-3 text-textPrimary">
                        Active
                      </h1>
                      <Toggle
                        input={!values.is_active_section_2}
                        handleClick={() =>
                          setValues({
                            ...values,
                            is_active_section_2: !values.is_active_section_2,
                          })
                        }
                      />
                    </div>
                  </div>
                  <hr className="my-1" />
                  <div className="w-full md:flex mt-4">
                    <div className="md:w-1/2">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        upload Image
                      </h1>
                      {data?.section_details?.length >= 1 && (
                        <div className="flex justify-center items-center">
                          <img
                            src={data?.section_details[1]?.image_path}
                            alt="-"
                            className="w-1/2 rounded-md shadow-md my-1"
                          />
                        </div>
                      )}
                      <h1 className="text-xs font-rom text-textPrimary mt-1">
                        (1 x 1), (PNG, JPG, JPEG) max. 1 MB
                      </h1>
                      <Filepond setFiles={setImgSection2} />
                    </div>
                    <div className="md:w-1/2 md:ml-8">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Description
                      </h1>
                      <textarea
                        value={values.description_section_2}
                        onChange={handleChange}
                        name="description_section_2"
                        placeholder="Place holder"
                        className="h-24 font-rom w-full rounded-md md:text-base text-xs mt-5 px-3 py-3 border-2 border-stroke focus:ring-1 focus:outline-none"
                      ></textarea>
                      {errors.description_section_2 &&
                        touched.description_section_2 && (
                          <p className="text-primary text-xs font-rom -mt-2 mb-1">
                            {errors.description_section_2}
                          </p>
                        )}
                    </div>
                  </div>
                </section>
              </div>

              <Save handleReset={() => handleReset({})} />
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export default EditTicket;
