import React from "react";
import { cancel } from "../../../../assets/icons";
import Input from "../../../../component/input/Input";

export interface RevenueFilterProps {
  showFilter: boolean;
  setShowFilter: (showFilter: boolean) => void;
  filter: any;
  setFilter: any;
  refetch: () => void;
  applyChip: any
}

const RevenueFilter: React.FC<RevenueFilterProps> = ({
  showFilter,
  filter,
  setFilter,
  setShowFilter,
  refetch,
  applyChip
}) => {
  const handleShowFilter = () => {
    setShowFilter(!showFilter);
    setFilter({
      ...filter,
      created_at_start: "",
      created_at_end: "",
    })
  };

  const handleChangeFilter = (event: any) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name === "amount") {
      Number(value);
    }
    setFilter({ ...filter, [name]: value });
  };

  const applyFilter = () => {
    refetch();
    applyChip();
    setTimeout(() => setShowFilter(!showFilter), 1000);
  };
  return (
    <div>
      {showFilter === false ? (
        ""
      ) : (
        <div className="fixed z-50 inset-0 overflow-y-auto bg-black bg-opacity-70 flex md:items-center items-start justify-center">
          <div
            className="inline-block align-bottom bg-white md:rounded-lg rounded-t-none rounded-b-3xl text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-3xl sm:w-full"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white mt-7 mb-5">
              <div className="sm:flex sm:items-start">
                <div className="px-9 flex justify-between w-full">
                  <h1
                    className="md:text-2xl text-lg leading-6 font-medium text-textPrimary"
                    id="modal-headline"
                  >
                    Filter
                  </h1>

                  <img
                    src={cancel}
                    alt="back"
                    className="cursor-pointer md:w-5 w-3"
                    onClick={handleShowFilter}
                  />
                </div>
              </div>
              <hr className="w-full mt-5" />
            </div>
            {/* <hr className="sm:mt-2 w-full " />
                            <div className="flex mx-4 sm:p-5">
                                <div className="mr-12 w-96">
                                    <h1 className="text-xl text-textPrimary">Transaction Code</h1>
                                    <input type="text" onChange={handleChangeFilter}
                                        name="retail_transaction_code"
                                        placeholder="Place holder" className="h-14 w-full rounded-md text-lg my-3 px-5 border-2 border-stroke" />
                                </div>
                                <div className=" w-96">
                                    <h1 className="text-xl text-textPrimary">Customer</h1>
                                    <input type="text" placeholder="Place holder" onChange={handleChangeFilter}
                                        name="buyer_username" className="h-14 w-full rounded-md text-lg my-3 px-5 border-2 border-stroke" />
                                </div>
                            </div>
                            <hr className="w-full " />
                            <div className="flex mx-4 sm:p-5">
                                <div className="mr-12 w-96">
                                    <h1 className="text-xl text-textPrimary">Product Name</h1>
                                    <input type="text" onChange={handleChangeFilter}
                                        name="code"
                                        placeholder="Place holder" className="h-14 w-full rounded-md text-lg my-3 px-5 border-2 border-stroke" />
                                </div>
                                <div className=" w-96">
                                    <h1 className="text-xl text-textPrimary">Product Price</h1>
                                    <input type="text" placeholder="Place holder" onChange={handleChangeFilter}
                                        name="price" className="h-14 w-full rounded-md text-lg my-3 px-5 border-2 border-stroke" />
                                </div>
                            </div>
                            <hr className="w-full " />
                            <div className="flex mx-4 sm:p-6">
                                <div className="mr-12 w-96">
                                    <h1 className="text-xl text-textPrimary">Quantity</h1>
                                    <input type="text" onChange={handleChangeFilter} name="quantity" placeholder="Place holder" className="h-14 w-full rounded-md text-lg my-3 px-5 border-2 border-stroke" />
                                </div>
                                <div className=" w-96">
                                    <h1 className="text-xl text-textPrimary">Total</h1>
                                    <input type="text" name="total" placeholder="Place holder" className="h-14 w-full rounded-md text-lg my-3 px-5 border-2 border-stroke" />
                                </div>
                            </div>
                            <hr className="w-full " /> */}
            {/* <div className="flex mx-4 sm:p-6">
                                <div className="mr-12 w-96">
                                    <h1 className="text-xl text-textPrimary">Shipping Cost</h1>
                                    <input type="text" onChange={handleChangeFilter} name="buyer_shipping_cost" placeholder="Place holder" className="h-14 w-full rounded-md text-lg my-3 px-5 border-2 border-stroke" />
                                </div>
                                <div className=" w-96">
                                    <h1 className="text-xl text-textPrimary">Seller Payout</h1>
                                    <input type="text" name="seller_payout" placeholder="Place holder" className="h-14 w-full rounded-md text-lg my-3 px-5 border-2 border-stroke" />
                                </div>
                            </div> */}
            <div className="flex px-5 md:mx-3 pb-3">
              <div className="mr-12 w-96">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Start Date
                </h1>
                <Input
                  type={"date"}
                  placeholder={"Place holder"}
                  name={"created_at_start"}
                  handleChange={handleChangeFilter}
                />
              </div>
              <div className=" w-96">
                <h1 className="md:text-base text-sm text-textPrimary">
                  End Date
                </h1>
                <Input
                  type={"date"}
                  placeholder={"Place holder"}
                  name={"created_at_end"}
                  handleChange={handleChangeFilter}
                />
              </div>
            </div>

            <hr className="w-full" />
            <div className="flex justify-center m-5">
              <button
                onClick={applyFilter}
                className="bg-primary w-36 h-9 rounded-full focus:ring-0 focus:outline-none hover:bg-red-300"
              >
                <h1 className="text-white text-base mb-0.5">Apply</h1>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RevenueFilter;
