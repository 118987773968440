import ErrorHandler from "../../component/toast/ErrorHandler";
import axiosInstance from "../../config/axiosInstance";

export class SubAccountRequest {
  static getSubAccount = async () => {
    try {
      const location = document.location.search;
      const query = new URLSearchParams(location);
      const results: any = await axiosInstance().get(`retail/retail-user`, {
        params: {
          retail_role_id: 2,
          is_banned: false,
          limit: query.get("limit") !== null ? query.get("limit") : 10,
          offset: query.get("offset") !== null ? query.get("offset") : 0,
          sort: `${
            query.get("sort") !== null ? query.get("sort") : "created_at"
          } ${query.get("isAsc") !== null ? query.get("isAsc") : "desc"}`,
        },
      });
      return results;
    } catch (error) {
      console.log(error);
    }
  };

  static getRetailRole = async () => {
    try {
      const results: any = await axiosInstance().get(`/retail/retail-role/2`);
      const data = results.data;
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  static registerAccount = async (data: any) => {
    try {
      const result = await axiosInstance().post("retail/auth/signup", data);
      ErrorHandler("#00C62E", "Add Account Completed");
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Add Failed");
      }
    }
  };

  static deleteAccount = async (id: string | number) => {
    const payload = {
      id,
      is_banned: true,
    };
    try {
      const result = await axiosInstance().post(
        "retail/retail-user/ban",
        payload
      );
      ErrorHandler("#00C62E", "Delete Account Completed");
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          error.response.data.errors[Object.keys(error.response.data.errors)[0]]
            .split("_")
            .join(" ")
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Delete Failed");
      }
    }
  };
}
