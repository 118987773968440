import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

function Loading() {
    return (
        <Loader type="Bars" color="#DF5354" height={50} width={50} />
    );
}

export default Loading



