export enum EAllowedRoles {
  RETAIL = "retail",
  ASSISTANT = "assistant",
}

export const listMenu = [
  {
    type: "Home",
    link: "/home",
    target: "parent",
    allowedRoles: [EAllowedRoles.RETAIL],
  },
  {
    type: "Profile",
    link: `/profile`,
    target: "parent",
    allowedRoles: [EAllowedRoles.RETAIL],
  },
  {
    type: "Products",
    link: `/admin/products`,
    target: "parent",
    allowedRoles: [EAllowedRoles.RETAIL],
  },
  {
    type: "Tickets",
    link: "/admin/ticket",
    target: "parent",
    allowedRoles: [EAllowedRoles.RETAIL],
  },
  {
    type: "Scan History",
    link: "/admin/scan",
    target: "parent",
    allowedRoles: [EAllowedRoles.RETAIL],
  },
  {
    type: "Transaction (Products)",
    link: "/transaction-product",
    target: "parent",
    allowedRoles: [EAllowedRoles.RETAIL],
  },
  {
    type: "Transaction (Tickets)",
    link: "/transaction-ticket",
    target: "parent",
    allowedRoles: [EAllowedRoles.RETAIL],
  },
  {
    type: "Revenues",
    link: "/admin/revenues",
    target: "parent",
    allowedRoles: [EAllowedRoles.RETAIL],
  },
  {
    type: "Request Withdraw",
    link: "/admin/request-withdraw",
    target: "parent",
    allowedRoles: [EAllowedRoles.RETAIL],
  },
  {
    type: "Wallet Mutation",
    link: "/admin/wallet-mutation",
    target: "parent",
    allowedRoles: [EAllowedRoles.RETAIL],
  },
  {
    type: "Sub Account",
    link: "/admin/sub-account",
    target: "parent",
    allowedRoles: [EAllowedRoles.RETAIL],
  },
  {
    type: "Logout",
    link: "/login",
    target: "parent",
    allowedRoles: [EAllowedRoles.RETAIL],
  },
];
