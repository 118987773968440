import React, { useContext, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { cancel } from "../../../../assets/icons";
import SmallButton from "../../../../component/button/Button";
import Input from "../../../../component/input/Input";
import { ActionType } from "../../../../store/sub_account/action";
import SubAccountContext from "../../../../store/sub_account/context";
import * as Yup from "yup";
import { useFormik } from "formik";
import { SubAccountRequest } from "../../../../api/sub_account/SubAccountRequest";
import { showPass, unshowPass } from "../../../../assets/icons/Web";
import CircularLoad from "../../../../component/loading/CircularLoad";
const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const numericRegex = /(?=.*[0-9])/;
const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const AddAccountModal = () => {
  const { SubAccountDispatch } = useContext(SubAccountContext);
  const [password, setPassword] = useState<string>("password");
  const [confirmPassword, setConfirmPassword] = useState<string>("password");

  const { data, isLoading } = useQuery(
    ["getRetailRole"],
    SubAccountRequest.getRetailRole,
    {
      refetchOnWindowFocus: false,
    }
  );

  const handleVisiblePassword = () => {
    if (password === "password") {
      setPassword("text");
    } else {
      setPassword("password");
    }
  };

  const handleVisibleConfirmPassword = () => {
    if (confirmPassword === "password") {
      setConfirmPassword("text");
    } else {
      setConfirmPassword("password");
    }
  };

  const goBack = () => {
    SubAccountDispatch({
      type: ActionType.ShowAddSubAccount,
    });
    handleReset({});
  };

  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    dirty,
    handleReset,
    setValues,
  } = useFormik({
    initialValues: {
      retail_role_id: "",
      username: "",
      email: "",
      phone: "",
      country_code: "62",
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required("Username is required"),
      phone: Yup.string()
        .required("Username is required")
        .matches(numericRegex, "Please enter valid phone number"),
      email: Yup.string()
        .required("email is required")
        .matches(
          emailRegex,
          "Please enter valid email, example: name@example.com"
        ),
      password: Yup.string()
        .required("Password is required!")
        .matches(lowercaseRegex, "One lowercase required!")
        .matches(uppercaseRegex, "One uppercase required!")
        .matches(numericRegex, "One number required!")
        .min(6, "The password must be of minimum 6 characters!"),
      confirm_password: Yup.string()
        .oneOf(
          [Yup.ref("password")],
          "The password confirmation does not match!"
        )
        .required("Confirm password is required!"),
    }),
    onSubmit: async () => {
      await mutation.mutate();
    },
  });

  useEffect(() => {
    if (data) {
      setValues({ ...values, retail_role_id: data?.id });
    }
  }, [data]);

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () => SubAccountRequest.registerAccount(values),
    {
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          await queryClient.invalidateQueries("getSubAccount");
          goBack();
        }
      },
    }
  );

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white md:w-1/3 w-10/12 h-auto rounded-xl my-auto mx-auto font-med">
        <div className="flex justify-between items-center p-7 pb-6">
          <div className="flex items-center">
            <h1 className="text-xl flex">Add</h1>
            <h1 className="ml-2 text-xl text-primary">Account</h1>
          </div>
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-5"
            onClick={goBack}
          />
        </div>
        <hr />
        {isLoading ? (
          <div className="my-20 flex justify-center items-center">
            <CircularLoad color={"#DF5354"} height={40} width={40} />
          </div>
        ) : (
          <form onSubmit={handleSubmit} className="px-10 pt-5">
            <div>
              <div className="flex justify-between">
                <h1 className="md:text-base text-sm text-textPrimary">Email</h1>
              </div>
              <Input
                type={"text"}
                handleChange={handleChange}
                disable={false}
                name={"email"}
                placeholder={"Place holder"}
              />
              {touched.email && errors.email && (
                <p className="text-primary text-xs font-rom -mt-2">
                  {errors.email}
                </p>
              )}
            </div>
            <div className="flex">
              <div className="mt-4 w-1/2">
                <div className="flex justify-between">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Username
                  </h1>
                </div>
                <Input
                  type={"text"}
                  disable={false}
                  handleChange={handleChange}
                  name={"username"}
                  placeholder={"Place holder"}
                />
                {touched.username && errors.username && (
                  <p className="text-primary text-xs font-rom -mt-2 ">
                    {errors.username}
                  </p>
                )}
              </div>
              <div className="mt-4 w-1/2 ml-5">
                <div className="flex justify-between">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Phone Number
                  </h1>
                </div>
                <div className="flex items-center">
                  <div className="flex justify-center items-center border-2 border-r-0 border-stroke h-10 w-14">
                    <h1 className="font-rom text-textPrimary md:text-sm text-xs">
                      +{values.country_code}
                    </h1>
                  </div>
                  <Input
                    disable={false}
                    type={"text"}
                    name={"phone"}
                    handleChange={handleChange}
                    placeholder={"Place holder"}
                  />
                </div>
                {errors.phone && touched.phone && (
                  <p className="text-primary text-xs font-rom -mt-2">
                    {errors.phone}
                  </p>
                )}
              </div>
            </div>
            <div className="flex">
              <div className="mt-4 w-1/2">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Password
                </h1>
                <div className="md:h-10 h-9 rounded-md border-2 my-3 border-stroke flex justify-between">
                  <input
                    type={password}
                    placeholder="Place holder"
                    className="md:text-sm text-xs font-rom px-3 w-full mr-5 focus:outline-none"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                  />
                  {password === "password" ? (
                    <img
                      src={showPass}
                      onClick={handleVisiblePassword}
                      className="md:w-5 w-3 mr-5 cursor-pointer"
                      alt=""
                    />
                  ) : (
                    <img
                      src={unshowPass}
                      onClick={handleVisiblePassword}
                      className="md:w-5 w-3 mr-5 cursor-pointer"
                      alt=""
                    />
                  )}
                </div>
                {touched.password && errors.password ? (
                  <h1 className="text-xs font-rom text-primary -mt-2">
                    {errors.password}
                  </h1>
                ) : null}
              </div>
              <div className="mt-4 w-1/2 ml-5">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Confirm Password
                </h1>
                <div className="md:h-10 h-9 rounded-md border-2 my-3 border-stroke flex justify-between">
                  <input
                    type={confirmPassword}
                    placeholder="Place holder"
                    className="md:text-sm text-xs font-rom px-3 w-full mr-5 focus:outline-none"
                    name="confirm_password"
                    value={values.confirm_password}
                    onChange={handleChange}
                  />
                  {confirmPassword === "password" ? (
                    <img
                      src={showPass}
                      onClick={handleVisibleConfirmPassword}
                      className="md:w-5 w-3 mr-5 cursor-pointer"
                      alt=""
                    />
                  ) : (
                    <img
                      src={unshowPass}
                      onClick={handleVisibleConfirmPassword}
                      className="md:w-5 w-3 mr-5 cursor-pointer"
                      alt=""
                    />
                  )}
                </div>
                {touched.confirm_password && errors.confirm_password ? (
                  <h1 className="text-xs font-rom text-primary -mt-2">
                    {errors.confirm_password}
                  </h1>
                ) : null}
              </div>
            </div>
            <div className="flex justify-center mt-5 mb-7 outline-none">
              <SmallButton
                isSubmitting={mutation.isLoading}
                dirty={dirty}
                height={10}
                loadColor={"white"}
                type={"submit"}
                color={"bg-primary"}
                hoverColor={"bg-red-300"}
                text={"Add"}
                textColor={"text-white"}
              />
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddAccountModal;
