import React, { useContext } from "react";
import QrReader from "react-qr-reader";
import { useNavigate } from "react-router";
import SubNav from "../../../../../component/navbar/SubNav";
import ErrorHandler from "../../../../../component/toast/ErrorHandler";
import { ActionType } from "../../../../../store/ticket/action";
import TicketContext from "../../../../../store/ticket/context";
import ConfirmQrModal from "./variant_transaction/ConfirmQrModal";

const ScanqrCode = () => {
  const navigate = useNavigate();
  const { TicketDispatch, TicketState } = useContext(TicketContext);
  const { scanResult } = TicketState;
  const handleErrorWebCam = (error: any) => {
    ErrorHandler("#DF5354", error);
  };

  const handleScanWebCam = (result: any) => {
    if (result) {
      TicketDispatch({
        type: ActionType.ShowScanResult,
        payload: {
          status: true,
          code: result,
        },
      });
    }
  };
  return (
    <div className="h-screen overflow-hidden">
      {scanResult.status && <ConfirmQrModal />}
      <div className="pt-20 flex flex-col h-screen max-h-screen items-center justify-center">
        <div className="xl:w-1/2 lg:w-10/12 w-full h-full">
          <QrReader
            delay={300}
            style={{ width: "100%" }}
            onError={handleErrorWebCam}
            onScan={handleScanWebCam}
          />
        </div>
      </div>
    </div>
  );
};

export default ScanqrCode;
