import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Ahome,
  Aprofile,
  Amoney,
  Aproduct,
  Aticket,
  Alogout,
  Atransaction,
  IIconProps,
  Arevenue,
  Ascan,
  Acardholder,
  Auser,
} from "../../components/atoms/icons";
import useAuth from "../../helpers/useAuth";
import { Awallet } from "../atoms/icons/Awallet";

export const MlistMenu: FC<{
  type: string;
  link: string;
  target: string;
  allowedRoles: string[];
  role: string;
  showSidebar: boolean;
  handleShowSidebar: () => void;
}> = ({
  type,
  link,
  target,
  showSidebar,
  handleShowSidebar,
  allowedRoles,
  role,
}) => {
  const { pathname } = useLocation();
  const { setAuth } = useAuth();
  const componentIcons: any = {
    Home: Ahome,
    Profile: Aprofile,
    Wallet: Amoney,
    Products: Aproduct,
    Tickets: Aticket,
    "Scan History": Ascan,
    "Transaction (Products)": Atransaction,
    "Transaction (Tickets)": Atransaction,
    Revenues: Arevenue,
    "Request Withdraw": Acardholder,
    "Wallet Mutation": Awallet,
    "Sub Account": Auser,
    Logout: Alogout,
  };

  const svgIcons = () => {
    const SVG: FC<IIconProps> = componentIcons[type];
    return (
      <SVG
        width="100%"
        height="100%"
        stroke={pathname.indexOf(link) !== -1 ? "#ed4846" : "#666666"}
      />
    );
  };

  const handleLogout = () => {
    setAuth({ role: "", token: "", username: "" });
    localStorage.clear();
  };

  return (
    <li className={allowedRoles.includes(role) ? "block" : "hidden"}>
      <div
        className={
          "md:my-5 my-4 flex " +
          (showSidebar ? "justify-start" : "justify-center")
        }
      >
        {target === "parent" ? (
          <Link
            onClick={
              type === "Logout"
                ? handleLogout
                : window.innerWidth <= 640
                ? handleShowSidebar
                : () => {}
            }
            className={
              "flex flex-row text-sm w-full " +
              (pathname.indexOf(link) !== -1
                ? "text-primary"
                : "text-iconColor hover:text-primary")
            }
            to={link}
          >
            <div
              className={
                "flex items-center sidebarlist w-full " +
                (showSidebar ? "justify-start" : "justify-center")
              }
            >
              <div
                className={
                  "flex justify-center " +
                  (showSidebar
                    ? "3xl:w-1/12 xl:w-[8%] md:w-[11%] sm:w-[13%] w-[3%]"
                    : "lg:w-4 w-3")
                }
              >
                {svgIcons()}
              </div>
              <div className={showSidebar ? "w-3/4" : ""}>
                <h1
                  className={
                    "xl:ml-5 ml-3 flex items-center whitespace-nowrap sm:text-sm text-xs " +
                    (showSidebar ? "inline-flex" : "hidden")
                  }
                >
                  {type}
                </h1>
              </div>
            </div>
          </Link>
        ) : (
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className={
              "flex flex-row text-sm w-full " +
              (pathname.indexOf(link) !== -1
                ? "text-primary"
                : "text-iconColor hover:text-primary")
            }
          >
            <div
              className={
                "flex items-center sidebarlist w-full " +
                (showSidebar ? "justify-start" : "justify-center")
              }
            >
              <div
                className={
                  "flex justify-center " +
                  (showSidebar
                    ? "3xl:w-1/12 xl:w-[8%] md:w-[11%] sm:w-[13%] w-[3%]"
                    : "lg:w-4 w-3")
                }
              >
                {svgIcons()}
              </div>
              <div className={showSidebar ? "w-3/4" : ""}>
                <h1
                  className={
                    "xl:ml-5 ml-3 flex items-center whitespace-nowrap sm:text-sm text-xs " +
                    (showSidebar ? "inline-flex" : "hidden")
                  }
                >
                  {type}
                </h1>
              </div>
            </div>
          </a>
        )}
      </div>
      <hr />
    </li>
  );
};
