import React, { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { ProductRequest } from "../../../../../../../api/product/ProductRequest";
import Input from "../../../../../../../component/input/Input";
import Save from "../../../../../../../component/input/Save";
import Toggle from "../../../../../../../component/toggleBtn/Toggle";

export interface EditVariantFormProps {
  dataStock: {
    label: string;
    weight: number;
    price: number;
    is_pre_order: boolean;
    pre_order_stock: number;
    stock: number;
    display_price: number;
  };
}

const EditVariantForm: React.FC<EditVariantFormProps> = ({ dataStock }) => {
  const navigate = useNavigate();
  const params = useParams();
  const [activeDiscount, setActiveDiscount] = useState<boolean>(false);
  const [stock, setStock] = useState<any>({
    label: "",
    weight: 0,
    price: 0,
    pre_order_stock: 0,
    stock: 0,
    is_pre_order: false,
  });

  const goBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    setStock({
      label: dataStock?.label,
      weight: dataStock?.weight,
      is_pre_order: dataStock?.is_pre_order,
      pre_order_stock: dataStock?.pre_order_stock,
      stock: dataStock?.stock,
      price:
        dataStock?.display_price > 0
          ? dataStock?.display_price
          : dataStock?.price,
      discount_price: dataStock?.display_price > 0 ? dataStock?.price : 0,
    });
    setActiveDiscount(dataStock?.display_price > 0 ? true : false);
    return function () {
      return setStock({
        label: "",
        weight: 0,
        price: 0,
        is_pre_order: false,
        pre_order_stock: 0,
        stock: 0,
      });
    };
  }, [dataStock]);

  const handlePreOrder = () => {
    if (stock.is_pre_order === false) {
      setStock({ ...stock, is_pre_order: true, stock: 0 });
    } else {
      setStock({ ...stock, is_pre_order: false, pre_order_stock: 0 });
    }
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name;
    let value: any = e.target.value;
    if (name !== "label") {
      value = parseInt(value);
    }
    setStock({ ...stock, [name]: value });
  };

  const handleClear = () => {
    setStock({
      ...stock,
      label: "",
      retail_product_id: Number(params.id),
      price: 0,
      stock: 0,
      is_pre_order: false,
      pre_order_stock: 0,
      weight: 0,
    });
    setStock(false);
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (payload: any) =>
      ProductRequest.editProductVariant(payload, params.detail || ""),
    {
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          await queryClient.invalidateQueries("variant");
          goBack();
        }
      },
    }
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    mutation.mutate(stock);
  };
  return (
    <div className="md:w-custom w-full md:mt-0 mt-10 h-full">
      <form onSubmit={handleSubmit}>
        <div className="rounded-md w-full h-full rounded-b-none border-2 border-stroke">
          <div className="md:h-16 h-14 flex items-center justify-between md:mx-8 mx-5">
            <h1 className="font-medium md:text-xl text-sm">
              Variant Information
            </h1>
          </div>
          <hr />
          <div className="md:mx-8 mx-5 md:mt-5 mt-4">
            <h1 className="md:text-base text-sm text-textPrimary">Variant</h1>
            <Input
              required={true}
              value={stock.label}
              type={"text"}
              name={"label"}
              placeholder={"Place holder"}
              handleChange={handleChangeInput}
            />
          </div>
          <div className="md:flex w-full">
            <div className="md:mx-8 mx-5 md:my-5 my-4 md:w-1/2">
              <div>
                <h1 className="md:text-base text-sm text-textPrimary">
                  Product Price
                </h1>
                <Input
                  value={stock.price}
                  type={"number"}
                  name={"price"}
                  required={true}
                  placeholder={"Place holder"}
                  handleChange={handleChangeInput}
                />
              </div>
              <div className="md:mt-4 mt-3">
                <h1 className="md:text-base text-sm text-textPrimary">Stock</h1>
                <Input
                  type={"number"}
                  disable={stock.is_pre_order ? true : false}
                  name={"stock"}
                  value={stock.stock}
                  placeholder={"place holder"}
                  handleChange={handleChangeInput}
                />
              </div>
              <div className="md:mt-4 mt-48">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Pre Order
                </h1>
                <div className="mt-3 flex">
                  <Toggle
                    input={!stock.is_pre_order}
                    handleClick={handlePreOrder}
                  />
                  <h1 className="font-rom md:text-base text-sm ml-4 text-textPrimary">
                    {stock.is_pre_order ? "Enabled" : "Disabled"}
                  </h1>
                </div>
              </div>
            </div>

            <div className="md:mx-8 mx-5 md:mt-5 -mt-60 md:w-1/2 ">
              <div className="flex justify-between">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Discount Price
                </h1>
                <div className="flex items-center">
                  <Toggle
                    input={!activeDiscount}
                    handleClick={() => {
                      setActiveDiscount(!activeDiscount);
                    }}
                  />
                  <h1 className="font-rom md:text-base text-sm ml-4 text-textPrimary">
                    {activeDiscount ? "Enable" : "Disable"}
                  </h1>
                </div>
              </div>
              <Input
                disable={!activeDiscount}
                value={stock.discount_price}
                type={"number"}
                name={"discount_price"}
                placeholder={"Place holder"}
                handleChange={handleChangeInput}
              />
              <div className="md:mt-4 mt-3">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Product Weight (Gr)
                </h1>
                <Input
                  type={"number"}
                  name={"weight"}
                  value={stock.weight}
                  placeholder={"place holder"}
                  handleChange={handleChangeInput}
                />
              </div>
              {stock.is_pre_order === true ? (
                <div className="md:mt-4 mt-24 mb-8">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Pre-Order Stock
                  </h1>
                  <Input
                    required={true}
                    type={"number"}
                    name={"pre_order_stock"}
                    value={stock.pre_order_stock}
                    placeholder={"place holder"}
                    handleChange={handleChangeInput}
                  />
                </div>
              ) : (
                <div className="mt-7 mb-7 h-20"></div>
              )}
            </div>
          </div>
        </div>

        {/*button save*/}
        <Save handleReset={handleClear} load={mutation.isLoading} />
      </form>
    </div>
  );
};

export default EditVariantForm;
