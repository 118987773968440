import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { GrClose } from "react-icons/gr";

interface IADialogBoxProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  content: {
    title: string;
    body: JSX.Element;
    footer?: JSX.Element;
  };
}

const ADialogBox: React.FC<IADialogBoxProps> = ({
  isOpen,
  setIsOpen,
  content,
}) => {
  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      onClose={() => setIsOpen(false)}
      open={isOpen}
    >
      <DialogTitle>
        <div className="flex justify-between items-center text-textPrimary">
          <p className="text-base font-med">{content.title}</p>
          <GrClose
            cursor="pointer"
            size={18}
            onClick={() => setIsOpen(false)}
          />
        </div>
      </DialogTitle>
      <DialogContent>{content.body}</DialogContent>
      {content.footer}
    </Dialog>
  );
};

export default ADialogBox;
