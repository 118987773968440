import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import { next } from "../../../../../../assets/icons";
import ContactSkeletonLoading from "../../../../../../component/loading/SkeletonLoading";
import axiosDicket from "../../../../../../config/axiosDicket";
import moment from "moment";

const getVariantTransaction = async (
  limit: number,
  offset: number,
  ticket_id: string
) => {
  try {
    const results: any = await axiosDicket().get(`admin/ownership/`, {
      params: {
        ticket_id,
        limit: limit,
        offset: offset,
      },
    });
    const result = await results.data;
    return result;
  } catch (error) {
    console.log(error);
  }
};

export interface VariantTransactionListProps {
  data: any;
}

const VariantTransactionList: React.FC<VariantTransactionListProps> = ({
  data,
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const { pathname } = useLocation();
  const dataVariant = data?.data;
  const meta = data?.meta?.total;
  const [variant, setProduct] = useState<any>(dataVariant);
  const [more, setMore] = useState<boolean>(true);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(10);

  const goDetailTransacation = (id: number) => {
    navigate(`${pathname}/${id}`);
  };

  useEffect(() => {
    setProduct(dataVariant);
  }, [data]);

  const fetchMoredata = async () => {
    const data = await getVariantTransaction(
      limit,
      offset,
      params.variantId || ""
    );
    const new_data = [...variant, ...data?.data];
    setProduct(new_data);
  };

  useEffect(() => {
    if (meta - variant?.length < limit) {
      setMore(false);
    } else {
      setMore(true);
    }
  }, [variant?.length]);

  useEffect(() => {
    if (variant?.length > limit) {
      setOffset(offset + 10);
    }
  }, [variant?.length]);

  return (
    <div className="w-full">
      <InfiniteScroll
        initialLoad={false}
        loadMore={fetchMoredata}
        hasMore={more}
        loader={<ContactSkeletonLoading />}
      >
        {variant.map((el: any) => (
          <div key={el.id} className="w-full">
            <div
              className="flex  items-center w-full justify-between"
              onClick={() => goDetailTransacation(el.id)}
            >
              <div className="w-1/4 p-2">
                <img
                  src={el.image_url}
                  className="w-full h-full shadow-sm rounded-md"
                  alt="img"
                />
              </div>
              <div className="flex items-center w-3/4 pl-2 justify-between">
                <div className="flex flex-col">
                  <div className="">
                    <h1 className="text-sm">{el.label}</h1>
                  </div>
                  <div className="">
                    <h1 className="text-sm font-rom">
                      Valid Until:{" "}
                      {moment(el.valid_time_until).format("ll hh:mm")}
                    </h1>
                  </div>
                  <div className="flex items-center">
                    <h1 className="text-sm font-rom"> Valid Until:</h1>
                    <h1
                      className={
                        "text-sm ml-1 " +
                        (el.purchase_status === "success"
                          ? "text-success"
                          : "text-primary")
                      }
                    >
                      {el.purchase_status}
                    </h1>
                  </div>
                </div>
                <div>
                  <img src={next} alt="imgs" className="w-3 h-3" />
                </div>
              </div>
            </div>
            <hr className="my-4" />
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default VariantTransactionList;
