import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router";
import Input from "../../../../../component/input/Input";
import Save from "../../../../../component/input/Save";
import SubNav from "../../../../../component/navbar/SubNav";
import Toggle from "../../../../../component/toggleBtn/Toggle";
import * as Yup from "yup";
import { useFormik } from "formik";
import DynamicBreadcrumb from "../../../../../component/breadcrumb/DynamicBreadcrumb";
import Select from "react-select";
import { optRoutesAddStockTicket } from "../../../ticket/helpers/list";
import { TicketRequest } from "../../../../../api/ticket/Ticket";
import Loading from "../../../../../component/loading/Loading";
import moment from "moment";
import { cancel } from "../../../../../assets/icons";

export const valid_time_type: any[] = [
  { label: "Set Time", value: "scheduled" },
  { label: "Period", value: "interval_day" },
];

const AddTicketVariant = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { data, isLoading } = useQuery("getTicketById", () =>
    TicketRequest.getTicketById(params.id || "")
  );
  const {
    values,
    handleChange,
    handleSubmit,
    errors,
    touched,
    handleReset,
    setValues,
  } = useFormik<any>({
    initialValues: {
      ticket_bundle_id: Number(params.id),
      label: "",
      description: "",
      stock: 0,
      usage_slot: 0,
      is_usage_limit_per_user: false,
      usage_limit_per_user: 0,
      how_to: "",
      is_limited: false,
      limit_per_user: 0,
      selling_time_enabled: false,
      selling_start_time: "",
      selling_end_time: "",
      valid_time_indefinite: true,
      valid_time_type: { label: "", value: "" },
      valid_time_from: "",
      valid_time_until: "",
      valid_time_interval: 0,
      price: 0,
      discount_price: 0,
      tnc: [],
      _claim_time_all: false,
      claim_time: {
        "0": {
          start: "",
          end: "",
          enabled: true,
        },
        "1": {
          start: "",
          end: "",
          enabled: true,
        },
        "2": {
          start: "",
          end: "",
          enabled: true,
        },
        "3": {
          start: "",
          end: "",
          enabled: true,
        },
        "4": {
          start: "",
          end: "",
          enabled: true,
        },
        "5": {
          start: "",
          end: "",
          enabled: true,
        },
        "6": {
          start: "",
          end: "",
          enabled: true,
        },
      },
      active_discount_price: false,
    },
    validationSchema: Yup.object({
      label: Yup.string().required("Variant is required"),
      description: Yup.string().required("Description is required"),
      how_to: Yup.string().required("How to use is required"),
      stock: Yup.number()
        .min(1, "Minimum of stock is 1")
        .required("Stock is required"),
      price: Yup.number().required("Price is required"),
      usage_slot: Yup.number()
        .min(1, "Minimum of use times is 1")
        .required("use times is required"),
      is_limited: Yup.boolean(),
      limit_per_user: Yup.number().when("is_limited", {
        is: true,
        then: Yup.number()
          .min(1, "Minimum of limit per user is 1")
          .required("Limit per user is required"),
      }),
      usage_limit_per_user: Yup.number().when("is_usage_limit_per_user", {
        is: true,
        then: Yup.number()
          .min(1, "Minimum of Limit using ticket is 1")
          .required("Limit using ticket is required"),
      }),
      selling_time_enabled: Yup.boolean(),
      selling_start_time: Yup.string().when("selling_time_enabled", {
        is: true,
        then: Yup.string().required("Start time is required"),
      }),
      selling_end_time: Yup.string().when("selling_time_enabled", {
        is: true,
        then: Yup.string().required("End time is required"),
      }),
      valid_time_indefinite: Yup.boolean(),
      valid_time_interval: Yup.number().when("valid_time_indefinite", {
        is: false,
        then: Yup.number().when("valid_time_type.value", {
          is: "interval_day",
          then: Yup.number()
            .min(1, "Minimum of period is 1")
            .required("Period is required"),
        }),
      }),
      valid_time_from: Yup.string().when("valid_time_indefinite", {
        is: false,
        then: Yup.string().when("valid_time_type.value", {
          is: "scheduled",
          then: Yup.string().required("Start date is required"),
        }),
      }),
      valid_time_until: Yup.string().when("valid_time_indefinite", {
        is: false,
        then: Yup.string().when("valid_time_type.value", {
          is: "scheduled",
          then: Yup.string().required("End date is required"),
        }),
      }),
      valid_time_type: Yup.object().when("valid_time_indefinite", {
        is: false,
        then: Yup.object().shape({
          value: Yup.string().required("Type is required"),
        }),
      }),
      discount_price: Yup.number().when("active_discount_price", {
        is: true,
        then: Yup.number().min(1, "Discount price is required"),
      }),
    }),
    onSubmit: async () => {
      await mutation.mutate(values);
    },
  });

  const [day, setDay] = useState({ start: "", end: "" });
  const [activeDay, setActiveDay] = useState("");
  const [arrDay, setArrDay] = useState<any>([]);
  const [tnc, setTnc] = useState<any[]>([]);
  const [tncStr, setTncStr] = useState("");

  const goBack = () => {
    navigate(-1);
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (payload) => TicketRequest.postTicketVariant(payload, false),
    {
      onMutate: () => {
        console.log("mutate add ticket variant");
      },
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          await queryClient.invalidateQueries("getTicketVariant");
          goBack();
        }
      },
    }
  );

  useEffect(() => {
    let claim_time = { ...values.claim_time };
    Object.keys(values.claim_time).map((el) =>
      el === activeDay
        ? (claim_time[el] = { start: day.start, end: day.end, enabled: true })
        : ""
    );
    setValues({ ...values, claim_time });
  }, [day]);

  useEffect(() => {
    setArrDay(
      Object.entries(values.claim_time).map(
        (key: any, idx: number) =>
          key[1].enabled && [
            idx === 0
              ? "Sunday"
              : idx === 1
              ? "Monday"
              : idx === 2
              ? "Tuesday"
              : idx === 3
              ? "Wednesday"
              : idx === 4
              ? "Thursday"
              : idx === 5
              ? "Friday"
              : idx === 6
              ? "Saturday"
              : "",
            values.claim_time[idx],
          ]
      )
    );
  }, [day, values.claim_time]);

  const handleSetClaimDay = (id: string) => {
    setActiveDay(id);
    setDay({
      start: values.claim_time[id].start,
      end: values.claim_time[id].end,
    });
  };

  const handleChangeTnc = (e: any, i: number) => {
    const newTnc = [...values.tnc];
    newTnc[i] = e.target.value;
    setValues({ ...values, tnc: newTnc });
  };

  const handleGenerateTnc = async () => {
    const data = await TicketRequest.postTicketVariant(values, true);
    const tncArr: any[] = [];
    if (data?.terms_and_conditions?.selling_time) {
      tncArr.push(data.terms_and_conditions.selling_time);
    }
    if (data?.terms_and_conditions?.valid_time) {
      tncArr.push(data.terms_and_conditions.valid_time);
    }
    if (data?.terms_and_conditions?.claim_time?.length) {
      tncArr.push(data.terms_and_conditions.claim_time.join(" "));
    }
    setTnc(tncArr);
  };

  const removeFromList = (index: number) => {
    const tnc = [...values.tnc];
    tnc.splice(index, 1);
    setValues({ ...values, tnc: tnc });
  };

  const handleResetClaimTime = () => {
    let claim_time = { ...values.claim_time };
    Object.keys(values.claim_time).map(
      (el) => (claim_time[el] = { start: "", end: "", enabled: true })
    );
    setValues({ ...values, claim_time });
  };

  const setClaimTime = () => {
    setValues({
      ...values,
      _claim_time_all: !values._claim_time_all,
      claim_time: {
        "0": {
          start: "",
          end: "",
          enabled: values._claim_time_all ? true : false,
        },
        "1": {
          start: "",
          end: "",
          enabled: values._claim_time_all ? true : false,
        },
        "2": {
          start: "",
          end: "",
          enabled: values._claim_time_all ? true : false,
        },
        "3": {
          start: "",
          end: "",
          enabled: values._claim_time_all ? true : false,
        },
        "4": {
          start: "",
          end: "",
          enabled: values._claim_time_all ? true : false,
        },
        "5": {
          start: "",
          end: "",
          enabled: values._claim_time_all ? true : false,
        },
        "6": {
          start: "",
          end: "",
          enabled: values._claim_time_all ? true : false,
        },
      },
    });
  };

  return (
    <main className="bg-white font-med p-4">
      {isLoading ? (
        <div className="flex justify-center my-10">
          <Loading />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <div className="w-full h-full md:flex justify-between">
            <div className="md:w-1/3">
              <div className="w-full rounded-md border-2 border-stroke">
                <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5 bg">
                  <h1 className="font-medium md:text-xl text-sm">
                    Product Images
                  </h1>
                </div>
                <hr />

                <div className="md:m-7 m-5">
                  <div>
                    <h1 className="md:text-base text-sm text-textPrimary">
                      Display Image
                    </h1>
                    <div className="flex justify-center items-center">
                      <img
                        src={data?.preview_image_path}
                        alt="-"
                        className="w-1/3 rounded-md shadow-md"
                      />
                    </div>
                  </div>

                  {data.display_media_type === "image" ? (
                    <div className="mt-10 text-textPrimary">
                      <h1 className="md:text-base text-sm">Upload Images</h1>
                      <div className="flex justify-center items-center">
                        <img
                          src={data?.display_image_path}
                          alt="-"
                          className="w-2/5 rounded-md shadow-md"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="mt-10 text-textPrimary">
                      <div className="flex justify-center">
                        <video
                          controls
                          src={data?.display_video_path}
                          autoPlay={true}
                          className="w-2/5 rounded-md shadow-md"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="w-full my-10 rounded-md border-2 border-stroke">
                <div className="items-center md:m-7 m-5">
                  <h1 className="md:text-base text-sm">Detail Images</h1>
                  <h1 className="text-xs font-rom text-textPrimary mt-1">
                    Note: You can add your size chart or detail type of your
                    product
                  </h1>
                </div>
                <hr />
                <div className="md:m-7 m-5">
                  <div className="grid grid-cols-4 gap-3">
                    {data?.description_image_paths.map((el: any) => (
                      <img
                        src={el?.image_path}
                        alt="-"
                        className="w-full rounded-md shadow-md"
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            {/* Variant information */}
            <div className="md:w-custom w-full md:mt-0 mt-10 h-full">
              <div className="rounded-md rounded-b-none border-2 border-stroke">
                <div className="md:h-16 h-14 flex items-center justify-between md:mx-8 mx-5">
                  <h1 className="font-medium md:text-xl text-sm">
                    Variant Information
                  </h1>
                </div>
                <hr />
                <div className="md:m-8 m-5">
                  <div className="md:flex">
                    <div className="md:w-1/2">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Variant
                      </h1>
                      <Input
                        type={"text"}
                        name={"label"}
                        placeholder={"Place holder"}
                        handleChange={handleChange}
                      />
                      {errors.label && touched.label && (
                        <p className="text-primary text-xs font-rom -mt-2 mb-1">
                          {errors.label}
                        </p>
                      )}
                    </div>
                    <div className="md:w-1/2 md:ml-8 md:mt-0 mt-2">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Stock
                      </h1>
                      <Input
                        type={"number"}
                        name={"stock"}
                        placeholder={"Place holder"}
                        handleChange={handleChange}
                      />
                      {errors.stock && touched.stock && (
                        <p className="text-primary text-xs font-rom -mt-2 mb-1">
                          {errors.stock}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="md:flex my-3">
                    <div className="md:w-1/2">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Product Price
                      </h1>
                      <Input
                        type={"number"}
                        name={"price"}
                        placeholder={"Place holder"}
                        handleChange={handleChange}
                      />
                      {errors.price && touched.price && (
                        <p className="text-primary text-xs font-rom -mt-2 mb-1">
                          {errors.price}
                        </p>
                      )}
                    </div>
                    <div className="md:w-1/2 md:ml-8 md:mt-0 mt-2">
                      <div className="flex justify-between">
                        <h1 className="md:text-base text-sm text-textPrimary">
                          Discount Price
                        </h1>
                        <div className="flex items-center">
                          <Toggle
                            input={!values.active_discount_price}
                            handleClick={() => {
                              setValues({
                                ...values,
                                active_discount_price:
                                  !values.active_discount_price,
                              });
                            }}
                          />
                          <h1 className="font-rom md:text-base text-sm ml-4 text-textPrimary">
                            {values.active_discount_price
                              ? "Enable"
                              : "Disable"}
                          </h1>
                        </div>
                      </div>
                      <Input
                        disable={!values.active_discount_price}
                        type={"number"}
                        name={"discount_price"}
                        placeholder={"Place holder"}
                        handleChange={handleChange}
                      />
                      {errors.discount_price && touched.discount_price && (
                        <p className="text-primary text-xs font-rom -mt-2 mb-1">
                          {errors.discount_price}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="md:flex my-3">
                    <div className="md:w-1/2 md:pr-5 pr-0">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Use Times
                      </h1>
                      <div className="flex items-center">
                        <Input
                          type={"number"}
                          name={"usage_slot"}
                          placeholder={"Place holder"}
                          handleChange={handleChange}
                        />
                        <div className="border border-stroke rounded-r-md flex items-center justify-center h-10 px-5">
                          <h1 className="font-rom">Times</h1>
                        </div>
                      </div>
                      {errors.usage_slot && touched.usage_slot && (
                        <p className="text-primary text-xs font-rom -mt-2 mb-1">
                          {errors.usage_slot}
                        </p>
                      )}
                    </div>
                    <div className="md:w-1/2 md:ml-8 md:mt-0 mt-2">
                      <div className="flex justify-between">
                        <h1 className="md:text-base text-sm text-textPrimary">
                          Limit Using Ticket / (Day)
                        </h1>
                        <div className="flex items-center">
                          <Toggle
                            input={!values.is_usage_limit_per_user}
                            handleClick={() => {
                              setValues({
                                ...values,
                                is_usage_limit_per_user:
                                  !values.is_usage_limit_per_user,
                              });
                            }}
                          />
                          <h1 className="font-rom md:text-base text-sm ml-4 text-textPrimary">
                            {values.is_usage_limit_per_user
                              ? "Enable"
                              : "Disable"}
                          </h1>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <Input
                          disable={!values.is_usage_limit_per_user}
                          type={"number"}
                          name={"usage_limit_per_user"}
                          placeholder={"Place holder"}
                          handleChange={handleChange}
                        />
                        <div className="border border-stroke rounded-r-md flex items-center justify-center h-10 px-5">
                          <h1 className="font-rom">Ticket</h1>
                        </div>
                      </div>
                      {errors.usage_limit_per_user &&
                        touched.usage_limit_per_user && (
                          <p className="text-primary text-xs font-rom -mt-2 mb-1">
                            {errors.usage_limit_per_user}
                          </p>
                        )}
                    </div>
                  </div>

                  <div className="md:flex my-3">
                    <div className="md:w-1/2">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Limited Product
                      </h1>
                      <div className="md:mt-5 mt-2 flex">
                        <Toggle
                          input={!values.is_limited}
                          handleClick={() =>
                            setValues({
                              ...values,
                              is_limited: !values.is_limited,
                            })
                          }
                        />
                        <h1 className="font-rom md:text-base text-sm ml-4 text-textPrimary">
                          {values.is_limited ? "Enable" : "Disable"}
                        </h1>
                      </div>
                    </div>
                    <div className="md:w-1/2 md:ml-8  md:mt-0 mt-5">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Limit / user
                      </h1>
                      <Input
                        disable={!values.is_limited}
                        type={"number"}
                        name={"limit_per_user"}
                        placeholder={"Place holder"}
                        handleChange={handleChange}
                      />
                      {errors.limit_per_user && touched.limit_per_user && (
                        <p className="text-primary text-xs font-rom -mt-2 mb-1">
                          {errors.limit_per_user}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="md:flex my-3">
                    <div className="md:w-1/2">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Selling Date & Time
                      </h1>
                      <h1 className="md:text-sm text-xs font-rom text-textPrimary">
                        If it is not activated, the ticket has no sales deadline
                      </h1>
                      <div className="flex mt-2">
                        <Toggle
                          input={!values.selling_time_enabled}
                          handleClick={() =>
                            setValues({
                              ...values,
                              selling_time_enabled:
                                !values.selling_time_enabled,
                            })
                          }
                        />
                        <h1 className="font-rom md:text-base text-sm ml-4 text-textPrimary">
                          {values.selling_time_enabled ? "Enable" : "Disable"}
                        </h1>
                      </div>
                    </div>
                    <div className="md:w-1/2 md:ml-8  md:mt-0 mt-5">
                      <div className="w-full">
                        <h1 className="md:text-base text-sm text-textPrimary">
                          From
                        </h1>
                        <Input
                          disable={!values.selling_time_enabled}
                          type={"datetime-local"}
                          name={"selling_start_time"}
                          placeholder={"Place holder"}
                          handleChange={handleChange}
                        />
                        {errors.selling_start_time &&
                          touched.selling_start_time && (
                            <p className="text-primary text-xs font-rom -mt-2 mb-1">
                              {errors.selling_start_time}
                            </p>
                          )}
                      </div>
                      <div className="w-full  md:mt-0 mt-2">
                        <h1 className="md:text-base text-sm text-textPrimary">
                          To
                        </h1>
                        <Input
                          disable={!values.selling_time_enabled}
                          type={"datetime-local"}
                          name={"selling_end_time"}
                          placeholder={"Place holder"}
                          handleChange={handleChange}
                        />
                        {errors.selling_end_time &&
                          touched.selling_start_time && (
                            <p className="text-primary text-xs font-rom -mt-2 mb-1">
                              {errors.selling_end_time}
                            </p>
                          )}
                      </div>
                    </div>
                  </div>
                  <div className="md:flex my-3">
                    <div className="md:w-1/2">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Valid Date & Time
                      </h1>
                      <h1 className="md:text-sm text-xs font-rom text-textPrimary">
                        If it is not activated, the ticket has no usage time
                        limit
                      </h1>
                      <div className="flex mt-2">
                        <Toggle
                          input={values.valid_time_indefinite}
                          handleClick={() =>
                            setValues({
                              ...values,
                              valid_time_indefinite:
                                !values.valid_time_indefinite,
                            })
                          }
                        />
                        <h1 className="font-rom md:text-base text-sm ml-4 text-textPrimary">
                          {values.valid_time_indefinite ? "Disable" : "Enable"}
                        </h1>
                      </div>
                    </div>

                    <div className="md:ml-8 md:w-1/2 md:mt-0 mt-5">
                      <div>
                        <h1 className="md:text-base text-sm text-textPrimary">
                          Option
                        </h1>
                        <Select
                          isDisabled={values.valid_time_indefinite}
                          className="font-rom text-sm mt-3"
                          options={valid_time_type}
                          onChange={(e: any) =>
                            setValues({ ...values, valid_time_type: e })
                          }
                        />
                        {errors.valid_time_type && touched.valid_time_type && (
                          <p className="text-primary text-xs font-rom mt-1">
                            {errors.valid_time_type &&
                              "Option type is required"}
                          </p>
                        )}
                      </div>
                      {values?.valid_time_type?.value === "scheduled" ? (
                        <div className="my-5">
                          <div className="w-full">
                            <h1 className="md:text-base text-sm text-textPrimary">
                              From
                            </h1>
                            <Input
                              type={"datetime-local"}
                              name={"valid_time_from"}
                              placeholder={"Place holder"}
                              handleChange={handleChange}
                            />
                            {errors.valid_time_from &&
                              touched.valid_time_from && (
                                <p className="text-primary text-xs font-rom -mt-2 mb-1">
                                  {errors.valid_time_from}
                                </p>
                              )}
                          </div>
                          <div className="w-full md:mt-0 mt-2">
                            <h1 className="md:text-base text-sm text-textPrimary">
                              To
                            </h1>
                            <Input
                              type={"datetime-local"}
                              name={"valid_time_until"}
                              placeholder={"Place holder"}
                              handleChange={handleChange}
                            />
                            {errors.valid_time_until &&
                              touched.valid_time_until && (
                                <p className="text-primary text-xs font-rom -mt-2 mb-1">
                                  {errors.valid_time_until}
                                </p>
                              )}
                          </div>
                        </div>
                      ) : values?.valid_time_type?.value === "interval_day" ? (
                        <div className="w-full my-3">
                          <h1 className="md:text-base text-sm text-textPrimary">
                            Period
                          </h1>
                          <Input
                            type={"number"}
                            name={"valid_time_interval"}
                            placeholder={"Place holder"}
                            handleChange={handleChange}
                          />
                          {errors.valid_time_interval &&
                            touched.valid_time_interval && (
                              <p className="text-primary text-xs font-rom -mt-2 mb-1">
                                {errors.valid_time_interval}
                              </p>
                            )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="md:my-10 my-7 w-full">
                    <div className="flex items-start justify-between">
                      <div className="md:w-1/2">
                        <h1 className="md:text-base text-sm text-textPrimary">
                          Select Day & Hours
                        </h1>
                        <h1 className="md:text-sm text-xs font-rom text-textPrimary">
                          If it is not activated, the ticket has no usage time
                          limit
                        </h1>
                      </div>
                      <div className="flex items-center">
                        <Toggle
                          input={!values._claim_time_all}
                          handleClick={setClaimTime}
                        />
                        <h1 className="font-rom md:text-base text-sm ml-4 text-textPrimary">
                          {values._claim_time_all ? "Enable" : "Disable"}
                        </h1>
                      </div>
                    </div>

                    <div
                      className={
                        "grid xl:grid-cols-7 grid-cols-3 md:gap-3 gap-2 my-5 " +
                        (values._claim_time_all
                          ? "cursor-pointer"
                          : "cursor-not-allowed")
                      }
                    >
                      <div
                        onClick={
                          values._claim_time_all
                            ? () => handleSetClaimDay("1")
                            : () => {}
                        }
                        className={
                          "md:h-24 h-20 flex items-center justify-center border rounded-md " +
                          (activeDay === "1" || values.claim_time[1].enabled
                            ? "border-primary"
                            : "border-stroke ")
                        }
                      >
                        <h1 className="md:text-base text-sm">Monday</h1>
                      </div>
                      <div
                        onClick={
                          values._claim_time_all
                            ? () => handleSetClaimDay("2")
                            : () => {}
                        }
                        className={
                          "md:h-24 h-20 flex items-center justify-center border rounded-md " +
                          (activeDay === "2" || values.claim_time[2].enabled
                            ? "border-primary"
                            : "border-stroke ")
                        }
                      >
                        <h1 className="md:text-base text-sm">Tuesday</h1>
                      </div>
                      <div
                        onClick={
                          values._claim_time_all
                            ? () => handleSetClaimDay("3")
                            : () => {}
                        }
                        className={
                          "md:h-24 h-20 flex items-center justify-center border rounded-md " +
                          (activeDay === "3" || values.claim_time[3].enabled
                            ? "border-primary"
                            : "border-stroke ")
                        }
                      >
                        <h1 className="md:text-base text-sm">Wednesday</h1>
                      </div>
                      <div
                        onClick={
                          values._claim_time_all
                            ? () => handleSetClaimDay("4")
                            : () => {}
                        }
                        className={
                          "md:h-24 h-20 flex items-center justify-center border rounded-md " +
                          (activeDay === "4" || values.claim_time[4].enabled
                            ? "border-primary"
                            : "border-stroke ")
                        }
                      >
                        <h1 className="md:text-base text-sm">Thursday</h1>
                      </div>
                      <div
                        onClick={
                          values._claim_time_all
                            ? () => handleSetClaimDay("5")
                            : () => {}
                        }
                        className={
                          "md:h-24 h-20 flex items-center justify-center border rounded-md " +
                          (activeDay === "5" || values.claim_time[5].enabled
                            ? "border-primary"
                            : "border-stroke ")
                        }
                      >
                        <h1 className="md:text-base text-sm">Friday</h1>
                      </div>
                      <div
                        onClick={
                          values._claim_time_all
                            ? () => handleSetClaimDay("6")
                            : () => {}
                        }
                        className={
                          "md:h-24 h-20 flex items-center justify-center border rounded-md " +
                          (activeDay === "6" || values.claim_time[6].enabled
                            ? "border-primary"
                            : "border-stroke ")
                        }
                      >
                        <h1 className="md:text-base text-sm">Saturday</h1>
                      </div>
                      <div
                        onClick={
                          values._claim_time_all
                            ? () => handleSetClaimDay("0")
                            : () => {}
                        }
                        className={
                          "md:h-24 h-20 flex items-center justify-center border rounded-md " +
                          (activeDay === "0" || values.claim_time[0].enabled
                            ? "border-primary"
                            : "border-stroke ")
                        }
                      >
                        <h1 className="md:text-base text-sm">Sunday</h1>
                      </div>
                    </div>

                    <div className="md:flex my-3">
                      <div className="md:w-1/2">
                        <h1 className="md:text-base text-sm text-textPrimary">
                          From
                        </h1>
                        <Input
                          disable={!values._claim_time_all ? true : false}
                          id={"from"}
                          type={"time"}
                          name={"start"}
                          placeholder={"Place holder"}
                          handleChange={(e: any) =>
                            setDay({
                              ...day,
                              start: e.target.value,
                            })
                          }
                          value={day.start}
                        />
                      </div>
                      <div className="md:w-1/2 md:ml-8 md:mt-0 mt-2">
                        <h1 className="md:text-base text-sm text-textPrimary">
                          To
                        </h1>
                        <Input
                          disable={!values._claim_time_all ? true : false}
                          id={"to"}
                          type={"time"}
                          name={"end"}
                          placeholder={"Place holder"}
                          handleChange={(e: any) =>
                            setDay({
                              ...day,
                              end: e.target.value,
                            })
                          }
                          value={day.end}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="md:m-8 m-5 md:text-lg text-base flex justify-between">
                  <h1>Summary</h1>
                  <h1
                    onClick={handleResetClaimTime}
                    className="cursor-pointer hover:text-primary"
                  >
                    Reset All
                  </h1>
                </div>
                <hr />
                <div className="md:m-8 m-5 font-rom md:text-base text-sm">
                  {arrDay.filter(Boolean).map((el: any) => (
                    <h1>
                      {el[0]} :{" "}
                      {moment("2200-03-13 " + el[1]?.start).format("HH:mm a")} -{" "}
                      {moment("2200-03-13 " + el[1]?.end).format("HH:mm a")}
                    </h1>
                  ))}
                </div>
                <hr />
                <div className="md:m-8 m-5">
                  <div className="md:text-lg text-base">
                    <h1 className="text-textPrimary md:text-base text-sm">
                      Description
                    </h1>
                    <textarea
                      name="description"
                      onChange={handleChange}
                      rows={5}
                      placeholder="Feature: Bicycle Intelligent Light Sensitive Light
                          
                 Note: When the blue light is on, it enters the induction mode, and with changes in the brightness around the lamp will change 
                 
                 This light is placed on the bicycle handlebar to provide light to the front of the road, when cycling at night or in the dark. Useful in foggy conditions where visibility is very limited."
                      className="border border-stroke w-full mt-2 rounded-md font-rom p-3 text-sm focus:ring-1 focus:outline-none"
                    ></textarea>
                    {errors.description && touched.description && (
                      <p className="text-primary text-xs font-rom">
                        {errors.description}
                      </p>
                    )}
                  </div>
                  <div className="my-5 md:text-lg text-base">
                    <h1 className="text-textPrimary md:text-base text-sm">
                      How to use
                    </h1>
                    <textarea
                      placeholder="- Open the QR.
                   - Show for our customer service.
                   - Redeem a ticket."
                      cols={30}
                      rows={5}
                      name="how_to"
                      onChange={handleChange}
                      className="border border-stroke w-full mt-2 rounded-md font-rom p-3 text-sm focus:ring-1 focus:outline-none"
                    ></textarea>
                    {errors.how_to && touched.how_to && (
                      <p className="text-primary text-xs font-rom">
                        {errors.how_to}
                      </p>
                    )}
                  </div>
                  <div className="my-5 md:text-lg text-base">
                    <div className="flex justify-between">
                      <h1 className="text-textPrimary md:text-base text-sm">
                        Term & Conditions
                      </h1>
                      <h1
                        onClick={handleGenerateTnc}
                        className="text-primary cursor-pointer md:text-base text-sm"
                      >
                        Auto Generate
                      </h1>
                    </div>
                    {tnc.length > 0 &&
                      tnc.map((el: string, i: number) => (
                        <Input
                          type={"text"}
                          name={"tnc"}
                          placeholder={"Place holder"}
                          disable={true}
                          value={el}
                        />
                      ))}
                    {values.tnc.length > 0 &&
                      values.tnc.map((el: string, i: number) => (
                        <div className="flex">
                          <Input
                            type={"text"}
                            name={"tnc"}
                            placeholder={"Place holder"}
                            handleChange={(e: any) => handleChangeTnc(e, i)}
                            value={el}
                          />
                          <img
                            src={cancel}
                            alt="del"
                            className="w-5 ml-4 cursor-pointer"
                            onClick={() => removeFromList(i)}
                          />
                        </div>
                      ))}
                    <Input
                      type={"text"}
                      name={"tncStr"}
                      placeholder={"Place holder"}
                      handleChange={(e: any) => setTncStr(e.target.value)}
                      value={tncStr}
                    />
                    <hr className="my-2" />
                    <div className="justify-center items-center w-full my-1 flex">
                      <h1
                        onClick={() => {
                          setValues({
                            ...values,
                            tnc: values.tnc.concat(tncStr),
                          });
                          setTncStr("");
                        }}
                        className="text-base text-textPrimary hover:text-primary cursor-pointer"
                      >
                        Add Terms & Conditions
                      </h1>
                    </div>
                  </div>
                </div>
              </div>

              <Save
                handleReset={() => handleReset({})}
                load={mutation.isLoading}
              />
            </div>
          </div>
        </form>
      )}
    </main>
  );
};

export default AddTicketVariant;
