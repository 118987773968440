import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import { TicketRequest } from "../../../../api/ticket/Ticket";
import { cancel } from "../../../../assets/icons";
import SmallButton from "../../../../component/button/Button";
import { ActionType } from "../../../../store/ticket/action";
import TicketContext from "../../../../store/ticket/context";

const ConfirmEditTicketModal = () => {
  const { TicketDispatch, TicketState } = useContext(TicketContext);
  const { confirmEditTicket } = TicketState;
  const navigate = useNavigate();

  const goBack = () => {
    TicketDispatch({
      type: ActionType.ConfirmEditTicket,
      payload: {
        id: 0,
        status: false,
        data: null,
      },
    });
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () =>
      TicketRequest.EditTicket(
        confirmEditTicket.data,
        confirmEditTicket.id.toString()
      ),
    {
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else {
          await queryClient.invalidateQueries("ticket");
          goBack();
          navigate(-1)
        }
      },
    }
  );

  return (
    <div className="relative z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white lg:w-1/3 md:w-2/5 w-10/12 x h-auto rounded-xl my-auto mx-auto font-med">
        <div className="flex w-full justify-end items-center px-8 pb-5 pt-8">
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-5"
            onClick={goBack}
          />
        </div>
        <div className="px-10 pb-3">
          <div className="flex flex-col items-center">
            <h1 className="md:text-lg text-base text-center text-textPrimary mt-4 font-rom">
              Are you sure to change the product's Information?
            </h1>
            <h1 className="text-sm text-center px-10 text-textPrimary font-rom">
              After change the product's information, you must wait confirmation
              from Depatu Admin, or contact Customer Service for further
              information
            </h1>
          </div>

          <div className="flex justify-center my-8 outline-none">
            <SmallButton
              loadColor={"white"}
              type={"button"}
              handleClick={goBack}
              color={"bg-white"}
              border={"border-2"}
              borderColor={"border-breadscrumbColor"}
              hoverColor={"opacity-70"}
              text={"Cancel"}
              textColor={"text-textPrimary"}
              height={10}
            />
            <div className="mx-1.5"></div>
            <SmallButton
              isSubmitting={mutation.isLoading}
              loadColor={"white"}
              type={"button"}
              height={10}
              handleClick={() => mutation.mutate()}
              color={"bg-primary"}
              hoverColor={"bg-red-300"}
              text={"Continue"}
              textColor={"text-white"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEditTicketModal;
