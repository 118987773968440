import React, { useContext } from "react";
import { useNavigate } from "react-router";
import ReactTooltip from "react-tooltip";
import { deleteIcon, edit, variant } from "../../../../assets/icons";
import NewPagination from "../../../../component/pagination/NewPagination";
import { ActionType } from "../../../../store/ticket/action";
import TicketContext from "../../../../store/ticket/context";

interface IProps {
  data: any;
}

const TicketTable: React.FC<IProps> = ({ data }) => {
  const { TicketDispatch } = useContext(TicketContext);
  const navigate = useNavigate();
  return (
    <div className="mt-10 w-full">
      <table className="w-full border relative text-center text-sm">
        <thead>
          <tr className="h-18 sticky top-0 bg-white shadow-sm">
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Product Image</h1>
              </div>
            </th>
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Product Name</h1>
              </div>
            </th>
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Product Status</h1>
              </div>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.data?.map((el: any) => (
            <tr className="border h-24 font-rom hover:bg-gray-50">
              <td className="border-r flex justify-center py-5">
                <img
                  src={el.preview_image_path}
                  alt="-"
                  className="w-24 shadow-sm"
                />
              </td>

              <td className="border-r">{el.name}</td>

              <td
                className={
                  "border-r font-med " +
                  (el.status === "on_selling"
                    ? "text-success"
                    : el.status === "awaiting_approval" ||
                      el.status === "awaiting_revision"
                    ? "text-yellow-400"
                    : "text-primary")
                }
              >
                {el.status.split("_").join(" ")}
              </td>

              <td className="">
                <div className="flex justify-evenly">
                  <div>
                    <img
                      data-tip
                      data-for="variant"
                      className="cursor-pointer md:w-5"
                      onClick={() =>
                        navigate(`/admin/ticket/${el.id}/stock-ticket`)
                      }
                      src={variant}
                      alt="variant"
                    />
                    <ReactTooltip id="variant" type="info">
                      <span>Variant</span>
                    </ReactTooltip>
                  </div>
                  <div>
                    <img
                      data-tip
                      data-for="edit"
                      className="cursor-pointer md:w-6"
                      onClick={() =>
                        navigate(`/admin/ticket/${el.id}/edit-ticket`)
                      }
                      src={edit}
                      alt="edit"
                    />
                    <ReactTooltip id="edit" type="dark">
                      <span>Edit</span>
                    </ReactTooltip>
                  </div>
                  <div>
                    <img
                      data-tip
                      data-for="delete"
                      className="cursor-pointer md:w-5"
                      onClick={() =>
                        TicketDispatch({
                          type: ActionType.ShowDeleteTicket,
                          payload: {
                            status: true,
                            id: el.id,
                          },
                        })
                      }
                      src={deleteIcon}
                      alt="del"
                    />
                    <ReactTooltip id="delete" type="error">
                      <span>Delete</span>
                    </ReactTooltip>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <NewPagination
        length={data?.data?.data?.length}
        total={data?.data?.meta?.total}
        isTable={true}
      />
    </div>
  );
};

export default TicketTable;
