import { useQuery } from "react-query";
import { AddressService } from "../../api/address";

const useFetchAddressProvince = (province_name?: string) => {
  return useQuery(
    ["address-province"],
    () => AddressService.getAddressProvince(province_name),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const useFetchAddressCity = (province_id: string, city_name?: string) => {
  return useQuery(
    ["address-city"],
    () => AddressService.getAddressCity(province_id, city_name),
    {
      refetchOnWindowFocus: false,
      enabled: province_id ? true : false,
    }
  );
};

const useFetchAddressDistrict = (city_id: string, district_name?: string) => {
  return useQuery(
    ["address-district"],
    () => AddressService.getAddressDistrict(city_id, district_name),
    {
      refetchOnWindowFocus: false,
      enabled: city_id ? true : false,
    }
  );
};

const useFetchAddressSubdistrict = (
  district_id: string,
  sub_district_name: string
) => {
  return useQuery(
    ["address-subdistrict"],
    () => AddressService.getAddressSubdistrict(sub_district_name, district_id),
    {
      refetchOnWindowFocus: false,
      enabled: district_id ? true : false,
    }
  );
};

export {
  useFetchAddressProvince,
  useFetchAddressCity,
  useFetchAddressDistrict,
  useFetchAddressSubdistrict,
};
