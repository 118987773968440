import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "../../../config/axiosInstance";
import { useQuery } from "react-query";

// components
import WalletMutationTable from "./walletMutationComponent/WalletMutationTable";
import WalletFilter from "./../../../pages/admin/walletMutation/walletMutationComponent/WalletFilter";
import Loading from "../../../component/loading/Loading";

//icons
import { asc, desc } from "../../../assets/icons";
import WalletList from "./walletMutationComponent/mobileView/WalletList";

function WalletMutation() {
  const [sortValue, setSortValue] = useState<string>("created_at");
  const [isAsc, setIsAsc] = useState("desc");
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [chip, setChip] = useState<any>([]);
  const [filter, setFilter] = useState<any>({
    // type: "",
    // amount: 0,
    withdraw_date_from: "",
    withdraw_date_to: "",
  });

  const getMutation = async () => {
    try {
      const results: any = await axios().get(`retail/retail-wallet-mutation`, {
        params: {
          // ...(filter.type.length !== 0 ? { type: filter.type } : ""),
          ...(filter.withdraw_date_from.length !== 0 &&
          filter.withdraw_date_to.length !== 0
            ? {
                created_at: `${filter.withdraw_date_from},${filter.withdraw_date_to}`,
              }
            : ""),
          ...(filter.withdraw_date_from.length !== 0 &&
          filter.withdraw_date_to.length === 0
            ? { created_at: `${filter.withdraw_date_from},2100-01-01` }
            : ""),
          ...(filter.withdraw_date_from.length === 0 &&
          filter.withdraw_date_to.length !== 0
            ? { created_at: `2000-01-01,${filter.withdraw_date_to}` }
            : ""),
          limit: limit,
          ...(offset !== 0 ? { offset: offset } : ""),
          sort: `${sortValue} ${isAsc}`,
        },
      });
      return results;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSort = (event: any) => {
    let target = event.target;
    setSortValue(target.value);
  };

  const handleAsc = () => {
    if (isAsc === "asc") {
      setIsAsc("desc");
    } else {
      setIsAsc("asc");
    }
  };

  const handleShowFilter = () => {
    setShowFilter(!showFilter);
  };

  const { data, isLoading, refetch } = useQuery(
    ["mutation", isAsc, sortValue, limit, offset, filter],
    getMutation,
    { refetchOnWindowFocus: false }
  );

  const applyChip = () => {
    const new_data = [];
    for (const key in filter) {
      if (filter[key].length > 0) {
        new_data.push(key);
      }
    }
    setChip(new_data);
  };

  const handleChipByKey = (el: any) => {
    const new_data = chip.filter((item: any) => item !== el);
    setChip(new_data);
    const new_filter = { ...filter, [el]: "" };
    setFilter(new_filter);
    refetch();
  };

  return (
    <div className="bg-white p-4 font-med">

      {/*show filter and sort*/}
      <div className="flex justify-between">
        <div className="md:flex hidden items-end">
          <h1
            onClick={handleShowFilter}
            className="text-base mr-5 cursor-pointer hover:text-primary"
          >
            Show Filter
          </h1>
        </div>

        <div className="md:flex hidden justify-between items-center">
          {isAsc === "asc" ? (
            <img
              className="cursor-pointer md:w-7 w-5"
              onClick={handleAsc}
              src={asc}
              alt="asc"
            />
          ) : (
            <img
              className="cursor-pointer md:w-7 w-5"
              onClick={handleAsc}
              src={desc}
              alt="desc"
            />
          )}
          <div className="border-2 border-stroke h-10 w-28 rounded-md rounded-r-none flex justify-center items-center ml-5">
            <h1 className="text-base">Sort By</h1>
          </div>
          <div className="border-stroke h-10 w-44 rounded-md rounded-l-none flex justify-around items-center">
            <div className="border-2 border-l-0 border-stroke h-10 w-44  rounded-md rounded-l-none flex justify-around items-center">
              <select
                onChange={handleSort}
                name="sorting"
                id=""
                className="w-full h-full cursor-pointer text-base my-3 px-5 shadow-sm transition duration-100 ease-in-out rounded-md rounded-l-none focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50"
              >
                <option value="created_at">Date</option>
                <option value="amount">Amount</option>
                <option value="type">Status</option>
                <option value="balance">Wallet balance</option>
              </select>
            </div>
          </div>
        </div>

        <div className="md:hidden w-full flex -mt-4 mb-5 items-center">
          {isAsc === "asc" ? (
            <img
              className="cursor-pointer w-6 mr-3"
              onClick={handleAsc}
              src={asc}
              alt="asc"
            />
          ) : (
            <img
              className="cursor-pointer w-6 mr-3"
              onClick={handleAsc}
              src={desc}
              alt="desc"
            />
          )}
          <div className="w-auto flex overflow-x-auto">
            <div className="grid md:gap-10 gap-x-24 grid-cols-8 md:grid-cols-4 w-full md:inline-grid font-med">
              <div className="w-24 flex flex-col items-center">
                <h1
                  className="text-sm"
                  onClick={() => setSortValue("created_at")}
                >
                  Date
                </h1>
                <hr
                  className={
                    sortValue === "created_at"
                      ? "w-full my-1 border-t-2 border-primary"
                      : "w-full my-1 border-t-2"
                  }
                />
              </div>
              <div className="w-24 flex flex-col items-center justify-center">
                <h1 className="text-sm" onClick={() => setSortValue("amount")}>
                  Amount
                </h1>
                <hr
                  className={
                    sortValue === "amount"
                      ? "w-full my-1 border-t-2 border-primary"
                      : "w-full my-1 border-t-2"
                  }
                />
              </div>
              <div className="w-24 flex flex-col items-center justify-center">
                <h1 className="text-sm" onClick={() => setSortValue("type")}>
                  Status
                </h1>
                <hr
                  className={
                    sortValue === "type"
                      ? "w-full my-1 border-t-2 border-primary"
                      : "w-full my-1 border-t-2"
                  }
                />
              </div>
              <div className="w-24 flex flex-col items-center justify-center">
                <h1 className="text-sm" onClick={() => setSortValue("balance")}>
                  Wallet Balance
                </h1>
                <hr
                  className={
                    sortValue === "balance"
                      ? "w-full my-1 border-t-2 border-primary"
                      : "w-full my-1 border-t-2"
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {chip ? (
        <div className="md:flex hidden my-4">
          {chip.map((el: any) => (
            <div className="inline-flex items-center rounded mx-2 bg-white border-2 border-stroke p-1 mt-1">
              <span className="px-2 text-sm">{el.split("_").join(" ")}</span>
              <button type="button" className="h-6 w-6 p-1 focus:outline-none">
                <svg
                  onClick={() => handleChipByKey(el)}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </button>
            </div>
          ))}
        </div>
      ) : (
        <div className="md:hidden h-10"></div>
      )}

      {/*show filter*/}
      <WalletFilter
        applyChip={applyChip}
        refetch={refetch}
        showFilter={showFilter}
        setShowFilter={() => setShowFilter(!showFilter)}
        filter={filter}
        setFilter={setFilter}
      />

      {/** filter for mobile */}
      <div className="flex justify-center mt-14">
        <div className="md:hidden flex rounded-full shadow-lg w-40 h-12 justify-center mt-96 bg-white fixed items-center z-40 text-primary">
          <h1 onClick={handleShowFilter}>Filter</h1>
        </div>
      </div>

      {/* table */}
      {isLoading ? (
        <div className="flex justify-center mt-10">
          <Loading />
        </div>
      ) : (
        <div>
          <div className="md:flex hidden -mt-12">
            <WalletMutationTable
              dataProps={data}
              limit={limit}
              setLimit={setLimit}
              offset={offset}
              setOffset={setOffset}
            />
          </div>
          <div className="md:hidden flex -mt-28">
            <WalletList data={data} sortValue={sortValue} isAsc={isAsc} />
          </div>
        </div>
      )}
    </div>
  );
}

export default WalletMutation;
