import React, { useContext } from "react";
import { useNavigate, useParams, useLocation } from "react-router";
import ReactTooltip from "react-tooltip";
import { deleteIcon, edit } from "../../../../../assets/icons";
import NewPagination from "../../../../../component/pagination/NewPagination";
import { ActionType } from "../../../../../store/ticket/action";
import TicketContext from "../../../../../store/ticket/context";
import { formatRupiah } from "../../../../../utils/formatRupiah";

interface IProps {
  data: any;
}

const StockTicketTable: React.FC<IProps> = ({ data }) => {
  const { TicketDispatch } = useContext(TicketContext);
  const navigate = useNavigate();
  const{ pathname } = useLocation();
  const params = useParams();

  return (
    <div className="mt-10 w-full">
      <table className="w-full border relative text-center text-sm">
        <thead>
          <tr className="h-18 sticky top-0 bg-white shadow-sm">
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Variant</h1>
              </div>
            </th>
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Price</h1>
              </div>
            </th>
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Stock</h1>
              </div>
            </th>
            <th className="border-r h-18">
              <div className="flex justify-center items-center">
                <h1>Ticket Sold</h1>
              </div>
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data?.data?.map((el: any) => (
            <tr className="border h-24 font-rom hover:bg-gray-50">
              <td className="border-r">{el.label}</td>
              <td className="border-r">
                <div className="flex flex-col justify-center">
                  {el?.price && <h1>{formatRupiah(el?.price)}</h1>}
                </div>
              </td>
              <td className="border-r">{el.stock}</td>
              <td className="border-r">
                <div className="flex flex-col items-center">
                  {el.sold_count}
                  {el.sold_count > 0 && (
                    <h1
                      onClick={() =>
                        navigate(`${pathname}/${el.id}/variant-transaction`)
                      }
                      className="hover:text-primary cursor-pointer font-rom"
                    >
                      Details
                    </h1>
                  )}
                </div>
              </td>
              <td className="">
                <div className="flex justify-evenly">
                  <div>
                    <img
                      data-tip
                      data-for="edit"
                      className="cursor-pointer md:w-6"
                      onClick={() =>
                        navigate(
                          `/admin/ticket/${params.id}/stock-ticket/edit-ticket-variant/${el.id}`
                        )
                      }
                      src={edit}
                      alt="edit"
                    />
                    <ReactTooltip id="edit" type="dark">
                      <span>Edit</span>
                    </ReactTooltip>
                  </div>
                  <div>
                    <img
                      data-tip
                      data-for="delete"
                      className="cursor-pointer md:w-5"
                      onClick={() =>
                        TicketDispatch({
                          type: ActionType.ShowDeleteTicketVariant,
                          payload: {
                            status: true,
                            id: el.id,
                          },
                        })
                      }
                      src={deleteIcon}
                      alt="del"
                    />
                    <ReactTooltip id="delete" type="error">
                      <span>Delete</span>
                    </ReactTooltip>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <NewPagination
        length={data?.data?.length}
        total={data?.meta?.total}
        isTable={true}
      />
    </div>
  );
};

export default StockTicketTable;
