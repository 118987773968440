import { useMutation, useQuery, useQueryClient } from "react-query";
import { RetailShopService } from "../../api/retail_shop";
import ErrorHandler from "../../component/toast/ErrorHandler";
import { PRetailShop } from "../../core/retail_shop/entities";

const useFetchRetailShop = () => {
  return useQuery([`retail-shop`], () => RetailShopService.getRetailShop(), {
    refetchOnWindowFocus: false,
  });
};

const useMutateUpdateRetailShop = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (shop: PRetailShop) => RetailShopService.updateRetailShop(shop),
    {
      onMutate: () => {
        console.log("mutate update retail shop");
      },
      onSettled: async (data) => {
        if (data) {
          queryClient.invalidateQueries(`retail-shop`);
          queryClient.invalidateQueries(`retail-user`);
          ErrorHandler("#00C62E", "Successfully update your profile");
        }
      },
    }
  );
};

export { useFetchRetailShop, useMutateUpdateRetailShop };
