import React, { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../../../../../../config/axiosInstance";
import { ProductRequest } from "../../../../../../api/product/ProductRequest";

import Input from "../../../../../../component/input/Input";
import Toggle from "../../../../../../component/toggleBtn/Toggle";
import Save from "../../../../../../component/input/Save";
import Loading from "../../../../../../component/loading/Loading";

interface IProps {
  label: string;
  retail_product_id: number;
  price: number;
  discount_price: number;
  stock: number;
  is_pre_order: boolean;
  pre_order_stock: number;
  weight: number;
}

function AddVariant() {
  const navigate = useNavigate();
  const params = useParams();
  const [preOrder, setPreOrder] = useState<boolean>(false);
  const [activeDiscount, setActiveDiscount] = useState<boolean>(false);
  const [newVariant, setnewVariant] = useState<IProps>({
    label: "",
    retail_product_id: Number(params.id),
    price: 0,
    discount_price: 0,
    stock: 0,
    is_pre_order: false,
    pre_order_stock: 0,
    weight: 0,
  });

  const handlePreOrder = () => {
    setPreOrder(!preOrder);
    if (newVariant.is_pre_order === false) {
      setnewVariant({ ...newVariant, stock: 0, is_pre_order: true });
    } else {
      setnewVariant({ ...newVariant, is_pre_order: false });
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleClear = () => {
    setnewVariant({
      ...newVariant,
      label: "",
      retail_product_id: Number(params.id),
      price: 0,
      discount_price: 0,
      stock: 0,
      is_pre_order: false,
      pre_order_stock: 0,
      weight: 0,
    });
    setPreOrder(false);
  };

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let name = e.target.name;
    let value: any = e.target.value;
    if (name !== "label") {
      value = parseInt(value);
    }
    setnewVariant({ ...newVariant, [name]: value });
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(
    (payload: IProps) => ProductRequest.addProductVariant(payload),
    {
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else if (data) {
          await queryClient.invalidateQueries("variant");
          goBack();
        }
      },
    }
  );

  const getDataVariant = async () => {
    try {
      const results: any = await axios().get(
        `retail/retail-product/${params.id}`
      );
      const result = results.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };
  const { data: dataVariant, isLoading } = useQuery("variant", getDataVariant, {
    refetchOnWindowFocus: false,
  });

  const handleSubmit = (e: any) => {
    e.preventDefault();
    mutation.mutate(newVariant);
  };

  return (
    <>
      <div className="bg-white p-4 font-med">
        [==]
        <form onSubmit={handleSubmit}>
          <div className="w-full h-full md:my-14 my-8 md:flex justify-between">
            {/* Product Images */}
            <div className="md:w-1/3 w-full h-full rounded-md border-2 border-stroke">
              <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
                <h1 className="font-medium md:text-xl text-sm">
                  Product Images
                </h1>
              </div>
              <hr />

              {isLoading ? (
                <div className="flex justify-center mt-10">
                  <Loading />
                </div>
              ) : (
                <div className="md:mx-8 mx-5 md:my-7 my-5">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Display Images
                  </h1>
                  <div className="md:w-full flex flex-col items-center">
                    <img
                      className="my-3 rounded-md shadow-md w-44"
                      src={dataVariant?.display_image_path}
                      alt="display_image"
                    />
                  </div>
                  <div className="mt-6 text-textPrimary">
                    <h1 className="md:text-base text-sm">Detail Images</h1>
                    <div className="grid grid-cols-3 md:gap-3 gap-2 justify-center h-auto">
                      {dataVariant.detail_image_path.length <= 0 ? (
                        <h1 className="my-5">No images uploaded</h1>
                      ) : (
                        dataVariant.detail_image_path
                          ?.split(",")
                          .map((el: string | undefined) => (
                            <div className="flex justify-start my-2">
                              <img
                                className="w-full rounded-md shadow-md"
                                src={el}
                                alt="detail_image"
                              />
                            </div>
                          ))
                      )}
                    </div>
                  </div>
                  <div className="mt-6 text-textPrimary">
                    <h1 className="md:text-base text-sm">Detail Images</h1>
                    <div className="grid grid-cols-3 md:gap-3 gap-2 justify-center h-auto">
                      {dataVariant.description_image_path.length <= 0 ? (
                        <h1 className="my-5">No images uploaded</h1>
                      ) : (
                        dataVariant.description_image_path
                          ?.split(",")
                          .map((el: string | undefined) => (
                            <div className="flex justify-start my-2">
                              <img
                                className="w-full rounded-md shadow-md"
                                src={el}
                                alt="detail_image"
                              />
                            </div>
                          ))
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Variant information */}
            <div className="md:w-custom w-full md:mt-0 mt-10 h-full">
              <div className="rounded-md rounded-b-none border-2 border-stroke">
                {/*Variant information title*/}
                <div className="md:h-16 h-14 flex items-center justify-between md:mx-8 mx-5">
                  <h1 className="font-medium md:text-xl text-sm">
                    Variant Information
                  </h1>
                </div>
                <hr />

                <div className="md:mx-8 mx-5 md:mt-5 mt-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Variant
                  </h1>
                  <Input
                    required={true}
                    type={"text"}
                    name={"label"}
                    placeholder={"Place holder"}
                    handleChange={handleChangeInput}
                  />
                </div>
                <div className="md:flex w-full">
                  <div className="md:mx-8 mx-5 my-4 md:w-1/2">
                    <div>
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Product Price
                      </h1>
                      <Input
                        type={"number"}
                        name={"price"}
                        required={true}
                        placeholder={"Place holder"}
                        handleChange={handleChangeInput}
                      />
                    </div>
                    <div className="md:mt-4 mt-3">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Stock
                      </h1>
                      <Input
                        disable={preOrder ? true : false}
                        type={"number"}
                        name={"stock"}
                        value={newVariant.stock}
                        required={true}
                        placeholder={"Place holder"}
                        handleChange={handleChangeInput}
                      />
                    </div>
                    <div className="md:mt-4 mt-3">
                      <div className="md:mt-4 mt-48">
                        <h1 className="md:text-base text-sm text-textPrimary">
                          Pre Order
                        </h1>
                        <div className="mt-3 flex">
                          <Toggle
                            input={!preOrder}
                            handleClick={handlePreOrder}
                          />
                          <h1 className="font-rom md:text-base text-sm ml-4 text-textPrimary">
                            {preOrder ? "Enabled" : "Disabled"}
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="md:mx-8 mx-5 md:mt-4 -mt-60 md:w-1/2 ">
                    <div className="flex justify-between">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Discount Price
                      </h1>
                      <div className="flex items-center">
                        <Toggle
                          input={!activeDiscount}
                          handleClick={() => {
                            setActiveDiscount(!activeDiscount);
                          }}
                        />
                        <h1 className="font-rom md:text-base text-sm ml-4 text-textPrimary">
                          {activeDiscount ? "Enabled" : "Disabled"}
                        </h1>
                      </div>
                    </div>
                    <Input
                      disable={!activeDiscount}
                      type={"number"}
                      name={"discount_price"}
                      placeholder={"Place holder"}
                      handleChange={handleChangeInput}
                    />
                    <div className="md:mt-4 mt-3">
                      <h1 className="md:text-base text-sm text-textPrimary">
                        Product Weight (Gr)
                      </h1>
                      <Input
                        type={"number"}
                        name={"weight"}
                        required={true}
                        placeholder={"Place holder"}
                        handleChange={handleChangeInput}
                      />
                    </div>
                    {preOrder ? (
                      <div className="md:mt-4 mt-20 mb-8">
                        <h1 className="md:text-base text-sm text-textPrimary">
                          Pre-Order Stock
                        </h1>
                        <Input
                          required={true}
                          type={"number"}
                          name={"pre_order_stock"}
                          placeholder={"Place holder"}
                          handleChange={handleChangeInput}
                        />
                      </div>
                    ) : (
                      <div className="mt-7 mb-7 h-20"></div>
                    )}
                  </div>
                </div>
              </div>
              <Save handleReset={handleClear} load={mutation.isLoading} />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default AddVariant;
