import RetailUserRepository from "../../core/retail_user/repository/repository";
import RetailUserUsecase from "../../core/retail_user/usecase/usecase";

export class RetailUserService {
  static getRetailUser = async () => {
    try {
      const retailUserRepo = new RetailUserRepository();
      const retailUserUsecase = new RetailUserUsecase(retailUserRepo);
      const retailUser = await retailUserUsecase.getRetailUser();
      return retailUser;
    } catch (error) {
      console.log(error);
    }
  };
}
