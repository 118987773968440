import ErrorHandler from "../../component/toast/ErrorHandler";
import axiosInstance from "../../config/axiosInstance";

export class ProductRequest {
  static addProductVariant = async (data: any) => {
    try {
      let payload: any = { ...data };
      if (payload.discount_price > 0) {
        payload["price"] = +payload.discount_price;
        payload["display_price"] = +data.price;
      }
      delete payload.discount_price;
      delete payload.active_discount_price;
      const result = await axiosInstance().post(
        "retail/retail-product-stock",
        payload
      );
      ErrorHandler("#00C62E", "Add Variant Completed");
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          `${Object.keys(error.response.data.errors)[0]
            .split("_")
            .join(" ")}: ${error.response.data.errors[
            Object.keys(error.response.data.errors)[0]
          ]
            .split("_")
            .join(" ")}`
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Add Failed");
      }
    }
  };

  static editProductVariant = async (data: any, id: string) => {
    try {
      let payload: any = { ...data };
      if (payload.discount_price > 0) {
        payload["price"] = +payload.discount_price;
        payload["display_price"] = +data.price;
      }
      delete payload.discount_price;
      delete payload.active_discount_price;
      const result = await axiosInstance().patch(
        `retail/retail-product-stock/${id}`,
        payload
      );
      ErrorHandler("#00C62E", "Edit Variant Completed");
      return result;
    } catch (error: any) {
      if (typeof error.response.data.errors === "object") {
        ErrorHandler(
          "#ed4846",
          `${Object.keys(error.response.data.errors)[0]
            .split("_")
            .join(" ")}: ${error.response.data.errors[
            Object.keys(error.response.data.errors)[0]
          ]
            .split("_")
            .join(" ")}`
        );
      } else {
        ErrorHandler("#ed4846", error.response.data.errors || "Add Failed");
      }
    }
  };
}
