import _ from "lodash";
import AxiosInstance from "../../../config/Instance";
import {
  IAddressCity,
  IAddressDistrict,
  IAddressProvince,
  IAddressSubdistrict,
} from "../entities";
import IAddressRepository from "../repository";

export default class AddressRepository implements IAddressRepository {
  // api routes
  private ADDRESS_BASE_ROUTE_FOR_RETAIL = "/retail/shipping-address";

  private getError(error: unknown) {
    return _.get(error, "response.data.errors", "Something went wrong!");
  }

  getParams(filter: any) {
    const params = _.pickBy(
      {
        ...filter,
      },
      (val) => val
    );
    return params;
  }

  async getAddressProvince(filter: {
    province_name?: string;
  }): Promise<IAddressProvince[]> {
    try {
      const response = await AxiosInstance.Celestine().get(
        `${this.ADDRESS_BASE_ROUTE_FOR_RETAIL}/province`,
        {
          params: {
            ...this.getParams(filter),
            limit: 100,
            offset: 0,
            sort: "name asc",
          },
        }
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw error;
    }
  }

  getAddressCity = async (filter: {
    province_id: string;
    city_name?: string;
  }): Promise<IAddressCity[]> => {
    try {
      const response = await AxiosInstance.Celestine().get(
        `${this.ADDRESS_BASE_ROUTE_FOR_RETAIL}/city`,
        {
          params: {
            ...this.getParams(filter),
            limit: 100,
            offset: 0,
            sort: "name asc",
          },
        }
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw error;
    }
  };

  getAddressDistrict = async (filter: {
    city_id: string;
    district_name?: string;
  }): Promise<IAddressDistrict[]> => {
    try {
      const response = await AxiosInstance.Celestine().get(
        `${this.ADDRESS_BASE_ROUTE_FOR_RETAIL}/district`,
        {
          params: {
            ...this.getParams(filter),
            limit: 100,
            offset: 0,
            sort: "name asc",
          },
        }
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw error;
    }
  };

  getAddressSubdistrict = async (filter: {
    district_id: string;
    sub_district_name: string;
  }): Promise<IAddressSubdistrict[]> => {
    try {
      const response = await AxiosInstance.Celestine().get(
        `${this.ADDRESS_BASE_ROUTE_FOR_RETAIL}/sub-district`,
        {
          params: {
            ...this.getParams(filter),
            limit: 100,
            offset: 0,
            sort: "name asc",
          },
        }
      );
      const { data } = response;
      return data;
    } catch (error) {
      throw error;
    }
  };
}
