import React, { useReducer } from "react";
import { Route, Routes } from "react-router-dom";
import {
  AddProduct,
  AddVariant,
  EditProduct,
  EditVariant,
  ProductDetail,
  Products,
  ProductVariant,
  ProductVariantDetail,
} from "../../pages/admin/products";
import {
  initialTicketState,
  TicketContextProvider,
} from "../../store/ticket/context";
import { TicketReducer } from "../../store/ticket/reducer";

const ProductRoute = () => {
  const [TicketState, TicketDispatch] = useReducer(
    TicketReducer,
    initialTicketState
  );

  return (
    <TicketContextProvider value={{ TicketState, TicketDispatch }}>
      <Routes>
        <Route path="/" element={<Products />} />
        <Route path="add-product" element={<AddProduct />} />
        <Route path=":id" element={<ProductDetail />} />
        <Route
          path=":id/edit-product"
          element={<EditProduct />}
        />
        <Route
          path=":id/stock-product"
          element={<ProductVariant />}
        />
        <Route
          path=":id/stock-product/add-variant"
          element={<AddVariant />}
        />
        <Route
          path=":id/stock-product/:detail/edit-variant"
          element={<EditVariant />}
        />
        <Route
          path=":id/stock-product/:detail"
          element={<ProductVariantDetail />}
        />
      </Routes>
    </TicketContextProvider>
  );
};

export default ProductRoute;
