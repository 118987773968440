import moment from "moment";
import { useContext } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { TicketRequest } from "../../../../../../api/ticket/Ticket";
import { back, notify, scan } from "../../../../../../assets/icons";
import Loading from "../../../../../../component/loading/Loading";
import { ActionType } from "../../../../../../store/ticket/action";
import TicketContext from "../../../../../../store/ticket/context";
import DeleteTicketVariantModal from "../../stock_ticket_component/DeleteTicketVariantModal";
import ScanQrModal from "./ScanQrModal";

const VariantTransactionDetail: React.FC = () => {
  const { TicketDispatch, TicketState } = useContext(TicketContext);
  const { showDeleteTicketVariant, showScanQr } = TicketState;
  const navigate = useNavigate();
  const params = useParams();

  const goBack = () => {
    navigate(
      `/admin/ticket/${params.id}/stock-ticket/${params.variantId}/variant-transaction`
    );
  };

  const goDetail = (id: number) => {
    navigate(
      `/admin/ticket/${params.id}/stock-ticket/${params.variantId}/variant-transaction/${id}`
    );
  };

  const { data, isLoading } = useQuery("getVariantTransactionByCode", () =>
    TicketRequest.getVariantTransactionByCode(params.detailId || "")
  );

  return (
    <div className="mb-28 font-med">
      {showDeleteTicketVariant.status && <DeleteTicketVariantModal />}
      {showScanQr.status && <ScanQrModal />}
      <div className="md:pt-28 pt-20 overflow-auto">
        <div onClick={goBack} className="flex items-center">
          <img src={back} alt="back" />
          <h1 className="text-mobile ml-1.5">Back</h1>
        </div>

        {isLoading ? (
          <div className="flex justify-center mt-10">
            <Loading />
          </div>
        ) : (
          <div>
            <div className="mt-12 flex justify-center">
              <img
                className="rounded-md shadow-md w-56"
                src={data?.image_url}
                alt=""
              />
            </div>
            <div className="px-5 mt-12">
              <div>
                <h1 className="text-mobile">Transaction Code</h1>
                <div className="flex items-center border-2 mt-2 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">{data.invoice_code}</h1>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-mobile">Customer</h1>
                <div className="flex items-center border-2 mt-2 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">
                    {data.username || "-"}
                  </h1>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-mobile">Status</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  <h1
                    className={
                      "text-sm ml-1 " +
                      (data.purchase_status === "success"
                        ? "text-success"
                        : "text-primary")
                    }
                  >
                    {data.purchase_status}
                  </h1>
                </div>
              </div>

              <div className="mt-5">
                <h1 className="text-mobile">Valid (from)</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">
                    {moment(data.valid_time_from).format("LL hh:mm")}
                  </h1>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-mobile">Valid (Until)</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">
                    {moment(data.valid_time_until).format("LL hh:mm")}
                  </h1>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-mobile">Release Date</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">
                    {moment(data.release_date).format("LL hh:mm")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <footer className="h-24 py-3 px-10 flex items-center w-full left-0 bottom-0 bg-white shadow-inner fixed">
        <div
          //   onClick={() => goDetail(data.id)}
          className="w-3/4 h-14 flex items-center justify-center bg-primary shadow-md rounded"
        >
          <h1 className="text-sm text-white">Details</h1>
        </div>
        <div className="w-1/4 flex justify-center">
          <div className="rounded-full w-14 h-14 bg-breadscrumbColor flex justify-center items-center">
            <img src={notify} alt="-" className="w-7 h-7" />
          </div>
        </div>
        <div
          onClick={() =>
            TicketDispatch({
              type: ActionType.ShowScanQr,
              payload: {
                id: data.id,
                status: true,
              },
            })
          }
          className="w-1/4 flex justify-center"
        >
          <div className="rounded-full w-14 h-14 bg-breadscrumbColor flex justify-center items-center">
            <img src={scan} alt="-" className="w-7 h-7" />
          </div>
        </div>
      </footer>
    </div>
  );
};

export default VariantTransactionDetail;
