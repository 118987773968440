import React, { Suspense } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { breadcrumbnext } from "../../assets/icons";
import Abutton from "../../components/atoms/Abutton";
import MproductListCard from "../../components/molecules/MproductListCard";
import { renderStatusColor } from "../../components/molecules/MtransactionCard";
import { ERetailTransactionStatus } from "../../core/retail_transaction/entities";
import { useFetchRetailTransaction } from "../../hooks/retail_transaction";
import { formatDate, formatRupiah } from "../../helpers/common";
import { Aloading } from "../../components/atoms/Aloading";

const TransactionProductMobile = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const params = useParams();
  const { data } = useFetchRetailTransaction(params?.code || "");

  const goBack = () => {
    navigate(-1);
  };

  const goTrackOrder = () => {
    navigate(`${pathname.replace("detail", "track")}`);
  };

  return (
    <Suspense
      fallback={
        <div className="w-full flex justify-center mt-20">
          <Aloading type="Bars" />
        </div>
      }
    >
      {data && (
        <main className="text-textPrimary text-xs">
          <div onClick={goBack} className="flex gap-1">
            <img src={breadcrumbnext} alt="-" className="w-2" />
            <span className="text-xs">Back</span>
          </div>
          <hr className="mt-3" />
          {data.status === ERetailTransactionStatus.BuyerComplain && (
            <article className="mt-3">
              <h1 className="text-black">Complain</h1>
              {/* <hr className="mt-4" />
                    <h1 className="my-4 text-textPrimary">Photo or videos</h1>
                    <div className="grid grid-cols-10 my-5">
                      {data.retail_transaction_complain.image_path ? (
                        data.retail_transaction_complain.image_path
                          .split(",")
                          .map((el, idx) => <img key={idx} src={el} alt="-" />)
                      ) : (
                        <h1 className="text-xs text-textSecondary col-span-2">No photo or videos uploaded</h1>
                      )}
                    </div> */}
              <hr className="mt-3" />
              <h1 className="my-3 text-textPrimary">Message</h1>
              {/* <TextArea
                  name="text"
                  value={data.retail_transaction_complain.message}
                /> */}
            </article>
          )}
          <section className="mt-4">
            <article>
              <h1 className="text-xs">{data?.code}</h1>
              <aside className="mt-3">
                <div className="flex justify-between font-rom">
                  <h1 className="text-textSecondary">Transaction Date</h1>
                  <h1>{formatDate(data?.created_at, "lll")}</h1>
                </div>
                <div className="flex justify-between font-rom mt-1">
                  <h1 className="text-textSecondary">Status</h1>
                  <h1 className={"font-med " + renderStatusColor(data.status)}>
                    {data.status_message}
                  </h1>
                </div>
              </aside>
            </article>
            <hr className="mt-3" />
            <article className="mt-4 font-rom">
              <div className="flex">
                <div className="w-2/5">
                  <h1 className="text-textSecondary">Courier (Must Same)</h1>
                </div>
                <div className="w-3/5">
                  <h1>: {data.shipping_provider}</h1>
                  <h1 className="ml-1 text-textSecondary">
                    {formatRupiah(data.buyer_shipping_cost)}
                  </h1>
                </div>
              </div>
              <div className="flex mt-1">
                <div className="w-2/5">
                  <h1 className="text-textSecondary">Address</h1>
                </div>
                <div className="w-3/5">
                  <h1>
                    : {data.buyer_shipping_address.recipient} |{" "}
                    {data.buyer_shipping_address.phone}
                  </h1>
                  <h1 className="ml-1">
                    {data.buyer_shipping_address.address}
                  </h1>
                </div>
              </div>
            </article>
            <hr className="mt-3" />
            <article className="mt-3">
              <div>
                <h1>
                  Customer :{" "}
                  <span className="font-rom">{data.buyer_username}</span>
                </h1>
              </div>
              <hr className="my-2" />
              <div className="flex mt-5">
                <div className="w-2/3">
                  <MproductListCard
                    image={data.retail_product_detail.display_image_path}
                    name={data.retail_product_detail.name}
                    variant={
                      data.retail_product_detail.retail_product_stock.label
                    }
                    quantity={data.quantity}
                  />
                </div>
                <div className="w-1/3 font-rom self-end flex justify-end pb-3">
                  <h1>{formatRupiah(data.price)}</h1>
                </div>
              </div>
              <hr className="mt-4" />
            </article>
            <article className="font-rom flex mt-3">
              <div className="w-2/3 flex justify-start flex-col gap-2 border-r">
                <span>Total Order</span>
                <span>Shipping Fee</span>
                <span className="font-med">Total Selling</span>
              </div>
              <div className="w-1/3 flex flex-col items-end gap-2">
                <span>{formatRupiah(data.price)}</span>
                <span>{formatRupiah(data.buyer_shipping_cost)}</span>
                <span className="font-med text-primary">
                  {formatRupiah(data.total)}
                </span>
              </div>
            </article>
          </section>

          <aside className="fixed bottom-0 left-0 w-full shadow-inner flex justify-center bg-white px-6 py-3">
            <Abutton title="Track Order" model="full" onClick={goTrackOrder} />
          </aside>
        </main>
      )}
    </Suspense>
  );
};

export default TransactionProductMobile;
