import React, { useState } from "react";
import { useQueryClient } from "react-query";
import Newfilepond from "../../../../component/filepond/Newfileponds";
import ErrorHandler from "../../../../component/toast/ErrorHandler";
import axiosInstance from "../../../../config/axiosInstance";

interface IProps {
  detailData: any;
}

const BackgroundImage: React.FC<IProps> = ({ detailData }) => {
  const [imgLandscape, setImgLandscape] = useState<any>(null);
  const [imgPortrait, setImgPortrait] = useState<any>(null);
  const queryClient = useQueryClient();

  const handleUpload = async () => {
    const data = new FormData();

    if (!imgPortrait && !imgLandscape) {
      ErrorHandler("#DF5354", "Please add image");
    } else {
      if (imgPortrait !== null) {
        data.append("background_image_portrait", imgPortrait);
      }
      if (imgLandscape !== null) {
        data.append("background_image_landscape", imgLandscape);
      }
      try {
        await axiosInstance().patch("retail/retail-shop", data);
        ErrorHandler("#00C62E", "Add Success");
        queryClient.invalidateQueries("retail-shop");
        queryClient.invalidateQueries("getDetailProfile");
        const removeFile: any = document.getElementsByClassName(
          "filepond--file-action-button filepond--action-remove-item"
        );
        removeFile[0]?.click();
        removeFile[1]?.click();
        setImgPortrait(null);
        setImgPortrait(null);
      } catch (error: any) {
        if (typeof error?.response?.data?.errors === "object") {
          ErrorHandler(
            "#ed4846",
            error.response.data.errors[
              Object.keys(error?.response?.data?.errors)[0]
            ]
              .split("_")
              .join(" ")
          );
        } else {
          ErrorHandler("#ed4846", error.response.data.errors || "Add Failed");
        }
      }
    }
  };

  return (
    <div>
      <aside className="flex justify-end">
        <h1
          onClick={handleUpload}
          className="md:text-lg text-base cursor-pointer hover:text-primary"
        >
          Save
        </h1>
      </aside>
      <div className="w-full h-full my-8 md:flex justify-between">
        <div className="md:w-1/2 md:mr-12">
          <div className="w-full h-full rounded-md border-2 border-stroke">
            <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
              <h1 className="font-medium md:text-xl text-base">
                Landscape Background
              </h1>
            </div>
            <hr />

            <div className="md:mx-8 mx-5 my-7">
              <div className="md:w-10/12 w-full">
                <img
                  src={detailData?.background_image_landscape_path}
                  alt=""
                  className="shadow-md rounded-md w-full"
                />
                <h1 className="md:text-base text-sm text-textPrimary mt-3">
                  Landscape Background
                </h1>
                <h1 className="font-rom md:text-sm text-xs">
                  (16 x 9), (PNG, JPG, JPEG) max. 1 MB
                </h1>
                <Newfilepond
                  setFiles={setImgLandscape}
                  imageType={["image/png", "image/jpg", "image/jpeg"]}
                  cropRatio={"16:9"}
                  maxFileSize={"1mb"}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="md:w-1/2 md:mt-0 mt-10">
          <div className="w-full h-full rounded-md border-2 border-stroke">
            <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
              <h1 className="font-medium md:text-xl text-base">
                Portrait Background
              </h1>
            </div>
            <hr />

            <div className="md:mx-8 mx-5 my-7 flex">
              <div className="w-1/2 flex justify-center items-center">
                <img
                  src={detailData?.background_image_portrait_path}
                  alt=""
                  className="shadow-md rounded-md w-11/12"
                />
              </div>
              <div className="w-1/2 ml-5">
                <h1 className="md:text-base text-sm text-textPrimary">
                  Portrait Background
                </h1>
                <h1 className="font-rom md:text-sm text-xs">
                  (9 x 16), (PNG, JPG, JPEG) max. 1 MB
                </h1>
                <div className="">
                  <Newfilepond
                    setFiles={setImgPortrait}
                    imageType={["image/png", "image/jpg", "image/jpeg"]}
                    cropRatio={"9:16"}
                    maxFileSize={"1mb"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BackgroundImage;
