import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";
import { UseOutsideClick } from "../../helpers/useOutsideClick";
import { ActionType } from "../../store/transaction_product/action";
import TransactionProductContext from "../../store/transaction_product/context";
import Abutton from "../../components/atoms/Abutton";
import {
  useFetchRetailTransactionCounter,
  useFetchRetailTransactions,
} from "../../hooks/retail_transaction";
import {
  IRetailTransaction,
  listFilter,
  listSort,
} from "../../core/retail_transaction/entities";
import { getTransaction } from "./helper";
import { formatDate } from "../../helpers/common";
import { useQueries } from "../../helpers/QuerySearchParams";
import generateCSV from "../../utils/generateCSV";
import { Aloading } from "../../components/atoms/Aloading";
import {
  applyDateFilter,
  handleChangeSort,
  handleSearchCode,
  resetDateFilter,
} from "../../helpers/helper";
import Mpagination from "../../components/molecules/Mpagination";
import { defineds } from "../../utils/date";
import AdateRangePicker, {
  checkLabelColor,
} from "../../components/atoms/AdateRangePicker";
import AfilterDateInput from "../../components/atoms/AfilterDateInput";
import MfilterTransactionStatus from "../../components/molecules/MfilterTransactionStatus";
import MfilterGeneral from "../../components/molecules/MfilterGeneral";
import MtransactionCard from "../../components/molecules/MtransactionCard";

const TransactionProduct = () => {
  const query = useQueries(useLocation);
  const { search } = useLocation();
  const navigate = useNavigate();
  const filterRef = useRef(null);
  const { TransactionProductDispatch, TransactionProductState } = useContext(
    TransactionProductContext
  );
  const { showFilter } = TransactionProductState;

  const [load, setLoad] = useState(false);
  const [date, setDate] = useState([
    {
      startDate: new Date(
        query.get("date")?.split(",")[0] || formatDate(defineds.startOfMonth)
      ),
      endDate: new Date(
        query.get("date")?.split(",")[1] || formatDate(defineds.endOfMonth)
      ),
      key: "selection",
    },
  ]);
  const [sort, setSort] = useState<
    SingleValue<{ label: string; value: string }>
  >(listSort[0]);
  const [searchCode, setSearchCode] = useState("");

  const { data, isLoading, isError } = useFetchRetailTransactions({
    startDate: formatDate(defineds.startOfMonth),
    endDate: formatDate(defineds.endOfMonth),
  });
  const { data: dataCounter, isLoading: loadCounter } =
    useFetchRetailTransactionCounter({
      startDate: formatDate(defineds.startOfMonth),
      endDate: formatDate(defineds.endOfMonth),
    });

  const handleShowFilter = () => {
    if (showFilter) {
      setTimeout(() => {
        TransactionProductDispatch({
          type: ActionType.ShowFilter,
        });
      }, 300);
    } else {
      TransactionProductDispatch({
        type: ActionType.ShowFilter,
      });
    }
  };
  UseOutsideClick(filterRef, handleShowFilter);

  useEffect(() => {
    const sort = query.get("sort");
    const search = query.get("code");
    if (sort) {
      listSort.map((el) => (el.value === sort ? setSort(el) : ""));
    }
    if (search) {
      setSearchCode(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showFilter) {
      checkLabelColor(date);
    }
  }, [date, showFilter]);

  const exportToCsv = async () => {
    let offset = 0;
    let limit = data && data?.[1]?.total < 300 ? data?.[1].total : 300;
    const datacsv: IRetailTransaction[] = [];
    while (data && offset < data?.[1].total) {
      const data = [...(await getTransaction(limit, offset, setLoad, date))];
      await datacsv.push(...data);
      if (!load) {
        offset += limit;
      }
    }
    generateCSV(
      datacsv,
      `transaction-product-(${formatDate(
        date[0].startDate,
        "DD/MM/YYYY"
      )}-${formatDate(date[0].endDate, "DD/MM/YYYY")})`
    );
  };

  if (isError) {
    return <h1>Something went wrong! Please refresh the page</h1>;
  } else if (load) {
    return (
      <div className="z-50 h-4/5 w-3/4 overflow-hidden flex justify-center items-center fixed">
        <Aloading type="TailSpin" height={50} width={50} />
      </div>
    );
  }

  return (
    <main className="w-full">
      <section>
        <aside>
          <article>
            <h1 className="font-Bold sm:text-base text-sm">
              Transaction (Product)
            </h1>
          </article>

          <article className="sm:flex hidden justify-between items-center mt-2">
            <AfilterDateInput
              showFilter={showFilter}
              handleShowFilter={handleShowFilter}
              date={date}
              handleReset={() => resetDateFilter({ navigate, search, setDate })}
              applyFilter={() => applyDateFilter({ date, navigate, search })}
            />

            <div>
              <Abutton
                onClick={exportToCsv}
                title="Export CSV"
                theme="secondary"
              />
            </div>

            {showFilter && (
              <div ref={filterRef} className="fixed z-50 my-3 top-40">
                <AdateRangePicker
                  date={date}
                  handleChangeDate={(item) => setDate([item.selection])}
                />
              </div>
            )}
          </article>
        </aside>
      </section>
      <section>
        <section className="mt-3 sm:bg-white sm:rounded-lg sm:shadow-md w-auto top-[4.2rem] sticky">
          <aside className="flex overflow-x-auto">
            {listFilter.map((el, idx) => (
              <MfilterTransactionStatus
                key={idx}
                item={el}
                counter={dataCounter || []}
              />
            ))}
          </aside>
          <MfilterGeneral
            sort={sort}
            handleChangeSort={(
              sort: SingleValue<{ label: string; value: string }>
            ) => handleChangeSort({ navigate, search, setSort, sort })}
            listSort={listSort}
            search={searchCode}
            setSearch={setSearchCode}
            handleSearch={() =>
              handleSearchCode({ search, searchCode, navigate })
            }
          />
        </section>

        {isLoading || loadCounter ? (
          <div className="w-full flex justify-center mt-20">
            <Aloading type="Bars" />
          </div>
        ) : data?.[0] && data?.[0].length > 0 ? (
          <section className="sm:mt-5 mt-8 pb-10">
            <div className="flex flex-col gap-y-5">
              {data?.[0].map((item: IRetailTransaction, idx) => (
                <MtransactionCard status={item.status} key={idx} data={item} />
              ))}
            </div>
            <div className="my-5">
              <Mpagination
                length={data?.[0].length || 0}
                total={data?.[1].total || 0}
                isTable={false}
              />
            </div>
          </section>
        ) : (
          <div className="flex w-full justify-center sm:my-20 my-10 text-textPrimary sm:text-base text-xs">
            Data Not Found.
          </div>
        )}
      </section>
    </main>
  );
};

export default TransactionProduct;
