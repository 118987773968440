import AddressRepository from "../../core/address/repository/repository";
import AddressUsecase from "../../core/address/usecase/usecase";

export class AddressService {
  static getAddressProvince = async (province_name?: string) => {
    try {
      const addressRepo = new AddressRepository();
      const addressUsecase = new AddressUsecase(addressRepo);
      const address = await addressUsecase.getAddressProvince({
        province_name,
      });
      return address;
    } catch (error) {
      console.log(error);
    }
  };

  static getAddressCity = async (province_id: string, city_name?: string) => {
    try {
      const addressRepo = new AddressRepository();
      const addressUsecase = new AddressUsecase(addressRepo);
      const address = await addressUsecase.getAddressCity({
        city_name,
        province_id,
      });
      return address;
    } catch (error) {
      console.log(error);
    }
  };

  static getAddressDistrict = async (
    city_id: string,
    district_name?: string
  ) => {
    try {
      const addressRepo = new AddressRepository();
      const addressUsecase = new AddressUsecase(addressRepo);
      const address = await addressUsecase.getAddressDistrict({
        district_name,
        city_id,
      });
      return address;
    } catch (error) {
      console.log(error);
    }
  };

  static getAddressSubdistrict = async (
    sub_district_name: string,
    district_id: string
  ) => {
    try {
      const addressRepo = new AddressRepository();
      const addressUsecase = new AddressUsecase(addressRepo);
      const address = await addressUsecase.getAddressSubdistrict({
        sub_district_name,
        district_id,
      });
      return address;
    } catch (error) {
      console.log(error);
    }
  };
}
