import React from "react";
import axios from "../../../../../../config/axiosInstance";
import { cancel } from "../../../../../../assets/icons";
import { useNavigate, useParams } from "react-router-dom";
import ErrorHandler from "../../../../../../component/toast/ErrorHandler";
import { useQueryClient } from "react-query";

interface DeleteProps {
  id: number | null;
  setDeleteProduct: (deleteProduct: boolean) => void;
  deleteProduct: boolean;
}

const DeleteProductModal: React.FC<DeleteProps> = ({
  deleteProduct,
  id,
  setDeleteProduct,
}) => {
  const navigate = useNavigate();
  const params = useParams();

  const goBack = () => {
    id ? setDeleteProduct(!deleteProduct) : navigate("/admin/products");
  };

  const query = useQueryClient();
  const goDelete = async () => {
    try {
      const result = await axios().delete(
        `retail/retail-product/${id ? id : params.id}`
      );
      if (id) {
        setDeleteProduct(!deleteProduct);
      } else {
        goBack();
      }
      query.invalidateQueries("products");
      ErrorHandler("#00C62E", "Success delete product");
      return result;
    } catch (error) {
      ErrorHandler("#ed4846", "Something went wrong!");
    }
  };

  return (
    <div className="w-full h-screen bg-black bg-opacity-80 z-50 fixed inset-0 flex justify-center items-center">
      <div className="mx-10 md:py-8 py-5 md:px-10 px-5 bg-white rounded-xl shadow-md">
        <div className="flex justify-end">
          <img
            src={cancel}
            alt="cancel"
            onClick={goBack}
            className="cursor-pointer md:w-5 w-3"
          />
        </div>
        <div className="flex flex-col items-center mt-6">
          <h1 className="md:text-xl text-base font-medium">Delete Product ?</h1>
          <h1 className="md:text-base font-rom text-sm text-breadscrumbColor mt-4 md:px-20 px-5 text-center">
            Delete product make your item deleted
          </h1>
        </div>
        <div className="flex justify-evenly md:mt-12 mt-8 md:px-16 px-4">
          <button
            onClick={goBack}
            className="bg-primary md:w-32 md:h-10 w-24 h-8 rounded-full focus:outline-none hover:bg-opacity-60"
          >
            <h1 className="text-white md:text-base text-sm">Close</h1>
          </button>
          <button
            onClick={goDelete}
            className="md:w-32 md:h-10 w-24 md:ml-4 h-8 rounded-full border-2 border-breadscrumbColor focus:outline-none hover:bg-breadscrumbColor hover:bg-opacity-50"
          >
            <h1 className="text-breadscrumbColor  hover:text-white md:text-base text-sm">
              Continue
            </h1>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeleteProductModal;
