import { useState } from "react";
import axios from "../../../../../config/axiosInstance";

// icons
import { cancel } from "../../../../../assets/icons";
import Input from "../../../../../component/input/Input";
import ErrorHandler from "../../../../../component/toast/ErrorHandler";
import { useQueryClient } from "react-query";

export interface PasswordModalProps {
  setModal: (modal: boolean) => void;
  modal: boolean;
  bank: any;
  setBank: void | any;
}

const PasswordModal: React.FC<PasswordModalProps> = ({
  setModal,
  modal,
  setBank,
  bank,
}) => {
  const queryClient = useQueryClient();
  const [errorBank, setErrorBank] = useState<boolean>(false);
  const goBack = () => {
    setModal(!modal);
  };

  const handleInputChange = (e: any) => {
    const target = e.target;
    setBank({ ...bank, [target.name]: target.value });
  };

  const goSave = async () => {
    if (bank.bank_name.length < 1) {
      delete bank.bank_name;
    }
    if (bank.bank_number.length < 1) {
      delete bank.bank_number;
    }
    if (bank.bank_owner.length < 1) {
      delete bank.bank_owner;
    }
    delete bank.amount;
    try {
      await axios()
        .patch(`retail/retail-shop`, bank)
        .then((data) => {
          setModal(!modal);
          queryClient.invalidateQueries("retail-shop");
          ErrorHandler("#00C62E", "Success updated Bank information");
        })
        .catch((err) => {
          setErrorBank(true);
        })
        .finally(() => {
          setBank({
            ...bank,
            password: "",
          });
        });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="w-full min-h-full bg-black bg-opacity-80 z-40 flex justify-center items-center inset-0 overflow-hidden fixed">
      <div className="md:w-WModal md:h-Hmodal md:py-6 py-4 bg-white rounded-xl shadow-md">
        <div className="flex justify-between md:px-10 px-5">
          <h1 className="md:text-lg flex text-primary">
            {" "}
            <h1 className="text-black mr-1.5">Change</h1>Information
          </h1>
          <img
            src={cancel}
            alt="cancel"
            onClick={goBack}
            className="cursor-pointer md:w-5 w-3"
          />
        </div>
        <hr className="my-5" />
        <div className="flex flex-col mt-8 md:px-10 px-5">
          <h1 className="md:text-base text-sm font-medium text-textPrimary">
            Please write your 'Password'
          </h1>
          <Input
            type={"password"}
            placeholder={"Input your password here"}
            handleChange={handleInputChange}
            name={"password"}
          />
          {errorBank ? (
            <h1 className="text-xs font-rom text-primary -mt-1 mb-1">
              Incorrect password
            </h1>
          ) : null}
        </div>
        <div className="flex justify-center mt-6 px-20">
          <button
            onClick={goSave}
            className="bg-primary md:w-36 w-28 md:h-10 h-7 rounded-full focus:outline-none hover:bg-opacity-60"
          >
            <h1 className="text-white md:text-lg text-sm">Save</h1>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PasswordModal;
