import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { edit, deleteIcon } from "../../../../../../assets/icons";
import DeletedModal from "./modal/DeleteModal";
import { formatRupiah } from "../../../../../../utils/formatRupiah";

interface Data {
  id: number;
}

interface HeadCell {
  id: keyof Data | any;
  label: string;
  disablePadding: boolean;
}

const headCells: HeadCell[] = [
  { id: "Variant", label: "Variant", disablePadding: true },
  { id: "Price", label: "Price", disablePadding: true },
  { id: "Product Status", label: "Product Status", disablePadding: true },
  { id: "Stock", label: "Stock (Ready)", disablePadding: true },
  { id: "Pre Order", label: "Stock (Pre Order)", disablePadding: true },
  { id: "Sold", label: "Product Sold", disablePadding: true },
  { id: "Actions", label: "Actions", disablePadding: true },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes } = props;

  return (
    <TableHead>
      <TableRow className="h-24">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            className={classes.tableHead}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface IProps {
  data: [] | any;
  deleteProduct: boolean;
  setDeleteProduct: (deleteProduct: boolean) => void;
  successDelete: boolean;
  setSuccessDelete: (successDelete: boolean) => void;
  refetch: any;
}

const EnhancedTable: React.FC<IProps> = ({
  data,
  deleteProduct,
  setDeleteProduct,
  refetch,
  successDelete,
  setSuccessDelete,
}) => {
  const dataStock = data.retail_product_stocks;
  const params = useParams();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [deletedId, setDeletedId] = useState<number | null>(null);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditProduct = (id: number) => {
    navigate(`${pathname}/${id}/edit-variant`, { state: params });
  };

  const handleDeleteProduct = async (id: number) => {
    if (deleteProduct) {
      setDeletedId(null);
      setDeleteProduct(false);
    } else {
      setDeletedId(id);
      setDeleteProduct(true);
    }
  };

  return (
    <div className={classes.root}>
      {deleteProduct && (
        <DeletedModal
          id={deletedId}
          setDeleteProduct={setDeleteProduct}
          deleteProduct={deleteProduct}
        />
      )}
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead classes={classes} />
          <TableBody>
            {dataStock
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row: any, index: number) => {
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    className="h-36"
                  >
                    <TableCell
                      component="th"
                      id={labelId}
                      align="center"
                      scope="row"
                      className={classes.tableCell}
                    >
                      {row.label}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      <div>
                        <h1 className="font-med">
                          {formatRupiah(row?.price || "-")}
                        </h1>
                        <del>
                          {row.display_price !== null &&
                            formatRupiah(row?.display_price || "-")}
                        </del>
                      </div>
                    </TableCell>
                    {row.stock > 0 && row.is_pre_order === true ? (
                      <TableCell
                        align="center"
                        className={classes.tableCell_status_selling}
                      >
                        <div className="flex flex-col justify-center">
                          <h1>Ready Stock</h1>
                          <h1 className="text-black">(Allow Pre-Order)</h1>
                        </div>
                      </TableCell>
                    ) : row.stock > 0 && row.is_pre_order === false ? (
                      <TableCell
                        align="center"
                        className={classes.tableCell_status_selling}
                      >
                        <h1>Ready Stock</h1>
                      </TableCell>
                    ) : row.stock <= 0 && row.is_pre_order === true ? (
                      <TableCell
                        align="center"
                        className={classes.tableCell_status_waiting}
                      >
                        <h1>Pre Order</h1>
                      </TableCell>
                    ) : row.stock <= 0 &&
                      row.is_pre_order === false &&
                      row.pre_order_stock <= 0 ? (
                      <TableCell
                        align="center"
                        className={classes.tableCell_status_reject}
                      >
                        <h1>Out Of Stock</h1>
                      </TableCell>
                    ) : (
                      ""
                    )}
                    <TableCell align="center" className={classes.tableCell}>
                      {row.stock}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {row.pre_order_stock}
                    </TableCell>
                    <TableCell align="center" className={classes.tableCell}>
                      {row.sold_count}
                    </TableCell>
                    <TableCell
                      padding="none"
                      align="center"
                      className={classes.tableCell}
                    >
                      <div className="flex justify-evenly">
                        <img
                          data-tip
                          data-for="edit"
                          className="cursor-pointer w-6 h-6"
                          onClick={() => handleEditProduct(row.id)}
                          src={edit}
                          alt="img"
                        />
                        <ReactTooltip id="edit" type="dark">
                          <span>Edit</span>
                        </ReactTooltip>
                        <img
                          data-tip
                          data-for="Delete"
                          className="cursor-pointer w-6 h-6"
                          onClick={() => handleDeleteProduct(row.id)}
                          src={deleteIcon}
                          alt="img"
                        />
                        <ReactTooltip id="Delete" type="error">
                          <span>Delete</span>
                        </ReactTooltip>
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={dataStock?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        className="border-2 border-t-0 font-med border-stroke rounded-b-md h-20 flex justify-end"
      />
    </div>
  );
};

export default EnhancedTable;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: "3%",
      marginBottom: "3%",
    },
    table: {
      minWidth: 750,
    },
    searchInput: {
      width: "75%",
      padding: "0.5%",
      margin: "1% 10% 3% 0%",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    tableCell: {
      borderWidth: 3,
      borderColor: "#EFEFEF",
      fontSize: 14,
      width: "10%",
      fontFamily: "NeueHaasDisplay",
    },
    tableCell_status_selling: {
      borderWidth: 3,
      borderColor: "#EFEFEF",
      fontSize: 14,
      width: "10%",
      color: "#00C62E",
      fontFamily: "NeueHaasDisplayMediu",
    },
    tableCell_status_reject: {
      borderWidth: 3,
      borderColor: "#EFEFEF",
      fontSize: 14,
      width: "10%",
      color: "#DF5354",
      fontFamily: "NeueHaasDisplayMediu",
    },
    tableCell_status_waiting: {
      borderWidth: 3,
      borderColor: "#EFEFEF",
      fontSize: 14,
      width: "10%",
      color: "#FFBA00",
      fontFamily: "NeueHaasDisplayMediu",
    },
    tableHead: {
      borderWidth: 3,
      borderColor: "#EFEFEF",
      fontSize: 16,
      fontFamily: "NeueHaasDisplayMediu",
    },
  })
);
