import { ActionType, SubAccountAction } from "./action";
import { ISubAccountState } from "./state";

export const SubAccountReducer = (
  state: ISubAccountState,
  action: SubAccountAction
) => {
  switch (action.type) {
    case ActionType.ShowFilter:
      let showFilter = state.showFilter;
      document.body.classList[!showFilter ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showFilter: !showFilter };
    case ActionType.ShowDeleteSubAccount:
      let showDeleteSubAccount = action.payload;
      document.body.classList[showDeleteSubAccount.status ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showDeleteSubAccount };
    case ActionType.HandleChip:
      let new_chip = action.payload;
      return { ...state, chip: new_chip };
    case ActionType.ShowAddSubAccount:
      let showAddSubAccount = state.showAddSubAccount;
      document.body.classList[!showAddSubAccount ? "add" : "remove"](
        "h-screen",
        "overflow-hidden"
      );
      return { ...state, showAddSubAccount: !showAddSubAccount };
  }
};
