import { useContext } from "react";
import { useMutation, useQueryClient } from "react-query";
import { SubAccountRequest } from "../../../../api/sub_account/SubAccountRequest";
import { cancel } from "../../../../assets/icons";
import SmallButton from "../../../../component/button/Button";
import { ActionType } from "../../../../store/sub_account/action";
import SubAccountContext from "../../../../store/sub_account/context";

const DeleteAccountModal = () => {
  const { SubAccountDispatch, SubAccountState } = useContext(SubAccountContext);
  const { showDeleteSubAccount } = SubAccountState;

  const goBack = () => {
    SubAccountDispatch({
      type: ActionType.ShowDeleteSubAccount,
      payload: {
        id: 0,
        status: false,
      },
    });
  };

  const queryClient = useQueryClient();
  const mutation = useMutation(
    () => SubAccountRequest.deleteAccount(showDeleteSubAccount.id),
    {
      onSettled: async (data: any, error: any) => {
        if (error) {
          console.log(error);
        } else {
          await queryClient.invalidateQueries("getSubAccount");
          goBack();
        }
      },
    }
  );

  return (
    <div className="fixed z-50 h-screen inset-0 flex justify-center md:items-center items-start bg-black bg-opacity-70">
      <div className="bg-white lg:w-1/3 md:w-2/5 w-10/12 h-auto rounded-xl my-auto mx-auto font-med">
        <div className="flex w-full justify-end items-center px-8 pb-5 pt-8">
          <img
            src={cancel}
            alt="cancel"
            className="cursor-pointer w-5"
            onClick={goBack}
          />
        </div>
        <div className="px-10 pb-3">
          <div className="flex flex-col items-center">
            <h1 className="text-xl">Delete Account</h1>
            <h1 className="text-base text-center w-60 text-textPrimary mt-4 font-rom">
              Are you sure you want to delete this account ?
            </h1>
          </div>

          <div className="flex justify-center my-8 outline-none">
            <SmallButton
              loadColor={"white"}
              type={"button"}
              handleClick={goBack}
              color={"bg-white"}
              border={"border-2"}
              borderColor={"border-breadscrumbColor"}
              hoverColor={"opacity-70"}
              text={"Cancel"}
              textColor={"text-textPrimary"}
              height={10}
            />
            <div className="mx-1.5"></div>
            <SmallButton
              isSubmitting={mutation.isLoading}
              loadColor={"white"}
              type={"button"}
              height={10}
              handleClick={() => mutation.mutate()}
              color={"bg-primary"}
              hoverColor={"bg-red-300"}
              text={"Delete"}
              textColor={"text-white"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAccountModal;
