import React from "react";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import Abutton from "../../atoms/Abutton";
import ADialogBox from "../../atoms/ADialogBox";
import ATextField from "../../atoms/AtextField";
import * as Yup from "yup";
import { Divider } from "@material-ui/core";
import { IRetailTransaction } from "../../../core/retail_transaction/entities";
import { useMutateSendProduct } from "../../../hooks/retail_transaction";

interface IOgenerateShippingLabelProps {
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
  data: IRetailTransaction;
}

const OgenerateShippingLabel: React.FC<IOgenerateShippingLabelProps> = ({
  isOpen,
  setOpen,
  data,
}) => {
  const params = useParams();

  const { handleChange, handleSubmit, values, errors, touched } = useFormik({
    initialValues: {
      pickup_time: "",
      is_simulated: data.is_shipping_fee_charged ? false : true
    },
    validationSchema: Yup.object({
      pickup_time: Yup.string().required("Pickup time is required!"),
    }),
    onSubmit: (values) => {
      mutate(values);
    },
  });

  const { mutate, isLoading: loadSendproduct } = useMutateSendProduct(
    params?.code || "",
    setOpen
  );

  return (
    <ADialogBox
      content={{
        title: "Generate Shipping Label",
        body: (
          <form onSubmit={handleSubmit} className="flex flex-col">
            <Divider />
            <div className="flex flex-wrap mt-5 items-center justify-between">
              <h1>Courier :</h1>
              {data.is_shipping_fee_charged ? (
                <div className="w-2/3 mb-1">
                  <ATextField
                    type="text"
                    name="courier"
                    isDisabled
                    placeholder="Place Holder"
                    value={data.shipping_courier_name}
                  />
                </div>
              ) : (
                <div></div>
              )}
            </div>
            <div className="flex flex-wrap mt-4 items-center justify-between">
              <h1>Pickup Time :</h1>
              <div
                className={
                  "h-10 w-2/3 " +
                  (touched.pickup_time && errors.pickup_time ? "mb-5" : "mb-1")
                }
              >
                <ATextField
                  type="datetime-local"
                  name="pickup_time"
                  placeholder="Place Holder"
                  value={values.pickup_time}
                  handleChange={handleChange}
                  error={
                    touched.pickup_time && errors.pickup_time ? true : false
                  }
                  helperText={errors.pickup_time}
                />
              </div>
            </div>

            <div className="mt-7 flex gap-3 justify-end">
              <Abutton
                title="Cancel"
                theme="secondary"
                onClick={() => setOpen(false)}
              />
              <Abutton
                isLoading={loadSendproduct}
                type="submit"
                title="Confirm"
              />
            </div>
          </form>
        ),
      }}
      isOpen={isOpen}
      setIsOpen={setOpen}
    />
  );
};

export default OgenerateShippingLabel;
