import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { RetailTransactionTicketService } from "../../api/retail_transaction_ticket";
import { ERetailTransactionTicketSortField } from "../../core/retail_transaction_ticket/entities";
import { useQueries } from "../../helpers/QuerySearchParams";

const useFetchRetailTransactionTickets = (date: {
  startDate: string;
  endDate: string;
}) => {
  const query = useQueries(useLocation);
  return useQuery(
    [
      "RetailTransactionTickets",
      query.get("sort"),
      query.get("status"),
      query.get("code"),
      query.get("limit"),
      query.get("offset"),
      query.get("date"),
    ],
    () =>
      RetailTransactionTicketService.getRetailTransactionTickets(
        {
          offset: Number(query.get("offset")) || 0,
          limit: Number(query.get("limit")) || 10,
        },
        {
          status: query.get("status") || undefined,
          code: query.get("code") || undefined,
          date: query.get("date") || `${date.startDate},${date.endDate}`,
        },
        [
          {
            field:
              query.get("sort") || ERetailTransactionTicketSortField.newest,
          },
        ]
      ),
    {
      refetchOnWindowFocus: false,
      refetchInterval: 10000,
      staleTime: 20000,
    }
  );
};

const useFetchRetailTransactionTicket = (code: string) => {
  return useQuery(
    [`RetailTransactionTicket-${code}`],
    () => RetailTransactionTicketService.getRetailTransactionTicket(code),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const useFetchRetailTransactionTicketCounter = (date: {
  startDate: string;
  endDate: string;
}) => {
  const query = useQueries(useLocation);
  return useQuery(["RetailTransactionsTicketCounter", query.get("date")], () =>
    RetailTransactionTicketService.getRetailTransactionTicketCounter({
      date: query.get("date") || `${date.startDate},${date.endDate}`,
    })
  );
};

export {
  useFetchRetailTransactionTickets,
  useFetchRetailTransactionTicket,
  useFetchRetailTransactionTicketCounter,
};
