import React from "react";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "../../../../../config/axiosInstance";

import EditForm from "./editForm/EditForm";
import Loading from "../../../../../component/loading/Loading";
import SubNav from "../../../../../component/navbar/SubNav";

function EditProduct() {
  const params: any = useParams();
  const navigate = useNavigate();

  const getProductById = async () => {
    try {
      const results: any = await axios().get(
        `retail/retail-product/${params.id}`
      );
      const result = results.data;
      return result;
    } catch (error:any) {
      if (error.message === "Request failed with status code 401") {
        navigate("/login");
      }
    }
  };
  const { data, isLoading, refetch } = useQuery("product", getProductById);

  const goBack = () => {
    navigate("/admin/products");
  };

  return (
    <>
      <div className="bg-white eont-med">
        {isLoading ? (
          <div className="flex justify-center items-center mt-10">
            <Loading />
          </div>
        ) : (
          <EditForm data={data} refetch={refetch} />
        )}
      </div>
    </>
  );
}

export default EditProduct;
