import React, { useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useNavigate, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";

// components
import Pagination from "../../../../../component/pagination/Pagination";

//  icons
import { variant, edit, deleteIcon, cancel } from "../../../../../assets/icons";
import DeletedModal from "./modal/DeletedModal";

interface Data {
  id: number;
}

interface HeadCell {
  id: keyof Data | any;
  label: string;
  disablePadding: boolean;
}

const headCells: HeadCell[] = [
  { id: "Image", label: "Product Image", disablePadding: true },
  { id: "code", label: "Product Code", disablePadding: true },
  { id: "name", label: "Product Name", disablePadding: true },
  { id: "view_count", label: "Product View", disablePadding: true },
  { id: "Status", label: "Product Status", disablePadding: true },
  { id: "Actions", label: "Actions", disablePadding: true },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes } = props;

  return (
    <TableHead>
      <TableRow className="h-24">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            className={classes.tableHead}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface IProps {
  dataProps?: [] | any;
  filterAp: void | any;
  limit: number;
  setLimit: any;
  offset: number;
  setOffset: any;
  detail: boolean;
  setDetail: (detail: boolean) => void;
  deleteProduct: boolean;
  setDeleteProduct: (deleteProduct: boolean) => void;
}

const EnhancedTable: React.FC<IProps> = ({
  dataProps,
  filterAp,
  limit,
  setLimit,
  offset,
  setOffset,
  detail,
  setDetail,
  deleteProduct,
  setDeleteProduct,
}) => {
  const meta = dataProps?.data?.meta;
  const data = dataProps?.data?.data;
  const navigate = useNavigate();
  const{ pathname } = useLocation();
  const classes = useStyles();
  const [detailMsg, setdetailMsg] = useState<string | any>("");
  const [deletedId, setDeletedId] = useState<number | any>(null);

  const handleEditProduct = (id: number) => {
    navigate(`${pathname}/${id}/edit-product`);
  };

  const handleStockProduct = (id: number) => {
    navigate(`${pathname}/${id}/stock-product`);
  };

  const handleDeleteProduct = async (id: number) => {
    if (deleteProduct) {
      setDeletedId(null);
      setDeleteProduct(false);
    } else {
      setDeletedId(id);
      setDeleteProduct(true);
    }
  };

  const showDetail = (msg?: string) => {
    if (detail) {
      setdetailMsg("");
      setDetail(false);
    } else {
      setdetailMsg(msg);
      setDetail(true);
    }
  };

  return (
    <div className={classes.root}>
      {detail ? (
        <div className="w-full min-h-full bg-black bg-opacity-70 inset-0 fixed z-50 flex justify-center items-center">
          <div className="md:w-WModal md:h-Hmodal  md:py-6 py-4 bg-white rounded-xl shadow-md absolute">
            <div className="flex justify-between md:px-10 px-5">
              <div className="flex items-center">
                <h1 className="md:text-lg flex text-primary">Product</h1>
                <h1 className="md:text-lg text-black ml-1.5">Rejected</h1>
              </div>
              <img
                src={cancel}
                alt="cancel"
                onClick={() => showDetail()}
                className="cursor-pointer md:w-5 w-3"
              />
            </div>
            <hr className="mt-5" />
            <div className="flex flex-col md:mt-3 md:pb-12 mt-0 md:px-10 px-5 h-full">
              <textarea
                value={detailMsg || "-"}
                disabled
                className="md:h-44 h-24 font-rom w-full rounded-md md:text-base text-black text-xs my-3 px-3 py-2 border-2 border-stroke focus:ring-1 focus:outline-none"
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {/**delete product*/}
      {deleteProduct && (
        <DeletedModal
          id={deletedId}
          setDeleteProduct={setDeleteProduct}
          deleteProduct={deleteProduct}
        />
      )}
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead classes={classes} />
          <TableBody>
            {filterAp.fn(data)?.map((row: any) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  className="h-56"
                >
                  <TableCell
                    component="th"
                    align="center"
                    scope="row"
                    className={classes.tableCell}
                  >
                    <div className="flex justify-center">
                      <img
                        src={row.display_image_path}
                        alt="product_image"
                        loading="lazy"
                        className="rounded-md w-40 shadow-md"
                      />
                    </div>
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {row.code}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {row.name}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {row.view_count}
                  </TableCell>
                  {row.status === 2 && row.is_active === false ? (
                    <TableCell
                      align="center"
                      className={classes.tableCell_status_reject}
                    >
                      Not Posted
                    </TableCell>
                  ) : row.status === 2 ? (
                    <TableCell
                      align="center"
                      className={classes.tableCell_status_selling}
                    >
                      {row.status_message}
                    </TableCell>
                  ) : row.status === 1 ? (
                    <TableCell
                      align="center"
                      className={classes.tableCell_status_reject}
                    >
                      <div>
                        <h1>{row?.status_message}</h1>
                        {row.status === 1 ? (
                          <h1
                            onClick={() => showDetail(row.rejected_reason)}
                            className="text-xs font-rom text-black hover:text-primary cursor-pointer"
                          >
                            Details
                          </h1>
                        ) : (
                          ""
                        )}
                      </div>
                    </TableCell>
                  ) : (
                    <TableCell
                      align="center"
                      className={classes.tableCell_status_waiting}
                    >
                      {row.status_message}
                    </TableCell>
                  )}

                  <TableCell
                    padding="none"
                    align="center"
                    className={classes.tableCell}
                  >
                    <div className="flex justify-evenly">
                      <div>
                        <img
                          data-tip
                          data-for="variant"
                          className="cursor-pointer md:w-5"
                          onClick={() => handleStockProduct(row.id)}
                          src={variant}
                          alt="variant"
                        />
                        <ReactTooltip id="variant" type="info">
                          <span>Variant</span>
                        </ReactTooltip>
                      </div>
                      <div>
                        <img
                          data-tip
                          data-for="edit"
                          className="cursor-pointer md:w-6"
                          onClick={() => handleEditProduct(row.id)}
                          src={edit}
                          alt="edit"
                        />
                        <ReactTooltip id="edit" type="dark">
                          <span>Edit</span>
                        </ReactTooltip>
                      </div>
                      <div>
                        <img
                          data-tip
                          data-for="delete"
                          className="cursor-pointer md:w-5"
                          onClick={() => handleDeleteProduct(row.id)}
                          src={deleteIcon}
                          alt="del"
                        />
                        <ReactTooltip id="delete" type="error">
                          <span>Delete</span>
                        </ReactTooltip>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        limit={limit}
        offset={offset}
        lengthData={data?.length}
        metaTotal={meta?.total}
        setLimit={setLimit}
        setOffset={setOffset}
      />
    </div>
  );
};

export default EnhancedTable;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: "3%",
      marginBottom: "3%",
    },
    table: {
      minWidth: 750,
    },
    searchInput: {
      width: "75%",
      padding: "0.5%",
      margin: "1% 10% 3% 0%",
    },
    tableCell: {
      borderWidth: 3,
      borderColor: "#EFEFEF",
      fontSize: 14,
      width: "10%",
      fontFamily: "NeueHaasDisplay",
    },
    tableCell_status_selling: {
      borderWidth: 3,
      borderColor: "#EFEFEF",
      fontSize: 14,
      width: "10%",
      color: "#00C62E",
      fontFamily: "NeueHaasDisplayMediu",
    },
    tableCell_status_reject: {
      borderWidth: 3,
      borderColor: "#EFEFEF",
      fontSize: 14,
      width: "10%",
      color: "#DF5354",
      fontFamily: "NeueHaasDisplayMediu",
    },
    tableCell_status_waiting: {
      borderWidth: 3,
      borderColor: "#EFEFEF",
      fontSize: 14,
      width: "10%",
      color: "#FFBA00",
    },
    tableHead: {
      borderWidth: 3,
      borderColor: "#EFEFEF",
      fontSize: 16,
      fontFamily: "NeueHaasDisplayMediu",
    },
  })
);
