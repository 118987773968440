export enum ActionType {
  ShowFilter,
  ShowDeleteSubAccount,
  ShowAddSubAccount,
  HandleChip,
}

export interface ShowFilter {
  type: ActionType.ShowFilter;
}

export interface HandleChip {
  type: ActionType.HandleChip;
  payload: any[];
}

export interface ShowAddSubAccount {
  type: ActionType.ShowAddSubAccount;
}

export interface ShowDeleteSubAccount {
  type: ActionType.ShowDeleteSubAccount;
  payload: { status: boolean; id: number };
}

export type SubAccountAction =
  | ShowFilter
  | HandleChip
  | ShowDeleteSubAccount
  | ShowAddSubAccount;
