import React, { useState } from "react";
import axios, { getWithExpiry } from "../../../../config/axiosInstance";
import { useFormik } from "formik";
import * as Yup from "yup";

import { showPass, unshowPass } from "../../../../assets/icons/Web/index";
import CircularLoad from "../../../../component/loading/CircularLoad";
import { isRetail } from "../../../../helpers/checkRole";
import ErrorHandler from "../../../../component/toast/ErrorHandler";

const Settings: React.FC = () => {
  const [oldType, setoldType] = useState<string>("password");
  const [newType, setnewType] = useState<string>("password");
  const [confirmType, seconfirmType] = useState<string>("password");
  const [load, setLoad] = useState<boolean>(false);
  const role = getWithExpiry("role");

  const handleVisibleOldType = () => {
    if (oldType === "password") {
      setoldType("text");
    } else {
      setoldType("password");
    }
  };

  const handleVisibleNewType = () => {
    if (newType === "password") {
      setnewType("text");
    } else {
      setnewType("password");
    }
  };

  const handleVisibleConfirmType = () => {
    if (confirmType === "password") {
      seconfirmType("text");
    } else {
      seconfirmType("password");
    }
  };

  const lowercaseRegex = /(?=.*[a-z])/;
  const uppercaseRegex = /(?=.*[A-Z])/;
  const numericRegex = /(?=.*[0-9])/;

  const { handleSubmit, handleChange, errors, touched, values, handleReset } =
    useFormik({
      initialValues: {
        password: "",
        new_password: "",
        renew_password: "",
      },
      validationSchema: Yup.object({
        password: Yup.string()
          .required("Required")
          .min(6, "The password must be of minimum 6 characters!"),
        new_password: Yup.string()
          .required("Required!")
          .matches(lowercaseRegex, "One lowercase required!")
          .matches(uppercaseRegex, "One uppercase required!")
          .matches(numericRegex, "One number required!")
          .min(6, "The password must be of minimum 6 characters!"),
        renew_password: Yup.string()
          .oneOf(
            [Yup.ref("new_password")],
            "The password confirmation does not match!"
          )
          .required("Required!"),
      }),
      onSubmit: (values) => {
        setLoad(true);
        axios()
          .patch(`${isRetail(role) ? "retail" : "assistant"}/retail-user`, {
            password: values.password,
            new_password: values.new_password,
          })
          .then((data) => {
            setLoad(false);
            ErrorHandler("#00C62E", "Success Updated Password");
            handleReset({});
          })
          .catch((err) => {
            setLoad(false);
          });
      },
    });

  return (
    <div className="w-full h-96 my-8 md:flex justify-between mb-40">
      <div className="md:w-1/3 md:inline-flex hidden h-full rounded-md border-2 border-stroke"></div>

      <div className="md:w-custom h-full">
        <form onSubmit={handleSubmit}>
          <div className="rounded-md rounded-b-none border-2 border-stroke">
            <div className="md:h-16 h-14 flex items-center justify-between md:mx-8 mx-5">
              <h1 className="font-medium md:text-lg text-base">
                Change Password
              </h1>
            </div>
            <hr />

            <div className="md:flex w-full h-full mb-8">
              <div className="md:mx-8 mx-5 mt-7 md:w-1/2 h-full">
                <div>
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Old Password
                  </h1>
                  <div className="md:h-10 h-9 rounded-md border-2 my-3 border-stroke flex justify-between">
                    <input
                      type={oldType}
                      placeholder="Place holder"
                      className="md:text-sm text-xs font-rom px-3 w-full mr-5 focus:outline-none"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                    />
                    {oldType === "password" ? (
                      <img
                        src={showPass}
                        onClick={handleVisibleOldType}
                        className="md:w-5 w-3 mr-5 cursor-pointer"
                        alt=""
                      />
                    ) : (
                      <img
                        src={unshowPass}
                        onClick={handleVisibleOldType}
                        className="md:w-5 w-3 mr-5 cursor-pointer"
                        alt=""
                      />
                    )}
                  </div>
                  {touched.password && errors.password ? (
                    <h1 className="text-xs font-rom text-primary -mt-1 mb-1">
                      {errors.password}
                    </h1>
                  ) : null}
                </div>

                <div className="md:mt-4 mt-28">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Confirm New Password
                  </h1>
                  <div className="md:h-10 h-9 rounded-md  border-2 my-3  border-stroke flex justify-between">
                    <input
                      type={confirmType}
                      placeholder="Place holder"
                      name="renew_password"
                      value={values.renew_password}
                      onChange={handleChange}
                      className="md:text-sm text-xs font-rom px-3 w-full mr-5 focus:outline-none"
                    />

                    {confirmType === "password" ? (
                      <img
                        src={showPass}
                        onClick={handleVisibleConfirmType}
                        className="md:w-5 w-3 mr-5 cursor-pointer"
                        alt=""
                      />
                    ) : (
                      <img
                        src={unshowPass}
                        onClick={handleVisibleConfirmType}
                        className="md:w-5 w-3 mr-5 cursor-pointer"
                        alt=""
                      />
                    )}
                  </div>
                  {touched.renew_password && errors.renew_password ? (
                    <h1 className="text-xs font-rom text-primary -mt-1 mb-1">
                      {errors.renew_password}
                    </h1>
                  ) : null}
                </div>
              </div>

              <div className="md:mx-8 mx-5 md:mt-7 -mt-44 md:mb-0 mb-32 md:w-1/2 h-full">
                <div>
                  <h1 className="md:text-base text-sm text-textPrimary">
                    New Password
                  </h1>
                  <div className="md:h-10 h-9 rounded-md border-2 my-3 border-stroke flex justify-between">
                    <input
                      type={newType}
                      placeholder="Place holder"
                      name="new_password"
                      value={values.new_password}
                      onChange={handleChange}
                      className="md:text-sm text-xs font-rom px-3 w-full mr-5 focus:outline-none"
                    />

                    {newType === "password" ? (
                      <img
                        src={showPass}
                        onClick={handleVisibleNewType}
                        className="md:w-5 w-3 mr-5 cursor-pointer"
                        alt=""
                      />
                    ) : (
                      <img
                        src={unshowPass}
                        onClick={handleVisibleNewType}
                        className="md:w-5 w-3 mr-5 cursor-pointer"
                        alt=""
                      />
                    )}
                  </div>
                  {touched.new_password && errors.new_password ? (
                    <h1 className="text-xs font-rom text-primary -mt-1 mb-1">
                      {errors.new_password}
                    </h1>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="rounded-md rounded-t-none border-2 border-t-0 border-stroke flex w-full md:h-20 h-16 mb-10 items-center justify-center">
            <div className="">
              <button type="submit" className="focus:outline-none">
                {load ? (
                  <div className="flex justify-center">
                    <CircularLoad color={"#e25351"} height={25} width={25} />
                  </div>
                ) : (
                  <h1 className="md:text-xl text-base cursor-pointer hover:text-primary">
                    Save
                  </h1>
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Settings;
