import React, { useContext } from "react";
import { useQuery } from "react-query";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { TicketRequest } from "../../../../../api/ticket/Ticket";
import DynamicBreadcrumb from "../../../../../component/breadcrumb/DynamicBreadcrumb";
import Loading from "../../../../../component/loading/Loading";
import TicketContext from "../../../../../store/ticket/context";
import { useQueries } from "../../../../../utils/QuerySearchParams";
import { optRoutesVariantTransaction } from "../../helpers/list";
import VariantTransactionTable from "./variant_transaction/VariantTransactionTable";
import ScanQrModal from "./variant_transaction/ScanQrModal";
import SubNav from "../../../../../component/navbar/SubNav";
import VariantTransactionList from "./variant_transaction/VariantTransactionList";

const VariantTransaction = () => {
  const { pathname } = useLocation();
  const query = useQueries(useLocation);
  const navigate = useNavigate();
  const params = useParams();
  const { TicketState } = useContext(TicketContext);
  const { showScanQr } = TicketState;
  const { data, isLoading } = useQuery(
    [
      `getTicketVariantTransaction`,
      query.get("sort"),
      query.get("limit"),
      query.get("offset"),
      query.get("isAsc"),
    ],
    () => TicketRequest.getVariantTransaction(params.variantId || ""),
    {
      refetchOnWindowFocus: false,
    }
  );
  const { data: dataTicketBundle, isLoading: loadTicketBundle } = useQuery(
    [`getTicketById`],
    () => TicketRequest.getTicketById(params.id || "")
  );

  const ScanQr = () => {
    navigate(`${pathname}/scan-qr-code`);
  };

  return (
    <div className="bg-white font-med p-4">
      {showScanQr.status && <ScanQrModal />}
      {isLoading || loadTicketBundle ? (
        <div className="flex justify-center mt-20">
          <Loading />
        </div>
      ) : (
        <div>
          <div className="flex flex-col items-center">
            <h1 className="md:text-3xl text-lg">{dataTicketBundle?.name}</h1>
          </div>
          <div className="md:flex hidden justify-end">
            <h1 onClick={ScanQr} className="hover:text-primary cursor-pointer">
              Scan Qr Code
            </h1>
          </div>
          <div className="md:hidden flex justify-center bg-red-300 fixed bottom-20 w-full">
            <div className="flex rounded-full shadow-lg w-2/5 h-10 justify-center bg-white fixed items-end">
              <div className="h-full flex justify-center items-center">
                <h1
                  onClick={ScanQr}
                  className="hover:text-primary cursor-pointer"
                >
                  Scan Qr Code
                </h1>
              </div>
            </div>
          </div>

          <div className="md:inline hidden">
            <VariantTransactionTable data={data} />
          </div>
          <div className="md:hidden flex">
            <VariantTransactionList data={data} />
          </div>
        </div>
      )}
    </div>
  );
};

export default VariantTransaction;
