import React, { useState } from "react";
import { down, up } from "../../../../../assets/icons";
import Input from "../../../../../component/input/Input";

export interface InformationProps {
  data: {
    code: string;
    buyer_username: string;
    status_message: string;
    quantity: number;
    price: number;
    created_at: string;
    retail_product_detail: {
      name?: string;
      retail_product_stock?: {
        label?: string;
      };
    };
  };
}

const Information: React.FC<InformationProps> = ({ data }) => {
  const [dropdownTrans, setdropdownTrans] = useState<boolean>(true);
  const handledropdownTrans = () => {
    setdropdownTrans(!dropdownTrans);
  };

  return (
    <div className="w-full h-full">
      <div className="rounded-md  border-2 border-stroke">
        {/*transactions information title and toggle*/}
        <div className="md:h-16 h-14 flex items-center justify-between md:mx-8 mx-5">
          <h1 className="font-medium md:text-xl text-base">
            Transaction Information
          </h1>
          <div className="flex">
            <div className="flex justify-between items-center">
              {dropdownTrans ? (
                <img
                  src={up}
                  alt="up"
                  className="duration-300 ease-in-out cursor-pointer md:w-5 w-4"
                  onClick={handledropdownTrans}
                />
              ) : (
                <img
                  src={down}
                  alt="up"
                  className="duration-300 ease-in-out cursor-pointer md:w-5 w-4"
                  onClick={handledropdownTrans}
                />
              )}
            </div>
          </div>
        </div>
        {dropdownTrans ? <hr className="border-t-2" /> : ""}


        {dropdownTrans ? (
          <div>
            {/*transactions Code*/}
            <div className="md:mx-8 mx-5 md:mt-5 mt-3 md:pt-0 pt-2">
              <div>
                <h1 className="md:text-base text-sm text-textPrimary">
                  Transactions Code
                </h1>
                <Input
                  disable={true}
                  type={"text"}
                  name={"Customer"}
                  value={data?.code}
                  placeholder={"Place holder"}
                />
              </div>
            </div>

            <div className="md:flex w-full mt-4">
              {/*transactions data left*/}
              <div className="md:mx-8 mx-5 md:w-1/2">
                <div>
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Customer
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"Customer"}
                    value={data?.buyer_username}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                  Transactions Status
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"status_message"}
                    value={data?.status_message}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Variant
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"Variant"}
                    value={
                      data.retail_product_detail?.retail_product_stock?.label
                    }
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Product Price
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"Price"}
                    value={data?.price.toLocaleString("EN-US", {
                      style: "currency",
                      currency: "IDR",
                    })}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>

              {/*transactions data right*/}
              <div className="md:mx-8 mx-5 md:w-1/2">
                <div>
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Product Name
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"Name"}
                    value={data?.retail_product_detail.name}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Quantity
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"Quantity"}
                    value={data?.quantity}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Transaction Date
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"Date"}
                    value={new Date(data?.created_at).toDateString()}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Information;
