import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import axios from "../../../../../../config/axiosInstance";
import EditVariantForm from "./modal/EditVariantForm";
import Loading from "../../../../../../component/loading/Loading";

function EditVariant() {
  const [error, setError] = useState<boolean>(false);
  const params = useParams();

  useEffect(() => {
    setTimeout(() => {
      setError(false);
    }, 1500);
  }, [error]);

  const getDataVariantStock = async () => {
    try {
      const results: any = await axios().get(
        `retail/retail-product-stock/${params.detail}`
      );
      const result = results.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };
  const { data: dataStock } = useQuery("stock", getDataVariantStock, {
    refetchOnWindowFocus: false,
  });

  const getDataVariant = async () => {
    try {
      const results: any = await axios().get(
        `retail/retail-product/${params.id}`
      );
      const result = results.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const { data: dataVariant, isLoading } = useQuery("variant", getDataVariant, {
    refetchOnWindowFocus: false,
  });

  return (
    <div className="bg-white p-4 font-med">
      {isLoading ? (
        <div className="my-20 flex justify-center">
          <Loading />
        </div>
      ) : (
        <div className="w-full h-full md:flex justify-between">
          {/* Product Images */}
          <div className="md:w-1/3 w-full h-full rounded-md border-2 border-stroke">
            <div className="md:h-16 h-14 flex items-center md:mx-8 mx-5">
              <h1 className="font-medium md:text-xl text-sm">Product Images</h1>
            </div>
            <hr />

            <div className="md:mx-8 mx-5 md:my-7 my-5">
              <div>
                <h1 className="md:text-base text-sm text-textPrimary">
                  Display Images
                </h1>
                <div className="md:w-full flex flex-col items-center mt-2">
                  <img
                    className="my-3 rounded-md shadow-md w-44"
                    src={dataVariant?.display_image_path}
                    alt="display_image"
                  />
                </div>
              </div>
              <div className="mt-6 text-textPrimary">
                <h1 className="md:text-base text-sm">Detail Images</h1>
                <div className="grid grid-cols-3 md:gap-3 gap-2 justify-center h-auto">
                  {dataVariant.detail_image_path.length <= 0 ? (
                    <h1 className="my-5">No images uploaded</h1>
                  ) : (
                    dataVariant.detail_image_path?.split(",").map((el: any) => (
                      <div className="flex justify-start my-2">
                        <img
                          className="w-full rounded-md shadow-md"
                          src={el}
                          alt="detail_image"
                        />
                      </div>
                    ))
                  )}
                </div>
              </div>
              <div className="mt-6 text-textPrimary">
                <h1 className="md:text-base text-sm">Description Images</h1>
                <div className="grid grid-cols-3 md:gap-3 gap-2 justify-center h-auto">
                  {dataVariant.description_image_path.length <= 0 ? (
                    <h1 className="my-5">No images uploaded</h1>
                  ) : (
                    dataVariant.description_image_path
                      ?.split(",")
                      .map((el: string | undefined) => (
                        <div className="flex justify-start my-2">
                          <img
                            className="w-full rounded-md shadow-md"
                            src={el}
                            alt="detail_image"
                          />
                        </div>
                      ))
                  )}
                </div>
              </div>
            </div>
          </div>
          <EditVariantForm dataStock={dataStock} />
        </div>
      )}
    </div>
  );
}

export default EditVariant;
