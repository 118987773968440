import React from "react";
import CircularLoad from "../loading/CircularLoad";

export interface SaveProps {
  handleReset?: any;
  handleSave?: any;
  load?: boolean;
}

const Save: React.FC<SaveProps> = ({ handleReset, handleSave, load }) => {
  return (
    <div className="rounded-md rounded-t-none border-2 border-t-0 border-stroke flex w-full md:h-20 h-14 mb-10">
      <div className="w-1/2 flex items-center justify-center border-r-2 border-stroke ">
        <button
          type="reset"
          onClick={handleReset}
          className="focus:outline-none"
        >
          <h1 className="md:text-lg text-sm cursor-pointer hover:text-primary">
            Clear
          </h1>
        </button>
      </div>
      <div className="w-1/2 flex items-center justify-center">
        <button
          type="submit"
          onClick={handleSave}
          className="focus:outline-none"
        >
          {load ? (
            <div className="flex justify-center">
              <CircularLoad color={"black"} height={17} width={17} />
            </div>
          ) : (
            <h1 className="md:text-lg text-sm cursor-pointer hover:text-primary">
              Save
            </h1>
          )}
        </button>
      </div>
    </div>
  );
};

export default Save;
