import moment from "moment";
import { useContext } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { TicketRequest } from "../../../../api/ticket/Ticket";
import { back } from "../../../../assets/icons";
import Loading from "../../../../component/loading/Loading";
import { ActionType } from "../../../../store/ticket/action";
import TicketContext from "../../../../store/ticket/context";
import DeleteTicketModal from "../ticket_component/DeleteTicketModal";

const TicketDetail: React.FC = () => {
  const { TicketDispatch, TicketState } = useContext(TicketContext);
  const { showDeleteTicket } = TicketState;
  const{ pathname } = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const goBack = () => {
    navigate("/admin/ticket");
  };

  const goEdit = () => {
    navigate(`${pathname}/edit-ticket`);
  };

  const goViewStock = () => {
    navigate(`${pathname}/stock-ticket`);
  };

  const goDelete = () => {
    TicketDispatch({
      type: ActionType.ShowDeleteTicket,
      payload: { status: true, id: Number(params.id) || 0 },
    });
  };

  const { data, isSuccess } = useQuery("getTicketById", () =>
    TicketRequest.getTicketById(params.id || '')
  );

  return (
    <div className="mb-28 font-med">
         {showDeleteTicket.status && <DeleteTicketModal />}
      <div className="md:pt-28 pt-20 overflow-auto">
        <div onClick={goBack} className="flex items-center">
          <img src={back} alt="back" />
          <h1 className="text-mobile ml-1.5">Back</h1>
        </div>

        {isSuccess ? (
          <div>
            <div className="mt-12 flex justify-center">
              <img
                className="rounded-md shadow-md w-56"
                src={data?.preview_image_path}
                alt=""
              />
            </div>
            <div className="px-5 mt-12">
              <div>
                <h1 className="text-mobile">Ticket Name</h1>
                <div className="flex items-center border-2 mt-2 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">{data.name}</h1>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-mobile">Ticket Description</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">{data.description}</h1>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-mobile">Release Date</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">
                    {moment(data.release_date).format("LL hh:mm")}
                  </h1>
                </div>
              </div>
              <div className="mt-5">
                <h1 className="text-mobile">Ticket Status</h1>
                <div className="flex items-center border-2 mt-2 py-1 px-3 rounded-md border-disableColor h-10">
                  <h1 className="text-mobile font-rom">
                    {data.status?.split("_").join(" ")}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex justify-center mt-10">
            <Loading />
          </div>
        )}
      </div>
      <footer className="h-24 p-3 flex justify-evenly items-center w-full left-0 bottom-0 bg-white shadow-inner fixed">
        <div
          onClick={goViewStock}
          className="w-1/4 h-14 flex items-center justify-center border-disableColor border-2 shadow-md rounded"
        >
          <h1 className="text-xs text-textPrimary">View Stock</h1>
        </div>
        <div
          onClick={goEdit}
          className="w-1/4 h-14 flex items-center justify-center bg-success shadow-md rounded"
        >
          <h1 className="text-xs text-white">Edit Ticket</h1>
        </div>
        <div
          onClick={goDelete}
          className="w-1/4 h-14 flex items-center justify-center bg-primary shadow-md rounded"
        >
          <h1 className="text-xs text-white">Delete Ticket</h1>
        </div>
      </footer>
    </div>
  );
};

export default TicketDetail;
