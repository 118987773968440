import React, { useState } from "react";
import { down, up } from "../../../../../assets/icons";
import Input from "../../../../../component/input/Input";

export interface PricingProps {
  data: {
    quantity: number;
    price: number;
    admin_fee_percentage: number;
    is_shipping_fee_charged: boolean;
    total: number;
    buyer_shipping_cost: number;
  };
}

const Pricing: React.FC<PricingProps> = ({ data }) => {
  const [dropdownPricing, setdropdownPricing] = useState<boolean>(true);

  const handledropdownPricing = () => {
    setdropdownPricing(!dropdownPricing);
  };
  return (
    <div className="w-full h-full my-8">
      <div className="rounded-md border-2 border-stroke">
        {/*Pricing information title and toggle*/}
        <div className="md:h-16 h-14 flex items-center justify-between md:mx-8 mx-5">
          <h1 className="font-medium md:text-xl text-base">
            Pricing Information
          </h1>
          <div className="flex">
            <div className="flex justify-between items-center">
              {dropdownPricing ? (
                <img
                  src={up}
                  alt="up"
                  className="duration-300 ease-in-out cursor-pointer md:w-5 w-4"
                  onClick={handledropdownPricing}
                />
              ) : (
                <img
                  src={down}
                  alt="up"
                  className="duration-300 ease-in-out cursor-pointer md:w-5 w-4"
                  onClick={handledropdownPricing}
                />
              )}
            </div>
          </div>
        </div>
        {dropdownPricing ? <hr className="border-t-2" /> : ""}

        {dropdownPricing ? (
          <div>
            <div className="md:flex w-full mt-4 md:py-2">
              {/*Pricing data left*/}
              <div className="md:mx-8 mx-5 md:w-1/2">
                <div>
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Quantity
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"quantity"}
                    value={data.quantity}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Shipping Cost
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"cost"}
                    value={data.buyer_shipping_cost.toLocaleString("EN-US", {
                      style: "currency",
                      currency: "IDR",
                    })}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>

              {/*Pricing data right*/}
              <div className="md:mx-8 mx-5 md:w-1/2 md:mt-0 mt-4">
              <div>
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Price
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"price"}
                    value={data.price.toLocaleString("EN-US", {
                      style: "currency",
                      currency: "IDR",
                    })}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Shipping Fee (charged to)
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"is_shipping_fee_charged"}
                    value={
                      data.is_shipping_fee_charged
                        ? "buyer"
                        : "seller"
                    }
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Total
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"total"}
                    value={data.total.toLocaleString("EN-US", {
                      style: "currency",
                      currency: "IDR",
                    })}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Pricing;
