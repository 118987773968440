import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";

import { next } from "../../../../../assets/icons";
import axiosInstance from "../../../../../config/axiosInstance";
import ContactSkeletonLoading from "../../../../../component/loading/SkeletonLoading";
import moment from "moment";

export interface RevenueListProps {
  data: any;
  sortValue: string;
}

const RevenueList: React.FC<RevenueListProps> = ({ data, sortValue }) => {
  const navigate = useNavigate();
  const dataTrans = data.data.data;
  const meta = data.data.meta.total;
  const [transaction, setTransaction] = useState<any>(dataTrans);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(10);
  const [more, setMore] = useState<boolean>(true);

  const goDetail = (code: string, type: string) => {
    if (type === "ticket") {
      navigate(`/admin/transaction-ticket/${code}`);
    } else {
      navigate(`/admin/revenues/detail/${code}`);
    }
  };

  useEffect(() => {
    setTransaction(dataTrans);
  }, [data]);

  const getRevenue = async () => {
    try {
      const results: any = await axiosInstance().get(`retail/retail-revenue`, {
        params: {
          limit: limit,
          offset: offset,
          sort: `${sortValue}`,
        },
      });
      const result = await results.data.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMoredata = async () => {
    const new_data = [...transaction, ...(await getRevenue())];
    setTransaction(new_data);
  };

  useEffect(() => {
    if (meta - transaction.length < limit) {
      setMore(false);
    } else {
      setMore(true);
    }
  }, [transaction.length]);

  useEffect(() => {
    if (transaction.length > limit) {
      setOffset(offset + 10);
    }
  }, [transaction.length]);

  return (
    <div className="w-full mb-28">
      <InfiniteScroll
        initialLoad={false}
        loadMore={fetchMoredata}
        hasMore={more}
        loader={<ContactSkeletonLoading />}
      >
        {transaction.map((el: any) => (
          <div key={el.id}>
            <div
              className="flex h-20 items-center"
              onClick={() =>
                goDetail(el.retail_transaction_code, el.retail_product_type)
              }
            >
              {/* <div>
                <img
                  src={el.retail_product_detail.display_image_path}
                  className="w-18 h-18 shadow-sm rounded-md"
                  alt=""
                />
              </div> */}
              <div className="flex ml-4 items-center justify-between w-full">
                <div className="flex flex-col">
                  <div className="w-56">
                    <h1 className="text-sm">{el?.buyer_username}</h1>
                  </div>
                  <div className="flex mt-2">
                    <div className="flex flex-col mt-0.5">
                      <h1 className="text-mobile text-black">
                        {moment(el.created_at).format("llll")}
                      </h1>
                    </div>
                  </div>
                </div>
                <div>
                  <img src={next} alt="imgs" className="w-3 h-3" />
                </div>
              </div>
            </div>
            <hr className="my-4" />
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default RevenueList;
