import { useQuery } from "react-query";
import { RetailStatisticService } from "../../api/retail_statistic";

const useFetchRetailStatistic = () => {
  return useQuery(
    [`retail-statistic`],
    () => RetailStatisticService.getRetailStatistic(),
    {
      refetchOnWindowFocus: false,
    }
  );
};

const useFetchRetailMonthlyRevenue = (month: string, year: string) => {
  return useQuery(
    [`retail-monthly-revenue`],
    () => RetailStatisticService.getRetailMonthlyRevenue(month, year),
    {
      refetchOnWindowFocus: false,
    }
  );
};

export { useFetchRetailStatistic, useFetchRetailMonthlyRevenue };
