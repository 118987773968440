import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useNavigate, useLocation } from "react-router-dom";

import { cancel, next } from "../../../../../assets/icons";
import Input from "../../../../../component/input/Input";
import ContactSkeletonLoading from "../../../../../component/loading/SkeletonLoading";
import axiosInstance from "../../../../../config/axiosInstance";

export interface WithdrawListProps {
  data: any;
  isAsc: string;
  sortValue: string;
}

const WithdrawList: React.FC<WithdrawListProps> = ({
  data,
  isAsc,
  sortValue,
}) => {
  const navigate = useNavigate();
  const{ pathname } = useLocation();
  const dataTrans = data.data.data;
  const meta = data.data.meta.total;
  const [withdraw, setWithdraw] = useState<any>(dataTrans);
  const [more, setMore] = useState<boolean>(true);
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(10);
  const [showWithdraw, setShowWithdraw] = useState<boolean>(false);
  const [detail, setDetail] = useState<any>({});
  
  useEffect(() => {
    setWithdraw(dataTrans);
  }, [data]);

  const getWithdraw = async () => {
    try {
      const results: any = await axiosInstance().get(
        `retail/retail-transaction`,
        {
          params: {
            limit: limit,
            offset: offset,
            sort: `${sortValue} ${isAsc}`,
          },
        }
      );
      const result = await results.data.data;
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchMoredata = async () => {
    const new_data = [...withdraw, ...(await getWithdraw())];
    setWithdraw(new_data);
  };

  useEffect(() => {
    if (meta - withdraw.length < limit) {
      setMore(false);
    } else {
      setMore(true);
    }
  }, [withdraw.length]);

  useEffect(() => {
    if (withdraw.length > limit) {
      setOffset(offset + 10);
    }
  }, [withdraw.length]);

  return (
    <div className="w-full mb-28">
      {showWithdraw ? (
        <div className="w-full min-h-full font-med bg-black bg-opacity-70 inset-0 fixed z-50 flex justify-center items-center">
          <div className="md:w-WModal w-10/12 md:h-Hmodal md:py-6 py-4 bg-white rounded-md shadow-md absolute">
            <div className="flex justify-between md:px-10 px-5">
              <div className="flex items-center">
                <h1 className="md:text-lg flex text-primary">Withdraw</h1>
                <h1 className="text-black ml-1.5">Details</h1>
              </div>
              <img
                src={cancel}
                alt="cancel"
                onClick={() => {
                  setShowWithdraw(!showWithdraw);
                  setDetail({})
                }}
                className="cursor-pointer md:w-5 w-3"
              />
            </div>
            <hr className="my-5" />
            <div className="px-5">
              <h1 className="md:text-base text-sm  text-textPrimary">Amount</h1>
              <Input
                disable={true}
                type={"text"}
                name={"amount"}
                value={detail.amount.toLocaleString("EN-US", {
                  style: "currency",
                  currency: "IDR",
                })}
                placeholder={"Place holder"}
              />
              <h1 className="md:text-base text-sm  text-textPrimary">Status</h1>
              <Input
                disable={true}
                type={"text"}
                name={"amount"}
                value={detail.status}
                placeholder={"Place holder"}
              />
              <h1 className="md:text-base text-sm  text-textPrimary">Message</h1>
              <Input
                disable={true}
                type={"text"}
                name={"amount"}
                value={detail.rejected_reason || '-'}
                placeholder={"Place holder"}
              />
            </div>
          </div>
        </div>
      ) : (
        <InfiniteScroll
          initialLoad={false}
          loadMore={fetchMoredata}
          hasMore={more}
          loader={<ContactSkeletonLoading />}
        >
          {withdraw.map((el: any) => (
            <div key={el.id}>
              <div
                className="flex h-20 items-center"
                onClick={() => {
                  setShowWithdraw(!showWithdraw);
                  setDetail(el)
                }}
              >
                {/* <div>
                <img
                //   src={el.retail_product_detail.display_image_path}
                className="w-18 h-18 shadow-sm rounded-md"
                alt=""
                />
              </div> */}
                <div className="flex items-center justify-between mx-4 w-full">
                  <div className="flex flex-col">
                    <div className="w-56">
                      <h1 className="text-sm">
                        {el.amount?.toLocaleString("EN-US", {
                          style: "currency",
                          currency: "IDR",
                        })}
                      </h1>
                    </div>
                    <div className="flex mt-2">
                      <div className="flex">
                        <h1 className="text-sm mr-2">Status :</h1>
                        {el.status === "pending" ? (
                          <h1 className="text-mobile text-yellow-400">
                            {el.status}
                          </h1>
                        ) : el.status === "success" ? (
                          <h1 className="text-mobile  text-success">
                            {el.status}
                          </h1>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <img src={next} alt="imgs" className="w-3 h-3" />
                  </div>
                </div>
              </div>
              <hr className="my-4" />
            </div>
          ))}
        </InfiniteScroll>
      )}
    </div>
  );
};

export default WithdrawList;
