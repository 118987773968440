import { IRetailUser } from "../entities";
import IRetailUserRepository from "../repository";
import IRetailUserUsecase from "../usecase";

export default class RetailUserUsecase implements IRetailUserUsecase {
  private RetailUserRepo: IRetailUserRepository;

  constructor(retailUserRepo: IRetailUserRepository) {
    this.RetailUserRepo = retailUserRepo;
  }

  async getRetailUser(): Promise<IRetailUser> {
    const RetailUser = await this.RetailUserRepo.getRetailUser();
    return RetailUser;
  }
}
