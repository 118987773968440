import { FC } from "react";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export interface IAloadingProps {
  color?: string;
  width?: number;
  height?: number;
  type:
    | "Audio"
    | "BallTriangle"
    | "Bars"
    | "Circles"
    | "Grid"
    | "Hearts"
    | "Oval"
    | "Puff"
    | "Rings"
    | "TailSpin"
    | "ThreeDots"
    | "Watch"
    | "RevolvingDot"
    | "Triangle"
    | "Plane"
    | "MutatingDots"
    | "CradleLoader";
}

export const Aloading: FC<IAloadingProps> = ({
  color = "#DF5354",
  width = 40,
  height = 40,
  type,
}) => {
  return <Loader type={type} color={color} height={height} width={width} />;
};
