import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Pagination from '../../../../component/pagination/Pagination';

interface Data {
    id: number;
}

interface HeadCell {
    id: keyof Data | any;
    label: string;
    disablePadding: boolean,
}

const headCells: HeadCell[] = [
    { id: 'Amount', label: 'Amount', disablePadding: true },
    { id: 'Status', label: 'Status', disablePadding: true },
    { id: 'Balance', label: 'Wallet Balance', disablePadding: true },
    { id: 'Message', label: 'Message', disablePadding: true },
];

interface EnhancedTableProps {
    classes: ReturnType<typeof useStyles>;
}

function EnhancedTableHead(props: EnhancedTableProps) {
    const { classes, } = props;

    return (
        <TableHead>
            <TableRow className="h-24">
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align='center'
                        className={classes.tableHead}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

interface IProps {
    dataProps?: [] | any
    limit: number
    setLimit: any
    offset: number
    setOffset: any
}

const EnhancedTable: React.FC<IProps> = ({ dataProps, limit, setLimit, offset, setOffset }) => {
    const data = dataProps?.data?.data
    const meta = dataProps?.data?.meta
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <TableContainer>
                <Table
                    className={classes.table}
                    aria-labelledby="tableTitle"
                    size='medium'
                    aria-label="enhanced table"
                >
                    <EnhancedTableHead
                        classes={classes}
                    />
                    <TableBody>
                        {data
                            .map((row: any, index: number) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={row.id}
                                        className="h-36"
                                    >

                                        <TableCell component="th" align="center" scope="row" className={classes.tableCell}>
                                        {row.amount?.toLocaleString('EN-US', { style: 'currency', currency: 'IDR' })}
                                        </TableCell>
                                        {row.type === 'debit' ?
                                            <TableCell align="center" className={classes.tableCell_status_selling}>{row?.type}</TableCell> : <TableCell align="center" className={classes.tableCell_status_waiting}>{row.type}</TableCell> }

                                        <TableCell align="center" className={classes.tableCell}>{row?.balance?.toLocaleString('EN-US', { style: 'currency', currency: 'IDR' })}</TableCell>
                                        <TableCell align="center" className={classes.tableCell}><h1 className="text-sm">{row?.description}</h1></TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination limit={limit} offset={offset} lengthData={data.length} metaTotal={meta.total} setLimit={setLimit} setOffset={setOffset} />
        </div >
    );
}

export default EnhancedTable

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: '3%',
            marginBottom: '3%'
        },
        table: {
            minWidth: 750,
        },
        searchInput: {
            width: '75%',
            padding: '0.5%',
            margin: '1% 10% 3% 0%',
        },
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        tableCell: {
            borderWidth: 3,
            borderColor: "#EFEFEF",
            fontSize: 14,
            width: '10%',
            fontFamily: 'NeueHaasDisplay'
        },
        tableCell_status_selling: {
            borderWidth: 3,
            borderColor: "#EFEFEF",
            fontSize: 14,
            width: '10%',
            color: '#00C62E',
            fontFamily: 'NeueHaasDisplayMediu'
        },
        tableCell_status_reject: {
            borderWidth: 3,
            borderColor: "#EFEFEF",
            fontSize: 14,
            width: '10%',
            color: '#DF5354',
            fontFamily: 'NeueHaasDisplayMediu'
        },
        tableCell_status_waiting: {
            borderWidth: 3,
            borderColor: "#EFEFEF",
            fontSize: 14,
            width: '10%',
            color: '#FFBA00',
            fontFamily: 'NeueHaasDisplayMediu'
        },
        tableHead: {
            borderWidth: 3,
            borderColor: "#EFEFEF",
            fontSize: 16,
            fontFamily: 'NeueHaasDisplayMediu'
        },
    }),
);