import { IRetailStatistic, IRetailMonthlyRevenue } from "../entities";
import IStatisticRepository from "../repository";
import IRetailStatisticUsecase from "../usecase";

export default class RetailStatisticUsecase implements IRetailStatisticUsecase {
  private statisticRepo: IStatisticRepository;

  constructor(statisticRepo: IStatisticRepository) {
    this.statisticRepo = statisticRepo;
  }

  async getRetailStatistic(): Promise<IRetailStatistic> {
    return await this.statisticRepo.getRetailStatistic();
  }

  async getRetailMonthlyRevenue(
    month: string,
    year: string
  ): Promise<IRetailMonthlyRevenue> {
    return await this.statisticRepo.getRetailMonthlyRevenue(month, year);
  }
}
