import React, { useState } from "react";
import { down, up } from "../../../../../assets/icons";
import Input from "../../../../../component/input/Input";

export interface ShippingProps {
  data: {
    shipping_provider: string;
    shipping_tracking_code: string;
    buyer_shipping_cost: number;
    buyer_shipping_address: {
      address: string;
      address_city_name: string;
      address_subdistrict_name: string;
      address_province_name: string;
      country: string;
      recipient: string;
      zip_code: string;
      additional_info: string;
      phone: string;
      name: string;
    };
  };
}

const Shipping: React.FC<ShippingProps> = ({ data }) => {
  const [dropdownShipping, setdropdownShipping] = useState<boolean>(true);

  const handledropdownShipping = () => {
    setdropdownShipping(!dropdownShipping);
  };

  return (
    <div className="w-full h-full my-8">
      <div className="rounded-md border-2 border-stroke">
        {/*Shipping information title and toggle*/}
        <div className="md:h-16 h-14 flex items-center justify-between md:mx-8 mx-5">
          <h1 className="font-medium md:text-xl text-base">
            Shipping Information
          </h1>

          <div className="flex">
            <div className="flex justify-between items-center">
              {dropdownShipping ? (
                <img
                  src={up}
                  alt="up"
                  className="duration-300 ease-in-out cursor-pointer md:w-5 w-4"
                  onClick={handledropdownShipping}
                />
              ) : (
                <img
                  src={down}
                  alt="up"
                  className="duration-300 ease-in-out cursor-pointer md:w-5 w-4"
                  onClick={handledropdownShipping}
                />
              )}
            </div>
          </div>
        </div>
        {dropdownShipping ? <hr className="border-t-2" /> : ""}

        {dropdownShipping ? (
          <div>
            <div className="md:flex w-full md:pt-5 pt-4">
              {/*Shipping data left*/}
              <div className="md:mx-8 mx-5 md:w-1/2">
                <div>
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Provider
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"shipping_provider"}
                    value={data.shipping_provider}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Shipping Cost
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"buyer_shipping_cost"}
                    value={data.buyer_shipping_cost.toLocaleString("EN-US", {
                      style: "currency",
                      currency: "IDR",
                    })}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Address
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"address"}
                    value={data.buyer_shipping_address.address}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Sub-District
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"address_subdistrict_name"}
                    value={data.buyer_shipping_address.address_subdistrict_name}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Country
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"country"}
                    value={data.buyer_shipping_address.country}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Recipient
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"recipient"}
                    value={data.buyer_shipping_address.recipient}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>

              {/*Shipping data right*/}
              <div className="md:mx-8 mx-5 md:w-1/2">
                <div>
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Tracking Code
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"shipping_tracking_code"}
                    value={data.shipping_tracking_code}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Address Alias
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"shipping_tracking_code"}
                    value={data.buyer_shipping_address.name}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">City</h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"address_city_name"}
                    value={data.buyer_shipping_address.address_city_name}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Province
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"address_city_name"}
                    value={data.buyer_shipping_address.address_province_name}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Phone
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"phone"}
                    value={data.buyer_shipping_address.phone}
                    placeholder={"Place holder"}
                  />
                </div>
                <div className="my-4">
                  <h1 className="md:text-base text-sm text-textPrimary">
                    Zip Code
                  </h1>
                  <Input
                    disable={true}
                    type={"text"}
                    name={"zip_code"}
                    value={data.buyer_shipping_address.zip_code}
                    placeholder={"Place holder"}
                  />
                </div>
              </div>
            </div>

            {/*additional Info*/}
            <div className="md:my-0 md:mb-2 my-4 md:mx-8 mx-5">
              <div>
                <h1 className="md:text-base text-sm text-textPrimary">
                  Additional Info
                </h1>
                <textarea
                  placeholder="Place holder"
                  disabled
                  value={data.buyer_shipping_address.additional_info}
                  className="md:h-28 h-24 w-full font-rom rounded-md md:text-sm text-xs my-3 px-3 py-2 border-2 border-stroke"
                ></textarea>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Shipping;
