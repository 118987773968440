import { FC } from "react";
import { IIconProps } from "./Ahome";

export const AfileArrowDown: FC<IIconProps> = ({
  stroke = "#666666",
  width = "18",
  height = "21",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.2 20.2H1.8C1.58783 20.2 1.38434 20.1157 1.23431 19.9657C1.08429 19.8157 1 19.6122 1 19.4V1.8C1 1.58783 1.08429 1.38434 1.23431 1.23431C1.38434 1.08429 1.58783 1 1.8 1H11.4L17 6.6V19.4C17 19.6122 16.9157 19.8157 16.7657 19.9657C16.6157 20.1157 16.4122 20.2 16.2 20.2Z"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.4 1V6.6H17"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.20001 13.3999L9.00001 16.1999L11.8 13.3999"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 9.7998V16.1998"
        stroke={stroke}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
