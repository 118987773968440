import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useNavigate, useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { listTrans } from "../../../../assets/icons";
import NewPagination from "../../../../component/pagination/NewPagination";

interface Data {
  id: number;
}

interface HeadCell {
  id: keyof Data | any;
  label: string;
  disablePadding: boolean;
}

const headCells: HeadCell[] = [
  { id: "Code", label: "Transaction Code", disablePadding: true },
  { id: "Customer", label: "Customer", disablePadding: true },
  { id: "Price", label: "Product Price", disablePadding: true },
  { id: "Quantity", label: "Quantity", disablePadding: true },
  { id: "Total", label: "Total Price", disablePadding: true },
  { id: "Adm", label: "Admin Fee", disablePadding: true },
  { id: "Cost", label: "Shipping Cost", disablePadding: true },
  { id: "Payout", label: "Seller Payout", disablePadding: true },
  { id: "Action", label: "Action", disablePadding: true },
];

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes } = props;

  return (
    <TableHead>
      <TableRow className="h-24">
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            className={classes.tableHead}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface IProps {
  dataProps?: any;
}

const EnhancedTable: React.FC<IProps> = ({ dataProps }) => {
  const navigate = useNavigate();
  const{ pathname } = useLocation();
  const classes = useStyles();

  const handleTransDetail = (code: string, type: string) => {
    if (type === "ticket") {
      navigate(`/admin/transaction-ticket/${code}`);
    } else {
      navigate(`${pathname}/${code}`);
    }
  };

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="enhanced table"
        >
          <EnhancedTableHead classes={classes} />
          <TableBody>
            {dataProps.data.data.map((row: any, index: number) => {
              return (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={row.id}
                  className="h-40"
                >
                  <TableCell
                    component="th"
                    align="center"
                    scope="row"
                    className={classes.tableCell}
                  >
                    {row.retail_transaction_code}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {row.buyer_username}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {row.price?.toLocaleString("EN-US", {
                      style: "currency",
                      currency: "IDR",
                    })}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {row.quantity}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {row.total?.toLocaleString("EN-US", {
                      style: "currency",
                      currency: "IDR",
                    })}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {row.admin_fee?.toLocaleString("EN-US", {
                      style: "currency",
                      currency: "IDR",
                    })}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {row.buyer_shipping_cost?.toLocaleString("EN-US", {
                      style: "currency",
                      currency: "IDR",
                    })}
                  </TableCell>
                  <TableCell align="center" className={classes.tableCell}>
                    {row.seller_payout?.toLocaleString("EN-US", {
                      style: "currency",
                      currency: "IDR",
                    })}
                  </TableCell>
                  <TableCell
                    padding="none"
                    align="center"
                    className={classes.tableCell}
                  >
                    <div className="flex justify-evenly">
                      <div>
                        <img
                          data-tip
                          data-for="Detail"
                          className="cursor-pointer w-6"
                          onClick={() =>
                            handleTransDetail(
                              row.retail_transaction_code,
                              row.retail_product_type
                            )
                          }
                          src={listTrans}
                          alt="img"
                        />
                        <ReactTooltip id="Detail" type="dark">
                          <span>Detail</span>
                        </ReactTooltip>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <NewPagination
        length={dataProps?.data?.data?.length}
        total={dataProps?.data?.meta?.total}
        isTable={true}
      />
    </div>
  );
};

export default EnhancedTable;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
      marginTop: "3%",
      marginBottom: "3%",
    },
    table: {
      minWidth: 750,
    },
    searchInput: {
      width: "75%",
      padding: "0.5%",
      margin: "1% 10% 3% 0%",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    tableCell: {
      borderWidth: 3,
      borderColor: "#EFEFEF",
      fontSize: 14,
      width: "10%",
      fontFamily: "NeueHaasDisplay",
    },
    tableHead: {
      borderWidth: 3,
      borderColor: "#EFEFEF",
      fontSize: 16,
      fontFamily: "NeueHaasDisplayMediu",
    },
  })
);
